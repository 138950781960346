import type { CommonSVGProps } from '../internal';

export const OutlookLogo = (props: Pick<CommonSVGProps, 'height' | 'width'>) => (
  <svg fill="none" height={16} width={18} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M15.05 1H6.454a.702.702 0 0 0-.702.702V2.5L10.597 4l5.155-1.5v-.798A.702.702 0 0 0 15.05 1Z"
        fill="#0364B8"
      />
      <path
        d="M16.577 8.703c.073-.23.132-.465.175-.703a.333.333 0 0 0-.166-.288l-.007-.004h-.002L11.158 4.62a.754.754 0 0 0-.406-.12.75.75 0 0 0-.406.12l-5.42 3.088h-.001l-.006.004A.333.333 0 0 0 4.752 8c.043.238.102.473.175.703l5.746 4.202 5.904-4.202Z"
        fill="#0A2767"
      />
      <path d="M12.753 2.5h-3.5L8.243 4l1.01 1.5 3.5 3h3v-3l-3-3Z" fill="#28A8EA" />
      <path d="M5.752 2.5h3.5v3h-3.5v-3Z" fill="#0078D4" />
      <path d="M12.752 2.5h3v3h-3v-3Z" fill="#50D9FF" />
      <path d="m12.752 8.5-3.5-3h-3.5v3l3.5 3 5.416.884L12.752 8.5Z" fill="#0364B8" />
      <path d="M9.252 5.5h3.5v3h-3.5v-3Z" fill="#0078D4" />
      <path d="M5.752 8.5h3.5v3h-3.5v-3Z" fill="#064A8C" />
      <path d="M12.752 8.5h3v3h-3v-3Z" fill="#0078D4" />
      <path
        d="m10.848 12.609-5.897-4.3.248-.435 5.454 3.106a.265.265 0 0 0 .21-.006l5.467-3.115.248.434-5.73 4.316Z"
        fill="#0A2767"
        opacity={0.5}
      />
      <path
        d="m16.585 8.289-.007.004h-.001l-5.42 3.088a.749.749 0 0 1-.73.045l1.888 2.53 4.127.899v.002a.749.749 0 0 0 .31-.607V8a.332.332 0 0 1-.167.289Z"
        fill="#1490DF"
      />
      <path
        d="M16.752 14.25v-.369l-4.992-2.844-.602.344a.749.749 0 0 1-.73.045l1.887 2.53 4.127.9v.001a.749.749 0 0 0 .31-.607Z"
        fill="#000"
        opacity={0.05}
      />
      <path
        d="m16.727 14.442-5.472-3.117-.097.055a.747.747 0 0 1-.73.046l1.887 2.53 4.127.9v.001a.75.75 0 0 0 .285-.415Z"
        fill="#000"
        opacity={0.1}
      />
      <path
        d="M4.927 8.295V8.29h-.005l-.015-.01A.325.325 0 0 1 4.752 8v6.25a.75.75 0 0 0 .75.75h10.5a.751.751 0 0 0 .275-.055.526.526 0 0 0 .145-.075c.01-.005.015-.005.02-.015L4.927 8.295Z"
        fill="#28A8EA"
      />
      <path
        d="M9.752 12.334V4.166a.668.668 0 0 0-.667-.666H5.767v3.728l-.84.479-.003.001-.006.003A.333.333 0 0 0 4.752 8v5h4.333a.668.668 0 0 0 .667-.666Z"
        fill="#000"
        opacity={0.1}
      />
      <path
        d="M9.252 12.834V4.666A.668.668 0 0 0 8.585 4H5.767v3.228l-.84.479-.003.001-.006.003A.333.333 0 0 0 4.752 8v5.5h3.833a.668.668 0 0 0 .667-.666Z"
        fill="#000"
        opacity={0.2}
      />
      <path
        d="M9.252 11.834V4.667A.668.668 0 0 0 8.585 4H5.767v3.228l-.84.479-.003.001-.006.003A.333.333 0 0 0 4.752 8v4.5h3.833a.668.668 0 0 0 .667-.666Z"
        fill="#000"
        opacity={0.2}
      />
      <path
        d="M8.752 11.834V4.667A.668.668 0 0 0 8.085 4H5.767v3.228l-.84.479-.003.001-.006.003A.333.333 0 0 0 4.752 8v4.5h3.333a.668.668 0 0 0 .667-.666Z"
        fill="#000"
        opacity={0.2}
      />
      <path
        d="M1.418 4h6.667a.667.667 0 0 1 .667.667v6.667a.666.666 0 0 1-.667.666H1.418a.666.666 0 0 1-.666-.666V4.667A.667.667 0 0 1 1.418 4Z"
        fill="#0078D4"
      />
      <path
        d="M2.685 6.734a2.09 2.09 0 0 1 .821-.907c.397-.227.848-.34 1.305-.327.423-.01.84.098 1.207.31.344.205.622.506.799.867.192.397.288.833.28 1.275.009.46-.09.917-.289 1.333a2.12 2.12 0 0 1-.822.897 2.4 2.4 0 0 1-1.253.318c-.432.01-.859-.098-1.234-.313a2.102 2.102 0 0 1-.809-.868 2.73 2.73 0 0 1-.283-1.26c-.009-.457.087-.91.278-1.325Zm.875 2.13c.095.238.255.445.462.596.21.148.464.224.721.218.275.01.545-.069.77-.225.206-.151.362-.36.45-.598.098-.267.146-.549.142-.833a2.531 2.531 0 0 0-.135-.843 1.334 1.334 0 0 0-.433-.62 1.194 1.194 0 0 0-.763-.236 1.246 1.246 0 0 0-.739.22 1.37 1.37 0 0 0-.472.6 2.388 2.388 0 0 0-.003 1.72Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M.656 0h16.688v16H.656z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
