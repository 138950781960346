import { useChangeEmailForm, useChangePasswordForm } from '@frontend/forms';
import { useAuthSlice } from '@frontend/react';
import {
  Button,
  Divider,
  Header,
  PageContainer,
  PasswordInput,
  Text,
  TextInput,
  XStack,
  YStack,
  showToast,
} from '@frontend/web-react';
import { safeCss } from '@frontend/web-utils';
import { useAuthentication, useChangeEmailMutation, useChangePasswordMutation } from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';
import { createFileRoute } from '@tanstack/react-router';

const INPUT_CLASS_NAME = safeCss('max-w-[500px] flex-1');

const EmailPassword = () => {
  const { storeToken } = useAuthSlice();
  const { logout } = useAuthentication();
  const [changeEmail, { isLoading: isChangeEmailLoading }] = useChangeEmailMutation();
  const {
    dirty: isChangeEmailDirty,
    getControl: getChangeEmailControl,
    handleSubmit: handleChangeEmailSubmit,
    isValid: isChangeEmailValid,
  } = useChangeEmailForm({
    onSubmit: (values, { resetForm }) =>
      handleMutation({
        mutation: async () =>
          changeEmail({
            email: values.email,
            password: values.password,
          }),
        onSuccess: ({ data: result }) => {
          showToast({
            title: `Email changed to ${result?.user.email}.`,
            variant: 'success',
          });
          if (result?.token) {
            storeToken(result.token);
            resetForm();
          }
        },
        showSuccessToast: false,
      }),
    validateOnMount: false,
  });

  const [changePassword, { isLoading: isChangePasswordLoading }] = useChangePasswordMutation();

  const {
    dirty: isChangePasswordDirty,
    getControl: getChangePasswordControl,
    handleSubmit: handleChangePasswordSubmit,
    isValid: isChangePasswordValid,
  } = useChangePasswordForm({
    onSubmit: (values, { resetForm }) => {
      handleMutation({
        mutation: () =>
          changePassword({ currentPassword: values.currentPassword, newPassword: values.newPassword }).unwrap(),
        onSuccess: async () => {
          resetForm();
          logout();
        },
        successMessage: 'Password reset successfully. Please log in again.',
      });
    },
    validateOnMount: false,
  });

  return (
    <PageContainer contentContainerClassName="px-lg" headerTitle="Change email" padding="none">
      <YStack gapY="lg">
        <YStack gapY="lg">
          <XStack className="flex-1" gapX="md">
            <TextInput
              {...getChangeEmailControl('email')}
              // eslint-disable-next-line tailwindcss/no-arbitrary-value
              className={INPUT_CLASS_NAME}
              label={'New Email'}
              placeholder="abc@xyz.com"
            />
            {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
            <PasswordInput {...getChangeEmailControl('password')} className={INPUT_CLASS_NAME} />
          </XStack>
          <XStack>
            <Button
              color="primary"
              disabled={!isChangeEmailValid || !isChangeEmailDirty}
              isLoading={isChangeEmailLoading}
              label="Change email"
              onClick={() => handleChangeEmailSubmit()}
              size="sm"
            />
          </XStack>
        </YStack>
        <Divider />
        <YStack gapY="lg">
          <YStack gapY="sm">
            <Header className="px-none" paddingHorizontal="none" title="Reset Password" />
            <Text color="tertiary" type="p-sm">
              {"You'll need to log in again after resetting your password."}
            </Text>
          </YStack>
          <PasswordInput
            className={INPUT_CLASS_NAME}
            {...getChangePasswordControl('currentPassword')}
            label="Current Password"
          />
          <PasswordInput
            className={INPUT_CLASS_NAME}
            {...getChangePasswordControl('newPassword')}
            label="New Password"
          />
          <PasswordInput
            className={INPUT_CLASS_NAME}
            {...getChangePasswordControl('newPasswordConfirmation')}
            label="Confirm new Password"
          />
        </YStack>
        <XStack>
          <Button
            color="primary"
            disabled={!isChangePasswordValid || !isChangePasswordDirty}
            isLoading={isChangePasswordLoading}
            label="Change password"
            onClick={() => handleChangePasswordSubmit()}
            size="sm"
          />
        </XStack>
      </YStack>
    </PageContainer>
  );
};

export const Route = createFileRoute('/app/settings/email-password')({
  component: EmailPassword,
});
