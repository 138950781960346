import { getInitials } from '@frontend/api-client';
import { type Client } from '@frontend/api-types';
import { safeCss } from '@frontend/web-utils';
import type { AvatarProps as MAvatarProps } from '@mantine/core';
import { Avatar as MAvatar } from '@mantine/core';

export type AvatarProps = Omit<MAvatarProps, 'variant'> & {
  initials?: string;
  userInfo?: Client;
};

export const Avatar = ({ initials, userInfo, ...props }: AvatarProps) => (
  <MAvatar
    classNames={{
      placeholder: safeCss('bg-surfaceBrand text-captionBold text-textAccent'),
      root: safeCss('bg-surfaceBrand p-xs'),
    }}
    color="blue"
    radius="xl"
    size="md"
    variant="filled"
    {...props}
  >
    {initials || (userInfo && getInitials(userInfo)) || ''}
  </MAvatar>
);
