import { AppScreenshot } from '@frontend/assets';
import { appStoreLinks } from '@frontend/constants';
import { useEffect } from '@frontend/react';
import {
  AppStoresButton,
  Button,
  Card,
  DownloadAppQRCode,
  Icon,
  Image,
  ScrollableContentPanel,
  Text,
  XStack,
  YStack,
  isAndroid,
  isIOS,
} from '@frontend/web-react';
import { useAnalytics } from '@frontend/web/hooks';
import { useNavigate } from '@tanstack/react-router';

export const DownloadAppAndComplete = () => {
  const { track } = useAnalytics();
  const navigate = useNavigate();

  useEffect(() => {
    // window.open seems to not work without user interaction
    if (isIOS) {
      window.location.replace(appStoreLinks.ios);
    } else if (isAndroid) {
      window.location.replace(appStoreLinks.android);
    }
  }, []);

  return (
    <XStack className="md:h-screen bg-surfacePrimary flex-col md:flex-row">
      <ScrollableContentPanel
        footer={
          <YStack className="w-3/4 self-end hidden md:flex pb-md">
            <Button label="Finish" onClick={() => navigate({ search: { tab: 'waiting_on_you' }, to: '/app' })} />
          </YStack>
        }
        justifyContent="start"
      >
        <YStack className="w-full md:w-3/4 self-end" gapY="lg">
          <YStack gapY="sm">
            <Text color="secondary" type="captionBold">
              For the best experience
            </Text>
            <Text type="h4">Download our mobile app</Text>
            <XStack alignItems="center" gapX="sm">
              <Icon color="secondary" name="IconCheck" />
              <Text color="secondary" type="h6">
                {"You'll get notifications as soon as your tasks have an update"}
              </Text>
            </XStack>
            <XStack alignItems="center" gapX="sm">
              <Icon color="secondary" name="IconCheck" />
              <Text color="secondary" type="h6">
                Get personalized task suggestions
              </Text>
            </XStack>
            <XStack alignItems="center" gapX="sm">
              <Icon color="secondary" name="IconCheck" />
              <Text color="secondary" type="h6">
                Early access to our latest & greatest features
              </Text>
            </XStack>
            <XStack className="md:hidden mt-md" gapX="md">
              <AppStoresButton
                onClick={() => track({ event: 'iosDownloadLinkSelected', topic: 'onboarding' })}
                platform="ios"
              />
              <AppStoresButton
                onClick={() => track({ event: 'androidDownloadLinkSelected', topic: 'onboarding' })}
                platform="android"
              />
            </XStack>
          </YStack>
          <YStack className="hidden md:flex" gapY="md">
            <Text color="secondary" textAlign="center">
              Scan one of the QR codes below, or search{' '}
              <Text className="font-bold" color="secondary" span>
                Duckbill
              </Text>{' '}
              in the App Store.
            </Text>
            <YStack gapY="lg">
              <YStack className="flex-1 grid grid-cols-1 xl:grid-cols-2 gap-md place-items-center">
                <Card
                  // eslint-disable-next-line tailwindcss/no-arbitrary-value
                  className="max-w-[300px] w-full gap-y-md shadow-one items-center justify-center"
                  padding="md"
                  withBorder
                >
                  <DownloadAppQRCode platform="ios" />
                  <Text type="captionBold">Scan to download</Text>
                  <AppStoresButton
                    onClick={() => track({ event: 'iosDownloadLinkSelected', topic: 'onboarding' })}
                    platform="ios"
                  />
                </Card>
                <Card
                  // eslint-disable-next-line tailwindcss/no-arbitrary-value
                  className="max-w-[300px] w-full gap-y-md shadow-one items-center justify-center"
                  padding="md"
                  withBorder
                >
                  <DownloadAppQRCode platform="android" />
                  <Text type="captionBold">Scan to download</Text>
                  <AppStoresButton
                    onClick={() => track({ event: 'androidDownloadLinkSelected', topic: 'onboarding' })}
                    platform="android"
                  />
                </Card>
              </YStack>
            </YStack>
          </YStack>
          <YStack className="flex-1 justify-center md:hidden">
            <Image
              // eslint-disable-next-line tailwindcss/no-arbitrary-value
              className="object-contain h-[41.3rem] w-[26.125rem]"
              source={AppScreenshot}
            />
          </YStack>
        </YStack>
      </ScrollableContentPanel>
      <YStack className="flex-1 justify-center items-center hidden md:flex">
        <Image
          // eslint-disable-next-line tailwindcss/no-arbitrary-value
          className="object-contain h-[41.3rem] w-[26.125rem]"
          source={AppScreenshot}
        />
      </YStack>
    </XStack>
  );
};
