import { match } from '@frontend/duck-tape';
import { AppShell, Wordmark, YStack } from '@frontend/web-react';
import type { ClassName } from '@frontend/web-utils';
import { applyClass, concatClasses, safeCss } from '@frontend/web-utils';
import { NavLinkWrapper } from '../NavLinks';

export type UnauthenticatedAppHeaderProps = {
  asAppShell?: boolean;
  className?: ClassName;
  color?: 'blue' | 'yellow';
  isHidden?: boolean;
  transparent?: boolean;
};
export const UnauthenticatedAppHeader = ({
  asAppShell = true,
  className,
  color = 'blue',
  isHidden = false,
  transparent = true,
}: UnauthenticatedAppHeaderProps) => {
  const parentContainerClass = concatClasses(
    'px-sm flex',
    applyClass(transparent, 'bg-transparent'),
    applyClass(isHidden, 'hidden'),
    className,
  );

  const mainContent = () => (
    <YStack className={concatClasses(parentContainerClass, 'py-sm')}>
      <YStack className="p-md" justifyContent="center">
        <NavLinkWrapper to="/login">
          <Wordmark
            className={match(color)
              .with('blue', () => safeCss('fill-textPrimary'))
              .with('yellow', () => safeCss('fill-textAccent'))
              .exhaustive()}
            height={20}
            width={90}
          />
        </NavLinkWrapper>
      </YStack>
    </YStack>
  );

  return asAppShell ? (
    <AppShell.Header className={concatClasses(parentContainerClass, 'items-center')} withBorder={false}>
      {mainContent()}
    </AppShell.Header>
  ) : (
    mainContent()
  );
};
