import { getInitials } from '@frontend/api-client';
import type { Recurrence, TaskDetail } from '@frontend/api-types';
import { partition } from '@frontend/duck-tape';
import { isImage, makeSavedFileList } from '@frontend/forms';
import { useEffect, useMemo, useState } from '@frontend/react';
import { useVisibilityController } from '@frontend/react/hooks';
import { Divider, LinkButton, Modal, XStack, YStack, type CommonModalProps } from '@frontend/web-react';
import { applyClass, concatClasses } from '@frontend/web-utils';
import {
  InviteHouseholdMemberModal,
  TaskChatImageThumbnail,
  TaskChatPdfThumbnail,
  TaskChatSecureNoteCard,
} from '@frontend/web/components';
import { TaskChatOptionSheetCard } from '@frontend/web/components/DomainSpecific/Tasks/TaskChatOptionSheetCard';
import DetailItemContainer from '@frontend/web/components/DomainSpecific/Tasks/TaskDetailModal/DetailItemContainer';
import { MemberAccess } from '@frontend/web/components/DomainSpecific/Tasks/TaskDetailModal/MemberAccess';
import {
  useLazyRetrieveHouseholdQuery,
  useRetrieveClientQuery,
  useShareRecurrenceMutation,
  useShareTaskMutation,
  useUnshareRecurrenceMutation,
  useUnshareTaskMutation,
} from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';

export type TaskDetailModal = CommonModalProps & {
  recurrence?: Recurrence;
  task?: TaskDetail;
};

export const TaskDetailModal = ({ isOpen, onClose, recurrence, task }: TaskDetailModal) => {
  const { data: client } = useRetrieveClientQuery();
  const [retrieveHousehold, { data: household }] = useLazyRetrieveHouseholdQuery();

  const isRecurrence = !!recurrence;
  const detail = isRecurrence ? recurrence : task;

  const otherHouseholdMembers = household?.members.filter(
    ({ id: memberId }) => memberId !== detail?.ownerId && memberId !== client?.id,
  );

  const [sharedWithClientIds, setSharedWithClientIds] = useState(detail?.sharedWith || []);
  const [shareTask] = useShareTaskMutation();
  const [unshareTask] = useUnshareTaskMutation();
  const [shareRecurrence] = useShareRecurrenceMutation();
  const [unshareRecurrence] = useUnshareRecurrenceMutation();

  const [isCreateInviteModalOpen, { close: closeCreateInviteModal, open: openCreateInviteModal }] =
    useVisibilityController();

  const [images, pdfs] = useMemo(
    () =>
      partition(
        makeSavedFileList(detail?.attachments || [], (a) => ({ ...a, key: a.fileName, url: a.contentUrl })),
        (file) => isImage(file),
      ),
    [detail?.attachments],
  );

  useEffect(() => {
    setSharedWithClientIds(detail?.sharedWith || []);
  }, [detail?.sharedWith]);

  useEffect(() => {
    if (client?.household) {
      retrieveHousehold(client.household);
    }
  }, []);

  const handleAccess = (clientId: string) => {
    if (sharedWithClientIds.includes(clientId)) {
      handleMutation({
        mutation: async () => {
          if (isRecurrence) {
            return unshareRecurrence({ clientId, id: detail?.id as string }).unwrap();
          } else {
            return unshareTask({ clientId, id: detail?.id as string }).unwrap();
          }
        },
        showSuccessToast: false,
      });
      setSharedWithClientIds(sharedWithClientIds.filter((sharedId) => sharedId !== clientId));
    } else {
      handleMutation({
        mutation: async () => {
          if (isRecurrence) {
            return shareRecurrence({ clientId, id: detail?.id as string }).unwrap();
          } else {
            return shareTask({ clientId, id: detail?.id as string }).unwrap();
          }
        },
        showSuccessToast: false,
      });
      setSharedWithClientIds([...sharedWithClientIds, clientId]);
    }
  };

  const getMemberInitials = (name: string, lastName: string) =>
    `${name.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;

  const taskOwnerInitials = useMemo(() => {
    if (client && detail?.ownerId === client?.id) {
      return getInitials(client);
    } else {
      const member = household?.members.find(({ id }) => id === detail?.ownerId);
      return member ? getMemberInitials(member.name, member.lastName) : '';
    }
  }, [detail?.ownerId, client, household]);

  const taskOwnerName = useMemo(() => {
    if (client && detail?.ownerId === client?.id) {
      return `${client?.nickname || client?.firstName} (You)`;
    } else {
      const member = household?.members.find(({ id }) => id === detail?.ownerId);
      return member?.name || '';
    }
  }, [detail?.ownerId, client, household]);

  const isRightSideVisible = !!(detail?.sheets?.length || detail?.secureNotes?.length);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={isRightSideVisible ? 'xl' : 'md'}>
      <XStack className="gap-y-md">
        <YStack
          className={concatClasses(
            'gap-lg',
            applyClass(isRightSideVisible, 'border-r-1 border-r-borderPrimary w-1/2 pr-md', 'w-full'),
          )}
        >
          {client?.household && household && detail ? (
            <DetailItemContainer title="Who has access">
              <YStack className="gap-md">
                {/* Render the task owner */}
                <MemberAccess
                  checked
                  disabled
                  initials={taskOwnerInitials}
                  isClient={detail?.ownerId === client?.id}
                  isOwner
                  name={taskOwnerName}
                />
                {/* Render the client if not the task owner */}
                {detail.ownerId !== client.id && detail.sharedWith.includes(client.id) ? (
                  <MemberAccess
                    checked={sharedWithClientIds.includes(client.id)}
                    disabled
                    initials={getInitials(client)}
                    isClient
                    name={`${client.nickname || client.firstName} (You)`}
                  />
                ) : null}
                {/* Render the household members excluding the task owner */}
                {otherHouseholdMembers?.map(({ id: memberId, lastName, name }) => (
                  <MemberAccess
                    checked={sharedWithClientIds.includes(memberId)}
                    disabled={client.id !== detail.ownerId}
                    initials={getMemberInitials(name, lastName)}
                    key={memberId}
                    name={name}
                    onChange={() => handleAccess(memberId)}
                  />
                ))}
              </YStack>
              {household && !household.members.length ? (
                <XStack className="justify-end">
                  <LinkButton
                    label="Invite"
                    leftIconName="IconPlus"
                    leftIconProps={{ size: 'md' }}
                    onClick={openCreateInviteModal}
                    type="h6"
                  />
                </XStack>
              ) : null}
            </DetailItemContainer>
          ) : null}
          {detail?.attachments?.length ? <Divider className="border-b-borderPrimary border-b-1" size={0} /> : null}
          {detail?.attachments?.length ? (
            <DetailItemContainer title={`Attachments (${detail.attachments.length})`}>
              <YStack gap="sm">
                <XStack className="flex-wrap gap-sm">
                  {images?.length
                    ? images.map((file) => (
                        <TaskChatImageThumbnail file={file} imageClassName="size-[83px] rounded-sm" key={file.key} />
                      ))
                    : null}
                </XStack>
                <XStack className="flex-wrap gap-sm">
                  {pdfs?.length ? pdfs.map((file) => <TaskChatPdfThumbnail file={file} key={file.id} />) : null}
                </XStack>
              </YStack>
            </DetailItemContainer>
          ) : null}
        </YStack>
        {isRightSideVisible ? (
          <YStack className="w-1/2 gap-md pl-md">
            {detail?.secureNotes?.length ? (
              <DetailItemContainer title={`Secure info (${detail.secureNotes.length})`}>
                <YStack className="gap-md">
                  {detail.secureNotes.map(({ id: secureNoteId, isActive, title }) => (
                    <TaskChatSecureNoteCard
                      isActive={isActive}
                      key={secureNoteId}
                      secureNoteId={secureNoteId}
                      taskId={detail.id}
                      title={title}
                    />
                  ))}
                </YStack>
              </DetailItemContainer>
            ) : null}
            {detail?.sheets?.length && detail?.secureNotes?.length ? (
              <Divider className="border-b-borderPrimary border-b-1" size={0} />
            ) : null}
            {detail?.sheets?.length ? (
              <DetailItemContainer title={`Options (${detail.sheets.length})`}>
                {detail.sheets.map(({ id: optionSheetId, imageUrl, title }) => (
                  <TaskChatOptionSheetCard
                    cardClassName="w-full"
                    imageUrl={imageUrl}
                    key={optionSheetId}
                    optionSheetId={optionSheetId}
                    title={title}
                  />
                ))}
              </DetailItemContainer>
            ) : null}
          </YStack>
        ) : null}
      </XStack>
      {household ? (
        <InviteHouseholdMemberModal
          householdId={household.id}
          isOpen={isCreateInviteModalOpen}
          onClose={closeCreateInviteModal}
        />
      ) : null}
    </Modal>
  );
};
