import type { Spacing } from '@frontend/constants';
import { isString, match } from '@frontend/duck-tape';
import type { ReactNode } from '@frontend/react';
import type { ClassName } from '@frontend/web-utils';
import { applyClass, concatClasses, safeCss } from '@frontend/web-utils';
import { XStack, YStack } from '../Containers';
import { Icon } from '../Icon/Icon';
import type { IconName } from '../Icon/helpers';
import type { TextProps } from '../Texts/Text';
import { Text } from '../Texts/Text';

export type HeaderProps = {
  children?: ReactNode;
  className?: ClassName;
  isLoading?: boolean;
  onClickBack?: () => void;
  onClickRightIcon?: () => void;
  paddingHorizontal?: Spacing;
  rightContent?: ReactNode;
  rightIconName?: IconName;
  title: ReactNode;
  titleIconName?: IconName;
  titleSize?: TextProps['type'];
  translucent?: boolean;
};

export const Header = ({
  children,
  className,
  isLoading = false,
  onClickBack,
  onClickRightIcon,
  paddingHorizontal = 'lg',
  rightContent,
  rightIconName,
  title,
  titleIconName,
  titleSize = 'h5',
  translucent = false,
}: HeaderProps) => {
  const paddingHorizontalClassName = getHorizontalPaddingClassName(paddingHorizontal);
  return (
    <YStack className={concatClasses(applyClass(translucent, 'absolute'), 'w-full', className)}>
      {translucent ? (
        <div
          className="absolute p-lg size-full items-center flex flex-col gap-x-md"
          style={{
            backgroundColor: 'rgba(255,255,255,0.8)',
          }}
        />
      ) : null}
      <XStack
        alignItems="start"
        className={concatClasses('flex-1 w-full p-lg z-10', paddingHorizontalClassName)}
        gapX="md"
      >
        {onClickBack ? <Icon name="IconArrowLeft" onClick={onClickBack} size="lg" /> : null}
        {isLoading ? null : (
          <YStack className="flex-1" gapY="sm">
            {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
            <XStack alignItems="center" className="flex-1 mt-[4px]" justifyContent="spaceBetween">
              <XStack alignItems="center" className="flex flex-1 gap-x-sm">
                {isString(title) ? (
                  <Text lineClamp={2} type={titleSize}>
                    {title}
                  </Text>
                ) : (
                  title
                )}
                {titleIconName ? <Icon name={titleIconName} /> : null}
              </XStack>
            </XStack>
            {children}
          </YStack>
        )}
        {isLoading || !rightIconName ? null : <Icon name={rightIconName} onClick={onClickRightIcon} size="lg" />}
        {rightContent}
      </XStack>
    </YStack>
  );
};

export const getHorizontalPaddingClassName = (padding: Spacing) =>
  match(padding)
    .with('none', () => safeCss('px-none'))
    .with('xs', () => safeCss('px-xs'))
    .with('sm', () => safeCss('px-sm'))
    .with('md', () => safeCss('px-md'))
    .with('lg', () => safeCss('px-lg'))
    .with('xl', () => safeCss('px-xl'))
    .with('xxl', () => safeCss('px-xxl'))
    .with('xxxl', () => safeCss('px-xxxl'))
    .with('1', () => safeCss('px-[1px]'))
    .exhaustive();
