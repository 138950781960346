import type { NotificationType } from '@frontend/api-types';

export const getNotificationCategories = (platform: DuckbillPlatform) => [
  {
    category: 'taskUpdates',
    // We don't render push notifications toggling on web to avoid confusing users
    options: (platform === 'web' ? ['sms', 'email'] : ['push', 'sms', 'email']) as NotificationType[],
    subtitle: 'Action-needed updates, options ready and copilot messages',
    title: 'Task related updates',
  },
  {
    category: 'nudges',
    options: ['sms'] as NotificationType[],
    subtitle: 'Recommended tasks based on your preference',
    title: 'Nudges & suggested tasks',
  },
  {
    category: 'birthdays',
    options: ['sms'] as NotificationType[],
    subtitle: "We'll remind you about when your loved ones' birthday is approaching — along with suggested gifts",
    title: 'Birthday reminders',
  },
];
