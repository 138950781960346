import { extractApiError } from '@frontend/duck-tape';
import type { CreateInviteFormSchema } from '@frontend/forms';
import { useEditProfileForm } from '@frontend/forms/settings/editProfileForm';
import type { CommonModalProps } from '@frontend/web-react';
import { Button, DateInput, Icon, Modal, PhoneNumberInput, TextInput, YStack, showToast } from '@frontend/web-react';
import { useEditProfileMutation, useRetrieveClientQuery } from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';

export const UpdateProfileModal = ({ isOpen, onClose }: CommonModalProps) => {
  const { data: client } = useRetrieveClientQuery();
  const [editProfile, { isLoading }] = useEditProfileMutation();

  const handleEditProfile = ({ email: _email, ...rest }: CreateInviteFormSchema) => {
    handleMutation({
      mutation: () => editProfile(rest).unwrap(),
      onError: (error) => {
        const errorString = extractApiError(error);
        const uniquenessError = errorString?.toLowerCase().includes('phone number: this field must be unique');
        return showToast({
          title: uniquenessError ? 'This phone number is already in use.' : errorString,
          variant: 'danger',
        });
      },
      onSuccess: onClose,
      showErrorToast: false,
      successMessage: 'Profile updated',
    });
  };
  const { dirty, getControl, handleSubmit, isValid } = useEditProfileForm({
    initialValues: {
      // @ts-expect-error Tedious types
      birthday: client?.birthday || '',
      email: client?.email || '',
      firstName: client?.firstName || '',
      lastName: client?.lastName || '',
      nickname: client?.nickname || '',
      phoneNumber: client?.phoneNumber || '',
    },
    onSubmit: handleEditProfile,
    validateOnMount: false,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Edit profile" titlesAlignment="start">
      <form onSubmit={handleSubmit}>
        <YStack gapY="lg">
          <YStack gapY="md">
            <TextInput {...getControl('firstName')} label="First name" />
            <TextInput {...getControl('lastName')} label="Last name" />
            <TextInput {...getControl('nickname')} label="Nickname" />
            <DateInput
              maxDate={new Date()}
              type="default"
              {...getControl('birthday')}
              label="Birthday"
              rightSection={<Icon className="mr-md" color="tertiary" name="IconCalendarEvent" size={24} />}
            />
            <PhoneNumberInput {...getControl('phoneNumber')} />
            <TextInput {...getControl('email')} disabled label="Email" />
            <Button disabled={!isValid || !dirty} fullWidth isForm isLoading={isLoading} label="Submit" />
          </YStack>
        </YStack>
      </form>
    </Modal>
  );
};
