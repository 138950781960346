import { Anchor, Icon, Text, XStack } from '@frontend/web-react';

export const PrivacyPolicyBanner = () => {
  return (
    <XStack alignItems="center" gapX="xs">
      <Icon name="IconShieldLock" />
      <Text color="primary" type="p-md">
        Covered by
      </Text>
      <Anchor href="https://www.getduckbill.com/privacy-policy" newTab type="h5" withUnderline>
        Privacy Policy
      </Anchor>
    </XStack>
  );
};
