import { contactUrl, privacyUrl, termsUrl } from '@frontend/constants';
import { useVisibilityController } from '@frontend/react';
import { Text, XStack, YStack, isMobile, useIsOnMobile, useIsOnTablet } from '@frontend/web-react';
import { applyClass } from '@frontend/web-utils';
import { ContactSupportModal, Outlet, SidebarAnchor, SidebarButton, SidebarNavLink } from '@frontend/web/components';
import { useAnalytics, useAuthentication } from '@frontend/web/hooks';
import { createFileRoute } from '@frontend/web/utils';
import { useMatchRoute } from '@tanstack/react-router';

const Settings = () => {
  const { logout } = useAuthentication();
  const { track } = useAnalytics();
  const [isContactModalOpen, { close: closeContactModal, open: openContactModal }] = useVisibilityController();
  const isOnMobile = useIsOnMobile();
  const isOnTablet = useIsOnTablet();
  const isCollapsed = isOnMobile || isOnTablet;

  const openContactUrl = () => window.open(contactUrl);

  const handleLogout = () => {
    track({ event: 'logout', topic: 'auth' });
    logout();
  };

  const matchRoute = useMatchRoute();

  return (
    <XStack className="flex-1">
      {isMobile ? null : (
        // eslint-disable-next-line tailwindcss/no-arbitrary-value
        <YStack className="lg:min-w-[250px] gap-y-xs bg-white px-lg py-xxl border-r-1 border-r-borderPrimary">
          <YStack className="gap-y-xl">
            <YStack className="gap-xs">
              {isCollapsed ? null : (
                <Text className="mb-sm" type="captionBold">
                  Account Settings
                </Text>
              )}
              <SidebarNavLink
                exact
                isActive={!!matchRoute({ to: '/app/settings' })}
                isCollapsed={isCollapsed}
                label="Profile"
                leftIconName="IconUserCircle"
                to="/app/settings"
              />
              <SidebarNavLink
                isActive={!!matchRoute({ to: '/app/settings/notifications' })}
                isCollapsed={isCollapsed}
                label="Notifications"
                leftIconName="IconBell"
                to="/app/settings/notifications"
              />
              <SidebarNavLink
                isActive={!!matchRoute({ to: '/app/settings/email-password' })}
                isCollapsed={isCollapsed}
                label="Email & Password"
                leftIconName="IconMail"
                to="/app/settings/email-password"
              />
              <SidebarNavLink
                isActive={!!matchRoute({ to: '/app/settings/payments' })}
                isCollapsed={isCollapsed}
                label="Payments"
                leftIconName="IconCreditCard"
                to="/app/settings/payments"
              />
            </YStack>
            <YStack className="gap-xs">
              {isCollapsed ? null : (
                <Text className="mb-sm" type="captionBold">
                  Level Up
                </Text>
              )}
              <SidebarNavLink
                isActive={!!matchRoute({ to: '/app/settings/calendar' })}
                isCollapsed={isCollapsed}
                label="Calendar"
                leftIconName="IconCalendarEvent"
                to="/app/settings/calendar"
              />
              <SidebarNavLink
                isActive={!!matchRoute({ to: '/app/settings/download-app' })}
                isCollapsed={isCollapsed}
                label="Download App"
                leftIconName="IconDownload"
                to="/app/settings/download-app"
              />
            </YStack>
            <YStack className="gap-xs">
              {isCollapsed ? null : (
                <Text className="mb-sm" type="captionBold">
                  Talk To Us
                </Text>
              )}
              <SidebarNavLink
                isActive={!!matchRoute({ to: '/app/settings/feedback' })}
                isCollapsed={isCollapsed}
                label="Feedback"
                leftIconName="IconMessage"
                to="/app/settings/feedback"
              />
              {/* On web, sometimes the mail client won't open automatically, so we should tell them where to manually send an email to */}
              <SidebarButton
                className={applyClass(isCollapsed, 'justify-center')}
                isCollapsed={isCollapsed}
                label="Email Duckbill"
                leftIconName="IconMail"
                onClick={isMobile ? openContactUrl : openContactModal}
              />
            </YStack>
            <YStack className="gap-xs">
              {isCollapsed ? null : (
                <Text className="mb-sm" type="captionBold">
                  The Nitty Gritty
                </Text>
              )}
              <SidebarAnchor
                className={applyClass(isCollapsed, 'justify-center')}
                href={termsUrl}
                isCollapsed={isCollapsed}
                label="Terms of Use"
                leftIconName="IconCircleArrowUpRight"
                target="_blank"
              />
              <SidebarAnchor
                className={applyClass(isCollapsed, 'justify-center')}
                href={privacyUrl}
                isCollapsed={isCollapsed}
                label="Privacy Policy"
                leftIconName="IconCircleArrowUpRight"
                target="_blank"
              />
            </YStack>
            <YStack className="gap-xs">
              <SidebarButton
                className={applyClass(isCollapsed, 'justify-center')}
                color="danger"
                isCollapsed={isCollapsed}
                label="Logout"
                leftIconName="IconLogout"
                onClick={handleLogout}
              />
            </YStack>
          </YStack>
        </YStack>
      )}
      <YStack className="w-full pt-md">
        <Outlet />
      </YStack>
      <ContactSupportModal isOpen={isContactModalOpen} onClose={closeContactModal} />
    </XStack>
  );
};

export const Route = createFileRoute('/app/settings')({
  component: Settings,
});
