import type { TaskChatAuthorInfo } from '@frontend/api-client';
import type { TaskComment } from '@frontend/api-types';
import { match, partition } from '@frontend/duck-tape';
import { isImage, makeSavedFileList } from '@frontend/forms';
import { BlinkingDot, RenderHTML, Text, XStack, YStack } from '@frontend/web-react';
import { TaskChatImageThumbnail } from '@frontend/web/components/DomainSpecific/Tasks/Chat/TaskChatImageThumbnail';
import { TaskChatAuthor } from '@frontend/web/components/DomainSpecific/Tasks/Chat/TaskChatMessageBubble/TaskChatAuthor';
import { TaskChatPdfThumbnail } from '@frontend/web/components/DomainSpecific/Tasks/Chat/TaskChatPdfThumbnail';
import Markdown from 'react-markdown';

type TaskChatMessageBubbleProps = Pick<TaskComment, 'attachments' | 'body'> & {
  authorInfo: TaskChatAuthorInfo;
  contentType?: 'html' | 'markdown' | 'plain';
  isAuthorVisible: boolean;
  isStreaming?: boolean;
};

export const TaskChatMessageBubble = ({
  attachments,
  authorInfo,
  body,
  contentType = 'html',
  isAuthorVisible,
  isStreaming,
}: TaskChatMessageBubbleProps) => {
  const [images, pdfs] = partition(
    makeSavedFileList(attachments, (a) => ({ ...a, key: a.fileName, url: a.contentUrl })),
    (file) => isImage(file),
  );
  return (
    <YStack gapY="md">
      {isAuthorVisible ? <TaskChatAuthor {...authorInfo} /> : null}
      <YStack className="pl-xl" gapY="md">
        <YStack>
          {match(contentType)
            .with('html', () => <RenderHTML html={body} />)
            .with('markdown', () => <Markdown className={'duckbill-markdown prose max-w-full'}>{body}</Markdown>)
            .with('plain', () => <Text>{body}</Text>)
            .exhaustive()}
          {isStreaming ? <BlinkingDot /> : null}
        </YStack>
        {images.length || pdfs.length ? (
          <XStack gapX="md">
            {images.map((file) => (
              <TaskChatImageThumbnail file={file} key={file.key} />
            ))}
            {pdfs.map((file) => (
              <TaskChatPdfThumbnail file={file} key={file.id} />
            ))}
          </XStack>
        ) : null}
      </YStack>
    </YStack>
  );
};
