import type { SelectOption } from '@frontend/forms';
import { useState } from '@frontend/react';
import type { CommonModalProps } from '@frontend/web-react';
import { Button, Clickable, Icon, Modal, Tag, Text, TextareaInput, XStack, YStack } from '@frontend/web-react';
import type { IconName } from '@frontend/web-react/components/Primitives/Icon/helpers';
import { applyClass, concatClasses } from '@frontend/web-utils';
import { useCreateGeneralFeedbackMutation } from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';

export type CalendarSkipReasonModalProps = CommonModalProps & {
  onSuccess?: () => void;
};

const calendarSkipReasons = [
  { label: 'Not comfortable sharing information', value: 'Not comfortable sharing information' },
  { label: "I don't use Google calendar", value: "I don't use Google calendar" },
  { label: 'Other', value: 'Other' },
] as const satisfies SelectOption<string, never, { label: string }>[];

const requestedCalendars = [
  { iconName: 'OutlookLogo', label: 'Outlook', value: 'Outlook' },
  { iconName: 'AppleLogo', label: 'Apple Calendar', value: 'Apple Calendar' },
  { label: 'Other', value: 'Other' },
] as const satisfies SelectOption<string, never, { iconName?: IconName; label: string }>[];

export const CalendarSkipReasonModal = ({ isOpen, onClose, onSuccess }: CalendarSkipReasonModalProps) => {
  const [createGeneralFeedback, { isLoading }] = useCreateGeneralFeedbackMutation();

  const [reason, setReason] = useState<string>('');
  const [otherExplanation, setOtherExplanation] = useState<string>('');
  const [requestedCalendar, setRequestedCalendar] = useState<string>('');

  const isFormValid =
    reason &&
    (reason === "I don't use Google calendar" ? requestedCalendar : true) &&
    (reason === 'Other' ? otherExplanation : true);

  const onSubmit = async () => {
    const feedbackString = `Calendar skip reason: ${reason}\n${reason === 'Other' ? `Explanation: ${otherExplanation ?? 'None'}\n` : ''}Requested calendar: ${requestedCalendar}`;
    return handleMutation({
      mutation: () => {
        return createGeneralFeedback({
          feedbackSource: 'web',
          text: feedbackString,
        }).unwrap();
      },
      onSuccess: () => {
        onSuccess?.();
        onClose();
      },
      showSuccessToast: false,
    });
  };

  return (
    <Modal
      headerGap="md"
      isOpen={isOpen}
      onClose={onClose}
      title="Can you let us know why you skipped?"
      titlesAlignment="start"
    >
      <YStack gapY="md">
        <YStack gap="sm">
          {calendarSkipReasons.map((option) => (
            <XStack key={option.value}>
              <Tag
                isActive={option.value === reason}
                label={option.label}
                onClick={() => {
                  if (reason !== 'Other') {
                    setOtherExplanation('');
                  }
                  return setReason(option.value);
                }}
              />
            </XStack>
          ))}
          {reason === 'Other' ? (
            <TextareaInput
              label=""
              onChange={(explanation) => setOtherExplanation(explanation)}
              placeholder="Let us know the reason"
              value={otherExplanation}
            />
          ) : null}
        </YStack>
        {reason === "I don't use Google calendar" ? (
          <YStack gapY="sm">
            <Text type="captionBold">Request other calendar</Text>
            <XStack flexWrap="wrap" gapX="md" gapY="sm">
              {requestedCalendars.map((calendar) => (
                <Clickable
                  className={concatClasses(
                    'border-1 py-xs px-sm rounded-md border-textTertiary',
                    applyClass(calendar.value === requestedCalendar, 'bg-surfaceAccent'),
                  )}
                  key={calendar.value}
                  onClick={() => setRequestedCalendar(calendar.value)}
                >
                  <XStack alignItems="center" gapX="xs">
                    {'iconName' in calendar && calendar.iconName ? <Icon name={calendar.iconName} /> : null}
                    <Text type="h6">{calendar.label}</Text>
                  </XStack>
                </Clickable>
              ))}
            </XStack>
          </YStack>
        ) : null}
        <YStack gapY="lg">
          <Button
            color="primary"
            disabled={!isFormValid}
            fullWidth
            isLoading={isLoading}
            label="Submit"
            onClick={onSubmit}
          />
        </YStack>
      </YStack>
    </Modal>
  );
};
