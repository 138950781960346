import type { TaskChatAuthorInfo } from '@frontend/api-client';
import { match, P } from '@frontend/duck-tape';
import { Emoji, Icon, Text, XStack } from '@frontend/web-react';
import { concatClasses, safeCss } from '@frontend/web-utils';

type TaskChatAvatarProps = Pick<TaskChatAuthorInfo, 'initials' | 'type'>;

export const TaskChatAvatar = ({ initials, type }: TaskChatAvatarProps) => {
  const backgroundColorClassName = match(type)
    .with('client', () => safeCss('bg-surfaceBrand'))
    .with('agent', () => safeCss('bg-textTertiary'))
    .with('ai_bot', () => safeCss('bg-surfaceAccent'))
    .with('automated_message', () => safeCss('bg-surfaceAccent'))
    .exhaustive();

  return (
    <XStack
      className={concatClasses(backgroundColorClassName, 'p-xs rounded-full size-[28px] justify-center items-center')}
    >
      {match(type)
        // Browser quirks make it such that emojis are difficult to horizontally center
        // https://stackoverflow.com/questions/44142464/how-do-i-horizontally-center-an-emoji
        // eslint-disable-next-line tailwindcss/no-arbitrary-value
        .with('ai_bot', () => <Emoji className="text-[2.5em] scale-[28%]" emoji=":duck:" />)
        .with('automated_message', () => <Icon name="DuckbillLogo" size="xs" />)
        .with(P.union('agent', 'client'), () => (
          <Text className="leading-none" color={type === 'client' ? 'accent' : 'inverse'} span type="captionBold">
            {initials}
          </Text>
        ))
        .exhaustive()}
    </XStack>
  );
};
