import { THREAD_TOPIC_SUGGESTIONS } from '@frontend/api-client';
import { chunkParts } from '@frontend/duck-tape';
import { useEffect, useMemo, useRef, useState, useTemporaryAnimation } from '@frontend/react';
import { Card, Clickable, LinearGradientWrapper, PageContainer, Text, YStack } from '@frontend/web-react';
import { applyClass } from '@frontend/web-utils';
import { ThreadChatFooter } from '@frontend/web/components';
import { useAnalytics, useCreateThreadMutation } from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';
import { createFileRoute } from '@tanstack/react-router';
import Marquee from 'react-fast-marquee';

const CreateThread = () => {
  const navigate = Route.useNavigate();
  const { animate, isAnimating } = useTemporaryAnimation({ duration: 1000 });
  const inputRef = useRef<HTMLAreaElement>();
  const suggestionGroups = useMemo(() => chunkParts(THREAD_TOPIC_SUGGESTIONS, 4), []);
  const [createThread, { isLoading: isCreateThreadLoading }] = useCreateThreadMutation();
  const [messageValue, setMessageValue] = useState('');
  const [currentlyUsedExample, setCurrentlyUsedExample] = useState<string>();
  const { track } = useAnalytics();

  // If input gets cleared, input can't be based off of an suggestion
  useEffect(() => {
    if (!messageValue) {
      setCurrentlyUsedExample(undefined);
    }
  }, [messageValue]);

  const onClickExample = (example: string) => {
    animate();
    setMessageValue(example);
    inputRef.current?.focus();
    track({ data: { example }, event: 'researchThreadExampleButtonSelected', topic: 'research' });
    setCurrentlyUsedExample(example);
  };

  const onClickSubmit = async () => {
    if (currentlyUsedExample) {
      track({
        data: {
          example: currentlyUsedExample,
          threadDescription: messageValue,
        },
        event: 'researchThreadFromExampleCreated',
        topic: 'research',
      });
    }

    handleMutation({
      mutation: async () => {
        const result = await createThread().unwrap();
        return result;
      },
      onSuccess: ({ id: threadId }) => {
        setMessageValue('');
        inputRef.current?.blur();
        return navigate({
          params: { threadId },
          search: { autoMessageSendContent: messageValue },
          to: '/app/thread/$threadId',
        });
      },
      showSuccessToast: false,
    });
  };

  return (
    <PageContainer isScrollable={false} padding="none">
      <YStack className="flex-1 justify-center overflow-x-clip mb-xxxl" gapY="md">
        {suggestionGroups.map((suggestionGroup, index) => {
          return (
            <Marquee direction={index % 2 === 0 ? 'left' : 'right'} key={index} pauseOnHover speed={25}>
              {suggestionGroup.map((suggestion, index) => {
                return (
                  <Clickable className="mr-md rounded-lg" key={index} onClick={() => onClickExample(suggestion)}>
                    {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
                    <LinearGradientWrapper className="p-[2px] rounded-lg">
                      {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
                      <Card className="bg-white rounded-lg" p={'md'}>
                        <Text type="p-sm">{suggestion}</Text>
                      </Card>
                    </LinearGradientWrapper>
                  </Clickable>
                );
              })}
            </Marquee>
          );
        })}
      </YStack>
      <ThreadChatFooter
        className={applyClass(isAnimating, 'slam-effect')}
        isCreateCommentLoading={isCreateThreadLoading}
        onChangeText={(messageValue) => setMessageValue(messageValue)}
        onClickSubmit={onClickSubmit}
        placeholder="Enter message"
        textValue={messageValue}
      />
    </PageContainer>
  );
};

export const Route = createFileRoute('/app/thread/create')({
  component: CreateThread,
});
