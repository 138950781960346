import { createTag } from '@frontend/api-client/utils';
import type { BaseApi } from '@frontend/api-client/utils/types';
import type { CompleteStoryResponse, ListStoriesResponse } from '@frontend/api-types';
import { convertArrayObjectKeysCase } from '@frontend/duck-tape';
import STORIES_ENDPOINTS from './endpoints';

export const getStoriesApi = (baseApi: BaseApi) =>
  baseApi.injectEndpoints({
    endpoints: (builder) => ({
      completeStory: builder.mutation<CompleteStoryResponse, string>({
        invalidatesTags: [createTag({ id: 'LIST', type: 'Story' })],
        query: (id) => ({
          method: 'POST',
          url: STORIES_ENDPOINTS.completeStory(id),
        }),
      }),
      listStories: builder.query<ListStoriesResponse, void>({
        providesTags: [createTag({ id: 'LIST', type: 'Story' })],
        query: STORIES_ENDPOINTS.listStories,
        transformResponse: (response: GenericRecord[]) =>
          convertArrayObjectKeysCase(response, 'camelCase') as ListStoriesResponse,
      }),
    }),
    overrideExisting: true,
  });
