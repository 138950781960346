import { validators } from '../utils';
import { makeFormComponent, makeFormHook } from '../utils/factory';
import type { InferFormType } from '../utils/types';

const createInviteFormSchema = validators.object({
  email: validators.email().optional(),
  firstName: validators.requiredString('First name'),
  lastName: validators.requiredString('Last name'),
  phoneNumber: validators.e164PhoneNumber(),
});

export type CreateInviteFormSchema = InferFormType<typeof createInviteFormSchema>;

const createInviteFormDefaultValues: CreateInviteFormSchema = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
};

export const useCreateInviteForm = makeFormHook(createInviteFormSchema, createInviteFormDefaultValues);

export const CreateInviteForm = makeFormComponent(createInviteFormSchema, createInviteFormDefaultValues);
