import { formatEndpoint } from '@frontend/duck-tape';

const namespace = 'duckuments/sheets';

const REVIEW = 'review';

export const SHEETS_ENDPOINTS = {
  retrieveOptionSheet: (optionSheetId: string) => formatEndpoint(optionSheetId, namespace),
  reviewOptionSheet: (optionSheetId: string) => formatEndpoint(`${optionSheetId}/${REVIEW}`, namespace),
};
