import { useState } from 'react';

export const useTemporaryAnimation = ({ duration }: { duration: number }) => {
  const [isAnimating, setAnimating] = useState(false);

  const animate = () => {
    setAnimating(true);
    setTimeout(() => setAnimating(false), duration);
  };

  return { animate, isAnimating };
};
