import {
  canTaskBeReviewed,
  canTaskBeSkipped,
  canTaskBeStarted,
  getCommentProps,
  getInitialComment,
  getTaskChatMessageAuthorInfo,
} from '@frontend/api-client';
import type { TaskComment, TaskDetail } from '@frontend/api-types';
import { P, match } from '@frontend/duck-tape';
import { forwardRef, useEffect } from '@frontend/react';
import type { FlatListProps } from '@frontend/web-react';
import { Banner, Divider, FlatList, LoadingSpinnerPanel, YStack } from '@frontend/web-react';
import { safeCss } from '@frontend/web-utils';
import {
  TaskChatInitialActions,
  TaskChatMessageBubble,
  TaskChatResearchThreadWhisper,
  TaskChatSecureNoteCard,
  TaskChatTime,
  TaskChatWhisper,
} from '@frontend/web/components/DomainSpecific';
import { TaskChatOptionSheetCard } from '@frontend/web/components/DomainSpecific/Tasks/TaskChatOptionSheetCard';
import {
  useAnalytics,
  useListCommentsQuery,
  useRetrieveClientQuery,
  useSkipTaskMutation,
  useStartTaskMutation,
  useUpdateTaskMutation,
} from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';
import { disconnectSocket, getSocket, initializeSocket } from '@frontend/web/utils/sockets';

type TaskChatMessagesProps = {
  onClickLeaveReview: () => void;
  refetchTask: () => void;
  scrollToBottom: () => void;
  task: TaskDetail;
};

export const TaskChatMessages = forwardRef<HTMLDivElement, TaskChatMessagesProps>(
  ({ onClickLeaveReview, refetchTask, scrollToBottom, task }, ref) => {
    const { id: taskId, isUnread, searchThread } = task;
    const { data: client } = useRetrieveClientQuery();
    const { data: comments = [], isLoading: isLoadingComments, isSuccess, refetch } = useListCommentsQuery(taskId);
    const [skipTask, { isLoading: isSkipTaskLoading }] = useSkipTaskMutation();
    const [startTask, { isLoading: isStartTaskLoading }] = useStartTaskMutation();
    const [updateTask] = useUpdateTaskMutation();
    const isLoading = isSkipTaskLoading || isStartTaskLoading;
    const { track } = useAnalytics();

    const setupSocket = ({ taskId }: { taskId: string }) => {
      if (getSocket('task')) {
        disconnectSocket();
      }

      const socket = initializeSocket({
        channelType: 'task',
        params: { task_id: taskId },
      });
      return socket;
    };

    useEffect(() => {
      const socket = setupSocket({ taskId });

      socket.on('receive_message', () => {
        refetch();
        // Since task info like secure notes, option sheets, attachments
        // are now displayed in task detail page,
        // task detail need to be refetched to get the latest data
        refetchTask();
      });

      return () => {
        socket.off('receive_message');
        socket.disconnect();
      };
    }, []);

    useEffect(() => {
      if (isSuccess && isUnread) {
        updateTask({ data: { isUnread: false }, id: taskId });
      }
    }, [isSuccess]);

    useEffect(() => {
      scrollToBottom();
    }, [comments, scrollToBottom]);

    const handleClickSkipTask = () => {
      track({ data: { taskId }, event: 'startTaskButtonSelected', topic: 'tasks' });
      handleMutation({
        mutation: async () => skipTask(taskId).unwrap(),
        showErrorToast: true,
        showSuccessToast: true,
        successMessage: 'You have skipped the task this time.',
      });
    };

    const handleClickStartTask = () => {
      track({ data: { taskId }, event: 'skipTaskButtonSelected', topic: 'tasks' });
      handleMutation({
        mutation: async () => startTask(taskId).unwrap(),
        showErrorToast: true,
        showSuccessToast: true,
        successMessage: 'Task started!',
      });
    };

    const commentsWithDescription = [
      getInitialComment(task, client, {
        labelClassName: safeCss('text-caption text-textPrimary font-semibold'),
        textClassName: safeCss('text-p-md text-textPrimary'),
      }),
      ...comments,
    ];

    const renderItem: FlatListProps<TaskComment>['renderItem'] = ({ index, item }) => {
      const { attachments, author, body, createdAt, secureNote, sheets } = item;
      const { isAuthorVisible, isDividerVisible, isTimeVisible } = getCommentProps(commentsWithDescription, index);
      const isFirstMessage = isTimeVisible && index === commentsWithDescription.length - 1;

      const content = (() => {
        if (secureNote) {
          const { id, isActive, title } = secureNote;
          return (
            <TaskChatSecureNoteCard
              className="ml-xl"
              isActive={isActive}
              secureNoteId={id}
              taskId={taskId}
              title={title}
            />
          );
        } else if (sheets?.[0]) {
          const { id, imageUrl, title } = sheets[0];
          return <TaskChatOptionSheetCard className="ml-xl" imageUrl={imageUrl} optionSheetId={id} title={title} />;
        }

        return match(author)
          .with({ type: 'system_bot' }, () => <TaskChatWhisper body={body} />)
          .with({ type: P.union('agent', 'ai_bot', 'client') }, (author) => (
            <TaskChatMessageBubble
              {...item}
              attachments={attachments}
              authorInfo={getTaskChatMessageAuthorInfo(author)}
              body={body}
              isAuthorVisible={isAuthorVisible}
            />
          ))
          .exhaustive();
      })();

      return (
        <YStack gapY="md">
          {isTimeVisible ? <TaskChatTime time={createdAt} /> : null}
          {isFirstMessage && searchThread ? <TaskChatResearchThreadWhisper searchThread={searchThread} /> : null}
          {content}
          {isDividerVisible ? <Divider /> : null}
        </YStack>
      );
    };

    const onClickLeaveReviewBanner = () => {
      track({ data: { taskId }, event: 'reviewTaskBannerSelected', topic: 'tasks' });
      onClickLeaveReview();
    };

    return (
      <FlatList
        ListFooterComponent={
          <>
            {canTaskBeSkipped(task) || canTaskBeStarted(task) ? (
              <TaskChatInitialActions
                disabled={isLoading}
                isSkipTaskLoading={isSkipTaskLoading}
                isStartTaskLoading={isStartTaskLoading}
                onSkip={handleClickSkipTask}
                onStart={handleClickStartTask}
                task={task}
              />
            ) : null}
            {canTaskBeReviewed(task) ? (
              <Banner
                className="mb-md"
                color="white"
                leftIconName="IconStar"
                onClick={onClickLeaveReviewBanner}
                rightIconName="IconArrowRight"
                title="Leave a review to say thanks"
              />
            ) : null}
          </>
        }
        ListLoadingComponent={<LoadingSpinnerPanel fullScreen />}
        className="px-lg flex-1"
        contentContainerClassName={safeCss('gap-y-md py-md flex-1 justify-end')}
        // TODO (ethan): Reverse immutably instead
        // eslint-disable-next-line fp/no-mutating-methods
        data={commentsWithDescription.reverse()}
        inverted
        isLoading={isLoadingComments}
        ref={ref}
        renderItem={renderItem}
      />
    );
  },
);
