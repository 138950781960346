import type { CommonSVGProps } from '../internal';

export const GoogleCalendarIcon = (props: Pick<CommonSVGProps, 'height' | 'width'>) => (
  <svg fill="none" height={32} width={32} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path d="M24.421 7.579H7.58V24.42H24.42V7.579Z" fill="#fff" />
      <path d="M24.421 32 32 24.42l-3.79-.647-3.789.647-.692 3.466.692 4.113Z" fill="#EA4335" />
      <path
        d="M0 24.421v5.053A2.526 2.526 0 0 0 2.526 32H7.58l.778-3.79-.778-3.789-4.13-.647L0 24.421Z"
        fill="#188038"
      />
      <path
        d="M32 7.579V2.526A2.526 2.526 0 0 0 29.474 0H24.42c-.461 1.88-.692 3.263-.692 4.15 0 .886.23 2.03.692 3.429 1.676.48 2.94.72 3.79.72.85 0 2.113-.24 3.789-.72Z"
        fill="#1967D2"
      />
      <path d="M32 7.579h-7.579V24.42H32V7.579Z" fill="#FBBC04" />
      <path d="M24.421 24.421H7.58v7.58H24.42v-7.58Z" fill="#34A853" />
      <path d="M24.421 0H2.526A2.526 2.526 0 0 0 0 2.526v21.895h7.579V7.58H24.42V0Z" fill="#4285F4" />
      <path
        d="M11.034 20.644c-.63-.425-1.066-1.046-1.303-1.867l1.46-.602c.133.505.365.896.695 1.174.329.278.729.415 1.196.415.478 0 .888-.145 1.232-.436.343-.29.515-.66.515-1.11 0-.458-.18-.833-.543-1.123-.362-.29-.816-.436-1.36-.436h-.844v-1.446h.758c.467 0 .861-.127 1.181-.38.32-.252.48-.597.48-1.037 0-.392-.143-.704-.43-.937-.286-.234-.648-.352-1.088-.352-.43 0-.77.114-1.023.343-.253.23-.442.521-.552.845l-1.446-.603c.192-.543.543-1.023 1.06-1.437.515-.415 1.174-.623 1.974-.623.591 0 1.124.113 1.596.343.471.23.842.547 1.11.951.266.406.4.861.4 1.367 0 .515-.125.951-.374 1.309a2.568 2.568 0 0 1-.915.823v.087c.467.192.873.509 1.174.915.306.41.46.901.46 1.474s-.146 1.084-.436 1.533c-.291.448-.693.802-1.203 1.058a3.79 3.79 0 0 1-1.724.388 3.569 3.569 0 0 1-2.05-.636Zm8.974-7.25-1.604 1.16-.802-1.217 2.878-2.076h1.103v9.791h-1.575v-7.658Z"
        fill="#4285F4"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h32v32H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
