import type { TaskDetail } from '@frontend/api-types';
import { useEffect } from '@frontend/react';
import { Button, Divider, Icon, Text, XStack, YStack } from '@frontend/web-react';
import { useQuackActionsContext } from '@frontend/web/context/QuackActionsContext';

type TaskChatInitialActionsProps = {
  disabled?: boolean;
  isSkipTaskLoading: boolean;
  isStartTaskLoading: boolean;
  onSkip?: () => Promise<void> | void;
  onStart?: () => Promise<void> | void;
  task: TaskDetail;
};

export const TaskChatInitialActions = ({
  disabled,
  isSkipTaskLoading,
  isStartTaskLoading,
  onSkip,
  onStart,
  task,
}: TaskChatInitialActionsProps) => {
  const { addAction, removeAction } = useQuackActionsContext();
  useEffect(() => {
    addAction({
      actions: [
        {
          description: 'Skip this time',
          id: 'skipTask',
          label: 'Skip task',
          leftSection: <Icon name="IconX" />,
          onClick: onSkip,
        },
        {
          description: 'Start now',
          id: 'startTask',
          label: 'Start task now',
          leftSection: <Icon name="IconPlayerPlay" />,
          onClick: onStart,
        },
      ],
      group: 'Current Task',
    });

    return () => {
      removeAction('skipTask', 'Current Task');
      removeAction('startTask', 'Current Task');
    };
  }, [task]);

  return (
    <YStack className="mb-lg">
      <Divider />
      <YStack alignItems="center" className="p-lg pb-none" gap="md">
        <XStack gapX="lg">
          <Button
            color="danger"
            disabled={disabled ? !isSkipTaskLoading : undefined}
            isLoading={isSkipTaskLoading}
            label="Skip this time"
            leftIconName="IconX"
            onClick={onSkip}
          />
          <Button
            color="secondary"
            disabled={disabled ? !isStartTaskLoading : undefined}
            isLoading={isStartTaskLoading}
            label="Start Now"
            leftIconColor="primary"
            leftIconName="IconSend"
            onClick={onStart}
          />
        </XStack>
        <Text color="tertiary" type="captionBold">
          Sending us a message will move this task to in-progress.
        </Text>
      </YStack>
    </YStack>
  );
};
