import { formatEndpoint } from '@frontend/duck-tape';

const namespace = 'oauth';

export const UPDATE_OAUTH_CODE = 'token-exchange';
export const RETRIEVE_OAUTH_ACCOUNT = '?purpose=calendar';
export const DEACTIVATE_OAUTH_ACCOUNT = 'deactivate';

export const OAUTH_ENDPOINTS = {
  deactivateOAuthAccount: (id: string) => formatEndpoint(`${id}/${DEACTIVATE_OAUTH_ACCOUNT}`, namespace),
  listOAuthAccounts: formatEndpoint(RETRIEVE_OAUTH_ACCOUNT, namespace, { removeTrailingSlash: true }),
  updateOAuthCode: formatEndpoint(UPDATE_OAUTH_CODE, namespace),
};
