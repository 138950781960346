import { safeCss } from '@frontend/web-utils';
import type { RadioProps as MRadioProps } from '@mantine/core';
import { Radio as MRadio } from '@mantine/core';

type RadioProps = Omit<MRadioProps, 'classNames' | 'color' | 'variant'>;

const classNames: MRadioProps['classNames'] = {
  body: safeCss('text-h5 text-textPrimary'),
  radio: safeCss('border-1 border-textPrimary'),
};

/**
 * Shouldn't be used on its own, if you need to render multiple radios, use RadioInput.
 */
export const Radio = ({ ...props }: RadioProps) => {
  // If indeterminate use default icon (we don't have one)
  return <MRadio classNames={classNames} color="neutral.0" iconColor={'blue.9'} {...props} />;
};
