import type { SetRequired, StrictExtract, StrictOmit } from '@frontend/duck-tape';
import { match } from '@frontend/duck-tape';
import type { ReactNode } from '@frontend/react';
import type { ClassName } from '@frontend/web-utils';
import { concatClasses, safeCss } from '@frontend/web-utils';
import type { NotificationData } from '@mantine/notifications';
import { Icon, notifications } from '../components';
import type { ColorVariant } from '../types';

export type ToastColorVariant = StrictExtract<ColorVariant, 'danger' | 'success'> | 'info' | 'warning';

export type ShowToastProps = SetRequired<
  StrictOmit<NotificationData, 'classNames' | 'closeButtonProps' | 'icon' | 'withBorder'>,
  'title'
> & {
  variant: ToastColorVariant;
};

export const showToast = ({ message = '', variant, ...props }: ShowToastProps) => {
  const variantClassNames = getClassNames({ variant });

  const classNames: NotificationData['classNames'] = {
    icon: safeCss('bg-transparent'),
    root: concatClasses('p-md', variantClassNames.root),
    title: concatClasses('text-p-md', variantClassNames.title),
  };

  // Returns id of the toast notification
  return notifications.show({
    classNames,
    closeButtonProps: { 'aria-label': 'Hide notification' },
    icon: getIcon({ variant }),
    message,
    withBorder: true,
    ...props,
  });
};

const getClassNames = ({ variant }: PickRequired<ShowToastProps, 'variant'>) =>
  match<ToastColorVariant, Record<'root' | 'title', ClassName>>(variant)
    .with('danger', () => ({
      root: safeCss('bg-surfaceError'),
      title: safeCss('text-textError'),
    }))
    .with('success', () => ({
      root: safeCss('bg-surfaceSuccess'),
      title: safeCss('text-textSuccess'),
    }))
    .with('info', () => ({
      root: safeCss('bg-white'),
      title: safeCss('text-textPrimary'),
    }))
    .with('warning', () => ({
      root: safeCss('bg-rose-200'),
      title: safeCss('text-rose-600'),
    }))
    .exhaustive();

const getIcon = ({ variant }: PickRequired<ShowToastProps, 'variant'>) =>
  match<ToastColorVariant, ReactNode>(variant)
    .with('success', () => <Icon color="success" name="IconCircleCheck" />)
    .with('danger', () => <Icon color="danger" name="IconAlertCircle" />)
    .with('info', () => <Icon color="primary" name="IconInfoCircle" />)
    .with('warning', () => <Icon color="warning" name="IconAlertCircle" />)
    .exhaustive();
