import { useElements, useStripe } from '@stripe/react-stripe-js';
import type {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { useState } from 'react';

export const useStripeCard = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [cardNumberInfo, setCardNumberInfo] = useState<StripeCardNumberElementChangeEvent>();
  const [cardExpiryInfo, setCardExpiryInfo] = useState<StripeCardExpiryElementChangeEvent>();
  const [cardCvcInfo, setCardCvcInfo] = useState<StripeCardCvcElementChangeEvent>();

  return {
    cardCvcInfo,
    cardExpiryInfo,
    cardNumberInfo,
    elements,
    setCardCvcInfo,
    setCardExpiryInfo,
    setCardNumberInfo,
    stripe,
  };
};
