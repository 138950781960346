import { validators } from '../utils';
import { makeFormComponent, makeFormHook } from '../utils/factory';
import type { InferFormType } from '../utils/types';

const generalFeedbackFormSchema = validators.object({
  feedback: validators.requiredString('Feedback'),
});

export type GeneralFeedbackFormSchema = InferFormType<typeof generalFeedbackFormSchema>;

const generalFeedbackFormDefaultValues: GeneralFeedbackFormSchema = {
  feedback: '',
};

export const useGeneralFeedbackForm = makeFormHook(generalFeedbackFormSchema, generalFeedbackFormDefaultValues);

export const GeneralFeedbackForm = makeFormComponent(generalFeedbackFormSchema, generalFeedbackFormDefaultValues);
