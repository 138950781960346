import { appStoreLinks } from '@frontend/constants';
import { concatClasses, type ClassName } from '@frontend/web-utils';
import QRCode from 'react-qr-code';

export type DownloadAppQRCodeProps = { className?: ClassName; platform: MobilePlatform; size?: number };
export const DownloadAppQRCode = ({ className, platform, size = 100 }: DownloadAppQRCodeProps) => {
  // eslint-disable-next-line tailwindcss/no-arbitrary-value
  return (
    <div className={concatClasses('w-full h-auto', className)} style={{ maxWidth: size }}>
      <QRCode className="h-auto w-full" size={100} value={appStoreLinks[platform]} viewBox={`0 0 ${size} ${size}`} />
    </div>
  );
};
