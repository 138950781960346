import type { StrictExtract, StrictOmit } from '@frontend/duck-tape';
import { match } from '@frontend/duck-tape';
import type { ColorVariant } from '@frontend/web-react/types';
import { concatClasses, safeCss } from '@frontend/web-utils';
import type { ButtonProps as MButtonProps } from '@mantine/core';
import { Button as MButton } from '@mantine/core';
import { forwardRef, useMemo } from 'react';
import type { IconColorVariant } from '../Icon/Icon';
import { Icon } from '../Icon/Icon';
import type { IconName } from '../Icon/helpers';

export type ButtonColorVariant = StrictExtract<ColorVariant, 'danger' | 'primary' | 'secondary' | 'success'>;
export type ButtonSizeVariant = 'lg' | 'md' | 'sm';
export type ButtonTypeVariant = Extract<MButtonProps['variant'], 'filled' | 'subtle'>;

// For now we won't use variants because overriding the styles is a pain
export type ButtonProps = StrictOmit<MButtonProps, 'children' | 'color' | 'loading' | 'size' | 'variant'> & {
  color?: ButtonColorVariant;
  isForm?: boolean;
  isLoading?: boolean;
  leftIconColor?: IconColorVariant;
  leftIconName?: IconName;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  rightIconColor?: IconColorVariant;
  rightIconName?: IconName;
  size?: ButtonSizeVariant;
  type?: ButtonTypeVariant;
} & ({ children: React.ReactNode } | { label: string });

// Buttons are complicated components. Mantine's theme can be a bit tricky to work with, so
// I've opted to use a more manual approach for styling
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      color = 'primary',
      disabled = false,
      isForm = false,
      isLoading = false,
      leftIconColor,
      leftIconName,
      leftSection,
      onClick,
      rightIconColor,
      rightIconName,
      rightSection,
      size = 'md',
      type = 'filled',
      ...restProps
    },
    ref,
  ) => {
    const classNames: ButtonProps['classNames'] = useMemo(
      () => ({
        inner: safeCss('gap-x-sm'),
        label: getLabelClass({ color, disabled }),
        root: getRootClass({ color, disabled, size, type }),
      }),
      [color, size, type, disabled],
    );

    return (
      <MButton
        classNames={classNames}
        disabled={disabled}
        leftSection={
          leftIconName ? (
            <Icon color={disabled ? 'secondary' : leftIconColor ?? color} name={leftIconName} />
          ) : (
            leftSection
          )
        }
        loaderProps={{
          color: match<ButtonColorVariant, MButtonProps['color']>(color)
            .with('primary', () => 'blue.9')
            .with('secondary', () => 'blue.9')
            .with('danger', () => 'red.5')
            .with('success', () => 'green.7')
            .exhaustive(),
        }}
        loading={isLoading}
        onClick={
          isForm
            ? undefined
            : (e) => {
                e.stopPropagation();
                e.preventDefault();
                onClick?.(e);
              }
        }
        ref={ref}
        rightSection={
          rightIconName ? (
            <Icon color={disabled ? 'secondary' : rightIconColor ?? color} name={rightIconName} />
          ) : (
            rightSection
          )
        }
        variant={type}
        {...restProps}
        type="submit"
      >
        {'label' in restProps ? restProps.label : restProps.children}
      </MButton>
    );
  },
);

const getLabelClass = ({ color, disabled }: Pick<Required<ButtonProps>, 'color' | 'disabled'>) => {
  const labelClassMap = {
    danger: disabled ? safeCss('text-textErrorDisabled') : safeCss('text-textError'),
    primary: disabled ? safeCss('text-textTertiary') : safeCss('text-textPrimary'),
    secondary: disabled ? safeCss('text-textTertiary') : safeCss('text-textPrimary'),
    success: disabled ? safeCss('text-textSuccessDisabled') : safeCss('text-textSuccess'),
  };

  return concatClasses(safeCss('font-bold text-h5'), labelClassMap[color]);
};

const getRootClass = ({
  color,
  disabled,
  size,
  type,
}: Pick<Required<ButtonProps>, 'color' | 'disabled' | 'size' | 'type'>) => {
  const rootClassMap = {
    filled: {
      danger: disabled
        ? safeCss('border-borderTertiary bg-buttonDisabled cursor-not-allowed')
        : safeCss('border-textError bg-buttonError hover:bg-red-200'),
      primary: disabled
        ? safeCss('border-borderPrimary bg-buttonDisabled cursor-not-allowed')
        : safeCss('border-borderSecondary bg-buttonPrimary  hover:bg-teal-100'),
      secondary: disabled
        ? safeCss('border-borderTertiary bg-buttonDisabled cursor-not-allowed')
        : safeCss('border-textSecondary bg-buttonSecondary hover:bg-surfacePrimary'),
      success: disabled
        ? safeCss('border-borderTertiary bg-buttonDisabled cursor-not-allowed')
        : safeCss('border-textSuccess bg-buttonSuccess hover:bg-green-100'),
    },
    size: {
      lg: safeCss('px-xl py-sm border-[2px] h-[54px]'),
      md: safeCss('px-lg py-sm border-[2px] h-[56px]'),
      sm: safeCss('px-md py-xs border-1 h-[29px]'),
    },
    subtle: {
      danger: safeCss('hover:bg-red-50'),
      primary: safeCss('hover:bg-teal-100'),
      secondary: safeCss('hover:bg-slate-50'),
      success: safeCss('hover:bg-green-50'),
    },
  };

  const baseClass = safeCss('content-none click-scale-effect items-center');

  return concatClasses(
    baseClass,
    match({ color, size, type })
      .with({ type: 'filled' }, ({ color }) =>
        concatClasses('rounded-xxl', rootClassMap.filled[color], rootClassMap.size[size]),
      )
      .with({ type: 'subtle' }, ({ color }) =>
        concatClasses('border-none bg-transparent', rootClassMap.filled[color], rootClassMap.size[size]),
      )
      .exhaustive(),
  );
};
