import { createFileRoute, redirect } from '../utils';

export const Route = createFileRoute('/')({
  beforeLoad: async ({ context, location }) => {
    const isAuthenticated = context.authentication?.isAuthenticated;
    if (!isAuthenticated) {
      const shouldRedirect = location.href !== '/';

      const options = shouldRedirect
        ? {
            search: {
              redirect: location.href,
            },
          }
        : {};

      throw redirect({
        ...options,
        to: '/login',
      });
    } else {
      throw redirect({
        to: '/app',
      });
    }
  },
  component: () => <div />,
});
