import type { TaskChatAuthorInfo } from '@frontend/api-client';
import { Icon, Text, XStack } from '@frontend/web-react';
import { TaskChatAvatar } from '@frontend/web/components/DomainSpecific/Tasks/Chat/TaskChatMessageBubble/TaskChatAvatar';

type TaskChatAuthorProps = TaskChatAuthorInfo;

export const TaskChatAuthor = ({ initials, label, type }: TaskChatAuthorProps) => {
  return (
    <XStack className="gap-x-sm items-center">
      <TaskChatAvatar initials={type === 'client' ? initials : 'CO'} type={type} />
      <Text color="tertiary" type="captionBold">
        {label}
      </Text>
      {type === 'agent' ? (
        // eslint-disable-next-line tailwindcss/no-arbitrary-value
        <Icon className="bg-surfacePink p-[3px] rounded-xl" color="white" name="IconStarFilled" size="xs" />
      ) : null}
    </XStack>
  );
};
