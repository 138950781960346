import type { StrictExtract, StrictOmit } from '@frontend/duck-tape';
import { match } from '@frontend/duck-tape';
import type { ColorVariant } from '@frontend/web-react/types';
import type { ClassName } from '@frontend/web-utils';
import { applyClass, concatClasses, safeCss } from '@frontend/web-utils';
import type { CardProps as MCardProps } from '@mantine/core';
import { Card as MCard } from '@mantine/core';
import type { TextColorVariant } from '../Texts';
import type { ClickableProps } from './Clickable';

export type CardBackgroundColorVariant =
  | StrictExtract<ColorVariant, 'primary' | 'secondary'>
  | StrictExtract<TextColorVariant, 'pink' | 'purple' | 'yellow'>;

export type CardProps = StrictOmit<MCardProps, 'classNames' | 'variant'> & {
  color?: CardBackgroundColorVariant;
  disabled?: boolean;
} & (
    | { clickableClassName?: ClassName; onClick: ClickableProps['onClick'] }
    | { clickableClassName?: never; onClick?: never }
  ) &
  (EmptyObject | { alt: string; image: string; imageHeight: number });

/** When using this component and it's utility component Card.Section, prefer using
 * my/mx/py/px props to add margin and padding, as it leverages Mantine's automatic
 * spacing feature for this component
 */
export const Card = ({
  children,
  className = safeCss('rounded-md'),
  color = 'primary',
  disabled = false,
  onClick,
  withBorder = false,
  ...props
}: CardProps) => {
  const classNames: MCardProps['classNames'] = {
    root: concatClasses(
      safeCss('gap-y-md'),
      applyClass(withBorder, 'border-1 border-borderPrimary'),
      getRootClassName({ color, onClick }),
      applyClass(!!onClick && !disabled, 'hover-opacity-effect'),
      className,
    ),
  };

  return (
    <MCard
      aria-disabled={disabled}
      classNames={classNames}
      onClick={onClick}
      role={onClick ? 'button' : undefined}
      tabIndex={onClick ? 0 : undefined}
      {...props}
    >
      {children}
    </MCard>
  );
};

const getRootClassName = ({ color, onClick }: Pick<CardProps, 'onClick'> & PickRequired<CardProps, 'color'>) =>
  match(color)
    .with('primary', () => concatClasses(safeCss('bg-surfaceSecondary'), applyClass(!!onClick, 'hover:bg-neutral-50')))
    .with('secondary', () => concatClasses(safeCss('bg-surfacePrimary'), applyClass(!!onClick, 'hover:bg-neutral-100')))
    .with('pink', () => concatClasses(safeCss('bg-pink-200'), applyClass(!!onClick, 'hover:bg-pink-100')))
    .with('purple', () => concatClasses(safeCss('bg-violet-300'), applyClass(!!onClick, 'hover:bg-violet-200')))
    .with('yellow', () => concatClasses(safeCss('bg-yellow-200'), applyClass(!!onClick, 'hover:bg-yellow-100')))
    .exhaustive();

// eslint-disable-next-line fp/no-mutation
Card.Section = MCard.Section;
