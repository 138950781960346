import type { Client, ClientPlanCode, ClientStatus } from '@frontend/api-types';
import { isNil, makeCent, match, pluralize } from '@frontend/duck-tape';

export const getInitials = ({ firstName, lastName, nickname }: Pick<Client, 'firstName' | 'lastName' | 'nickname'>) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const firstLetter = nickname?.[0] ?? firstName?.[0] ?? '';
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const lastLetter = lastName?.[0] ?? '';
  return firstLetter.toUpperCase() + lastLetter.toUpperCase();
};

export type MembershipPlanType = 'champagneHousehold' | 'champagneIndividual' | 'household' | 'individual' | 'vip';

type MembershipPlansShape = {
  [key in MembershipPlanType]: {
    benefits: string[];
    label: string;
    planCode: ClientPlanCode;
    price: Cent;
  };
};

export const MEMBERSHIP_PLANS = {
  champagneHousehold: {
    benefits: [],
    label: 'Duckbill Champagne Tester (Household)',
    planCode: 'HOUSEHOLD',
    price: makeCent(0),
  },
  // The benefits never need to be shown as this is only relevant to internal users
  champagneIndividual: {
    benefits: [],
    label: 'Duckbill Champagne Tester (Individual)',
    planCode: 'INDIVIDUAL',
    price: makeCent(50),
  },
  household: {
    benefits: ['2 member accounts', '5 active tasks* per member', 'Share tasks with the other member on your plan'],
    label: 'Household',
    planCode: 'HOUSEHOLD',
    price: makeCent(16900),
  },
  individual: {
    benefits: ['1 member account', '5 active tasks*'],
    label: 'Individual',
    planCode: 'INDIVIDUAL',
    price: makeCent(9900),
  },
  vip: {
    benefits: [
      '2 member accounts',
      '10 active tasks* per member',
      'Share tasks with the other member on your plan',
      'Receive prioritized responses within 1 business day',
      'Schedule unlimited calls with your copilot team',
    ],
    label: 'Duckbill VIP',
    planCode: 'VIP',
    price: makeCent(44900),
  },
} as const satisfies MembershipPlansShape;

export type MembershipPlans = typeof MEMBERSHIP_PLANS;

export const getMembershipPlanType = ({ isEmployeePrice, plan }: Client) =>
  plan
    ? match<ClientPlanCode, MembershipPlanType>(plan.code)
        .with('HOUSEHOLD', () => (isEmployeePrice ? 'champagneHousehold' : 'household'))
        .with('INDIVIDUAL', () => (isEmployeePrice ? 'champagneIndividual' : 'individual'))
        .with('VIP', () => 'vip')
        .exhaustive()
    : undefined;

export type MembershipPlan = MembershipPlans[MembershipPlanType];

export const isClientSubscribed = (clientStatus: MaybeUndefined<ClientStatus>): boolean =>
  Boolean(
    clientStatus?.membershipStatus && ['active', 'subscription_pending'].includes(clientStatus?.membershipStatus),
  );

type PromotionDataNoPromotion = {
  countFreeTasksCreated: undefined;
  countPromotionalTasksLeftToUnlock: undefined;
  countPromotionalTasksTotal: undefined;
  hasPromotion: false;
  promotionalSubtitle: undefined;
  promotionalTasksLeftText: undefined;
  promotionalTitle: undefined;
};

type PromotionDataWithPromotion = {
  countFreeTasksCreated: number;
  countPromotionalTasksLeftToUnlock: number;
  countPromotionalTasksTotal: number;
  hasPromotion: true;
  promotionalSubtitle: string;
  promotionalTasksLeftText: string;
  promotionalTitle: string;
};

type PromotionData = PromotionDataNoPromotion | PromotionDataWithPromotion;

export type RequiredClientStatus = Pick<
  ClientStatus,
  | 'freeTasksCap'
  | 'promotionalFeedActive'
  | 'promotionalFeedDurationInTasks'
  | 'promotionalPartnerName'
  | 'tasksRemaining'
>;

/**
 * Note: `promotionalFeedDurationInTasks` can be less than the `freeTasksCap`. For example, they could have a
 * cap of 5 and only need to create 3 tasks to unlock the full feed experience.
 */
export const getPromotionData = (clientStatus: MaybeUndefined<RequiredClientStatus>): PromotionData => {
  if (
    !clientStatus ||
    !clientStatus.promotionalFeedActive ||
    isNil(clientStatus.promotionalFeedDurationInTasks) ||
    isNil(clientStatus.freeTasksCap) ||
    isNil(clientStatus.promotionalFeedActive) ||
    isNil(clientStatus.tasksRemaining) ||
    isNil(clientStatus.promotionalPartnerName)
  ) {
    return {
      countFreeTasksCreated: undefined,
      countPromotionalTasksLeftToUnlock: undefined,
      countPromotionalTasksTotal: undefined,
      hasPromotion: false,
      promotionalSubtitle: undefined,
      promotionalTasksLeftText: undefined,
      promotionalTitle: undefined,
    };
  }

  const countFreeTasksCreated = clientStatus.freeTasksCap ? clientStatus.freeTasksCap - clientStatus.tasksRemaining : 0;
  const countPromotionalTasksTotal = clientStatus.promotionalFeedDurationInTasks;
  const countPromotionalTasksLeftToUnlock = clientStatus.promotionalFeedDurationInTasks
    ? clientStatus.promotionalFeedDurationInTasks - countFreeTasksCreated
    : 0;

  const promotionalTitle = `Recommendations from ${clientStatus.promotionalPartnerName || 'Duckbill'} 🎁`;
  const promotionalSubtitle = `Create ${countPromotionalTasksLeftToUnlock} ${
    countPromotionalTasksLeftToUnlock === clientStatus.promotionalFeedDurationInTasks ? '' : 'more'
  } ${pluralize('task', countPromotionalTasksLeftToUnlock)} to unlock the full feed experience`;

  return {
    countFreeTasksCreated,
    countPromotionalTasksLeftToUnlock,
    countPromotionalTasksTotal,
    hasPromotion: true,
    promotionalSubtitle,
    promotionalTasksLeftText: `${countFreeTasksCreated}/${countPromotionalTasksTotal} tasks`,
    promotionalTitle,
  };
};
