import type { CommonModalProps } from '@frontend/web-react';
import { ConfirmationModal } from '@frontend/web-react';
import { useAnalytics, useDeleteRecurrenceMutation } from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';

export type DeleteRecurrenceModalProps = CommonModalProps & {
  onComplete?: () => void;
  recurrenceId: string;
};

export const DeleteRecurrenceModal = ({ isOpen, onClose, onComplete, recurrenceId }: DeleteRecurrenceModalProps) => {
  const { track } = useAnalytics();
  const [deleteRecurrence, { isLoading: isDeleteRecurrenceLoading }] = useDeleteRecurrenceMutation();

  const onClickDeleteRecurrence = async () => {
    track({ data: { recurrenceId }, event: 'deleteRecurrenceButtonSelected', topic: 'recurrences' });
    return handleMutation({
      mutation: async () => {
        return deleteRecurrence(recurrenceId).unwrap();
      },
      onSuccess: () => {
        onClose();
        onComplete?.();
      },
      successMessage: 'Repeating task deleted.',
    });
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      isSubmitLoading={isDeleteRecurrenceLoading}
      onClickCancel={onClose}
      onClickSubmit={onClickDeleteRecurrence}
      onClose={onClose}
      submitLabel="Delete"
      subtitle="Repeat tasks will no longer be created."
      title="Are you sure you want to delete this repeating task?"
      variant="danger"
    />
  );
};
