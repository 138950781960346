import { Icon, Title, XStack, YStack } from '@frontend/web-react';

export const OnboardingStepHeader = ({ onClickBackArrow, title }: { onClickBackArrow?: () => void; title: string }) => {
  return (
    <YStack gapY="sm">
      {onClickBackArrow ? (
        <XStack justifyContent="spaceBetween">
          <Icon name="IconArrowLeft" onClick={onClickBackArrow} />
        </XStack>
      ) : null}
      <Title type="h3">{title}</Title>
    </YStack>
  );
};
