import * as ArrayHelpers from '@frontend/duck-tape/array/array';

import { useState } from 'react';

// This could include several additional helper functions, we'll add more as needed
export type UseListStateHandlers<T> = {
  append: (...items: T[]) => void;
  head: T | undefined;
  last: T | undefined;
  pop: () => void;
  prepend: (...items: T[]) => void;
  remove: (shouldRemove: (t: T) => boolean) => void;
  removeUntil: (callback: (item: T) => boolean) => void;
  replace: (index: number, newValue: (originalValue: T) => T) => void;
  setState: React.Dispatch<React.SetStateAction<T[]>>;
  shift: () => void;
  toggle: (item: T, idExtractor: (t: T) => string) => void;
};

export type UseListState<T> = [T[], UseListStateHandlers<T>];

export function useListState<T>(initialValue: T[] = []): UseListState<T> {
  const [state, setState] = useState(initialValue);

  const append: UseListStateHandlers<T>['append'] = (...items) =>
    setState((current) => ArrayHelpers.append(current, ...items));

  const prepend: UseListStateHandlers<T>['prepend'] = (...items) =>
    setState((current) => ArrayHelpers.prepend(current, ...items));

  const shift: UseListStateHandlers<T>['shift'] = () => setState((current) => ArrayHelpers.shift(current));

  const remove: UseListStateHandlers<T>['remove'] = (shouldRemove) =>
    setState((current) => current.filter((item) => !shouldRemove(item)));

  const pop: UseListStateHandlers<T>['pop'] = () => setState((current) => ArrayHelpers.pop(current));

  const replace: UseListStateHandlers<T>['replace'] = (index, newValue) =>
    // @ts-expect-error Tedious types
    setState((current) => [...current.slice(0, index), newValue(current[index]), ...current.slice(index + 1)]);

  const removeUntil: UseListStateHandlers<T>['removeUntil'] = (callback) =>
    setState((current) => {
      const index = current.findIndex((item) => !callback(item));
      return index === -1 ? [] : current.slice(index);
    });

  const toggle: UseListStateHandlers<T>['toggle'] = (item, idExtractor) => {
    if (state.map(idExtractor).includes(idExtractor(item))) {
      setState((current) => current.filter((i) => i !== item));
    } else {
      setState((current) => [...current, item]);
    }
  };

  const last: T | undefined = state[state.length - 1];

  const head: T | undefined = state[0];

  return [
    state,
    {
      append,
      head,
      last,
      pop,
      prepend,
      remove,
      removeUntil,
      replace,
      setState,
      shift,
      toggle,
    },
  ];
}
