import type { SetRequired, StrictExtract, StrictOmit } from '@frontend/duck-tape';
import { match } from '@frontend/duck-tape';
import { BetaBadge, Icon, Tooltip } from '@frontend/web-react';
import type { IconName } from '@frontend/web-react/components/Primitives/Icon/helpers';
import type { ColorVariant } from '@frontend/web-react/types';
import type { ClassName } from '@frontend/web-utils';
import { applyClass, concatClasses, safeCss } from '@frontend/web-utils';
import type { NavLinkProps as MNavLinkProps, MantineColors } from '@mantine/core';
import { NavLink as MNavLink } from '@mantine/core';
import { Link as TLink } from '@tanstack/react-router';
import type { LinkComponentProps } from '@tanstack/react-router/dist/esm/link';

type SidebarNavLinkColor = StrictExtract<ColorVariant, 'danger' | 'primary'>;

type SidebarNavLinkProps = LinkComponentProps<'a'> &
  StrictOmit<SetRequired<MNavLinkProps, 'label'>, 'active' | 'color'> & {
    activeClassName?: ClassName;
    color?: SidebarNavLinkColor;
    exact?: boolean;
    inactiveClassName?: ClassName;
    // Override when route matching automatically doesn't work as expected
    isActive?: boolean;
    isBeta?: boolean;
    isCollapsed?: boolean;
    leftIconName?: IconName;
    onClickCallback?: () => void;
    rightIconName?: IconName;
  };

export const SidebarNavLink = ({
  activeClassName = safeCss('bg-white'),
  color = 'primary',
  exact = false,
  inactiveClassName,
  isActive = false,
  isBeta,
  isCollapsed,
  label,
  leftIconName,
  onClickCallback,
  rightIconName,
  ...props
}: SidebarNavLinkProps) => {
  const resolvedColor = match<SidebarNavLinkColor, MantineColors>(color)
    .with('danger', () => 'red')
    .with('primary', () => 'navy')
    .exhaustive();

  return (
    <Tooltip disabled={!isCollapsed} label={label} position="right">
      {/* @ts-expect-error Fake news */}
      <MNavLink
        // active={isActive}
        activeOptions={{
          exact,
          includeSearch: true,
        }}
        activeProps={{
          className: activeClassName,
        }}
        classNames={{
          label: getLabelClassName({ color, isActive }),
          root: concatClasses(
            safeCss('hover-background-effect rounded-md p-sm gap-x-xs no-underline'),
            applyClass(isCollapsed, 'size-xxl'),
          ),
        }}
        color={resolvedColor}
        component={TLink}
        inactiveProps={{
          className: inactiveClassName,
        }}
        label={
          isCollapsed && leftIconName ? <Icon color={isActive ? 'primary' : 'tertiary'} name={leftIconName} /> : label
        }
        leftSection={
          isCollapsed ? null : leftIconName ? (
            <Icon color={isActive ? 'primary' : 'tertiary'} name={leftIconName} />
          ) : undefined
        }
        onClick={onClickCallback}
        rightSection={
          isBeta && !isCollapsed ? (
            <BetaBadge />
          ) : rightIconName ? (
            <Icon color={isActive ? 'primary' : 'tertiary'} name={rightIconName} />
          ) : undefined
        }
        {...props}
      />
    </Tooltip>
  );
};

const getLabelClassName = ({ color, isActive }: PickRequired<SidebarNavLinkProps, 'color' | 'isActive'>) => {
  const labelClassMap = {
    danger: safeCss('text-textError'),
    primary: safeCss(isActive ? 'text-textPrimary' : 'text-textTertiary'),
  };

  return concatClasses(safeCss('font-bold text-h5'), labelClassMap[color]);
};
