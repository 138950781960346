import { validators } from '../utils';
import { makeFormComponent, makeFormHook } from '../utils/factory';
import type { InferFormType } from '../utils/types';

const changeEmailFormSchema = validators.object({
  email: validators.email(),
  password: validators.legacyPassword(),
});

export type ChangeEmailFormSchema = InferFormType<typeof changeEmailFormSchema>;

const changeEmailFormDefaultValues: ChangeEmailFormSchema = {
  email: '',
  password: '',
};

export const useChangeEmailForm = makeFormHook(changeEmailFormSchema, changeEmailFormDefaultValues);

export const ChangeEmailForm = makeFormComponent(changeEmailFormSchema, changeEmailFormDefaultValues);
