import { getThreadTitle } from '@frontend/api-client';
import { groupByDate, reverse, sortedGroupByDate } from '@frontend/duck-tape';
import { useCallback, useEffect, useFeatureIsOn, useMemo, useState } from '@frontend/react';
import type { FlatListProps } from '@frontend/web-react';
import { Clickable, Divider, FlatList, Icon, Text, Tooltip, XStack, YStack } from '@frontend/web-react';
import { applyClass, concatClasses } from '@frontend/web-utils';
import { useLazyListThreadsQuery } from '@frontend/web/hooks';
import { Outlet, createFileRoute, useMatchRoute } from '@tanstack/react-router';

export type ThreadCardProps = {
  isActive: boolean;
  label: string;
  onClick?: () => void;
};

const ThreadCard = ({ isActive, label, onClick }: ThreadCardProps) => {
  return (
    <Clickable
      className={concatClasses('p-sm rounded-md', applyClass(isActive, 'bg-surfacePrimary', 'bg-white'))}
      enableHoverEffect
      onClick={onClick}
    >
      <Text lineClamp={1} type="h6">
        {label}
      </Text>
    </Clickable>
  );
};

const Thread = () => {
  const { enabled: isThreadsEnabled } = useFeatureIsOn('is_duckbill_research_enabled');
  const matchRoute = useMatchRoute();
  const navigate = Route.useNavigate();
  const [getThreads, { data = [], isUninitialized }] = useLazyListThreadsQuery();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const onUpdateThreads = useCallback(() => {
    if (isThreadsEnabled) {
      getThreads();
    }
  }, [isThreadsEnabled]);

  useEffect(() => {
    if (isUninitialized) {
      onUpdateThreads();
    }
  }, [isUninitialized, isThreadsEnabled]);

  useEffect(() => {
    if (!isThreadsEnabled) {
      navigate({
        to: '/app',
      });
    }
  }, [isThreadsEnabled]);

  const { groupedByDateData, oldestGroupWithEntries } = useMemo(() => {
    const groupedByDateData = sortedGroupByDate(groupByDate(data, ({ createdAt }) => createdAt));
    const entriesLength = groupedByDateData.length;
    const oldestGroupWithEntries = reverse(groupedByDateData).find(([, threads]) => threads.length > 0);
    return {
      entriesLength,
      groupedByDateData,
      oldestGroupWithEntries,
    };
  }, [data]);

  // @ts-expect-error Tedious types
  const currentThreadThreadId = matchRoute({ fuzzy: false, to: '/app/thread/$threadId' }).threadId;

  type Label = (typeof groupedByDateData)[number][0];

  const onClickItem = ({ threadId }: { threadId: string }) =>
    navigate({
      params: { threadId },
      to: '/app/thread/$threadId',
    });

  const renderItem: FlatListProps<(typeof groupedByDateData)[number]>['renderItem'] = ({ index, item }) => {
    const sectionLabel = item[0] as Label;
    const threads = item[1];
    const hideDivider = Boolean(oldestGroupWithEntries && sectionLabel === oldestGroupWithEntries[0]);

    if (!threads.length) {
      return null;
    }

    return (
      <YStack className="mb-sm" gapY="sm" key={index}>
        <Text color="tertiary" type="captionBold">
          {sectionLabel}
        </Text>
        {threads.map((thread) => (
          <ThreadCard
            isActive={currentThreadThreadId === thread.id}
            key={thread.id}
            label={getThreadTitle(thread)}
            onClick={() => onClickItem({ threadId: thread.id })}
          />
        ))}
        {hideDivider ? null : <Divider />}
      </YStack>
    );
  };

  if (!isThreadsEnabled) {
    return <div />;
  }

  return (
    <XStack className="flex-1">
      {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
      <YStack className="min-w-[280px] border-r-borderPrimary border-r-1 overflow-y-auto h-screen">
        <FlatList
          ListEmptyComponent={
            <YStack className="gap-y-sm mb-sm">
              <Text color="tertiary" type="captionBold">
                Today
              </Text>
              <ThreadCard isActive label="New thread" />
            </YStack>
          }
          ListHeaderComponent={
            <YStack className="gap-y-sm mb-md">
              <Text color="primary" type="h5">
                Recent threads
              </Text>
              <Tooltip
                className="bg-white text-textTertiary p-md rounded-lg shadow-three text-p-sm"
                label="Threads are chats with our AI assistant Bill. Start your requests with Bill and he will help you triage your request to our team of amazing copilots to work on your tasks."
                multiline
                opened={isTooltipOpen}
                position="bottom-start"
                transitionProps={{ duration: 300, transition: 'fade-up' }}
                w={250}
              >
                <Clickable
                  className="shadow-transparent"
                  onMouseOut={() => setIsTooltipOpen(false)}
                  onMouseOver={() => setIsTooltipOpen(true)}
                >
                  <XStack alignItems="center" gapX="xs">
                    <Text color="tertiary" type="captionBold">
                      What are threads?
                    </Text>
                    <Icon color="tertiary" name={isTooltipOpen ? 'IconChevronUp' : 'IconChevronDown'} />
                  </XStack>
                </Clickable>
              </Tooltip>
            </YStack>
          }
          className="px-md py-md"
          data={data.length ? groupedByDateData : []}
          renderItem={renderItem}
        />
      </YStack>
      <YStack className="w-full overflow-hidden">
        <Outlet />
      </YStack>
    </XStack>
  );
};

export const Route = createFileRoute('/app/thread')({
  component: Thread,
});
