import type { FontSize } from '@frontend/constants';
import type { StrictExtract, StrictOmit } from '@frontend/duck-tape';
import { match } from '@frontend/duck-tape';
import { forwardRef, type ReactNode } from '@frontend/react';
import { applyClass, concatClasses, safeCss } from '@frontend/web-utils';
import type { TextProps as MTextProps } from '@mantine/core';
import { Text as MText } from '@mantine/core';
import type { ColorVariant } from '../../../types';

export type TextColorVariant =
  | StrictExtract<ColorVariant, 'danger' | 'inverse' | 'primary' | 'secondary' | 'success' | 'tertiary'>
  | 'accent'
  | 'orange'
  | 'pink'
  | 'purple'
  | 'warning'
  | 'yellow';

export type TextProps = StrictOmit<MTextProps, 'color' | 'size' | 'variant'> & {
  children: ReactNode;
  color?: TextColorVariant;
  isSelectable?: boolean;
  onClick?: (e: React.MouseEvent<HTMLParagraphElement>) => void;
  textAlign?: 'center' | 'end' | 'start';
  title?: string;
  type?: FontSize;
  uppercase?: boolean;
};

export const Text = forwardRef<HTMLParagraphElement, TextProps>(
  (
    {
      className,
      color = 'primary',
      isSelectable = true,
      onClick,
      textAlign = 'start',
      title = '',
      type = 'p-md',
      uppercase = false,
      ...props
    },
    ref,
  ) => {
    const classNames: TextProps['classNames'] = {
      root: concatClasses(
        applyClass(!!onClick, 'hover-opacity-effect cursor-pointer'),
        applyClass(uppercase, 'uppercase'),
        applyClass(!isSelectable, 'select-none'),
        getTextAlignClassName({ textAlign }),
        getTextColorClassName({ className, color }),
        getTextSizeClassName({ type }),
        className,
      ),
    };
    return <MText classNames={classNames} onClick={onClick} ref={ref} title={title} {...props} />;
  },
);

export const getTextAlignClassName = ({ textAlign }: PickRequired<TextProps, 'textAlign'>) =>
  match(textAlign)
    .with('center', () => safeCss('text-center'))
    .with('end', () => safeCss('text-end'))
    .with('start', () => safeCss('text-start'))
    .exhaustive();

export const getTextSizeClassName = ({ type }: PickRequired<TextProps, 'type'>) =>
  match(type)
    .with('h1', () => safeCss('text-h1'))
    .with('h2', () => safeCss('text-h2'))
    .with('h3', () => safeCss('text-h3'))
    .with('h4', () => safeCss('text-h4'))
    .with('h5', () => safeCss('text-h5'))
    .with('h6', () => safeCss('text-h6'))
    .with('p-sm', () => safeCss('text-p-sm'))
    .with('p-md', () => safeCss('text-p-md'))
    .with('p-lg', () => safeCss('text-p-lg'))
    .with('accent', () => safeCss('text-accent'))
    .with('caption', () => safeCss('text-caption'))
    .with('captionBold', () => safeCss('text-captionBold'))
    .exhaustive();

export const getTextColorClassName = ({
  className,
  color,
}: Pick<TextProps, 'className'> & PickRequired<TextProps, 'color'>) =>
  // TODO (ethan): Temporary hotfix-- eventually I'll add a tailwind class de-duping utility instead
  className && className.includes('text-')
    ? ''
    : match(color)
        .with('primary', () => safeCss('text-textPrimary'))
        .with('secondary', () => safeCss('text-textSecondary'))
        .with('tertiary', () => safeCss('text-textTertiary'))
        .with('inverse', () => safeCss('text-textInverse'))
        .with('success', () => safeCss('text-textSuccess'))
        .with('danger', () => safeCss('text-textError'))
        .with('accent', () => safeCss('text-textAccent'))
        .with('purple', () => safeCss('text-violet-950'))
        .with('orange', () => safeCss('text-rose-900'))
        .with('pink', () => safeCss('text-pink-950'))
        .with('yellow', () => safeCss('text-yellow-900'))
        .with('warning', () => safeCss('text-rose-600'))
        .exhaustive();
