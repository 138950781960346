import type { SetOptional, StrictOmit } from '@frontend/duck-tape';
import { safeCss } from '@frontend/web-utils';
import type { TextareaProps as MTextareaProps } from '@mantine/core';
import { Textarea as MTextarea } from '@mantine/core';
import type { InputLabelProps } from '../../InputLabel';
import { InputLabel } from '../../InputLabel';

export type TextareaInputProps = StrictOmit<MTextareaProps, 'labelProps' | 'onChange' | 'variant'> & {
  height?: number;
  label: string;
  labelProps?: SetOptional<InputLabelProps, 'label'>;
  onChange: (value: string) => void;
  showOptionalText?: boolean;
};

export const TextareaInput = ({
  height = 56,
  label,
  labelProps,
  maxRows = 10,
  minRows = 5,
  onChange,
  showOptionalText,
  ...props
}: TextareaInputProps) => {
  const textareaInputClassNames: MTextareaProps['classNames'] = {
    description: safeCss('border-1'),
    input: safeCss('p-md text-p-md rounded-sm focus-within:border-textPrimary'),
    root: safeCss('gap-y-xs flex flex-col'),
  };

  return (
    <MTextarea
      autosize
      classNames={textareaInputClassNames}
      label={label ? <InputLabel label={label} showOptionalText={showOptionalText} {...labelProps} /> : null}
      maxRows={maxRows}
      minRows={minRows}
      onChange={(e) => onChange(e.currentTarget.value)}
      styles={{
        input: { height },
      }}
      {...props}
    />
  );
};
