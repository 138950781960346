import type { StrictOmit } from '@frontend/duck-tape';
import { match } from '@frontend/duck-tape';
import { useMediaQuery } from '@mantine/hooks';

export const useIsOnMobile = () => useMediaQuery('(max-width: 639px)', true);

// Hook for tablet screens (from 640px to 1023px)
export const useIsOnTablet = () => useMediaQuery('(min-width: 640px) and (max-width: 1023px)', true);

// Hook for desktop screens (from 1024px to 1279px)
export const useIsOnSmallDesktop = () => useMediaQuery('(min-width: 1024px) and (max-width: 1279px)', true);

// Hook for large desktop screens (from 1280px to 1535px)
export const useIsOnLargeDesktop = () => useMediaQuery('(min-width: 1280px) and (max-width: 1535px)', true);

// Hook for extra large desktop screens (1536px and up)
export const useIsOnXLargeDesktop = () => useMediaQuery('(min-width: 1536px)', true);

export const useIsOnAnyDesktop = () => useMediaQuery('(min-width: 1024px)', true);

export const useBreakpoints = () => {
  const isAnyDesktop = useIsOnAnyDesktop();
  const isLargeDesktop = useIsOnLargeDesktop();
  const isMobile = useIsOnMobile();
  const isSmallDesktop = useIsOnSmallDesktop();
  const isTablet = useIsOnTablet();
  const isXLargeDesktop = useIsOnXLargeDesktop();

  const breakpoints = {
    isAnyDesktop,
    isLargeDesktop,
    isMobile,
    isSmallDesktop,
    isTablet,
    isXLargeDesktop,
  };

  const currentBreakpoint = match<
    StrictOmit<typeof breakpoints, 'isAnyDesktop'>,
    'extraLargeDesktop' | 'largeDesktop' | 'mobile' | 'smallDesktop' | 'tablet'
  >({
    isLargeDesktop,
    isMobile,
    isSmallDesktop,
    isTablet,
    isXLargeDesktop,
  })
    .with(
      {
        isLargeDesktop: true,
      },
      () => 'largeDesktop',
    )
    .with(
      {
        isMobile: true,
      },
      () => 'mobile',
    )
    .with(
      {
        isSmallDesktop: true,
      },
      () => 'smallDesktop',
    )
    .with(
      {
        isXLargeDesktop: true,
      },
      () => 'extraLargeDesktop',
    )

    .with(
      {
        isTablet: true,
      },
      () => 'tablet',
    )
    .otherwise(() => 'mobile');
  return {
    currentBreakpoint,
    ...breakpoints,
  };
};
