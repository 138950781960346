import type { ValueOf } from 'type-fest';
import type { CrossPlatformConfig } from '../types';

const nativeSpacing = {
  // eslint-disable-next-line quote-props
  '1': 1,
  lg: 24,
  md: 16,
  none: 0,
  sm: 8,
  xl: 32,
  xs: 4,
  xxl: 40,
  xxxl: 80,
};

// Not rem because we want spacing to be consistent regardless of the base font size
const webSpacing = {
  // eslint-disable-next-line quote-props
  '1': '1px',
  lg: '24px',
  md: '16px',
  none: '0px',
  sm: '8px',
  xl: '32px',
  xs: '4px',
  xxl: '40px',
  xxxl: '80px',
};

export const spacing = {
  native: nativeSpacing,
  web: webSpacing,
} satisfies CrossPlatformConfig<
  Record<keyof typeof nativeSpacing, ValueOf<typeof nativeSpacing>>,
  Record<keyof typeof nativeSpacing, ValueOf<typeof webSpacing>>
>;

export type Spacing = keyof typeof nativeSpacing;
