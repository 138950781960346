import { convertDateLikeToDateISOString, dayjs } from '@frontend/duck-tape';
import { validators } from '../utils';
import { makeFormComponent, makeFormHook } from '../utils/factory';
import type { InferFormType, SelectOption } from '../utils/types';

const snoozeTaskFormSchema = validators.object({
  customSnoozeUntilDate: validators.dateISOString(),
  singleOptionSnoozeUntilDate: validators.dateISOString().optional(),
});

export type SnoozeTaskFormSchema = InferFormType<typeof snoozeTaskFormSchema>;

export const SNOOZE_OPTIONS: SelectOption<DateISOString, never, { label: string }>[] = [
  { label: 'In a week', value: convertDateLikeToDateISOString(dayjs().add(1, 'week')) },
  { label: 'In 2 weeks', value: convertDateLikeToDateISOString(dayjs().add(2, 'week')) },
  { label: 'Next month', value: convertDateLikeToDateISOString(dayjs().add(1, 'month')) },
  { label: 'Next quarter', value: convertDateLikeToDateISOString(dayjs().add(1, 'quarter')) },
  { label: 'Next year', value: convertDateLikeToDateISOString(dayjs().add(1, 'year')) },
];

export const snoozeTaskFormDefaultValues: SnoozeTaskFormSchema = {
  // @ts-expect-error Tedious types
  customSnoozeUntilDate: undefined,
  singleOptionSnoozeUntilDate: undefined,
};

export const useSnoozeTaskForm = makeFormHook(snoozeTaskFormSchema, snoozeTaskFormDefaultValues);

export const SnoozeTaskForm = makeFormComponent(snoozeTaskFormSchema, snoozeTaskFormDefaultValues);
