import { dayjs } from '@frontend/duck-tape';
import { useEffect, useState } from '@frontend/react';
import { DateInput, Icon, Text, XStack, YStack, useShortcut } from '@frontend/web-react';
import { useCreateTaskContext } from '@frontend/web/hooks/context/useCreateTaskContext';

export const DateNeeded = () => {
  const { createTaskState, decrementStep, incrementStep, isOnLastStep, isSubmitting, updateTaskData } =
    useCreateTaskContext();
  const { dateNeeded } = createTaskState.taskData;
  const [isStepComplete, setIsStepComplete] = useState(!!dateNeeded);
  const isSubmitDisabled = !isStepComplete;

  useEffect(() => {
    setIsStepComplete(!!dateNeeded);
  }, [dateNeeded]);

  useShortcut({
    Enter: {
      isDisabled: isSubmitDisabled,
      onSubmit: incrementStep,
    },
  });

  return (
    <YStack gap="md">
      <XStack>
        {createTaskState.currentStep !== createTaskState.steps[0] ? (
          <Icon name="IconArrowLeft" onClick={decrementStep} size={18} />
        ) : null}
      </XStack>
      <YStack>
        <YStack gapY="xs">
          <Text type="captionBold">Date Needed</Text>
          <Text color="tertiary" type="p-sm">
            Choose the date by which your task must be completed
          </Text>
        </YStack>
        <DateInput
          label=""
          minDate={dayjs().add(1, 'day').toDate()}
          onChange={(dateNeeded: DateISOString | undefined) => {
            updateTaskData({ dateNeeded });
          }}
          rightSection={<Icon className="mr-md" color="tertiary" name="IconCalendarEvent" size={24} />}
          type="default"
          value={dateNeeded}
        />
      </YStack>
      <XStack justifyContent="end">
        <Icon
          color="primary"
          disabled={isSubmitDisabled}
          enableBackgroundColor
          isLoading={isSubmitting}
          name={isOnLastStep ? 'IconSend' : 'IconArrowRight'}
          onClick={incrementStep}
          rounded
          size="lg"
        />
      </XStack>
    </YStack>
  );
};
