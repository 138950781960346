import { useEffect, useFeatureIsOn } from '@frontend/react';
import { StoriesSlides } from '@frontend/web/components';
import { useListStoriesQuery } from '@frontend/web/hooks';
import { createFileRoute } from '@tanstack/react-router';

export const Stories = () => {
  const { data: stories } = useListStoriesQuery();
  const navigate = Route.useNavigate();
  const { enabled: isThreadsEnabled } = useFeatureIsOn('is_duckbill_research_enabled');

  const onComplete = () => navigate({ to: isThreadsEnabled ? '/app/thread/create' : '/app/task' });

  useEffect(() => {
    if (stories && !stories?.length) {
      onComplete();
    }
  }, [stories]);

  if (!stories?.length) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return <StoriesSlides onComplete={onComplete} />;
};

export const Route = createFileRoute('/stories')({
  component: Stories,
});
