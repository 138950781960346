import type { StrictExtract } from '@frontend/duck-tape';
import { match } from '@frontend/duck-tape';
import type { ColorVariant } from '@frontend/web-react/types';
import { concatClasses, safeCss } from '@frontend/web-utils';
import type { BadgeProps as MBadgeProps } from '@mantine/core';
import { Badge as MBadge } from '@mantine/core';
import { Icon } from '../Icon/Icon';
import type { IconName } from '../Icon/helpers';

type BadgeColorVariant = StrictExtract<ColorVariant, 'danger' | 'primary' | 'success'>;

type BadgeProps = Omit<MBadgeProps, 'classNames' | 'color' | 'rounded' | 'variant'> & {
  bold?: boolean;
  color?: BadgeColorVariant;
  leftIconName?: IconName;
  rightIconName?: IconName;
  rounded?: boolean;
  uppercase?: boolean;
};

export const Badge = ({
  bold = false,
  color = 'primary',
  leftIconName,
  leftSection,
  rightIconName,
  rightSection,
  rounded = false,
  uppercase = false,
  ...props
}: BadgeProps) => {
  const classNames: MBadgeProps['classNames'] = {
    label: labelClassName({ bold, color, uppercase }),
    root: rootClassName({ color, rounded }),
  };

  return (
    <MBadge
      classNames={classNames}
      leftSection={leftIconName ? <Icon color={color} name={leftIconName} /> : leftSection}
      rightSection={rightIconName ? <Icon color={color} name={rightIconName} size="xs" /> : rightSection}
      {...props}
    />
  );
};

const labelClassName = ({ bold, color, uppercase }: PickRequired<BadgeProps, 'bold' | 'color' | 'uppercase'>) => {
  const baseClassName = safeCss('text-caption');
  return concatClasses(
    baseClassName,
    safeCss(bold ? 'font-bold' : 'font-medium'),
    safeCss(uppercase ? 'uppercase' : 'normal-case'),
    match(color)
      .with('primary', () => safeCss(' text-textPrimary'))
      .with('success', () => safeCss(' text-textSuccess'))
      .with('danger', () => safeCss(' text-textError'))
      .exhaustive(),
  );
};

const rootClassName = ({ color, rounded }: PickRequired<BadgeProps, 'color' | 'rounded'>) => {
  const baseClassName = safeCss('border-1 px-sm py-none gap-x-xs');

  return concatClasses(
    baseClassName,
    safeCss(rounded ? `rounded-xxl` : `rounded-sm`),
    match(color)
      .with('primary', () => safeCss(' border-textPrimary bg-surfaceAccent'))
      .with('success', () => safeCss(' bg-surfaceSuccess'))
      .with('danger', () => safeCss(' bg-surfaceError'))
      .exhaustive(),
  );
};
