import { curriedReplaceAll, pipe } from '@frontend/duck-tape';
import type { ClassName } from '@frontend/web-utils';
import { concatClasses, safeCss } from '@frontend/web-utils';
import DOMPurify from 'dompurify';

// Add a hook to make all links open a new window
DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
  }
  // set non-HTML/MathML links to xlink:show=new
  if (!node.hasAttribute('target') && (node.hasAttribute('xlink:href') || node.hasAttribute('href'))) {
    node.setAttribute('xlink:show', 'new');
  }
});

// Parsing the html using a library like html-react-parser is difficult, because the html is not always well-formed and dealing with nesting
// is difficult. This is the simplest implementation that works
export const RenderHTML = ({ className, html }: { className?: ClassName; html: string }) => {
  const finalHtml = pipe(
    html,
    curriedReplaceAll('<a', `<a class="${safeCss('underline-offset-2 underline')}" target="_blank"`),
    curriedReplaceAll(
      'ql-align-justify',
      `ql-align-justify ${safeCss('line-clamp-2 break-words text-p-md font-normal')}`,
    ),
    curriedReplaceAll('<br>', '<br/>'),
    curriedReplaceAll('<ul', `<ul class="${safeCss('list-disc pl-xl')}"`),
    curriedReplaceAll('<ol', `<ol class="${safeCss('list-disc pl-xl')}"`),
  );

  return (
    <div
      // break-all breaks long text blocks with no spaces
      className={concatClasses('break-words', className)}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(finalHtml) }}
    ></div>
  );
};
