import { formatEndpoint } from '@frontend/duck-tape';

const namespace = 'clients';

const LOG_IN = 'log-in';
const RETRIEVE_CLIENT = 'retrieve';
const RETRIEVE_CLIENT_STATUS = 'status';
const REFERRER = 'referrer';

export const CLIENT_ENDPOINTS = {
  changeEmail: () => formatEndpoint('email/change', namespace),
  createClient: () => formatEndpoint('', namespace),
  editProfile: () => formatEndpoint('update', namespace),
  logIn: () => formatEndpoint(LOG_IN, namespace),
  retrieveClient: () => formatEndpoint(RETRIEVE_CLIENT, namespace),
  retrieveClientStatus: () => formatEndpoint(RETRIEVE_CLIENT_STATUS, namespace),
  retrieveReferrerDetails: (referrerId: string) =>
    formatEndpoint(REFERRER, namespace, { queryParams: { referrer_id: referrerId } }),
};
