import type { GetLinkPropsFromRouter } from '@frontend/web-react/types';
import { GenericErrorPage } from '@frontend/web/errorPages/GenericError';
import { routeTree } from '@frontend/web/routeTree.gen';
import type { RoutePaths } from '@tanstack/react-router';
import { createRouter } from '@tanstack/react-router';

declare module '@tanstack/react-router' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Register {
    router: typeof router;
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HistoryState {
    routeHistoryStack?: RoutePaths<Router['routeTree']>[];
  }
}

export const router = createRouter({
  context: { authentication: undefined },
  defaultErrorComponent: () => <GenericErrorPage subtitle="Something went wrong." />,
  defaultNotFoundComponent: () => <GenericErrorPage subtitle="This page doesn't exist." />,
  routeTree,
});

export const appendRouteHistoryStack = (route: RoutePaths<Router['routeTree']>): RoutePaths<Router['routeTree']>[] => {
  const routeHistoryStack = router.history.location.state.routeHistoryStack || [];
  return [...routeHistoryStack, route];
};

export type Router = typeof router;

export type DerivedLinkProps<
  TFrom extends RoutePaths<Router['routeTree']> | string = string,
  TTo extends string = '',
  TMaskFrom extends RoutePaths<Router['routeTree']> | string = TFrom,
  TMaskTo extends string = '',
> = GetLinkPropsFromRouter<Router, TFrom, TTo, TMaskFrom, TMaskTo>;
