import { P, match } from '@frontend/duck-tape';
import { useState } from '@frontend/react';
import { concatClasses, safeCss } from '@frontend/web-utils';
import { Dialog } from '@mantine/core';
import { YStack } from './Primitives';
import { Text } from './Primitives/Texts/Text';

export type EnvironmentDialogProps = {
  apiUrl: string;
  env: EnvType;
};

export const EnvironmentDialog = ({ apiUrl, env }: EnvironmentDialogProps) => {
  const [opened, setOpened] = useState(true);
  const bgClassName = match<EnvType>(env)
    .with('development', () => safeCss('bg-blue-600'))
    .with('local', () => safeCss('bg-green-600'))
    .with('staging', () => safeCss('bg-yellow-500'))
    // Should never get here
    .with('production', () => '')
    .exhaustive();

  const { api, textClassName } = match(apiUrl)
    .with(P.string.includes('dev'), () => ({ api: 'development', textClassName: safeCss('text-blue-600') }))
    .with(P.string.includes('staging'), () => ({ api: 'staging', textClassName: safeCss('text-yellow-500') }))
    .otherwise(() => ({ api: 'invalid', textClassName: '' }));

  if (!env || env === 'production') {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
  return (
    <Dialog
      className="flex flex-row items-center gap-x-md border-1 border-borderPrimary"
      onClose={() => setOpened(false)}
      opened={opened}
      radius="md"
      shadow="xl"
      withCloseButton
    >
      <YStack className="mr-lg">
        <YStack className={concatClasses('w-md h-md rounded-lg', bgClassName)} />
      </YStack>
      <Text type="h6">
        You are on the {env} environment, pointing to the{' '}
        <Text className={textClassName} span type="h6">
          {api}
        </Text>{' '}
        API
      </Text>
    </Dialog>
  );
};
