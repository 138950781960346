import type { ThreadTaskSuggestionMessage } from '@frontend/api-types';
import { isLast } from '@frontend/duck-tape';
import { Clickable, Icon, Text, XStack, YStack } from '@frontend/web-react';
import { useAnalytics } from '@frontend/web/hooks';
import { useCreateTaskContext } from '@frontend/web/hooks/context/useCreateTaskContext';
import type { ThreadReferenceForTask } from '@frontend/web/routes/app/thread/$threadId';
import { ThreadSuggestionCard } from './ThreadSuggestionCard';

type ThreadSuggestionMessageProps = {
  isStreaming: boolean;
  message: ThreadTaskSuggestionMessage;
  messageIndex: number;
  onClickSuggestionCard: (researchThreadReferenceData: ThreadReferenceForTask) => void;
  onCreateTask: () => Promise<void>;
  researchThreadId: string;
};

export const ThreadSuggestionMessage = ({
  isStreaming,
  message,
  messageIndex,
  onClickSuggestionCard,
  onCreateTask,
  researchThreadId,
}: ThreadSuggestionMessageProps) => {
  const { track } = useAnalytics();
  const { openCreateTaskModal } = useCreateTaskContext();

  const composedOnClickSuggestionCard = (researchThreadReferenceData: ThreadReferenceForTask) => {
    onClickSuggestionCard(researchThreadReferenceData);
    if (
      !message.content?.[researchThreadReferenceData.taskSuggestionIdx]?.taskId &&
      !message.content?.[researchThreadReferenceData.taskSuggestionIdx]?.recurrenceId
    ) {
      track({
        data: {
          id: researchThreadReferenceData.id,
          isRecurrence: researchThreadReferenceData.suggestion.isRecurrence,
          researchThreadId,
        },
        event: 'unusedSuggestedTaskCardSelected',
        topic: 'research',
      });
    }
  };

  if (!message.content.length) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <Clickable className="gap-md py-md" disabled={isStreaming}>
      <XStack className="flex-1 justify-between">
        <YStack>
          <Text color="tertiary" isSelectable={false} type="h6">
            Suggested tasks
          </Text>
          <Text color="tertiary" type="caption">
            Tap below to handover tasks to copilot
          </Text>
        </YStack>
        <YStack>
          <Icon
            color="primary"
            enableBackgroundColor
            name="IconPlus"
            onClick={() => {
              openCreateTaskModal({
                data: {
                  researchThreadId: researchThreadId,
                },
                isRecurrence: false,
                onCreate: onCreateTask,
              });
            }}
            rounded
            size="sm"
          />
        </YStack>
      </XStack>
      <YStack className="gap-sm">
        {message.content.map((suggestion, index, array) => (
          <ThreadSuggestionCard
            isAnyStreaming={isStreaming}
            isCurrentStreaming={Boolean(isStreaming ? isLast(array, index) : null)}
            key={index}
            onClick={() =>
              composedOnClickSuggestionCard({
                id: researchThreadId,
                messageIdx: messageIndex,
                suggestion,
                taskSuggestionIdx: index,
              })
            }
            suggestion={suggestion}
          />
        ))}
      </YStack>
    </Clickable>
  );
};
