import type { MembershipPlanType } from '@frontend/api-client';
import { BrowsingBooks } from '@frontend/assets';
import { usePromoSlice, useState } from '@frontend/react';
import {
  Button,
  Card,
  Icon,
  ScrollableContentPanel,
  SplitImagePageContainer,
  Text,
  XStack,
  YStack,
} from '@frontend/web-react';
import { useOnboardingContext } from '@frontend/web/context/OnboardingContext';
import { useLazyRetrievePromotionDetailsQuery } from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';
import { useEffect } from 'react';
import { MembershipPlanCard } from './MembershipPlanCard';
import { OnboardingStepHeader } from './OnboardingStepHeader';

export const SelectMembership = () => {
  const { incrementStep, onboardingState, updateSelectMembership } = useOnboardingContext();
  const {
    setPromoDetails,
    state: { promoCode, promoDetails },
  } = usePromoSlice();
  const [retrievePromotionDetails] = useLazyRetrievePromotionDetailsQuery();

  useEffect(() => {
    if (promoCode && !promoDetails) {
      handleMutation({
        mutation: () => retrievePromotionDetails(promoCode).unwrap(),
        onSuccess: (promoDetails) => {
          setPromoDetails(promoDetails);
        },
        showErrorToast: false,
        showSuccessToast: false,
      });
    }
  }, [promoCode, promoDetails]);

  const [selectedMembershipPlan, setSelectedMembershipPlan] = useState<MaybeUndefined<MembershipPlanType>>(
    onboardingState.selectMembership.selectedPlan,
  );

  const onClickMembershipPlan = (plan: MembershipPlanType) => setSelectedMembershipPlan(plan);

  const submitForm = () => {
    if (selectedMembershipPlan) {
      updateSelectMembership({ selectedPlan: selectedMembershipPlan });
      incrementStep();
    }
  };

  return (
    <SplitImagePageContainer
      imageBackgroundChildren={
        <Card className="shadow-four mx-md" padding="md">
          <Text type="h4">All plans include</Text>
          <XStack gapX="sm">
            <Icon color="secondary" name="IconCheck" />
            <Text color="secondary" type="p-sm">
              Unlimited tasks
            </Text>
          </XStack>
          <XStack gapX="sm">
            <Icon color="secondary" name="IconCheck" />
            <Text color="secondary" type="p-sm">
              Access to our team of expert copilots
            </Text>
          </XStack>
          <XStack gapX="sm">
            <Icon color="secondary" name="IconCheck" />
            <Text color="secondary" type="p-sm">
              Task-anticipating technology, and exclusive perks.
            </Text>
          </XStack>
        </Card>
      }
      imageSource={BrowsingBooks}
    >
      <ScrollableContentPanel
        footer={<Button disabled={!selectedMembershipPlan} fullWidth label="Continue" onClick={submitForm} />}
        header={<OnboardingStepHeader title="Pick your membership plan" />}
        justifyContent="start"
      >
        <YStack gapY="sm">
          <MembershipPlanCard
            isSelected={selectedMembershipPlan === 'individual'}
            onClick={onClickMembershipPlan}
            plan={'individual'}
            promoDetails={promoDetails}
          />
          <MembershipPlanCard
            isSelected={selectedMembershipPlan === 'household'}
            onClick={onClickMembershipPlan}
            plan={'household'}
            promoDetails={promoDetails}
          />
          <MembershipPlanCard
            isSelected={selectedMembershipPlan === 'vip'}
            onClick={onClickMembershipPlan}
            plan={'vip'}
            promoDetails={promoDetails}
          />
        </YStack>
        <YStack className="mt-sm">
          <Text color="secondary" type="p-sm">
            *Active tasks are tasks being worked on by our copilots. Members can always submit unlimited tasks, and any
            task above your plan limit will go into your drafts until {"you're"} ready to tackle them.
          </Text>
        </YStack>
      </ScrollableContentPanel>
    </SplitImagePageContainer>
  );
};
