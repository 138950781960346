import { isNumber, pluralize, safeParseInt } from '@frontend/duck-tape';
import { useState } from '@frontend/react';
import { Card, Icon, LinkButton, NumberInput, Text, XStack, YStack } from '@frontend/web-react';

type RecurrenceBufferInputBannerProps = {
  buffer: number | string;
  isEditing: boolean;
  setBuffer: (value: number) => void;
  setIsEditing: (value: boolean) => void;
};

export const RecurrenceBufferInputBanner = ({
  buffer,
  isEditing,
  setBuffer,
  setIsEditing,
}: RecurrenceBufferInputBannerProps) => {
  const [updatedBuffer, setUpdatedBuffer] = useState<number>();

  const onSubmitEditing = () => {
    if (updatedBuffer !== undefined) {
      setBuffer(updatedBuffer);
    }

    setIsEditing(false);
    setUpdatedBuffer(0);
  };

  return isEditing ? (
    <YStack gapY="sm">
      <Text type="p-md">When should the task begin?</Text>
      <XStack className="grid grid-cols-6 gap-x-md items-center">
        <NumberInput
          autoFocus
          className="col-span-1"
          onBlur={onSubmitEditing}
          onChange={(val) => {
            if (isNumber(val)) {
              setUpdatedBuffer(val);
            }
          }}
          value={updatedBuffer}
        />
        <Text className="col-span-5" type="p-md">
          Days before the date needed
        </Text>
      </XStack>
    </YStack>
  ) : (
    <Card padding="md">
      <XStack alignItems="center" gapX="sm">
        <Icon name="IconInfoCircle" size={24} />
        <YStack gapY="sm">
          <Text>Copilot will start working {pluralize('day', safeParseInt(buffer), true)} before the date needed.</Text>
          <XStack>
            <LinkButton color="primary" label="Edit" leftIconName="IconPencil" onClick={() => setIsEditing(true)} />
          </XStack>
        </YStack>
      </XStack>
    </Card>
  );
};
