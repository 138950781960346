import type { NonNegativeInteger } from '../types';

export const removeByIndex = <T>(list: T[], index: number): T[] => [...list.slice(0, index), ...list.slice(index + 1)];

export const shift = <T>(list: T[]): T[] => list.slice(1);

export const append = <T>(list: T[], ...items: T[]): T[] => [...list, ...items];

export const pop = <T>(list: T[]): T[] => list.slice(0, -1);

export const prepend = <T>(list: T[], ...items: T[]): T[] => [...items, ...list];

export const findFirstN = <T, N extends number>(
  list: T[],
  condition: (item: T) => boolean,
  n: NonNegativeInteger<N>,
): T[] => list.filter(condition).slice(0, n);

export const isLast = <T>(list: T[], index: number) => index === list.length - 1;

// This logic is a lot harder to write in fp/immutable way
export const chunkParts = <T>(array: T[], chunkSize: number) => {
  if (chunkSize <= 0 || !Array.isArray(array)) {
    return [];
  }

  const result = [];
  const totalElements = array.length;
  // eslint-disable-next-line fp/no-let
  let start = 0;

  // eslint-disable-next-line fp/no-loops, fp/no-let, fp/no-mutation
  for (let i = 0; i < chunkSize; i++) {
    // Calculate the size of each part
    const size = Math.ceil((totalElements - start) / (chunkSize - i));
    // Slice the array and push to result
    // eslint-disable-next-line fp/no-mutating-methods
    result.push(array.slice(start, start + size));
    // Move the start index
    // eslint-disable-next-line fp/no-mutation
    start += size;
  }

  return result;
};

export const getPreviousItem = <TStep extends string, TSteps extends readonly TStep[]>(
  steps: TSteps,
  currentStep: TStep,
) => {
  const currentIndex = steps.indexOf(currentStep);
  // Can't decrement if already at the first step
  if (currentIndex === 0) return;
  return steps[currentIndex - 1]!;
};

export const getNextItem = <TStep extends string, TSteps extends readonly TStep[]>(
  steps: TSteps,
  currentStep: TStep,
) => {
  const currentIndex = steps.indexOf(currentStep);
  // Can't increment if already at the last step
  if (currentIndex === steps.length - 1) return;
  return steps[currentIndex + 1]!;
};

export const splitIntoGroups = <T>(array: T[], numberOfGroups: number) => {
  // Initialize an array with the specified number of empty groups
  const initialGroups: T[][] = Array.from({ length: numberOfGroups }, () => []);

  return array.reduce((acc, task, index) => {
    // Determine the group index for the current task
    const groupIndex = (index + 1) % numberOfGroups;
    // Push the task into the appropriate group
    // eslint-disable-next-line fp/no-mutating-methods
    acc[groupIndex]?.push(task);
    return acc;
  }, initialGroups);
};
