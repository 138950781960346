import { P, match } from '@frontend/duck-tape';
import type { ReactNode } from '@frontend/react';
import { Text, YStack } from '@frontend/web-react';

export type ThreadChatWhisperProps = {
  content: ReactNode | { onClickBoldText?: () => void; taskTitle: string };
};

export const ThreadChatWhisper = ({ content }: ThreadChatWhisperProps) => {
  const resolvedContent = match<ThreadChatWhisperProps['content'], ReactNode>(content)
    .with(P.string, (content) => (
      <Text color="tertiary" textAlign="center" type="caption">
        {content}
      </Text>
    ))
    .with({ taskTitle: P.string }, (content) => (
      <Text color="tertiary" textAlign="center" type="caption">
        You created the task{' '}
        <Text className="underline" color="tertiary" onClick={content.onClickBoldText} span type="captionBold">
          {content.taskTitle}
        </Text>
        . Our team of copilots are on it!
      </Text>
    ))
    .otherwise((content) => content);
  return <YStack className="items-center">{resolvedContent}</YStack>;
};
