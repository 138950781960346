import { make } from 'ts-brand';

const dollarToCent = (n: Dollar) => Math.round(n * 100);
const centToDollar = (n: Cent) => Number((n / 100).toFixed(2));

export const makeDollar = make<Dollar>();
export const makeCent = make<Cent>();

export const convertDollarToCent = (input: Dollar): Cent => makeCent(dollarToCent(input));
export const convertCentToDollar = (input: Cent): Dollar => makeDollar(centToDollar(input));

export const applyDiscount = (price: Cent, percentage: number): Cent => makeCent(price - price * (percentage / 100));

export type Currency =
  | 'ADP'
  | 'AED'
  | 'AFA'
  | 'AFN'
  | 'ALK'
  | 'ALL'
  | 'AMD'
  | 'ANG'
  | 'AOA'
  | 'AOK'
  | 'AON'
  | 'AOR'
  | 'ARA'
  | 'ARL'
  | 'ARM'
  | 'ARP'
  | 'ARS'
  | 'ATS'
  | 'AUD'
  | 'AWG'
  | 'AZM'
  | 'AZN'
  | 'BAD'
  | 'BAM'
  | 'BAN'
  | 'BBD'
  | 'BDT'
  | 'BEC'
  | 'BEF'
  | 'BEL'
  | 'BGL'
  | 'BGM'
  | 'BGN'
  | 'BGO'
  | 'BHD'
  | 'BIF'
  | 'BMD'
  | 'BND'
  | 'BOB'
  | 'BOL'
  | 'BOP'
  | 'BOV'
  | 'BRB'
  | 'BRC'
  | 'BRE'
  | 'BRL'
  | 'BRN'
  | 'BRR'
  | 'BRZ'
  | 'BSD'
  | 'BTN'
  | 'BUK'
  | 'BWP'
  | 'BYB'
  | 'BYN'
  | 'BYR'
  | 'BZD'
  | 'CAD'
  | 'CDF'
  | 'CHE'
  | 'CHF'
  | 'CHW'
  | 'CLE'
  | 'CLF'
  | 'CLP'
  | 'CNH'
  | 'CNX'
  | 'CNY'
  | 'COP'
  | 'COU'
  | 'CRC'
  | 'CSD'
  | 'CSK'
  | 'CUC'
  | 'CUP'
  | 'CVE'
  | 'CYP'
  | 'CZK'
  | 'DDM'
  | 'DEM'
  | 'DJF'
  | 'DKK'
  | 'DOP'
  | 'DZD'
  | 'ECS'
  | 'ECV'
  | 'EEK'
  | 'EGP'
  | 'ERN'
  | 'ESA'
  | 'ESB'
  | 'ESP'
  | 'ETB'
  | 'EUR'
  | 'FIM'
  | 'FJD'
  | 'FKP'
  | 'FRF'
  | 'GBP'
  | 'GEK'
  | 'GEL'
  | 'GHC'
  | 'GHS'
  | 'GIP'
  | 'GMD'
  | 'GNF'
  | 'GNS'
  | 'GQE'
  | 'GRD'
  | 'GTQ'
  | 'GWE'
  | 'GWP'
  | 'GYD'
  | 'HKD'
  | 'HNL'
  | 'HRD'
  | 'HRK'
  | 'HTG'
  | 'HUF'
  | 'IDR'
  | 'IEP'
  | 'ILP'
  | 'ILR'
  | 'ILS'
  | 'IMP'
  | 'INR'
  | 'IQD'
  | 'IRR'
  | 'ISJ'
  | 'ISK'
  | 'ITL'
  | 'JMD'
  | 'JOD'
  | 'JPY'
  | 'KES'
  | 'KGS'
  | 'KHR'
  | 'KMF'
  | 'KPW'
  | 'KRH'
  | 'KRO'
  | 'KRW'
  | 'KWD'
  | 'KYD'
  | 'KZT'
  | 'LAK'
  | 'LBP'
  | 'LKR'
  | 'LRD'
  | 'LSL'
  | 'LTL'
  | 'LTT'
  | 'LUC'
  | 'LUF'
  | 'LUL'
  | 'LVL'
  | 'LVR'
  | 'LYD'
  | 'MAD'
  | 'MAF'
  | 'MCF'
  | 'MDC'
  | 'MDL'
  | 'MGA'
  | 'MGF'
  | 'MKD'
  | 'MKN'
  | 'MLF'
  | 'MMK'
  | 'MNT'
  | 'MOP'
  | 'MRO'
  | 'MRU'
  | 'MTL'
  | 'MTP'
  | 'MUR'
  | 'MVP'
  | 'MVR'
  | 'MWK'
  | 'MXN'
  | 'MXP'
  | 'MXV'
  | 'MYR'
  | 'MZE'
  | 'MZM'
  | 'MZN'
  | 'NAD'
  | 'NGN'
  | 'NIC'
  | 'NIO'
  | 'NLG'
  | 'NOK'
  | 'NPR'
  | 'NZD'
  | 'OMR'
  | 'PAB'
  | 'PEI'
  | 'PEN'
  | 'PES'
  | 'PGK'
  | 'PHP'
  | 'PKR'
  | 'PLN'
  | 'PLZ'
  | 'PTE'
  | 'PYG'
  | 'QAR'
  | 'RHD'
  | 'ROL'
  | 'RON'
  | 'RSD'
  | 'RUB'
  | 'RUR'
  | 'RWF'
  | 'SAR'
  | 'SBD'
  | 'SCR'
  | 'SDD'
  | 'SDG'
  | 'SDP'
  | 'SEK'
  | 'SGD'
  | 'SHP'
  | 'SIT'
  | 'SKK'
  | 'SLE'
  | 'SLL'
  | 'SOS'
  | 'SRD'
  | 'SRG'
  | 'SSP'
  | 'STD'
  | 'STN'
  | 'SUR'
  | 'SVC'
  | 'SYP'
  | 'SZL'
  | 'THB'
  | 'TJR'
  | 'TJS'
  | 'TMM'
  | 'TMT'
  | 'TND'
  | 'TOP'
  | 'TPE'
  | 'TRL'
  | 'TRY'
  | 'TTD'
  | 'TVD'
  | 'TWD'
  | 'TZS'
  | 'UAH'
  | 'UAK'
  | 'UGS'
  | 'UGX'
  | 'USD'
  | 'USN'
  | 'USS'
  | 'UYI'
  | 'UYP'
  | 'UYU'
  | 'UYW'
  | 'UZS'
  | 'VEB'
  | 'VED'
  | 'VEF'
  | 'VES'
  | 'VND'
  | 'VNN'
  | 'VUV'
  | 'WST'
  | 'XAF'
  | 'XAG'
  | 'XAU'
  | 'XBA'
  | 'XBB'
  | 'XBC'
  | 'XBD'
  | 'XCD'
  | 'XDR'
  | 'XEU'
  | 'XFO'
  | 'XFU'
  | 'XOF'
  | 'XPD'
  | 'XPF'
  | 'XPT'
  | 'XRE'
  | 'XSU'
  | 'XTS'
  | 'XUA'
  | 'YDD'
  | 'YER'
  | 'YUD'
  | 'YUM'
  | 'YUN'
  | 'YUR'
  | 'ZAL'
  | 'ZAR'
  | 'ZMK'
  | 'ZMW'
  | 'ZRN'
  | 'ZRZ'
  | 'ZWD'
  | 'ZWL'
  | 'ZWN'
  | 'ZWR';

type FormatCurrencyFunction = (pennies: Cent, currency?: Currency) => string;

type FormatterType = 'decimal' | 'exact' | 'natural' | 'rounded';

const formatter = (dollars: Dollar, type: FormatterType = 'exact', currency: string = 'USD') =>
  new Intl.NumberFormat('en-US', {
    currency,
    // 2500.99 -> $2,501
    maximumFractionDigits: type === 'rounded' ? 0 : undefined,
    // 2500.10 -> $2,500.1)
    minimumFractionDigits: type === 'decimal' || type === 'natural' ? 2 : undefined,
    style: 'currency',
  }).format(dollars);

// Example Input/Output: 252000 -> $2,520.00
export const formatCurrencyWithCommasAndDecimal: FormatCurrencyFunction = (pennies, currency) =>
  formatter(convertCentToDollar(pennies), 'decimal', currency);

// Example Input/Output: 252000 -> $2,520
export const formatCurrencyRounded: FormatCurrencyFunction = (pennies) =>
  formatter(convertCentToDollar(pennies), 'rounded');

// Example Input/Outputs:
// 252000 -> $2,520
// 252012 -> $2,520.12
export const formatCurrencyNatural: FormatCurrencyFunction = (pennies) => {
  const dollars = convertCentToDollar(pennies);
  if (pennies % 100 === 0) {
    return formatter(dollars, 'rounded');
  } else {
    return formatter(dollars, 'decimal');
  }
};
