import { createTag } from '@frontend/api-client/utils';
import type { BaseApi } from '@frontend/api-client/utils/types';
import type {
  OptionSheet,
  RetrieveOptionSheetResponse,
  ReviewOptionSheetRequest,
  ReviewOptionSheetResponse,
} from '@frontend/api-types';
import { convertObjectKeysCase } from '@frontend/duck-tape';
import { SHEETS_ENDPOINTS } from './endpoints';

export const getSheetsApi = (baseApi: BaseApi) =>
  baseApi.injectEndpoints({
    endpoints: (builder) => ({
      retrieveOptionSheet: builder.query<RetrieveOptionSheetResponse, string>({
        providesTags: (_result, _error, optionSheetId) => [
          createTag({ id: optionSheetId, prefix: 'optionSheetId', type: 'Sheet' }),
        ],
        query: SHEETS_ENDPOINTS.retrieveOptionSheet,
        transformResponse: (response: GenericRecord) => convertObjectKeysCase(response, 'camelCase') as OptionSheet,
      }),
      reviewOptionSheet: builder.mutation<ReviewOptionSheetResponse, ReviewOptionSheetRequest>({
        invalidatesTags: (_result, _error, { optionSheetId, taskId }) => [
          createTag({ id: taskId, prefix: 'taskId', type: 'Comment' }),
          createTag({ id: optionSheetId, prefix: 'optionSheetId', type: 'Sheet' }),
          createTag({ id: taskId, prefix: 'taskId', type: 'Task' }),
        ],
        query: (payload) => ({
          body: convertObjectKeysCase(payload, 'snakeCase'),
          method: 'POST',
          url: SHEETS_ENDPOINTS.reviewOptionSheet(payload.optionSheetId),
        }),
        transformResponse: (response: GenericRecord) => convertObjectKeysCase(response, 'camelCase') as OptionSheet,
      }),
    }),
    overrideExisting: true,
  });
