import type { CreateClientRequest, RetrieveInviteResponse } from '@frontend/api-types';
import { HelloStranger } from '@frontend/assets';
import { useOnboardingUserInfoForm } from '@frontend/forms';
import {
  Button,
  DateInput,
  Icon,
  PhoneNumberInput,
  ScrollableContentPanel,
  SplitImagePageContainer,
  Text,
  TextInput,
  XStack,
  YStack,
} from '@frontend/web-react';
import { NavLink } from '../../Navigation';
import { OnboardingStepHeader } from '../Onboarding/OnboardingStepHeader';

export type UserInfoStepState = Pick<
  CreateClientRequest,
  'birthday' | 'email' | 'firstName' | 'lastName' | 'nickname' | 'phoneNumber'
>;

type UserInfoProps = {
  incrementStep: () => void;
  inviteDetails?: RetrieveInviteResponse;
  onSubmitUserInfo: (userInfo: UserInfoStepState) => void;
  userInfo: UserInfoStepState;
};

export const UserInfo = ({ incrementStep, inviteDetails, onSubmitUserInfo, userInfo }: UserInfoProps) => {
  const { getControl, isValid, submitForm } = useOnboardingUserInfoForm({
    // @ts-expect-error Tedious types
    initialValues: userInfo,
    onSubmit: (values) => {
      onSubmitUserInfo(values);
      incrementStep();
    },
  });

  return (
    <SplitImagePageContainer imageSource={HelloStranger}>
      <ScrollableContentPanel
        footer={<Button disabled={!isValid} label="Continue" onClick={submitForm} />}
        header={<OnboardingStepHeader title="First things first" />}
        justifyContent="start"
      >
        <YStack gapY="md">
          <YStack className="justify-center" gapY="sm">
            {/* Is api provides this data, don't let use overwrite it */}
            <TextInput
              label={'Email'}
              {...getControl('email', ['value'])}
              autoComplete="email"
              disabled={!!inviteDetails?.email}
              value={inviteDetails?.email || getControl('email').value}
            />
            {inviteDetails?.email ? (
              <XStack alignItems="center" gapX="xs" justifyContent="end">
                <Text>Wrong email?</Text>
                <NavLink bold={false} to="/login">
                  Contact us
                </NavLink>
              </XStack>
            ) : null}
          </YStack>
          <TextInput {...getControl('firstName')} autoComplete="given-name" label={'First name'} />
          <TextInput {...getControl('lastName')} autoComplete="family-name" label={'Last name'} />
          <TextInput
            {...getControl('nickname')}
            autoComplete="nickname"
            label={'What should we call you?'}
            showOptionalText
          />
          {/* Is api provides this data, don't let use overwrite it */}
          <PhoneNumberInput
            {...getControl('phoneNumber', ['value'])}
            disabled={!!inviteDetails?.phoneNumber}
            value={inviteDetails?.phoneNumber || getControl('phoneNumber').value}
          />
          {/* @ts-expect-error Tedious types */}
          <DateInput
            {...getControl('birthday')}
            label="Birthday"
            maxDate={new Date()}
            rightSection={<Icon className="mr-md" color="tertiary" name="IconCalendarEvent" size={24} />}
            type="default"
          />
        </YStack>
      </ScrollableContentPanel>
    </SplitImagePageContainer>
  );
};
