import { validators } from '../utils';
import { makeFormComponent, makeFormHook } from '../utils/factory';
import type { InferFormType } from '../utils/types';

export const loginFormSchema = validators.object({
  email: validators.loginEmail(),
  password: validators.legacyPassword(),
});

export type LogInFormSchema = InferFormType<typeof loginFormSchema>;

export const loginFormDefaultValues: LogInFormSchema = {
  email: '',
  password: '',
};

export const useLoginForm = makeFormHook(loginFormSchema, loginFormDefaultValues);

export const LoginForm = makeFormComponent(loginFormSchema, loginFormDefaultValues);
