import type { TaskExample } from '@frontend/api-types';
import { Card, ExpandableText, Icon, Text, XStack, YStack } from '@frontend/web-react';

export type ExampleCardProps = Pick<TaskExample, 'client' | 'description' | 'id' | 'isUsed' | 'name'> & {
  onClick: () => void;
};

export const ExampleCard = ({ client, description, id, isUsed, name, onClick }: ExampleCardProps) => {
  const isPersonalized = !!client;
  return (
    <Card className="gap-y-md bg-white border-1 border-borderPrimary" key={id} onClick={onClick}>
      <XStack className="gap-x-sm">
        {isPersonalized ? (
          <XStack>
            <Icon name="IconSparkles" />
          </XStack>
        ) : null}
        <Text type="h5">{name}</Text>
        {isUsed ? (
          <XStack>
            <Icon color="success" name="IconCheckbox" />
          </XStack>
        ) : null}
      </XStack>
      <YStack>
        <ExpandableText breakWord labelDirection="left" maxLines={3}>
          {description}
        </ExpandableText>
      </YStack>
    </Card>
  );
};
