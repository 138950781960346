import type { SetRequired, StrictOmit } from '@frontend/duck-tape';
import type { ButtonProps } from '@frontend/web-react';
import { Button } from '@frontend/web-react';
import type { LinkProps, RegisteredRouter, RoutePaths } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';

export type NavLinkButtonProps<
  TRouter extends RegisteredRouter = RegisteredRouter,
  TFrom extends RoutePaths<TRouter['routeTree']> | string = string,
  TTo extends string = '',
  TMaskFrom extends RoutePaths<TRouter['routeTree']> | string = TFrom,
  TMaskTo extends string = '',
> = SetRequired<Pick<LinkProps<TRouter, TFrom, TTo, TMaskFrom, TMaskTo>, 'params' | 'search' | 'to'>, 'to'> &
  StrictOmit<ButtonProps, 'onClick'>;

export const NavLinkButton = <
  TRouter extends RegisteredRouter = RegisteredRouter,
  TFrom extends RoutePaths<TRouter['routeTree']> | string = string,
  TTo extends string = '',
  TMaskFrom extends RoutePaths<TRouter['routeTree']> | string = TFrom,
  TMaskTo extends string = '',
>({
  params,
  search,
  to,
  ...props
}: NavLinkButtonProps<TRouter, TFrom, TTo, TMaskFrom, TMaskTo>) => {
  const navigate = useNavigate<TRouter, TFrom>();

  return <Button onClick={() => navigate({ params, search, to })} {...props} />;
};
