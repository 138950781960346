import { make } from 'ts-brand';

const objectToStrJson = <T extends ValidJson>(json: T): string => JSON.stringify(json);
const strToObjectJson = <T extends ValidJson>(strJson: string): T => JSON.parse(strJson);

// Have to curry to pass down the generic
// @ts-expect-error Doing generic branded types with ts-brand takes a little ts suppression
const makeStrJson = <T extends ValidJson>(str: string) => make<SerializedJson<T>>()(str);

// @ts-expect-error Doing generic branded types with ts-brand takes a little ts suppression

const makeObjectJson = <T extends ValidJson>(object: T) => make<DeserializedJson<T>>()(object);

export const serializeJson = <T extends ValidJson>(json: T): SerializedJson<T> => makeStrJson<T>(objectToStrJson(json));

export const deserializeJson = <T extends ValidJson>(str: SerializedJson<T>): DeserializedJson<T> =>
  // @ts-expect-error Doing generic branded types with ts-brand takes a little ts suppression
  makeObjectJson(strToObjectJson(str as string));
