import type { FlexShrinkGrowNumber, Spacing } from '@frontend/constants';
import type { ClassName } from '@frontend/web-utils';
import { concatClasses, safeCss } from '@frontend/web-utils';

export type FlexStyleConvenientProps = {
  alignContent?: keyof typeof alignContentTwMapping;
  alignItems?: keyof typeof alignItemsTwMapping;
  className?: ClassName;
  flexDirection?: keyof typeof flexDirectionTwMapping;
  flexGrow?: FlexShrinkGrowNumber;
  flexShrink?: FlexShrinkGrowNumber;
  flexWrap?: keyof typeof flexWrapTwMapping;
  gap?: Spacing;
  gapX?: Spacing;
  gapY?: Spacing;
  justifyContent?: keyof typeof justifyContentTwMapping;
};

const gapTwMapping: Record<Spacing, string> = {
  // eslint-disable-next-line quote-props
  '1': safeCss('gap-1'),
  lg: safeCss('gap-lg'),
  md: safeCss('gap-md'),
  none: safeCss('gap-none'),
  sm: safeCss('gap-sm'),
  xl: safeCss('gap-xl'),
  xs: safeCss('gap-xs'),
  xxl: safeCss('gap-xxl'),
  xxxl: safeCss('gap-xxxl'),
};

const xGapTwMapping = {
  // eslint-disable-next-line quote-props
  '1': safeCss('gap-x-1'),
  lg: safeCss('gap-x-lg'),
  md: safeCss('gap-x-md'),
  none: safeCss('gap-x-none'),
  sm: safeCss('gap-x-sm'),
  xl: safeCss('gap-x-xl'),
  xs: safeCss('gap-x-xs'),
  xxl: safeCss('gap-x-xxl'),
  xxxl: safeCss('gap-x-xxxl'),
};

const yGapTwMapping = {
  // eslint-disable-next-line quote-props
  '1': safeCss('gap-y-1'),
  lg: safeCss('gap-y-lg'),
  md: safeCss('gap-y-md'),
  none: safeCss('gap-y-none'),
  sm: safeCss('gap-y-sm'),
  xl: safeCss('gap-y-xl'),
  xs: safeCss('gap-y-xs'),
  xxl: safeCss('gap-y-xxl'),
  xxxl: safeCss('gap-y-xxxl'),
};

const alignItemsTwMapping = {
  baseline: safeCss('items-baseline'),
  center: safeCss('items-center'),
  end: safeCss('items-end'),
  start: safeCss('items-start'),
  stretch: safeCss('items-stretch'),
};

const flexDirectionTwMapping = {
  column: safeCss('flex-col'),
  columnReverse: safeCss('flex-col-reverse'),
  row: safeCss('flex-row'),
  rowReverse: safeCss('flex-row-reverse'),
};

const justifyContentTwMapping = {
  center: safeCss('justify-center'),
  end: safeCss('justify-end'),
  spaceAround: safeCss('justify-around'),
  spaceBetween: safeCss('justify-between'),
  spaceEvenly: safeCss('justify-evenly'),
  start: safeCss('justify-start'),
};

const alignContentTwMapping = {
  center: safeCss('content-center'),
  end: safeCss('content-end'),
  spaceAround: safeCss('content-around'),
  spaceBetween: safeCss('content-between'),
  start: safeCss('content-start'),
  stretch: safeCss('content-stretch'),
};

const flexWrapTwMapping = {
  nowrap: safeCss('flex-nowrap'),
  wrap: safeCss('flex-wrap'),
  wrapReverse: safeCss('flex-wrap-reverse'),
};

const flexShrinkTwMapping: Record<FlexShrinkGrowNumber, ClassName> = {
  0: safeCss('shrink'),
  1: safeCss('shrink-1'),
  10: safeCss('shrink-10'),
  11: safeCss('shrink-11'),
  12: safeCss('shrink-12'),
  2: safeCss('shrink-2'),
  3: safeCss('shrink-3'),
  4: safeCss('shrink-4'),
  5: safeCss('shrink-5'),
  6: safeCss('shrink-6'),
  7: safeCss('shrink-7'),
  8: safeCss('shrink-8'),
  9: safeCss('shrink-9'),
};

const flexGrowTwMapping: Record<FlexShrinkGrowNumber, ClassName> = {
  0: safeCss('grow'),
  1: safeCss('grow-1'),
  10: safeCss('grow-10'),
  11: safeCss('grow-11'),
  12: safeCss('grow-12'),
  2: safeCss('grow-2'),
  3: safeCss('grow-3'),
  4: safeCss('grow-4'),
  5: safeCss('grow-5'),
  6: safeCss('grow-6'),
  7: safeCss('grow-7'),
  8: safeCss('grow-8'),
  9: safeCss('grow-9'),
};

// Usually it's best to memoize when using this function because it likely will be called frequently otherwise
export const flexPropsToClasses = ({
  alignContent,
  alignItems,
  className,
  flexDirection = 'column',
  flexGrow,
  flexShrink,
  flexWrap,
  gap,
  gapX,
  gapY,
  justifyContent,
}: FlexStyleConvenientProps): string =>
  concatClasses(
    safeCss('flex'),
    alignItems ? alignItemsTwMapping[alignItems] : null,
    flexDirection ? flexDirectionTwMapping[flexDirection] : null,
    justifyContent ? justifyContentTwMapping[justifyContent] : null,
    alignContent ? alignContentTwMapping[alignContent] : null,
    flexWrap ? flexWrapTwMapping[flexWrap] : null,
    flexGrow !== undefined ? flexGrowTwMapping[flexGrow] : null,
    flexShrink !== undefined ? flexShrinkTwMapping[flexShrink] : null,
    gap !== undefined ? gapTwMapping[gap] : null,
    gapX !== undefined ? xGapTwMapping[gapX] : null,
    gapY !== undefined ? yGapTwMapping[gapY] : null,
    className,
  );
