import { faqUrl } from '@frontend/constants';
import type { CommonModalProps } from '@frontend/web-react';
import { Anchor, Button, Checkbox, Modal, PageContainer, showToast, Text, XStack, YStack } from '@frontend/web-react';
import { CardCvc, CardExpiry, CardNumber } from '@frontend/web/components';
import {
  useCreateSetupIntentMutation,
  useRetrieveClientQuery,
  useSetupIntentConfirmedMutation,
  useStripeCard,
  useUpdateCardMutation,
} from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';
import { CardNumberElement } from '@stripe/react-stripe-js';
import type { SetupIntent } from '@stripe/stripe-js';
import type { FormEvent } from 'react';
import { useEffect, useState } from 'react';

export type CreditCardFormProps = CommonModalProps & {
  cardId?: string;
  declinedCard?: boolean;
};

export const CreditCardModal = ({ cardId, declinedCard, isOpen, onClose }: CreditCardFormProps) => {
  const { data: client, isLoading: isClientLoading } = useRetrieveClientQuery();
  const [createSetupIntent, { data: stripeSetupIntent, isLoading: isCreateSetupIntentLoading }] =
    useCreateSetupIntentMutation();
  const [setupIntentConfirmed, { isLoading: isSetupIntentConfirmedLoading }] = useSetupIntentConfirmedMutation();
  const [updateCard, { isLoading: isUpdateCardLoading }] = useUpdateCardMutation();

  const [isDefault, setIsDefault] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isEditing = !!cardId;

  const {
    cardCvcInfo,
    cardExpiryInfo,
    cardNumberInfo,
    elements,
    setCardCvcInfo,
    setCardExpiryInfo,
    setCardNumberInfo,
    stripe,
  } = useStripeCard();

  useEffect(() => {
    if (!stripeSetupIntent && isOpen) {
      handleMutation({
        mutation: () => createSetupIntent().unwrap(),
        showSuccessToast: false,
      });
    }
  }, [createSetupIntent, stripeSetupIntent, isOpen]);

  const verifySetupIntent = async () => {
    const cardElement = elements && elements.getElement(CardNumberElement);
    if (stripe && cardElement && client && stripeSetupIntent) {
      return stripe.confirmCardSetup(stripeSetupIntent.stripeClientSecret, {
        payment_method: {
          billing_details: {
            email: client.email,
            name: `${client.firstName} ${client.lastName}`,
            phone: client.phoneNumber,
          },
          card: cardElement,
        },
      });
    } else {
      return;
    }
  };

  const handleAddCard = async (setupIntent: SetupIntent) => {
    handleMutation({
      mutation: () => setupIntentConfirmed({ isDefault, setupIntentId: setupIntent.id }).unwrap(),
      successMessage: 'Card added',
    });
    onClose();
  };

  const handleUpdateCard = async (setupIntent: SetupIntent) => {
    handleMutation({
      mutation: () => updateCard({ id: cardId as string, setupIntentId: setupIntent.id }).unwrap(),
      successMessage: 'Card updated',
    });
    onClose();
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await verifySetupIntent();
    setIsLoading(false);
    if (!response) return;
    const { error, setupIntent } = response;
    if (error) {
      showToast({
        title: error.message || 'Encountered an error while creating payment method',
        variant: 'danger',
      });
    } else if (setupIntent) {
      if (isEditing) {
        await handleUpdateCard(setupIntent);
      } else {
        await handleAddCard(setupIntent);
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      subtitle={declinedCard ? 'Update your credit card info to continue using Duckbill.' : ''}
      title={declinedCard ? 'Your last payment failed' : isEditing ? 'Edit default card' : 'Add new card'}
      titlesAlignment="start"
    >
      <PageContainer className="px-none" isLoading={isClientLoading || isCreateSetupIntentLoading} padding="none">
        <form onSubmit={handleSubmit}>
          <YStack className="gap-lg">
            <YStack className="gap-lg">
              <CardNumber onChange={setCardNumberInfo} />
              <XStack className="gap-md">
                <CardExpiry onChange={setCardExpiryInfo} />
                <CardCvc onChange={setCardCvcInfo} />
              </XStack>
              {!isEditing ? (
                <>
                  <Checkbox
                    checked={isDefault}
                    label="Make this card default"
                    onChange={() => setIsDefault(!isDefault)}
                  />
                  <Text color="tertiary" type="caption">
                    We ask for your credit card to make task purchases seamless and efficient. Rest assured, you will
                    not be charged for your subscription - our way of simplifying your experience. For more details,
                    refer to our{' '}
                    <Anchor color="tertiary" href={faqUrl} type="caption" withUnderline>
                      FAQ
                    </Anchor>
                  </Text>
                </>
              ) : null}
            </YStack>
            <Button
              disabled={!cardNumberInfo?.complete || !cardExpiryInfo?.complete || !cardCvcInfo?.complete}
              fullWidth
              isForm
              isLoading={isLoading || isSetupIntentConfirmedLoading || isUpdateCardLoading}
              label={isEditing ? 'Update' : 'Add card'}
            />
          </YStack>
        </form>
      </PageContainer>
    </Modal>
  );
};
