import type {
  Client,
  DraftTaskDetail,
  Field,
  Recurrence,
  Section,
  Task,
  TaskComment,
  TaskCommentAuthor,
  TaskDetail,
  TaskStatusType,
} from '@frontend/api-types';
import type { StrictExclude, StrictOmit } from '@frontend/duck-tape';
import { P, concat, dayjs, match, replaceAll } from '@frontend/duck-tape';
import { getInitials } from '../client/utils';

export const taskStatuses = {
  all_tasks: ['new', 'open', 'pending', 'on_hold', 'snoozed', 'solved', 'archived'] as readonly TaskStatusType[],
  completed: ['solved', 'archived'] as readonly TaskStatusType[],
  draft: ['draft'] as readonly TaskStatusType[],
  in_progress: ['new', 'open', 'on_hold'] as readonly TaskStatusType[],
  snoozed: ['snoozed'] as readonly TaskStatusType[],
  waiting_on_you: ['pending'] as readonly TaskStatusType[],
} as const;

export type TaskTabType = keyof typeof taskStatuses;

export type TaskTabTypeWithScheduled = TaskTabType | 'scheduled';

export const getTaskStatusLabel = (status: TaskStatusType) =>
  match<TaskStatusType, string>(status)
    .with('archived', () => 'Completed')
    .with('new', () => 'In Progress')
    .with('on_hold', () => 'In Progress')
    .with('open', () => 'In Progress')
    .with('pending', () => 'Waiting On You')
    .with('snoozed', () => 'Snoozed')
    .with('solved', () => 'Completed')
    .with('draft', () => 'Draft')
    .exhaustive();

export const commentDoesNotOpenStatuses = ['new', 'open'];

// Read as status can be transitioned to left from right
export const ALLOWED_TASK_STATE_TRANSITIONS = {
  snoozed: ['open', 'new', 'pending', 'on_hold'],
  solved: ['open', 'new', 'pending', 'on_hold', 'snoozed'],
};

export const SOURCE_PARAM_FOR_COPY_TASK_REDIRECT = 'app';

export const isTaskCompleted = (task: Pick<Task, 'status'>) => taskStatuses.completed.includes(task.status);

export const shouldPromptTaskReview = (task: Pick<Task, 'isReviewSubmitted' | 'isStarted' | 'isUnread' | 'status'>) =>
  isTaskCompleted(task) && !task.isReviewSubmitted && !task.isUnread && task.isStarted;

export const canTaskBeReviewed = (task: Pick<TaskDetail, 'isReviewed' | 'isStarted' | 'status'>) =>
  isTaskCompleted(task) && !task.isReviewed && task.isStarted;

export const canTaskBeSnoozed = (task: Pick<Task, 'isStarted' | 'status'>) => {
  return task.isStarted && ALLOWED_TASK_STATE_TRANSITIONS.snoozed.includes(task.status);
};

export const canTaskBeCompleted = (task: Pick<Task, 'isStarted' | 'status'>) => {
  return task.isStarted && ALLOWED_TASK_STATE_TRANSITIONS.solved.includes(task.status);
};

export const canTaskBeSkipped = (task: Pick<Task, 'isStarted' | 'status'>) => {
  return task.status === 'pending' && !task.isStarted;
};

export const canTaskBeStarted = (task: Pick<Task, 'isStarted' | 'status'>) => {
  return task.status === 'pending' && !task.isStarted;
};

export const getSectionsFields = (sections: Section[]) =>
  sections.reduce((agg: Field[], section: Section) => [...agg, ...section.fields], []);

const formatHtml = (text: string) => replaceAll(text, '\n', '<br/>');

type CommentConfig = {
  labelClassName: string;
  textClassName?: string;
};

export const getDraftTaskAttachmentsComment = (task: DraftTaskDetail, client: Client | undefined) => {
  const { attachments, createdAt } = task;
  const { firstName, lastName, nickname } = client ?? {};
  const author: TaskCommentAuthor = { firstName, isUser: true, lastName, nickname, type: 'client', zendeskId: '' };

  return {
    attachments: attachments,
    author,
    body: '',
    createdAt: createdAt as DateTimeISOString,
    isUser: true,
    secureNote: null,
    sheets: [],
  } as TaskComment;
};

export const getInitialComment = (
  task: DraftTaskDetail | Recurrence | TaskDetail,
  client: Client | undefined,
  config?: CommentConfig,
) => {
  const { createdAt, description, proceedDecision } = task;

  const descriptionHtml = `<div class="${config?.textClassName}">${formatHtml(description)}</div>`;

  const proceedDecisionHtml =
    `<br/><div class="${config?.labelClassName}">How should we proceed?</div>` +
    `<div class="${config?.textClassName}">${formatHtml(proceedDecision)}</div>`;

  const initialQuestionsHtml =
    'aiQuestionnaire' in task && task.aiQuestionnaire
      ? task.aiQuestionnaire.responses.reduce((agg: string, response) => {
          const formattedAnswer = match(response)
            .with(
              { type: 'multi_choice' },
              ({ answer }) => `<div class="${config?.textClassName}">${formatHtml(answer.join(', '))}</div>`,
            )
            .with({ type: 'text' }, ({ answer }) => `<div class="${config?.textClassName}">${formatHtml(answer)}</div>`)
            .exhaustive();

          return `${agg}<br/><div class="${config?.labelClassName}">${formatHtml(response.question)}</div>${formattedAnswer}`;
        }, '')
      : '';

  const body = concat(descriptionHtml, proceedDecisionHtml, initialQuestionsHtml).join('');

  const { firstName, lastName, nickname } = client ?? {};
  const author: TaskCommentAuthor = { firstName, isUser: true, lastName, nickname, type: 'client', zendeskId: '' };

  return { attachments: [], author, body, createdAt, isUser: true, secureNote: null, sheets: [] } as TaskComment;
};

export const getCommentProps = (comments: TaskComment[], index: number) => {
  // Show time if it's the first message or it's been at least an hour since the previous message
  const getIsTimeVisible = (index: number) => {
    if (index === comments.length - 1) {
      return true;
    }
    const lastMessageDate = dayjs(comments[index + 1]?.createdAt);
    const currentMessageDate = dayjs(comments[index]?.createdAt);
    return currentMessageDate.diff(lastMessageDate, 'h', true) >= 1;
  };

  const { type, zendeskId } = comments[index]?.author ?? {};
  const isTimeVisible = getIsTimeVisible(index);

  // Show author if it's not a system bot message and the time is visible or the previous author was different
  const isAuthorVisible = (() => {
    if (type === 'system_bot') {
      return false;
    }
    if (isTimeVisible) {
      return true;
    }
    const { zendeskId: previousZendeskId } = comments[index + 1]?.author ?? {};
    return zendeskId !== previousZendeskId;
  })();

  // Show divider if it's not the last message, current message is not a system bot message, and next message is by a
  // different author or time is visible
  const isDividerVisible = (() => {
    if (index === 0) {
      return false;
    }
    const { zendeskId: nextZendeskId } = comments[index - 1]?.author ?? {};
    return type !== 'system_bot' && (zendeskId !== nextZendeskId || getIsTimeVisible(index - 1));
  })();

  return { isAuthorVisible, isDividerVisible, isTimeVisible };
};

// We don't render author info for system bot messages, those usually turn into whispers
export type TaskChatAuthorType = StrictExclude<TaskCommentAuthor['type'], 'system_bot'> | 'automated_message';

export type TaskChatAuthorInfo = {
  initials: string;
  // Usually first name + last name
  label: string;
  type: TaskChatAuthorType;
};

export const getTaskChatMessageAuthorInfo = ({
  firstName = '',
  isUser,
  lastName = '',
  nickname = '',
  type,
}: StrictOmit<TaskCommentAuthor, 'type'> & { type: TaskChatAuthorType }): TaskChatAuthorInfo => {
  const preferredName = nickname || firstName;
  const initials = getInitials({ firstName, lastName, nickname });

  const label: string = (() => {
    const duckbotText = 'Duckbot';
    if (isUser) return 'You';
    return (
      match(type)
        .with('agent', () => 'Copilot')
        .with('client', () => preferredName)
        // Important note: Since we're rebranding to use "Bill" for our AI bot, I'm overriding "ai_bot" to "automated_message" when in
        // the normal task chat (which Bill is not present in)
        .with(P.union('ai_bot', 'automated_message'), () => duckbotText)
        .exhaustive()
    );
  })();
  return { initials, label, type: type === 'ai_bot' ? 'automated_message' : type };
};

export const CREATE_TASK_NEXT_STEPS_OPTIONS = [
  {
    description:
      "If Qs arise, we'll make basic assumptions to share some options. You can always ask for alternatives & we won't bill your card without approval.",
    title: 'Start with some options',
    value: 'Start with some options',
  },
  {
    description:
      'Based on your initial inputs, you authorize Duckbill to complete the task with any associated charges billed to your card.',
    title: 'Go ahead and complete the task',
    value: 'Go ahead and complete the task',
  },
] as const;
