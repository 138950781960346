import { formatEndpoint } from '@frontend/duck-tape';

const namespace = 'research';

export const RESEARCH_ENDPOINTS = {
  // Creates a new search thread for the client + generates search result and task suggestions.
  createThread: () => formatEndpoint('', namespace),
  // Deletes a single search thread record including all embedded documents.
  deleteThread: (id: string) => formatEndpoint(`${id}`, namespace),
  // Returns a list of existing search threads for the client
  listThreads: () => formatEndpoint('', namespace),
  // Returns a single search thread record including all embedded documents.
  retrieveThread: (id: string) => formatEndpoint(`${id}`, namespace),
  retrieveThreadEntityLinks: (id: string) => formatEndpoint(`thread_entities/${id}`, namespace),
};
