import { InputLabel, YStack } from '@frontend/web-react';
import { creditCardInputClassNames, creditCardInputStyle } from '@frontend/web/components';
import { CardExpiryElement } from '@stripe/react-stripe-js';
import type { StripeCardExpiryElementChangeEvent } from '@stripe/stripe-js';

type CardExpiryProps = {
  onChange: (e: StripeCardExpiryElementChangeEvent) => void;
};

export const CardExpiry = ({ onChange }: CardExpiryProps) => (
  <YStack className="gap-sm w-full">
    <InputLabel label="Expiration" />
    <CardExpiryElement className={creditCardInputClassNames} onChange={onChange} options={creditCardInputStyle} />
  </YStack>
);
