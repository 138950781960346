import type { StrictOmit } from '@frontend/duck-tape';
import { applyClass, concatClasses, safeCss } from '@frontend/web-utils';
import type { IndicatorProps as MIndicatorProps } from '@mantine/core';
import { Indicator as MIndicator } from '@mantine/core';
import { View } from './Containers/View';

export type IndicatorProps = StrictOmit<MIndicatorProps, 'classNames'> & { isVisible?: boolean };

export const Indicator = ({ children, isVisible, ...props }: IndicatorProps) => {
  const visibilityClassName = applyClass(!isVisible, 'bg-transparent before:bg-transparent');
  // The default behavior of this component is to render the Indicator relative to a child, but if there is no children,
  // it will render in-line
  if (!children) {
    // eslint-disable-next-line tailwindcss/no-arbitrary-value
    return <View className={concatClasses('size-[8px] bg-textError rounded-full', visibilityClassName)} />;
  }
  return (
    <MIndicator
      classNames={{ indicator: concatClasses(safeCss('before:bg-textError'), visibilityClassName) }}
      size={8}
      {...props}
    >
      {children}
    </MIndicator>
  );
};
