import { convertObjectKeysCase } from '@frontend/duck-tape';
import { initGrowthBook } from '@frontend/react';
import { initCio, initSegment, initSentry } from '@frontend/web-utils';
import { Env } from './env';

export const initApp = () => {
  initSentry({ dsn: Env.VITE_SENTRY_DSN, env: Env.VITE_ENV });

  const segmentClient = initSegment({ writeKey: Env.VITE_SEGMENT_WRITE_KEY });

  initCio({ window });

  // eslint-disable-next-line fp/no-mutation
  const growthbook = initGrowthBook({
    autoInit: true,
    clientKey: Env.VITE_GROWTHBOOK_CLIENT_KEY,
    env: Env.VITE_ENV,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    trackingCallback: async (experiment, result) => {
      return segmentClient.track(
        'Experiment Viewed',
        convertObjectKeysCase(
          {
            experimentId: experiment.key,
            variationId: result.variationId,
          },
          'snakeCase',
        ),
      );
    },
  });

  return { growthbook };
};
