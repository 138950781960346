import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type CommonReduxState = {
  hasViewedThreadTooltip: boolean;
};

const initialState: CommonReduxState = {
  hasViewedThreadTooltip: false,
};

const commonSlice = createSlice({
  initialState,
  name: 'common',
  reducers: {
    setHasViewedThreadTooltip: (state, { payload }: PayloadAction<boolean>) => {
      // eslint-disable-next-line fp/no-mutation
      state.hasViewedThreadTooltip = payload;
    },
  },
});

export const { reducer: commonReducer } = commonSlice;
export const { setHasViewedThreadTooltip } = commonSlice.actions;
