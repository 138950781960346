/* eslint-disable fp/no-mutation */
import type { PromotionDetails } from '@frontend/api-types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type PromoReduxState = {
  promoCode?: string;
  promoDetails?: PromotionDetails;
};

const initialState: PromoReduxState = {};

const promoSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    clearPromo: (state) => {
      state.promoCode = undefined;
      state.promoDetails = undefined;
    },
    setPromoCode: (state, { payload }: PayloadAction<string>) => {
      state.promoCode = payload;
    },
    setPromoDetails: (state, { payload }: PayloadAction<PromotionDetails>) => {
      state.promoDetails = payload;
    },
  },
});

export const { reducer: promoReducer } = promoSlice;
export const { clearPromo, setPromoCode, setPromoDetails } = promoSlice.actions;
