import { pluralize } from '@frontend/duck-tape';
import { useFeatureIsOn } from '@frontend/react';
import { Text, XStack, YStack } from '@frontend/web-react';
import { useRetrieveClientStatusQuery } from '@frontend/web/hooks';

export const ActiveLimitBanner = () => {
  const { enabled: isThrottlingEnabled } = useFeatureIsOn('is_throttling_tasks_enabled');
  const { data: clientStatus } = useRetrieveClientStatusQuery();

  if (!clientStatus?.currentActiveTasks || !clientStatus?.planMaxActiveTasks) {
    return null;
  }

  const remainingTasks =
    (clientStatus?.individualMaxActiveTasks || clientStatus.planMaxActiveTasks) - clientStatus.currentActiveTasks;

  // Use Case A: Users has individual tasks remaining but has maxed out the plan quota.
  const hasIndividualTasksRemaining = remainingTasks > 0;
  const hasMaxedOutPlanQuota = clientStatus.currentActiveTasks >= clientStatus.planMaxActiveTasks;

  // Determine if the component should be displayed
  if (!isThrottlingEnabled || (hasIndividualTasksRemaining && !hasMaxedOutPlanQuota)) {
    return null;
  }

  const closeOutTasks =
    clientStatus.currentActiveTasks - (clientStatus.individualMaxActiveTasks || clientStatus.planMaxActiveTasks) + 1;

  return (
    <XStack className="justify-between pr-sm pb-lg border-b-borderPrimary border-b-1">
      <YStack gap="sm">
        <Text type="h6">Your Active limit*</Text>
        <Text color="tertiary" type="p-sm">
          {hasIndividualTasksRemaining && hasMaxedOutPlanQuota
            ? `*Your active task limit is ${clientStatus?.individualMaxActiveTasks}, but your plan has a limit of ${clientStatus?.planMaxActiveTasks}. Lucky you!`
            : `*You’ve reached the limit for active tasks. Close out ${closeOutTasks} active ${pluralize('task', closeOutTasks)} to start a new one.`}
        </Text>
      </YStack>
      <Text type="h6">
        {remainingTasks > 0 ? remainingTasks : 0} {pluralize('Task', remainingTasks)} remaining
      </Text>
    </XStack>
  );
};
