import type { Client } from '@frontend/api-types';

// eslint-disable-next-line fp/no-let
let cio: MaybeUndefined<EmbeddedCioClient>;

// Cio doesn't have a type definition, so we're creating a type for it here
// This is forcibly embedded into the window object by the CustomerIO script in the index.html file
export type EmbeddedCioClient = {
  identify: (params: { email: string; first_name: string; id: string; last_name: string; phone: string }) => void;
  reset: () => void;
};

export const initCio = ({ window }: { window: Window & { _cio: EmbeddedCioClient } }) => {
  if (!cio) {
    // eslint-disable-next-line fp/no-mutation
    cio = window._cio;
  } else {
    // eslint-disable-next-line no-console
    console.warn(
      'Running initCio a second time, which should not happen. To get the existing instance, use getCio instead.',
    );
  }

  return cio;
};

export const getCio = (params?: { required: boolean }): NotNil<typeof cio> => {
  if (!cio && params?.required) {
    // eslint-disable-next-line no-console
    console.warn("Cio has not been initialized yet and isn't available in the window. Call initCio() first.");
  }
  // @ts-expect-error We want consumers of this function to always assume it's not undefined
  return cio;
};

export const identifyCio = async ({ client, env }: { client: Client; env: EnvType }) => {
  const { email, firstName, id, lastName, phoneNumber } = client;
  if (env === 'local') {
    // eslint-disable-next-line no-console
    console.info('Mock cio identify:', { email, firstName, id, lastName, phoneNumber });
    return;
  }

  return cio?.identify({
    email,
    first_name: firstName,
    id,
    last_name: lastName,
    phone: phoneNumber,
  });
};

export const deidentifyCio = () => getCio({ required: true }).reset();
