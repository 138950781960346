import type { StrictOmit } from '@frontend/duck-tape';
import type { FlexStyleConvenientProps } from '@frontend/web-react/utils';
import { forwardRef, useMemo } from 'react';
import type { DivProps } from 'react-html-props';
import { flexPropsToClasses } from '../../../utils/style';

export type ViewProps = StrictOmit<DivProps & FlexStyleConvenientProps, 'style'>;

// This component shouldn't be used directly in any "apps", it's just a base component to be used in <XStack/> and <YStack/>
export const View = forwardRef<HTMLDivElement, ViewProps>(
  (
    {
      alignContent,
      alignItems,
      className,
      flexDirection = 'column',
      flexGrow,
      flexShrink,
      flexWrap,
      gap,
      gapX,
      gapY,
      justifyContent,
      ...props
    },
    ref,
  ) => {
    const baseClasses = useMemo(
      () =>
        flexPropsToClasses({
          alignContent,
          alignItems,
          className,
          flexDirection,
          flexGrow,
          flexShrink,
          flexWrap,
          gap,
          gapX,
          gapY,
          justifyContent,
        }),

      [
        alignContent,
        alignItems,
        flexDirection,
        flexGrow,
        className,
        flexShrink,
        flexWrap,
        justifyContent,
        gap,
        gapX,
        gapY,
      ],
    );

    return <div className={baseClasses} ref={ref} {...props} />;
  },
);
