import { match, type StrictExtract, type StrictOmit } from '@frontend/duck-tape';
import { applyClass, concatClasses, safeCss, type ClassName } from '@frontend/web-utils';
import type { ButtonProps } from 'react-html-props';
import type { IconName } from '../Icon/helpers';
import type { IconProps } from '../Icon/Icon';
import { Icon } from '../Icon/Icon';
import { Spinner } from '../Spinner';
import type { TextProps } from '../Texts/Text';
import { Text } from '../Texts/Text';

export type LinkButtonProps = StrictOmit<TextProps, 'children' | 'className' | 'classNames' | 'color' | 'onClick'> & {
  className?: ClassName;
  color?: StrictExtract<TextProps['color'], 'danger' | 'primary' | 'secondary' | 'success' | 'tertiary' | 'warning'>;
  disabled?: boolean;
  hoverEffect?: 'background' | 'opacity';
  iconColor?: IconProps['color'];
  isLoading?: boolean;
  leftIconName?: IconName;
  leftIconProps?: StrictOmit<IconProps, 'name'>;
  onClick: ButtonProps['onClick'];
  textClassName?: ClassName;
  withUnderline?: boolean;
} & ({ children: React.ReactNode } | { label: string });

export const LinkButton = ({
  className,
  color = 'primary',
  disabled = false,
  iconColor = color,
  isLoading,
  leftIconName,
  leftIconProps,
  onClick,
  textClassName,
  withUnderline,
  hoverEffect = 'opacity',
  ...props
}: LinkButtonProps) => {
  return (
    <button
      className={concatClasses(
        'flex flex-row items-center gap-x-xs justify-center',
        getHoverEffectClassName({ hoverEffect }),
        className,
      )}
      disabled={disabled}
      onClick={
        disabled
          ? undefined
          : (e) => {
              e.stopPropagation();
              e.preventDefault();
              onClick?.(e);
            }
      }
    >
      {isLoading ? (
        <Spinner size="xs" />
      ) : (
        <>
          {leftIconName ? <Icon color={iconColor} name={leftIconName} {...leftIconProps} /> : null}
          <Text
            children={'children' in props ? props.children : props.label}
            className={concatClasses(
              'font-bold',
              applyClass(withUnderline, 'underline underline-offset-4'),
              textClassName,
            )}
            color={color}
            {...props}
          />
        </>
      )}
    </button>
  );
};

const getHoverEffectClassName = ({ hoverEffect }: PickRequired<LinkButtonProps, 'hoverEffect'>) =>
  match(hoverEffect)
    .with('background', () => safeCss('hover-background-effect'))
    .with('opacity', () => safeCss('hover-opacity-effect'))
    .exhaustive();
