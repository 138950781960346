import { canReviewOptionSheet } from '@frontend/api-client';
import type { OptionSheetOption, TaskStatusType } from '@frontend/api-types';
import type { Currency } from '@frontend/duck-tape';
import { formatCurrencyWithCommasAndDecimal, isNil } from '@frontend/duck-tape';
import { Fragment, useEffect } from '@frontend/react';
import {
  Button,
  Header,
  Icon,
  LinkButton,
  Modal,
  RenderHTML,
  Text,
  View,
  XStack,
  YStack,
  type CommonModalProps,
} from '@frontend/web-react';
import { safeCss } from '@frontend/web-utils';
import { OptionSheetOptionImage } from '@frontend/web/components/DomainSpecific/Options/OptionSheetOptionImage';
import { useAnalytics } from '@frontend/web/hooks';

export type OptionSheetSelectionModal = CommonModalProps & {
  allOptions: OptionSheetOption[];
  currency?: Currency;
  currentOption: OptionSheetOption;
  onClickBack: () => void;
  onClickReviewResponse: () => void;
  onDeselect: (option: OptionSheetOption) => void;
  onSelect: (option: OptionSheetOption) => void;
  optionSheetId: string;
  selectedOptions: OptionSheetOption[];
  setCurrentOption: (option: OptionSheetOption) => void;
  taskStatus: TaskStatusType;
};

export const OptionSheetSelectionModal = ({
  allOptions,
  currency,
  currentOption,
  isOpen,
  onClickBack,
  onClickReviewResponse,
  onClose,
  onDeselect,
  onSelect,
  optionSheetId,
  selectedOptions,
  setCurrentOption,
  taskStatus,
}: OptionSheetSelectionModal) => {
  const { track } = useAnalytics();
  const currentIndex = allOptions.findIndex(({ id }) => id === currentOption.id);
  const showNextChevron = currentIndex < allOptions.length - 1;
  const showPreviousChevron = currentIndex > 0;
  const currentImageUrl = currentOption?.imageUrls?.[0];

  useEffect(() => {
    if (isOpen) {
      track({ data: { optionId: currentOption.id, sheetId: optionSheetId }, event: 'viewedOption', topic: 'options' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onClickNextChevron = () => {
    const nextOption = allOptions[currentIndex + 1];
    if (nextOption) {
      setCurrentOption(nextOption);
    }
  };

  const onClickPreviousChevron = () => {
    const previousOption = allOptions[currentIndex - 1];
    if (previousOption) {
      setCurrentOption(previousOption);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'ArrowLeft') {
      onClickPreviousChevron();
    } else if (event.key === 'ArrowRight') {
      onClickNextChevron();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentIndex]);

  return (
    <Modal
      Footer={
        <YStack className="p-md gap-y-md bg-surfaceSecondary shadow-three border-t-1 border-t-borderPrimary">
          <XStack>
            <YStack>
              {showPreviousChevron ? (
                <Icon name="IconChevronLeft" onClick={onClickPreviousChevron} />
              ) : (
                <View className="size-lg" />
              )}
            </YStack>
            <YStack className="grow-1 justify-center items-center">
              <Text color="tertiary" type="caption">
                {currentIndex + 1}/{allOptions.length}
              </Text>
            </YStack>
            <YStack>
              {showNextChevron ? (
                <Icon name="IconChevronRight" onClick={onClickNextChevron} />
              ) : (
                <View className="size-lg" />
              )}
            </YStack>
          </XStack>
          {canReviewOptionSheet(taskStatus) ? (
            <Fragment>
              {selectedOptions.length ? (
                <YStack gapY="md">
                  <YStack gapY="xs">
                    {selectedOptions.map((option) => {
                      return (
                        <XStack alignItems="center" gapX="sm" key={option.id}>
                          <Icon color="primary" name="IconCircleCheck" size="lg" />
                          <Text className="flex-1" lineClamp={2} type="h6">
                            {option.title} selected
                          </Text>
                          <Icon
                            className="bg-surfaceAccent border-1 border-borderSecondary"
                            color="accent"
                            name="IconX"
                            onClick={() => onDeselect(option)}
                            rounded
                            size="xs"
                          />
                        </XStack>
                      );
                    })}
                  </YStack>
                  <Text color="tertiary" textAlign="center" type="caption">
                    You can still review other options
                  </Text>
                </YStack>
              ) : (
                <YStack className="pb-md">
                  <Text color="tertiary" type="h5">
                    No options selected
                  </Text>
                </YStack>
              )}
              <XStack gap="sm">
                {selectedOptions.find((o) => o.id === currentOption.id) ? (
                  <LinkButton
                    className="flex-1"
                    color="secondary"
                    label="Deselect this option"
                    onClick={() => onDeselect(currentOption)}
                  />
                ) : (
                  <Button
                    className="flex-1"
                    color="secondary"
                    label="Select Option"
                    onClick={() => onSelect(currentOption)}
                  />
                )}
                <Button className="flex-1" color="primary" label="Review Response" onClick={onClickReviewResponse} />
              </XStack>
            </Fragment>
          ) : null}
        </YStack>
      }
      Header={
        <Header
          onClickBack={onClickBack}
          // eslint-disable-next-line no-restricted-globals
          onClickRightIcon={() => open(currentOption?.url, '_blank')}
          rightIconName={currentOption?.url ? 'IconExternalLink' : undefined}
          title={currentOption?.title}
        />
      }
      contentContainerClassName={safeCss('px-md')}
      isOpen={isOpen}
      onClose={onClose}
      padding="none"
      size="550px"
    >
      <YStack className="pb-md gap-y-md">
        {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
        <OptionSheetOptionImage imageUrl={currentImageUrl} {...currentOption} currency={currency} />
        {/* We only want to show price here if the section above doesn't already show price */}
        {!isNil(currentOption?.price) && currentImageUrl && currentOption.description ? (
          <YStack alignItems="end">
            <Text type="h3">{formatCurrencyWithCommasAndDecimal(currentOption.price, currency)}</Text>
          </YStack>
        ) : null}
        {currentOption?.description ? (
          <YStack>
            <RenderHTML html={currentOption.description} />
          </YStack>
        ) : null}
      </YStack>
    </Modal>
  );
};
