import type { ButtonProps } from '@frontend/web-react';
import { Button, Text, Title, XStack, YStack } from '@frontend/web-react';
import { NavLink } from '../components';
import { Env } from '../utils';

export type GenericErrorPageProps = {
  buttonProps?: ButtonProps;
  giantText?: string;
  subtitle?: string;
  title?: string;
};
export const GenericErrorPage = ({
  buttonProps,
  giantText = 'Oh f*ck,',
  subtitle = 'Head to our Directory to get help for all of the "oh f*ck" moments.',
  title = "There's nothing here.",
}: GenericErrorPageProps) => {
  return (
    <YStack className="flex min-h-screen w-full px-md">
      <YStack alignItems="center" className="gap-y-xxl flex-1" justifyContent="center">
        <YStack alignItems="center">
          {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
          <Title className="text-[100px] font-extrabold uppercase italic leading-[130px]" type="h1">
            {giantText}
          </Title>
          <Title className="text-h1 uppercase italic" type="h2">
            {title}
          </Title>
          <Text type="p-lg">{subtitle}</Text>
        </YStack>
        <YStack alignItems="center" gapY="lg">
          <XStack>
            {/* eslint-disable-next-line no-restricted-globals */}
            <Button onClick={() => open(Env.VITE_DUCKBILL_DIRECTORY_URL)} {...buttonProps}>
              Visit the Duckbill Directory
            </Button>
          </XStack>
          <NavLink to={'/app'}>Back to Dashboard</NavLink>
        </YStack>
      </YStack>
    </YStack>
  );
};
