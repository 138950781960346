import { make } from 'ts-brand';
import { decodeParam, safeEncodeURIComponent } from '../url/url';

export const makeEncodedURI = make<EncodedURI>();

// Function to encode a DecodedURI to an EncodedURI
export const convertDecodedToEncodedURI = (decoded: string): EncodedURI =>
  makeEncodedURI(safeEncodeURIComponent(decoded));

// Function to decode an EncodedURI to a DecodedURI
export const convertEncodedToDecodedURI = (encoded: EncodedURI): string => decodeParam(encoded);

export const isValidEncodedURI = (uri: EncodedURI | string) => {
  try {
    decodeParam(uri);
    return true;
  } catch (e) {
    return false;
  }
};
