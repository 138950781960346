import { safeCss } from '@frontend/web-utils';
import type { CheckboxProps as MCheckboxProps } from '@mantine/core';
import { Checkbox as MCheckbox } from '@mantine/core';

export type CheckboxProps = Omit<MCheckboxProps, 'classNames' | 'color' | 'variant'>;

const classNames: MCheckboxProps['classNames'] = {
  body: safeCss('text-p-sm text-textPrimary'),
  input: safeCss('border-1 border-textPrimary rounded-sm'),
};

// For now only supports one size, can add more variants if needed
/**
 * Should only be used if rendering a "one off" checkbox for presentational purposes. If you need to render multiple checkboxes, use CheckboxGroupInput.
 */
export const Checkbox = ({ indeterminate, ...props }: CheckboxProps) => {
  // If indeterminate use default icon (we don't have one)
  return <MCheckbox classNames={classNames} color={'blue.9'} indeterminate={indeterminate} {...props} />;
};
