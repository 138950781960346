import type { LocalOrSavedFile } from '@frontend/api-types';
import { removeByIndex, type Tuple, type XOR } from '@frontend/duck-tape';
import type { MakeInputProps } from '@frontend/forms';
import { makeLocalFileList } from '@frontend/forms';
import type { FileButtonProps as MFileButtonProps } from '@mantine/core';
import { FileButton as MFileButton } from '@mantine/core';
import { XStack, YStack } from '../../../Containers';
import { Icon } from '../../../Icon/Icon';
import { Text } from '../../../Texts/Text';
import type { FilePreviewsProps } from './FilePreviews';
import { FilePreviews } from './FilePreviews';

export type FileInputProps = MakeInputProps<
  LocalOrSavedFile[],
  Pick<MFileButtonProps<true>, 'accept' | 'disabled' | 'name'> & {
    allowedFileTypes: Tuple<FileType>;
    noLabel?: boolean;
  }
> &
  XOR<
    {
      // For previewing files, unsure if best done in this component or the parent
      previewType?: FilePreviewsProps['type'];
      showPreview?: true;
    },
    {
      previewType: never;
      showPreview?: false;
    }
  >;

type FileType = keyof typeof mimeTypesMap;

export const mimeTypesMap = {
  audio: 'audio/*',
  image: 'image/*',
  pdf: 'application/pdf',
  video: 'video/*',
};

/**
 * This component doesn't handle displaying uploaded files. Use the `FilePreviews` component for that
 * We set multiple to always be true for simplicity purposes so that you're always dealing with an array of files
 */
export const FileInput = ({
  allowedFileTypes,
  disabled,
  label = 'Attach file',
  name,
  noLabel,
  onChange,
  previewType = 'listItems',
  showPreview,
  value,
  ...props
}: FileInputProps) => {
  const onChangeComposed = (newFiles: File[]) => {
    const localFileList = makeLocalFileList(newFiles);
    return onChange([...value, ...localFileList]);
  };

  const onDelete: FilePreviewsProps['onDelete'] = (index) => onChange(removeByIndex(value, index));

  return (
    <MFileButton
      accept={allowedFileTypes.map((type) => mimeTypesMap[type!]).join(',')}
      inputProps={{
        disabled,
        name,
      }}
      multiple
      onChange={onChangeComposed}
      {...props}
    >
      {(buttonProps) => (
        <YStack gapY="sm">
          <XStack>
            <Icon name="IconFilePlus" size="lg" stroke={1.5} {...buttonProps} />
            {noLabel ? null : <Text type="h6">{label}</Text>}
          </XStack>
          {showPreview ? <FilePreviews files={value} onDelete={onDelete} type={previewType} /> : null}
        </YStack>
      )}
    </MFileButton>
  );
};
