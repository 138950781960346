import { getDedicatedCalendarAccounts } from '@frontend/api-client';
import { PhoneCalendar } from '@frontend/assets';
import { useState, useVisibilityController } from '@frontend/react';
import { GoogleCalendarIcon } from '@frontend/web-icons';
import {
  Button,
  Card,
  Icon,
  LinkButton,
  ScrollableContentPanel,
  SplitImagePageContainer,
  Text,
  XStack,
  YStack,
  isMobile,
  showToast,
} from '@frontend/web-react';
import { useOnboardingContext } from '@frontend/web/context/OnboardingContext';
import { useListOAuthAccountsQuery, useUpdateOAuthCodeMutation } from '@frontend/web/hooks';
import { calendarBlockedPopUpErrorMessage, calendarScopes } from '@frontend/web/routes/app/settings/calendar';
import { Env, handleMutation } from '@frontend/web/utils';
import { useNavigate } from '@tanstack/react-router';
import { CalendarSkipReasonModal } from './CalendarSkipReasonModal';
import { OnboardingStepHeader } from './OnboardingStepHeader';

const onboardingCalendarAccessDeniedErrorMessage =
  'We were not able to access your calendar. If you need additional help, please contact us.';

export const Calendar = () => {
  const navigate = useNavigate();
  const { decrementStep, incrementStep } = useOnboardingContext();
  const [isCalendarSkipReasonModalOpen, { close: closeCalendarSkipReasonModal, open: openCalendarSkipReasonModal }] =
    useVisibilityController();

  const { data: oAuthAccounts } = useListOAuthAccountsQuery();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateOAuthCode, { isLoading: _isUpdateOAuthCodeLoading }] = useUpdateOAuthCodeMutation();
  const [isConnectCalendarLoading, setIsConnectCalendarLoading] = useState(false);
  const hasConnectedCalendar = Boolean(getDedicatedCalendarAccounts(oAuthAccounts).length);

  const onClickConnectCalendar = async () => {
    setIsConnectCalendarLoading(true);
    const client = google.accounts.oauth2.initCodeClient({
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      callback: async (response: { code?: string; error?: string }) => {
        if (response.error) {
          showToast({ title: onboardingCalendarAccessDeniedErrorMessage, variant: 'danger' });
        } else if (response.code) {
          await handleMutation({
            mutation: () =>
              updateOAuthCode({
                code: response.code!,
                provider: 'Google',
                purposes: ['calendar'],
                scopes: calendarScopes,
              }).unwrap(),
            onComplete: () => setIsConnectCalendarLoading(false),
            onSuccess: () => {
              // If on mobile, skip the download app screen
              if (isMobile) {
                navigate({ to: '/app' });
              } else {
                incrementStep();
              }
            },
            successMessage: 'Calendar connected successfully',
          });
        }
      },
      client_id: Env.VITE_GOOGLE_OAUTH_CLIENT_ID as string,
      error_callback: (response: { type?: string }) => {
        setIsConnectCalendarLoading(false);
        if (response.type === 'popup_closed') {
          showToast({ title: onboardingCalendarAccessDeniedErrorMessage, variant: 'danger' });
        } else if (response.type === 'popup_failed_to_open') {
          showToast({ title: calendarBlockedPopUpErrorMessage, variant: 'danger' });
        }
      },
      scope: calendarScopes.join(' '),
      ux_mode: 'popup',
    });
    client.requestCode();
  };

  return (
    <SplitImagePageContainer imageSource={PhoneCalendar}>
      <ScrollableContentPanel
        footer={
          <YStack gapY="md">
            {hasConnectedCalendar ? (
              <Button label="Continue" onClick={incrementStep} />
            ) : (
              <Button isLoading={isConnectCalendarLoading} label="Connect" onClick={onClickConnectCalendar} />
            )}
            <LinkButton label="Skip" onClick={openCalendarSkipReasonModal} withUnderline />
          </YStack>
        }
        header={
          <OnboardingStepHeader
            onClickBackArrow={decrementStep}
            title={
              hasConnectedCalendar
                ? "Great job! You've already connected your calendar. Feel free to go on to the next step."
                : 'Provide access to your calendar'
            }
          />
        }
        justifyContent="start"
      >
        {hasConnectedCalendar ? (
          <Card className="flex-row items-center justify-between shadow-one">
            <XStack alignItems="center" gapX="md">
              <GoogleCalendarIcon />
              <Text type="h5">Google Calendar</Text>
            </XStack>
            {hasConnectedCalendar ? <Icon name="IconCheck" /> : null}
          </Card>
        ) : (
          <YStack className="px-lg w-full" gapY="md">
            <Text type="h5">Why are we asking for this?</Text>
            <XStack alignItems="center" gapX="md">
              <Icon color="tertiary" name="IconCheck" size="lg" />
              <Text color="tertiary" type="p-sm">
                Avoid the dreaded scheduling back-and-forth by letting us see your availability and add events on your
                behalf
              </Text>
            </XStack>
            <XStack alignItems="center" gapX="md">
              <Icon color="tertiary" name="IconCheck" size="lg" />
              <Text color="tertiary" type="p-sm">
                {"We'll use your calendar to identify opportunities to better support you"}
              </Text>
            </XStack>
            <Card className="flex-row items-center justify-between shadow-one" withBorder>
              <XStack alignItems="center" gapX="md">
                <GoogleCalendarIcon />
                <Text type="h5">Google Calendar</Text>
              </XStack>
              {hasConnectedCalendar ? <Icon name="IconCheck" /> : null}
            </Card>
          </YStack>
        )}
      </ScrollableContentPanel>
      <CalendarSkipReasonModal
        isOpen={isCalendarSkipReasonModalOpen}
        onClose={closeCalendarSkipReasonModal}
        onSuccess={incrementStep}
      />
    </SplitImagePageContainer>
  );
};
