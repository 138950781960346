import { formatPromoDetails } from '@frontend/api-client';
import type { RetrieveInviteResponse } from '@frontend/api-types';
import { ManDiscoBall } from '@frontend/assets';
import { onboardingCreatePasswordFormDefaultValues, useOnboardingCreatePasswordForm } from '@frontend/forms';
import { usePromoSlice, useState } from '@frontend/react';
import {
  Banner,
  Button,
  PasswordInput,
  ScrollableContentPanel,
  SplitImagePageContainer,
  Text,
  TextInput,
  YStack,
} from '@frontend/web-react';
import { useAuthentication, useCreateClientMutation, useLazyRetrievePromotionDetailsQuery } from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';
import { useNavigate } from '@tanstack/react-router';
import { OnboardingStepHeader } from '../Onboarding/OnboardingStepHeader';
import type { UserInfoStepState } from './UserInfo';

export type CreatePasswordProps = {
  decrementStep: () => void;
  inviteDetails?: RetrieveInviteResponse;
  searchParamsPromoCode?: string;
  userInfo: UserInfoStepState;
};

export const CreatePassword = ({
  decrementStep,
  inviteDetails,
  searchParamsPromoCode = '',
  userInfo,
}: CreatePasswordProps) => {
  const navigate = useNavigate();
  const { clearPromo, setPromoCode, setPromoDetails, state: promoStoreState } = usePromoSlice();
  const [
    retrievePromotionDetails,
    { data: promotionDetails, error: retrievePromotionalDetailsError, isLoading: isRetrievePromotionalDetailsLoading },
  ] = useLazyRetrievePromotionDetailsQuery();
  const { login } = useAuthentication();
  const [promoCodeErrorMessage, setPromoCodeErrorMessage] = useState('');
  const [createClient, { isLoading: isCreateClientLoading }] = useCreateClientMutation();
  const { getControl, isValid, submitForm, values } = useOnboardingCreatePasswordForm({
    initialValues: {
      ...onboardingCreatePasswordFormDefaultValues,
      promoCode: searchParamsPromoCode || promoStoreState.promoCode || '',
    },
    onSubmit: async (values) =>
      handleMutation({
        mutation: () =>
          createClient({
            ...userInfo,
            ...values,
          }).unwrap(),
        onSuccess: async () => {
          // Authenticate them for the onboarding route
          await login({ email: userInfo.email, handleRedirect: false, password: values.password });
          return navigate({
            to: '/onboarding',
          });
        },
        showSuccessToast: false,
      }),
  });

  const onClickApplyPromoCode = async () =>
    handleMutation({
      mutation: async () => retrievePromotionDetails(values.promoCode).unwrap(),
      onError: () => {
        setPromoCodeErrorMessage(
          'This promo code is invalid. Please try another code, or leave blank before submitting.',
        );
        clearPromo();
      },
      onSuccess: (promoDetails) => {
        setPromoCodeErrorMessage('');
        setPromoCode(values.promoCode);
        setPromoDetails(promoDetails);
      },
      showErrorToast: false,
      showSuccessToast: false,
    });

  const needsToClickApply = Boolean(
    !retrievePromotionalDetailsError && !promotionDetails && !promoStoreState.promoDetails && values.promoCode,
  );

  const needsToClearPromo = Boolean(retrievePromotionalDetailsError && values.promoCode);

  const canSubmit = isValid && !needsToClickApply && !needsToClearPromo;

  return (
    <SplitImagePageContainer imageSource={ManDiscoBall}>
      <ScrollableContentPanel
        footer={
          <Button disabled={!canSubmit} isLoading={isCreateClientLoading} label="Continue" onClick={submitForm} />
        }
        header={
          <OnboardingStepHeader
            onClickBackArrow={decrementStep}
            title={`Great, ${userInfo.nickname || userInfo.firstName}! Let's make it official.`}
          />
        }
        justifyContent="start"
      >
        <YStack gapY="md">
          <YStack className="justify-center" gapY="xs">
            <PasswordInput label={'Password'} {...getControl('password')} />
            <Text className="font-normal" type="caption">
              Minimum 12 characters
            </Text>
          </YStack>
          <PasswordInput label={'Confirm password'} {...getControl('confirmPassword')} />
          {/* No promos for household members */}
          {inviteDetails?.household ? null : (
            <YStack gapY="xs">
              <TextInput
                error={
                  getControl('promoCode').error ||
                  (needsToClickApply ? 'Click apply or leave blank before proceeding.' : undefined)
                }
                label="Promo code"
                onChange={(val) => {
                  if (val === '') {
                    setPromoCodeErrorMessage('');
                  }
                  getControl('promoCode').onChange(val);
                }}
                showOptionalText
                {...getControl('promoCode', ['error', 'onChange'])}
              />
              <Button
                className="self-end"
                color="secondary"
                disabled={!values.promoCode}
                isLoading={isRetrievePromotionalDetailsLoading}
                label="Apply"
                onClick={onClickApplyPromoCode}
                size="sm"
              />
            </YStack>
          )}
          {promoCodeErrorMessage ? (
            <Banner color="danger" leftIconName="IconAlertCircle" title={promoCodeErrorMessage} />
          ) : promoStoreState.promoDetails ? (
            <Banner color="success" leftIconName="IconCheck" title={formatPromoDetails(promoStoreState.promoDetails)} />
          ) : null}
        </YStack>
      </ScrollableContentPanel>
    </SplitImagePageContainer>
  );
};
