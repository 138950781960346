import type { StrictExtract } from '@frontend/duck-tape';
import { isLast, match, type StrictOmit } from '@frontend/duck-tape';
import { Fragment, type ReactNode } from '@frontend/react';
import type { IconColorVariant } from '@frontend/web-react';
import { Icon } from '@frontend/web-react';
import type { MenuProps as MMenuProps } from '@mantine/core';
import { Menu as MMenu } from '@mantine/core';
import type { IconName } from '../Icon/helpers';
import type { TextColorVariant } from '../Texts/Text';
import { Text } from '../Texts/Text';

type DropdownLayoutItemColorVariant = StrictExtract<
  SharedFromUnion<IconColorVariant, TextColorVariant>,
  'danger' | 'primary'
>;

type DropdownLayoutItem =
  | {
      color?: DropdownLayoutItemColorVariant;
      icon?: IconName;
      label: string;
      onClick: () => void;
      type: 'item';
    }
  | {
      color?: DropdownLayoutItemColorVariant;
      label: string;
      type: 'label';
    };

// Sometimes when changing a children component to use forwardRef is too much work,
// just wrap the child with a <YStack/> (preferred) or a <div/>
type DropdownMenuProps = StrictOmit<MMenuProps, 'children'> & {
  /** Note that for closeOnClickOutside to work, the child must accept a ref prop */
  children: ReactNode;
  layout: DropdownLayoutItem[];
};

export const DropdownMenu = ({ children, layout, ...props }: DropdownMenuProps) => {
  return (
    <MMenu closeDelay={400} closeOnClickOutside openDelay={100} trigger="click-hover" {...props}>
      <MMenu.Target>{children}</MMenu.Target>

      <MMenu.Dropdown className="rounded-md">
        {layout.map((item, index, array) => (
          <Fragment key={item.label}>
            {match(item)
              .with({ type: 'item' }, ({ color, icon, label, onClick }) => (
                <Fragment>
                  <MMenu.Item
                    className="py-sm px-md gap-md rounded-md"
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onClick();
                    }}
                    rightSection={icon ? <Icon color={color} name={icon} /> : undefined}
                  >
                    <Text color={color} type="h5">
                      {label}
                    </Text>
                  </MMenu.Item>
                  {isLast(array, index) ? null : <MMenu.Divider className="mx-xs" />}
                </Fragment>
              ))
              .with({ type: 'label' }, ({ label }) => <MMenu.Label>{label}</MMenu.Label>)
              .exhaustive()}
          </Fragment>
        ))}
      </MMenu.Dropdown>
    </MMenu>
  );
};
