import { InputLabel, YStack } from '@frontend/web-react';
import { creditCardInputClassNames, creditCardInputStyle } from '@frontend/web/components';
import { CardNumberElement } from '@stripe/react-stripe-js';
import type { StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';

type CardNumberProps = {
  onChange: (e: StripeCardNumberElementChangeEvent) => void;
};

export const CardNumber = ({ onChange }: CardNumberProps) => (
  <YStack className="gap-sm">
    <InputLabel label="Card number" />
    <CardNumberElement className={creditCardInputClassNames} onChange={onChange} options={creditCardInputStyle} />
  </YStack>
);
