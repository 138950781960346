import type { XOR } from '@frontend/duck-tape';
import { isLast, omit } from '@frontend/duck-tape';
import { safeCss } from '@frontend/web-utils';
import type { DividerProps as MDividerProps } from '@mantine/core';
import { Divider as MDivider } from '@mantine/core';

export type DividerProps = XOR<MDividerProps, MDividerProps & { forList: true; index: number; list: unknown[] }>;

export const Divider = (props: DividerProps) => {
  // eslint-disable-next-line react/destructuring-assignment
  if (props.forList && isLast(props.list, props.index)) {
    return null;
  }
  return <MDivider classNames={{ root: safeCss('border-t-neutral-200') }} {...omit(props, ['forList'])} />;
};
