import type { Thread, ThreadMessage } from '@frontend/api-types';
import { dateFormatters, dayjs } from '@frontend/duck-tape';

export const getThreadTitle = (thread: MaybeUndefined<Thread>) =>
  thread ? thread.title ?? `Thread from ${dateFormatters['MM/DD/YYYY'](thread.createdAt)}` : '';

export const THREAD_TOPIC_SUGGESTIONS = [
  'Help me brainstorm a trip with my family to Denver',
  'Where should I eat in Los Angeles?',
  "What's a good gift idea for my 12 year old niece?",
  'I want to plan a birthday party for my son, help!',
  'What are the visa requirements to go to Tokyo?',
  'Can you suggest some eco-friendly hotels in New York City?',
  'I need a list of top-rated vegan restaurants in Paris.',
  'Recommend a good hiking trail near San Francisco for beginners.',
  'What are the must-see historical sites in Rome?',
  'What are some interesting date night opportunities in Austin?',
  'Where are some local volunteer opportunities in Chicago?',
  'What are some fun outdoor activities for kids in London?',
  "I'm interested in art galleries showcasing contemporary artists in Berlin.",
  'Can you suggest a weekend wellness spa in the mountains?',
  'I need to organize a lunch in a quiet restaurant in downtown Toronto.',
  'What are some good venue ideas for a company happy hour?',
  'I need information on family-friendly activities in Orlando.',
  'What are the deadlines for Real IDs?',
  'What are the different forms of meditation?',
  'Tell me everything about bespoke custom suits',
  'What are fun day trips for my family surrounding Portland?',
];

export const getThreadTextMessageProps = (comments: ThreadMessage[], index: number) => {
  // Show time if it's the first message or it's been at least an hour since the previous message
  const getIsTimeVisible = (index: number) => {
    if (index === comments.length - 1) {
      return true;
    }
    const lastMessageDate = dayjs(comments[index + 1]!.createdAt);
    const currentMessageDate = dayjs(comments[index]!.createdAt);
    const duration = dayjs.duration(currentMessageDate.diff(lastMessageDate));
    const hours = duration.asHours();
    return hours >= 1;
  };

  // Show divider if it's not the last message, current message is not an ai bot message, and next message is by a
  // different author or time is visible
  const getIsDividerVisible = (index: number) => {
    if (index === 0) {
      return false;
    }
    // List is reversed so get "next" message
    const lastMessage: MaybeUndefined<ThreadMessage> = comments[index - 1];
    // Suggestions render right after this
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (lastMessage && lastMessage.role === 'ai_recommender' && lastMessage.content.length > 0) {
      return true;
    }
    const { role } = lastMessage ?? {};
    return role !== 'ai_bot' && getIsTimeVisible(index - 1);
  };
  const isDividerVisible = getIsDividerVisible(index);
  const isTimeVisible = getIsTimeVisible(index);
  return { isDividerVisible, isTimeVisible };
};
