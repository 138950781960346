import type { StrictOmit } from '@frontend/duck-tape';
import type { ReactNode } from '@frontend/react';
import type { BackgroundImageProps as MImageBackgroundProps } from '@mantine/core';
import { BackgroundImage as MImageBackground } from '@mantine/core';
import type { AspectRatios } from './utils';
import { getAspectRatioClassName } from './utils';

export type ImageBackgroundProps = StrictOmit<MImageBackgroundProps, 'classNames' | 'src'> & {
  aspectRatio?: AspectRatios;
  children?: ReactNode;
  source: MImageBackgroundProps['src'];
};

export const ImageBackground = ({ aspectRatio, source, ...props }: ImageBackgroundProps) => {
  const classNames: MImageBackgroundProps['classNames'] = {
    root: getAspectRatioClassName(aspectRatio),
  };
  return <MImageBackground classNames={classNames} src={source} {...props} />;
};
