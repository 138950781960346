import { useAuthSlice } from '@frontend/react';
import { isMobile } from '@frontend/web-react';
import { identifyClient } from '@frontend/web-utils';
import { useLazyRetrieveClientStatusQuery, useLogInMutation } from '@frontend/web/hooks/api';
import { router } from '@frontend/web/router';
import { handleMutation } from '@frontend/web/utils';
import { Env } from '@frontend/web/utils/env';

export const useAuthentication = () => {
  const { clearAuth, storeToken, token } = useAuthSlice();

  const [loginMutation, { isLoading: isLoginLoading }] = useLogInMutation();
  const [retrieveClientStatusMutation, { isLoading: isRetrieveClientStatusLoading }] =
    useLazyRetrieveClientStatusQuery();

  const login = async ({
    email,
    handleRedirect,
    onSuccess,
    password,
    redirect,
  }: {
    email: string;
    handleRedirect?: boolean;
    onSuccess?: () => void;
    password: string;
    redirect?: string;
  }) =>
    handleMutation({
      mutation: () =>
        loginMutation({
          email,
          password,
        }).unwrap(),
      onSuccess: async ({ token, user: client }) => {
        storeToken(token);
        await identifyClient(client, Env.VITE_ENV);
        return handleMutation({
          // This should never happen, but just in case
          errorMessage: 'Failed to retrieve client status. Please try again.',
          mutation: async () => retrieveClientStatusMutation().unwrap(),
          onError: logout,
          onSuccess: (clientStatus) => {
            onSuccess?.();
            router.invalidate();
            if (handleRedirect) {
              if (!clientStatus.onboardingComplete) {
                return router.navigate({ to: '/onboarding' });
              } else {
                return router.navigate({ to: isMobile ? '/app/settings/payments' : redirect || '/app' });
              }
            }
            return;
          },
          showSuccessToast: false,
        });
      },
      showSuccessToast: false,
    });

  const logout = () => {
    // Redirect to /auth/login happens automatically
    clearAuth();
    router.invalidate();
    router.navigate({ to: '/login' });
  };

  const isAnyLoading = isLoginLoading || isRetrieveClientStatusLoading;
  const isAuthenticated = !!token;

  return {
    isAuthenticated,
    isLoading: isAnyLoading,
    login,
    logout,
  };
};

export type AuthContext = ReturnType<typeof useAuthentication>;
