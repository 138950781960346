import { validators, z } from '../utils';
import { makeFormComponent, makeFormHook } from '../utils/factory';
import type { InferFormType } from '../utils/types';

const changePasswordFormSchema = z
  .object({
    currentPassword: validators.legacyPassword(),
    newPassword: validators.password(),
    newPasswordConfirmation: validators.password(),
  })
  .refine((data) => data.newPassword === data.newPasswordConfirmation, {
    message: 'Passwords do not match',
    path: ['newPasswordConfirmation'],
  });

export type ChangePasswordFormSchema = InferFormType<typeof changePasswordFormSchema>;

const changePasswordFormDefaultValues: ChangePasswordFormSchema = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
};

export const useChangePasswordForm = makeFormHook(changePasswordFormSchema, changePasswordFormDefaultValues);

export const ChangePasswordForm = makeFormComponent(changePasswordFormSchema, changePasswordFormDefaultValues);
