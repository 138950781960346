import { identity, match } from '@frontend/duck-tape';
import { useEffect, useRef } from '@frontend/react';

export const useScrollView = (props?: {
  onRenderScroll: 'bottom' | 'center' | 'top';
  onRenderScrollDelay?: number;
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () =>
    scrollRef.current?.scrollTo({ behavior: 'smooth', top: scrollRef.current?.scrollHeight });

  const scrollToCenter = () =>
    scrollRef.current?.scrollTo({ behavior: 'smooth', top: scrollRef.current?.scrollHeight / 2 });

  const scrollToTop = () => scrollRef.current?.scrollTo({ behavior: 'smooth', top: 0 });
  const scrollTo = (options: ScrollToOptions) => scrollRef.current?.scrollTo({ behavior: 'smooth', ...options });

  useEffect(() => {
    const scrollFn = match(props?.onRenderScroll)
      .with('bottom', () => scrollToBottom)
      .with('center', () => scrollToCenter)
      .with('top', () => scrollToTop)
      .with(undefined, () => identity)
      .exhaustive();

    setTimeout(scrollFn, props?.onRenderScrollDelay ?? 0);
  }, []);

  return { scrollRef, scrollTo, scrollToBottom, scrollToCenter, scrollToTop };
};
