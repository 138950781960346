import type { CommonModalProps } from '@frontend/web-react';
import { ConfirmationModal } from '@frontend/web-react';
import { useAnalytics, useSkipTaskMutation } from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';

export type SkipTaskModalProps = CommonModalProps & {
  onComplete?: () => void;
  taskId: string;
};

export const SkipTaskModal = ({ isOpen, onClose, onComplete, taskId }: SkipTaskModalProps) => {
  const { track } = useAnalytics();
  const [skipTask, { isLoading: isSkipLoading }] = useSkipTaskMutation();
  const onClickSkipTask = async () => {
    track({ data: { taskId }, event: 'skipTaskSubmitButtonSelected', topic: 'tasks' });
    return handleMutation({
      mutation: async () => {
        return skipTask(taskId).unwrap();
      },
      onSuccess: () => {
        onClose();
        onComplete?.();
      },
      showErrorToast: true,
      showSuccessToast: true,
      successMessage: 'This task will be skipped',
    });
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      isSubmitLoading={isSkipLoading}
      onClickCancel={onClose}
      onClickSubmit={onClickSkipTask}
      onClose={onClose}
      submitLabel="Skip"
      subtitle="This task won't appear again until the next cycle."
      title="Are you sure you want to skip this task?"
      variant="primary"
    />
  );
};
