import { match } from '@frontend/duck-tape';
import { Modal, YStack } from '@frontend/web-react';
import { Quota } from '@frontend/web/components/DomainSpecific/Tasks/CreateTaskModal/Quota';
import type { CreateTaskStep } from '@frontend/web/context/CreateTaskContext/createTaskContext';
import { useCreateTaskContext } from '@frontend/web/hooks/context/useCreateTaskContext';
import type { ReactNode } from 'react';
import { DateNeeded } from './DateNeeded';
import { Description } from './Description';
import { InitialQuestions } from './InitialQuestions';
import { Interval } from './Interval';
import { NextSteps } from './NextSteps';

export const CreateTaskModal = () => {
  const { closeCreateTaskModal, createTaskState, isOpen } = useCreateTaskContext();

  return (
    <Modal contentBorderRadius="rounded-lg" isOpen={isOpen} onClose={closeCreateTaskModal} padding="md" size="800px">
      <YStack gapY="md">
        {match<CreateTaskStep, ReactNode>(createTaskState.currentStep)
          .with('description', () => <Description />)
          .with('quota', () => <Quota />)
          .with('dateNeeded', () => <DateNeeded />)
          .with('interval', () => <Interval />)
          .with('nextSteps', () => <NextSteps />)
          .with('initialQuestions', () => <InitialQuestions />)
          .exhaustive()}
      </YStack>
    </Modal>
  );
};
