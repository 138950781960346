import { evaluateClientQuota } from '@frontend/api-client';
import type { DraftTask } from '@frontend/api-types';
import { dateFormatters } from '@frontend/duck-tape';
import { LinkButton, showToast, type ShowToastProps, Text, XStack, YStack } from '@frontend/web-react';
import type { ClassName } from '@frontend/web-utils';
import { concatClasses } from '@frontend/web-utils';
import { NavLinkWrapper } from '@frontend/web/components';
import { useConvertDraftTaskToTaskMutation, useRetrieveClientStatusQuery } from '@frontend/web/hooks';
import { appendRouteHistoryStack } from '@frontend/web/router';
import { handleMutation } from '@frontend/web/utils';
import { useNavigate } from '@tanstack/react-router';

export type DraftTaskCardProps = {
  className?: ClassName;
  onCloseModal?: () => void;
  task: DraftTask;
};

export const DraftTaskCard = ({ className, onCloseModal, task }: DraftTaskCardProps) => {
  const navigate = useNavigate();
  const { data: clientStatus } = useRetrieveClientStatusQuery();
  const [convertDraftTaskToTask, { isLoading: isConvertDraftToTaskLoading }] = useConvertDraftTaskToTaskMutation();

  const isThrottled = clientStatus && evaluateClientQuota(clientStatus).isThrottled;

  const onClickStartButton = () => {
    handleMutation({
      fallbackErrorMessage:
        "Something went wrong on our end, and we can't start this task right now. Try again shortly.",
      mutation: () => convertDraftTaskToTask(task.id).unwrap(),
      onSuccess: ({ id: taskId }) => {
        const toastProps: ShowToastProps = {
          message: (
            <LinkButton
              className="mr-lg"
              color="success"
              label="See task"
              onClick={() => {
                onCloseModal?.();
                navigate({ params: { taskId }, to: `/app/task/$taskId` });
              }}
              type="h5"
              withUnderline
            />
          ),
          title: `"${task.name}" is now in progress`,
          variant: 'success',
        };
        showToast(toastProps);
      },
      showSuccessToast: false,
    });
  };

  return (
    <NavLinkWrapper
      className={concatClasses('flex flex-row gap-x-sm items-center px-sm py-md hover-background-effect', className)}
      params={{ taskId: task.id }}
      state={{ routeHistoryStack: appendRouteHistoryStack('/app') }}
      to={`/app/draft-task/${task.id}`}
    >
      <YStack className="flex-1">
        <YStack className="flex-1" gapY="sm">
          <XStack alignItems="center" gapX="sm">
            <Text type="h6">{task.name}</Text>
          </XStack>
          <XStack justifyContent="spaceBetween">
            <Text color="tertiary" type="caption">
              Description
            </Text>
            <Text color="tertiary" type="caption">
              {dateFormatters.message(task.updatedAt)}
            </Text>
          </XStack>
          <Text lineClamp={2} type="p-sm">
            {task.description}
          </Text>
        </YStack>
        {!isThrottled ? (
          <XStack justifyContent="end">
            <LinkButton
              isLoading={isConvertDraftToTaskLoading}
              label="Start now"
              leftIconName="IconSend"
              onClick={onClickStartButton}
              type="h6"
            />
          </XStack>
        ) : null}
      </YStack>
    </NavLinkWrapper>
  );
};
