import type { CreateClientRequest } from '@frontend/api-types';
import { contactUrl } from '@frontend/constants';
import { getNextItem, getPreviousItem, match } from '@frontend/duck-tape';
import { validators } from '@frontend/forms';
import { useEffect, useState } from '@frontend/react';
import { AppShell, LoadingSpinnerPanel, useIsOnMobile } from '@frontend/web-react';
import { AppContentWrapper, UnauthenticatedAppHeader } from '@frontend/web/components';
import { CreatePassword, UserInfo, Welcome } from '@frontend/web/components/DomainSpecific/AcceptInvite';
import { GenericErrorPage } from '@frontend/web/errorPages';

import { useAuthentication, useLazyRetrieveReferrerDetailsQuery, useRetrieveInviteQuery } from '@frontend/web/hooks';
import { createFileRoute } from '@tanstack/react-router';

const acceptInviteSteps = ['welcome', 'userInfo', 'createPassword'] as const;

type AcceptInviteStep = (typeof acceptInviteSteps)[number];

const initialValues: CreateClientRequest = {
  birthday: '',
  email: '',
  firstName: '',
  inviteId: '',
  lastName: '',
  nickname: '',
  password: '',
  phoneNumber: '',
};

const AcceptInvite = () => {
  const { inviteId } = Route.useParams();
  const { promo } = Route.useSearch();
  const navigate = Route.useNavigate();
  const { data: inviteData, isLoading: isRetrieveInviteLoading } = useRetrieveInviteQuery(inviteId);
  const isOnMobile = useIsOnMobile();
  const [currentStep, setCurrentStep] = useState<AcceptInviteStep>('welcome');
  const [userInfo, setUserInfo] = useState<CreateClientRequest>(initialValues);
  const { isAuthenticated } = useAuthentication();
  const [retrieveReferrerData, { data: referrerDetails, isLoading: isReferrerDetailsLoading }] =
    useLazyRetrieveReferrerDetailsQuery();

  const isAnyLoading = isReferrerDetailsLoading || isRetrieveInviteLoading;

  const incrementStep = () => {
    const nextStep = getNextItem(acceptInviteSteps, currentStep);
    if (nextStep) {
      setCurrentStep(nextStep);
    }
  };
  const decrementStep = () => {
    const lastStep = getPreviousItem(acceptInviteSteps, currentStep);
    if (lastStep) {
      setCurrentStep(lastStep);
    }
  };

  useEffect(() => {
    if (inviteData) {
      if (inviteData?.invitedBy) {
        retrieveReferrerData(inviteData?.invitedBy);
      }
      setUserInfo({
        birthday: inviteData?.birthday,
        email: inviteData?.email,
        firstName: inviteData?.firstName,
        inviteId: inviteData?.id,
        lastName: inviteData?.lastName,
        nickname: inviteData?.nickname ?? '',
        password: '',
        phoneNumber: inviteData?.phoneNumber,
      });
    }
  }, [inviteData]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate({
        to: '/app',
      });
    }
  }, []);

  return (
    <AppShell>
      <UnauthenticatedAppHeader isHidden={isOnMobile} />
      {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
      <AppContentWrapper className="flex-1">
        {isAnyLoading ? (
          <LoadingSpinnerPanel fullScreen />
        ) : inviteData ? (
          match(currentStep)
            .with('welcome', () => (
              <Welcome incrementStep={incrementStep} inviteDetails={inviteData} referrerDetails={referrerDetails} />
            ))
            .with('userInfo', () => (
              <UserInfo
                incrementStep={incrementStep}
                inviteDetails={inviteData}
                onSubmitUserInfo={(userInfo) => setUserInfo((prev) => ({ ...prev, ...userInfo }))}
                userInfo={userInfo}
              />
            ))
            .with('createPassword', () => (
              <CreatePassword
                decrementStep={decrementStep}
                inviteDetails={inviteData}
                searchParamsPromoCode={promo}
                userInfo={userInfo}
              />
            ))
            .exhaustive()
        ) : (
          <GenericErrorPage
            buttonProps={{
              label: 'Get Help',
              // eslint-disable-next-line no-restricted-globals
              onClick: () => open(contactUrl),
            }}
            giantText="Oops,"
            subtitle="It seems like invite link is either expired or claimed already."
            title="Invalid invite"
          />
        )}
      </AppContentWrapper>
    </AppShell>
  );
};

export const Route = createFileRoute('/accept-invite/$inviteId')({
  component: AcceptInvite,
  validateSearch: (search) =>
    validators
      .object({
        promo: validators.optionalString(),
      })
      .parse(search),
});
