import { InputLabel, YStack } from '@frontend/web-react';
import { creditCardInputClassNames, creditCardInputStyle } from '@frontend/web/components';
import { CardCvcElement } from '@stripe/react-stripe-js';
import type { StripeCardCvcElementChangeEvent } from '@stripe/stripe-js';

type CardCvcProps = {
  onChange: (e: StripeCardCvcElementChangeEvent) => void;
};

export const CardCvc = ({ onChange }: CardCvcProps) => (
  <YStack className="gap-sm w-full">
    <InputLabel label="CVC" />
    <CardCvcElement className={creditCardInputClassNames} onChange={onChange} options={creditCardInputStyle} />
  </YStack>
);
