import type { Client, PromotionDetails } from '@frontend/api-types';
import { P, applyDiscount, formatCurrencyNatural, makeCent, match, pluralize } from '@frontend/duck-tape';

export const formatSubscriptionEnd = (date: string) => new Date(date).toLocaleDateString();

export const buildCancellationURLParams = (client: Client) => {
  const params = [
    client?.firstName && client?.lastName ? `name=${client.firstName}+${client.lastName}` : undefined,
    client?.id ? `client_id=${client.id}` : undefined,
    client?.email ? `email=${encodeURIComponent(client.email)}` : undefined,
    client?.phoneNumber ? `phone_number=${encodeURIComponent(client.phoneNumber)}` : undefined,
    client?.price ? `price=${formatCurrencyNatural(client.price)}` : undefined,
    client?.subscriptionEnd ? `subscription_end=${formatSubscriptionEnd(client.subscriptionEnd)}` : undefined,
  ];
  return params.filter(Boolean).join('&');
};

export const formatPromoDetails = (promotionDetails: PromotionDetails): string => {
  const { amountOff: amountOffCents, durationInMonths, freeTasksCap, percentOff } = promotionDetails;

  const amountOff = amountOffCents ? formatCurrencyNatural(amountOffCents) : amountOffCents;

  return match(promotionDetails)
    .with(
      {
        amountOff: P.number,
        durationInMonths: P.number,
        freeTasksCap: P.number,
        percentOff: P.number,
      },
      () =>
        `With this promo code, you'll get up to ${freeTasksCap} free tasks. After that you'll get ${percentOff}% off for ${durationInMonths} ${pluralize('month', durationInMonths!)} and save ${amountOff}.`,
    )
    .with(
      {
        amountOff: P.number,
        durationInMonths: P.number,
        percentOff: P.number,
      },
      () =>
        `With this promo code, you'll get ${percentOff}% off for ${durationInMonths} ${pluralize('month', durationInMonths!)} and save ${amountOff}.`,
    )
    .with(
      {
        durationInMonths: P.number,
        freeTasksCap: P.number,
        percentOff: P.number,
      },
      () =>
        `With this promo code, you'll get up to ${freeTasksCap} free tasks. After that you'll get ${percentOff}% off for ${durationInMonths} ${pluralize('month', durationInMonths!)}.`,
    )
    .with(
      {
        amountOff: P.number,
        durationInMonths: P.number,
        freeTasksCap: P.number,
      },
      () =>
        `With this promo code, you'll get up to ${freeTasksCap} free tasks. After that you'll save ${amountOff} for ${durationInMonths} ${pluralize('month', durationInMonths!)}.`,
    )
    .with(
      {
        freeTasksCap: P.number,
        percentOff: P.number,
      },
      () =>
        `With this promo code, you'll get up to ${freeTasksCap} free tasks. After that you'll get ${percentOff}% off.`,
    )
    .with(
      {
        amountOff: P.number,
        freeTasksCap: P.number,
      },
      () => `With this promo code, you'll get up to ${freeTasksCap} free tasks. After that you'll save ${amountOff}.`,
    )
    .with(
      {
        durationInMonths: P.number,
        percentOff: P.number,
      },
      () =>
        `With this promo code, you'll get ${percentOff}% off for ${durationInMonths} ${pluralize('month', durationInMonths!)}.`,
    )
    .with(
      {
        amountOff: P.number,
        durationInMonths: P.number,
      },
      () =>
        `With this promo code, you'll save ${amountOff} for ${durationInMonths} ${pluralize('month', durationInMonths!)}.`,
    )
    .with(
      {
        amountOff: P.number,
        percentOff: P.number,
      },
      () => `With this promo code, you'll get ${percentOff}% off and save ${amountOff}.`,
    )
    .with(
      {
        percentOff: P.number,
      },
      () => `With this promo code, you'll get ${percentOff}% off.`,
    )
    .with(
      {
        amountOff: P.number,
      },
      () => `With this promo code, you'll save ${amountOff}.`,
    )
    .with(
      {
        freeTasksCap: P.number,
      },
      () => `With this promo code, you'll get up to ${freeTasksCap} free tasks.`,
    )
    .otherwise(() => 'This promo code has no discount details.');
};

export const formatPromoPricingAffect = (basePrice: Cent, promotionDetails: PromotionDetails): string => {
  const { amountOff, durationInMonths, percentOff } = promotionDetails;
  const promo = { amountOff, durationInMonths, percentOff };
  return match(promo)
    .with(
      {
        durationInMonths: P.number,
        percentOff: P.number,
      },
      ({ durationInMonths, percentOff }) => {
        const discountedPrice = applyDiscount(basePrice, percentOff);
        return `${formatCurrencyNatural(discountedPrice)}/month for your first ${durationInMonths} ${pluralize('month', durationInMonths)}`;
      },
    )
    .with(
      {
        amountOff: P.number,
        durationInMonths: P.number,
      },
      ({ amountOff, durationInMonths }) => {
        const discountedPrice = makeCent(basePrice - amountOff);
        return `${formatCurrencyNatural(discountedPrice)}/month for your first ${durationInMonths} ${pluralize('month', durationInMonths)}`;
      },
    )
    .otherwise(() => `${formatCurrencyNatural(basePrice)}/month`);
};
