import { getUseAnalyticsHook } from '@frontend/react';
import { useSegment } from '@frontend/web-react';
import { getSegment } from '@frontend/web-utils';
import { useRetrieveClientQuery } from '@frontend/web/hooks';
import { Env } from '@frontend/web/utils';

export const useAnalytics = () => {
  const segment = getSegment();
  const { track: segmentTrack, trackRouteChange } = useSegment({ analytics: segment, env: Env.VITE_ENV });
  const { data: client } = useRetrieveClientQuery();

  const { track } = getUseAnalyticsHook({ client, segmentTrack });

  return { track, trackRouteChange };
};
