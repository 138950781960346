import { useAuthSlice, useEffect } from '@frontend/react';
import { MantineProvider, ModalsProvider, Notifications } from '@frontend/web-react';
import { identifyClient } from '@frontend/web-utils';
import { RouterProvider } from '@frontend/web/components';
import { useAuthentication, useLazyRetrieveClientQuery } from '@frontend/web/hooks';
import { mantineTheme } from '@frontend/web/mantine';
import { persistor, store } from '@frontend/web/redux/store';
import { router } from '@frontend/web/router';
import { Env } from '@frontend/web/utils';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
// Core always needs to be first
import '@mantine/carousel/styles.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/spotlight/styles.css';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { initApp } from './utils/initApp';

const { growthbook } = initApp();

// Needs to be wrapped inside Redux Provider/PersistGate before useAuthentication is accessible
export const Router = () => {
  const authentication = useAuthentication();

  return (
    <Suspense fallback={<div />}>
      <RouterProvider context={{ authentication }} router={router} />
    </Suspense>
  );
};

export const App = () => {
  const [retrieveClient, { isLoading }] = useLazyRetrieveClientQuery();
  const { token } = useAuthSlice();

  const identify = async () => {
    const client = await retrieveClient().unwrap();
    if (client) {
      identifyClient(client, Env.VITE_ENV);
    }
  };

  useEffect(() => {
    if (token) {
      (async () => identify())();
    } else {
      // const segment = getSegment();
      // const growthbook = getGrowthBook();
      // From old web app but not sure if it's necessary
      // segment.ready(() => growthbook.setAttributes({ anonymousId: window.analytics?.user().anonymousId }));
    }
  }, []);

  if (isLoading) {
    return <div />;
  }
  return (
    <GrowthBookProvider growthbook={growthbook}>
      <Router />
    </GrowthBookProvider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const rootElement = document.getElementById('root')!;
if (!rootElement?.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <MantineProvider theme={mantineTheme}>
      <div className="min-h-screen">
        <ModalsProvider>
          <Notifications position="bottom-left" />
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
          {/* <TanStackRouterDevtools initialIsOpen={false} router={router} /> */}
        </ModalsProvider>
      </div>
    </MantineProvider>,
  );
}
