import type { SwitchInputProps } from '@frontend/web-react';
import { Avatar, SwitchInput, Tag, Text, XStack } from '@frontend/web-react';
import { applyClass, concatClasses, safeCss } from '@frontend/web-utils';

type MemberAccessProps = Pick<SwitchInputProps, 'checked' | 'disabled'> & {
  initials: string;
  isClient?: boolean;
  isOwner?: boolean;
  name: string;
  onChange?: () => void;
};

export const MemberAccess = ({ checked, disabled, initials, isClient, isOwner, name, onChange }: MemberAccessProps) => {
  const handleChange = () => onChange?.();
  return (
    <XStack className="items-center">
      <XStack className="items-center grow-1" gap="sm">
        <Avatar
          classNames={{
            placeholder: concatClasses(
              safeCss('bg-surfaceBrand text-captionBold text-textAccent'),
              applyClass(!isClient, safeCss('text-textSecondary bg-surfacePrimary')),
            ),
            root: concatClasses(safeCss('bg-surfaceBrand p-xs'), applyClass(!isClient, safeCss('bg-surfacePrimary'))),
          }}
          initials={initials}
          size="sm"
        />
        <Text color="tertiary" type="captionBold">
          {name}
        </Text>
        {isOwner ? <Tag label="Owner" /> : null}
      </XStack>
      <SwitchInput checked={checked} disabled={disabled} onChange={handleChange} />
    </XStack>
  );
};
