import { DownloadOnTheAppStoreImage, GetItOnThePlayStoreImage } from '@frontend/assets';
import { appStoreLinks } from '@frontend/constants';
import { match } from '@frontend/duck-tape';
import { Anchor } from '@mantine/core';
import { XStack } from '../Containers';
import { Image } from '../Image/Image';

export type AppStoresButtonProps = {
  height?: number;
  onClick: () => void;
  platform: MobilePlatform;
};

const DEFAULT_HEIGHT = 40;

export const AppStoresButton = ({ height = DEFAULT_HEIGHT, onClick, platform }: AppStoresButtonProps) => {
  const { height: calculatedHeight, width: calculatedWidth } = calculateDimensions(height);

  return (
    <XStack>
      <Anchor href={appStoreLinks[platform]} onClick={onClick} target="_blank">
        <Image
          h={calculatedHeight}
          source={match(platform)
            .with('android', () => GetItOnThePlayStoreImage)
            .with('ios', () => DownloadOnTheAppStoreImage)
            .exhaustive()}
          w={calculatedWidth}
        />
      </Anchor>
    </XStack>
  );
};

// Aspect ratio is 3:1, width/height
const calculateDimensions = (height: number) => {
  const [widthRatio, heightRatio] = [3, 1];
  const calculatedWidth = (height * widthRatio) / heightRatio;

  return {
    height,
    width: calculatedWidth,
  };
};
