import type { CalendarRecurrence, Recurrence, RecurrenceInterval } from '@frontend/api-types';
import { dayjs, pluralize } from '@frontend/duck-tape';
import { match } from 'ts-pattern';

export const calendarRecurrenceIntervalString = (calendarRecurrence: CalendarRecurrence) => {
  const intervalString = `Every ${pluralize(calendarRecurrence.interval, calendarRecurrence.intervalCount, true)}`;
  const dateNeeded = dayjs(calendarRecurrence.nextStartDate);

  return match(calendarRecurrence.interval)
    .with('week', () => `${intervalString} on ${dateNeeded.format('ddd')}`)
    .with('month', () => `${intervalString} on the ${dateNeeded.format('Do')}`)
    .with('year', () => `${intervalString} on ${dateNeeded.format('M/D')}`)
    .otherwise(() => intervalString);
};

export const recurrenceIntervalString = (recurrence: Recurrence) => {
  const intervalString = `Every ${pluralize(recurrence.interval, recurrence.intervalCount, true)}`;
  const dateNeeded = dayjs(recurrence.latestTaskDate || recurrence.startDate).add(recurrence.buffer, 'days');

  return match(recurrence.interval)
    .with('week', () => `${intervalString} on ${dateNeeded.format('ddd')}`)
    .with('month', () => `${intervalString} on the ${dateNeeded.format('Do')}`)
    .with('year', () => `${intervalString} on ${dateNeeded.format('M/D')}`)
    .otherwise(() => intervalString);
};

export const recurrenceBufferString = (recurrence: Recurrence) => {
  return pluralize('day', recurrence.buffer, true) + ' before';
};

export const calculateDefaultRecurrenceBuffer = (interval: RecurrenceInterval, intervalCount: number) => {
  const totalDays: number = match(interval)
    .with('day', () => intervalCount * 1)
    .with('week', () => intervalCount * 7)
    .with('month', () => intervalCount * 30)
    .with('year', () => intervalCount * 365)
    .exhaustive();

  const buffer: number = Math.pow(totalDays, 0.65);

  if (buffer < 7) {
    return Math.ceil(buffer);
  }
  return Math.round(buffer / 7) * 7;
};
