import type { LocalFile } from '@frontend/api-types';
import { type ReplaceSecureNoteInput } from '@frontend/api-types';
import type { SnakeCasedProperties } from '@frontend/duck-tape';
import type { SecureNoteFormSchema } from '@frontend/forms';
import {
  checkDidSecureNoteFormValuesChange,
  findRemovedAttachments,
  getFileExceededError,
  getLocalFiles,
  getSecureNoteFormDefaultValues,
  makeSavedFileList,
  useSecureNoteForm,
} from '@frontend/forms';
import { Fragment, useEffect, useState, useVisibilityController } from '@frontend/react';
import {
  Button,
  ConfirmationModal,
  FileInput,
  LinkButton,
  Modal,
  PasswordInput,
  TextInput,
  TextareaInput,
  XStack,
  YStack,
  showToast,
  type CommonModalProps,
} from '@frontend/web-react';
import { WebTypedFormData } from '@frontend/web-utils';
import { PrivacyPolicyBanner } from '@frontend/web/components/DomainSpecific/PrivacyPolicyBanner';
import {
  useAnalytics,
  useDeleteSecureNoteMutation,
  useLazyListCommentsQuery,
  useLazyRetrieveSecureNoteContentQuery,
  useReplaceSecureNoteContentMutation,
} from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';

export type SecureNoteModalProps = CommonModalProps & {
  secureNoteId: string;
  taskId: string;
  title: string;
};

export const SecureNoteModal = ({ isOpen, onClose, secureNoteId, taskId, title }: SecureNoteModalProps) => {
  const [retrieveSecureNoteContent, { data: secureNoteContent, isLoading: isRetrieveSecureNoteContentLoading }] =
    useLazyRetrieveSecureNoteContentQuery();

  const [replaceSecureNoteContent, { isLoading: isReplaceSecureNoteContentLoading }] =
    useReplaceSecureNoteContentMutation();

  const [deleteSecureNote, { isLoading: isDeleteSecureNoteLoading }] = useDeleteSecureNoteMutation();
  const [retrieveComments] = useLazyListCommentsQuery();

  const [isEditing, setIsEditing] = useState(false);

  const [isDeleteSecureNoteModalOpen, { close: closeDeleteSecureNoteModal, open: openDeleteSecureNoteModal }] =
    useVisibilityController();

  const { track } = useAnalytics();

  useEffect(() => {
    track({ data: { secureNoteId }, event: 'secureNoteViewed', topic: 'secureNote' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secureNoteId]);

  useEffect(() => {
    if (isOpen) {
      (async () => retrieveSecureNoteContent(secureNoteId))();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, secureNoteId]);

  const onUpdateSecureNoteContent = async (values: SecureNoteFormSchema) => {
    if (!checkDidSecureNoteFormValuesChange(secureNoteContent!, values)) {
      setIsEditing(false);
      onClose();
      return;
    }
    track({ data: { secureNoteId }, event: 'secureNoteUpdated', topic: 'secureNote' });
    const { attachments, notes, password, username, website } = values;
    return handleMutation({
      mutation: async () => {
        const formData = new WebTypedFormData<SnakeCasedProperties<ReplaceSecureNoteInput>>();
        const newAttachments = attachments.filter((a) => a.__typename === 'LocalFile') as LocalFile[];
        const deleteAttachmentsKeys: string[] = findRemovedAttachments(
          makeSavedFileList(secureNoteContent?.attachments ?? [], (a) => a),
          attachments,
        ).map((a) => a.key);

        if (newAttachments.length) formData.appendLocalFiles('attachments', newAttachments);
        if (deleteAttachmentsKeys.length) formData.append('delete_attachments', deleteAttachmentsKeys);
        if (notes) formData.append('notes', notes);
        if (password) formData.append('password', password);
        if (username) formData.append('username', username);
        if (website) formData.append('website', website);
        if (taskId) formData.append('task', taskId);
        return replaceSecureNoteContent({ formData, secureNoteId });
      },
      onSuccess: () => {
        retrieveSecureNoteContent(secureNoteId);
        onClose();
        retrieveComments(taskId);
      },
      showErrorToast: true,
      showSuccessToast: true,
      successMessage: isEditing ? 'Successfully edited.' : 'Successfully added.',
    });
  };

  const onDeleteSecureNote = async () => {
    track({ data: { secureNoteId }, event: 'secureNoteDeleted', topic: 'secureNote' });
    return handleMutation({
      mutation: async () => deleteSecureNote({ secureNoteId, taskId: taskId! }),
      onSuccess: () => {
        closeDeleteSecureNoteModal();
        onClose();
        retrieveComments(taskId);
      },
      showErrorToast: true,
      showSuccessToast: false,
    });
  };

  const onPressEditButton = () => setIsEditing(true);

  const isNew =
    !secureNoteContent?.attachments?.length &&
    !secureNoteContent?.notes &&
    !secureNoteContent?.password &&
    !secureNoteContent?.username &&
    !secureNoteContent?.website;

  const hideSubmissionFooter = !isNew && !isEditing;
  const disableInputs = isNew ? false : !isEditing;

  const { getControl, isValid, setFieldValue, submitForm, values } = useSecureNoteForm({
    enableReinitialize: true,
    initialValues: getSecureNoteFormDefaultValues(secureNoteContent),
    onSubmit: onUpdateSecureNoteContent,
  });

  const didValuesChange = checkDidSecureNoteFormValuesChange(secureNoteContent!, values);
  return (
    <Fragment>
      <Modal
        isLoading={isRetrieveSecureNoteContentLoading}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setIsEditing(false);
        }}
        size="500px"
        subtitle="We keep your sensitive info safe and sound, using top-notch encryption and strict access rules – only our trained copilots get a peek."
        title={title}
        titlesAlignment="start"
      >
        <YStack className="gap-y-md">
          <TextInput
            disabled={disableInputs}
            placeholder="Email or username"
            value={getControl('username').value ?? ''}
            {...getControl('username', ['value'])}
          />
          <PasswordInput
            disabled={disableInputs}
            label="Password"
            value={getControl('password').value ?? ''}
            {...getControl('password', ['value'])}
          />
          <TextInput
            disabled={disableInputs}
            label="Website"
            placeholder="getduckbill.com"
            value={getControl('website').value ?? ''}
            {...getControl('website', ['value'])}
          />
          <FileInput
            allowedFileTypes={['image']}
            disabled={disableInputs}
            onChange={(files) => {
              const localFiles = getLocalFiles(files);
              const fileExceededError = getFileExceededError(localFiles);
              if (fileExceededError) {
                showToast({ title: fileExceededError, variant: 'danger' });
              } else {
                setFieldValue('attachments', files);
              }
            }}
            previewType="listItems"
            showPreview
            {...getControl('attachments', ['onChange'])}
          />
          <TextareaInput
            disabled={disableInputs}
            label="Notes"
            placeholder="Anything else we should know?"
            {...getControl('notes')}
            showOptionalText
          />

          {isNew || isEditing ? null : (
            <XStack gapX="lg">
              <LinkButton label="Edit" leftIconName="IconPencil" onClick={onPressEditButton} />
              <LinkButton
                color="danger"
                iconColor="danger"
                label="Delete"
                leftIconName="IconTrash"
                onClick={openDeleteSecureNoteModal}
              />
            </XStack>
          )}

          {hideSubmissionFooter ? null : (
            <Fragment>
              <Button
                color="primary"
                disabled={!isValid || !didValuesChange}
                isLoading={isReplaceSecureNoteContentLoading}
                label="Save"
                leftIconName="IconLock"
                onClick={submitForm}
              />
              {isNew ? (
                <XStack justifyContent="center">
                  <PrivacyPolicyBanner />
                </XStack>
              ) : null}
            </Fragment>
          )}
        </YStack>
      </Modal>
      <ConfirmationModal
        isOpen={isDeleteSecureNoteModalOpen}
        isSubmitLoading={isDeleteSecureNoteLoading}
        onClickCancel={closeDeleteSecureNoteModal}
        onClickSubmit={onDeleteSecureNote}
        onClose={closeDeleteSecureNoteModal}
        submitLabel="Delete"
        subtitle="Our copilots will have to ask you this detail in the future."
        title="Are you sure you want to delete this information?"
        variant="danger"
      />
    </Fragment>
  );
};
