import { P, isEmpty, match } from '@frontend/duck-tape';
import type { ForwardedRef } from '@frontend/react';
import { Fragment, forwardRef, type ReactNode } from '@frontend/react';
import { concatClasses, type ClassName } from '@frontend/web-utils';
import { View, YStack } from '../Containers';

export type FlatListProps<TData> = {
  ListEmptyComponent?: ReactNode;
  ListErrorComponent?: boolean;
  ListFooterComponent?: ReactNode;
  ListHeaderComponent?: ReactNode;
  ListLoadingComponent?: ReactNode;
  className?: ClassName;
  contentContainerClassName?: ClassName;
  data: MaybeUndefined<TData[]>;
  error?: boolean;
  inverted?: boolean;
  isLoading?: boolean;
  keyExtractor?: (item: TData) => string;
  renderItem: (renderItemInfo: { array: TData[]; index: number; item: TData }) => ReactNode;
};

export const FlatListComponent = <TData,>(
  {
    className,
    contentContainerClassName,
    data,
    error,
    inverted,
    isLoading,
    keyExtractor,
    ListEmptyComponent,
    ListErrorComponent,
    ListFooterComponent,
    ListHeaderComponent,
    ListLoadingComponent,
    renderItem,
  }: FlatListProps<TData>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const resolvedData = data ?? [];
  if (isLoading) {
    return <YStack className={className}>{ListLoadingComponent}</YStack>;
  }
  return (
    <YStack className={concatClasses(className, 'overflow-y-auto')} ref={ref}>
      {ListHeaderComponent}
      {match({ ListEmptyComponent, ListErrorComponent, data: resolvedData, error, isEmpty: isEmpty(resolvedData) })
        .with({ ListErrorComponent: P.nonNullable, error: true }, ({ ListErrorComponent }) => ListErrorComponent)
        .with({ ListEmptyComponent: P.nonNullable, isEmpty: true }, ({ ListEmptyComponent }) => ListEmptyComponent)
        .otherwise(({ data }) => (
          <View className={contentContainerClassName} flexDirection={inverted ? 'columnReverse' : 'column'}>
            <div />
            {data.map((item, index, array) => (
              <Fragment key={keyExtractor ? keyExtractor(item) : index}>{renderItem({ array, index, item })}</Fragment>
            ))}
          </View>
        ))}
      {ListFooterComponent}
    </YStack>
  );
};

export const FlatList = forwardRef(FlatListComponent) as <TData>(
  props: FlatListProps<TData> & { ref?: React.ForwardedRef<HTMLDivElement> },
) => JSX.Element;
