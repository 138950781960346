import { createTag } from '@frontend/api-client/utils';
import type { BaseApi } from '@frontend/api-client/utils/types';
import type { RetrieveHouseholdResponse } from '@frontend/api-types';
import { convertObjectKeysCase } from '@frontend/duck-tape';
import { HOUSEHOLD_ENDPOINTS } from './endpoints';

export const getHouseholdApi = (baseApi: BaseApi) =>
  baseApi.injectEndpoints({
    endpoints: (builder) => ({
      retrieveHousehold: builder.query<RetrieveHouseholdResponse, string>({
        providesTags: (_result, _error, householdId) => [
          createTag({ id: householdId, prefix: 'householdId', type: 'Household' }),
        ],
        query: (id) => HOUSEHOLD_ENDPOINTS.retrieveHousehold(id),
        transformResponse: (response: GenericRecord) =>
          convertObjectKeysCase(response, 'camelCase') as RetrieveHouseholdResponse,
      }),
    }),
    overrideExisting: true,
  });
