import { skipQuotaStep } from '@frontend/api-client';
import type {
  CreateRecurrenceRequest,
  CreateTaskMutation,
  DraftTaskDetail,
  TaskInitialQuestion,
  TaskInitialQuestionAnswer,
  TaskInitialQuestionInput,
  UpdateDraftTaskMutation,
} from '@frontend/api-types';
import { dayjs, debounce, deserializeJson, generateUUID, isArray, serializeJson } from '@frontend/duck-tape';
import type { ReactNode } from '@frontend/react';
import {
  useAuthSlice,
  useEffect,
  useFeatureIsOn,
  useListState,
  useMemo,
  useState,
  useTaskSlice,
} from '@frontend/react';
import type { SocketMethod } from '@frontend/sockets';
import type { ShowToastProps } from '@frontend/web-react';
import { LinkButton, showToast } from '@frontend/web-react';
import { WebTypedFormData } from '@frontend/web-utils';
import {
  useAnalytics,
  useCreateRecurrenceMutation,
  useCreateTaskMutation,
  useRetrieveClientStatusQuery,
  useUpdateDraftTaskMutation,
} from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';
import { disconnectSocket, initializeSocket } from '@frontend/web/utils/sockets';
import { useNavigate } from '@tanstack/react-router';
import type {
  Answer,
  CreateTaskData,
  InitializationOptions,
  NextStep,
  SkippableCreateTaskStep,
} from './createTaskContext';
import { CreateTaskContext, allSteps, defaultTaskData, initialCreateTaskState } from './createTaskContext';

const processAiQuestionnaire = (
  aiQuestionnaire: MaybeNull<{ responses: TaskInitialQuestionAnswer[] }>,
): {
  answers: Answer[];
  initialQuestions: TaskInitialQuestion[];
} => {
  if (!aiQuestionnaire) {
    return { answers: [], initialQuestions: [] };
  }

  const initialQuestions: TaskInitialQuestion[] = aiQuestionnaire.responses.map((response) => ({
    choices: response.choices,
    question: response.question,
  }));

  const answers: Answer[] = aiQuestionnaire.responses.map((response) => ({
    answer: Array.isArray(response.answer) ? response.answer.map((a) => ({ id: a, value: a })) : response.answer,
    choices: response.choices?.map((choice) => ({ id: choice, value: choice })) ?? [],
    question: response.question,
  }));

  return { answers, initialQuestions };
};

const processAnswersForSubmission = (answers: Answer[]): TaskInitialQuestionInput[] => {
  return answers.map((q): TaskInitialQuestionInput => {
    const answer = isArray(q.answer)
      ? // We want to fallback to the literal text "Other" if the other checkbox is checked and no textual answer was provided
        q.answer.map((a) => (a.id === 'Other' && !a.value ? 'Other' : a.value))
      : q.answer;
    return { answer, choices: q.choices.map((c) => c.id), question: q.question };
  });
};

const stepsForRepeatingTasksOnly = ['dateNeeded', 'interval'];

export type CreateTaskProviderProps = {
  children: ReactNode;
};

export const CreateTaskProvider = ({ children }: CreateTaskProviderProps) => {
  const navigate = useNavigate();
  const { enabled: isThrottlingTasksEnabled } = useFeatureIsOn('is_throttling_tasks_enabled');
  const [state, setState] = useState(initialCreateTaskState);
  const [isOpen, setIsOpen] = useState(false);
  const [onCreateCallback, setOnCreateCallback] = useState<() => void>();
  const [initialQuestions, { setState: setInitialQuestions }] = useListState<TaskInitialQuestion>([]);
  const [answers, { replace: onUpdateAnswer, setState: setAnswers }] = useListState<Answer>([]);
  const [descriptionSnapshot, setDescriptionSnapshot] = useState('');
  const [shouldSkipTitleGeneration, setShouldSkipTitleGeneration] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const { token } = useAuthSlice();
  const { track } = useAnalytics();
  const [createRecurrence, { isLoading: isCreateRecurrenceLoading }] = useCreateRecurrenceMutation();
  const [createTask, { isLoading: isCreateTaskLoading }] = useCreateTaskMutation();
  const { data: clientStatus, refetch: refetchClientStatus } = useRetrieveClientStatusQuery();
  const { hideIndividualTaskQuotaStep } = useTaskSlice();
  const [updateDraftTask, { isLoading: isUpdateDraftTaskLoading }] = useUpdateDraftTaskMutation();
  const [draftTask, setDraftTask] = useState<DraftTaskDetail | null>(null);

  useEffect(() => {
    setAnswers((answers) =>
      initialQuestions.map(({ choices, question, ...rest }) => {
        const answer = answers.find((a) => a.question?.includes(question))?.answer;
        return {
          ...rest,
          answer: !answer ? (choices?.length ? [] : '') : answer,
          choices: choices?.map((choice) => ({ id: choice, value: choice })) ?? [],
          question,
        };
      }),
    );
  }, [initialQuestions]);

  const updateTaskData = (data: Partial<CreateTaskData>) => {
    setState((prev) => ({ ...prev, taskData: { ...prev.taskData, ...data } }));
  };

  const closeCreateTaskModal = () => {
    disconnectSocket();
    track({
      data: {
        currentStep: state.currentStep,
        uniqueKey: state.uniqueKey,
        ...(draftTask && { entityId: draftTask.id, entityType: 'draft' }),
        ...(clientStatus && {
          currentActiveTasks: clientStatus.currentActiveTasks,
          individualMaxActiveTasks: clientStatus.individualMaxActiveTasks,
          planMaxActiveTasks: clientStatus.planMaxActiveTasks,
        }),
      },
      event: 'taskFlowEnded',
      topic: 'tasks',
    });
    setIsOpen(false);
    setState(initialCreateTaskState);
    setInitialQuestions([]);
    setAnswers([]);
    setDescriptionSnapshot('');
    setDraftTask(null);
  };

  const handleSubmitUpdateDraftTask = async ({
    attachments = [],
    description,
    id,
    name,
    nextSteps,
  }: CreateTaskData & { id: string }) => {
    disconnectSocket();
    return handleMutation({
      mutation: async () => {
        track({
          data: {
            attachments,
            description,
            draftTaskId: id,
            name,
            nextSteps,
            uniqueKey: state.uniqueKey,
            ...(draftTask && { entityId: draftTask.id, entityType: 'draft' }),
            ...(clientStatus && {
              currentActiveTasks: clientStatus.currentActiveTasks,
              individualMaxActiveTasks: clientStatus.individualMaxActiveTasks,
              planMaxActiveTasks: clientStatus.planMaxActiveTasks,
            }),
          },
          event: 'updateDraftTaskSubmitButtonSelected',
          topic: 'draftTasks',
        });

        const formData = new WebTypedFormData<UpdateDraftTaskMutation['input']>();

        const updatePayload: UpdateDraftTaskMutation['input']['data'] = {
          ai_questionnaire: answers.length ? { responses: processAnswersForSubmission(answers) } : undefined,
          description,
          name,
          proceed_decision: nextSteps!,
        };

        formData.appendStringified('data', updatePayload);
        formData.appendLocalFiles('attachments', attachments);

        return updateDraftTask({ data: { formData }, id }).unwrap();
      },
      onComplete: closeCreateTaskModal,
      onSuccess: () => {
        const toastProps: ShowToastProps = {
          title: `Draft task "${name}" updated.`,
          variant: 'success',
        };

        showToast(toastProps);
        onCreateCallback?.();
      },
      showErrorToast: true,
      showSuccessToast: false,
    });
  };

  const handleSubmitCreateRecurrence = async ({
    buffer = 0,
    calendarRecurrenceId,
    calendarRecurrenceTaskSuggestionIdx,
    dateNeeded,
    description,
    exampleId,
    exampleType,
    interval,
    intervalCount,
    name,
    nextSteps,
    sharedWithClientIds,
  }: CreateTaskData) => {
    const startDate = dayjs(dateNeeded).subtract(buffer, 'days').format('YYYY-MM-DD');
    const formattedDateNeeded = dayjs(dateNeeded).format('MM/DD/YYYY');
    const nameOrDefaultName = name || `Repeating Task for ${formattedDateNeeded}`;
    disconnectSocket();
    return handleMutation({
      mutation: async () => {
        track({
          data: {
            description,
            exampleId,
            exampleType,
            name: nameOrDefaultName,
            nextSteps,
            uniqueKey: state.uniqueKey,
            ...(clientStatus && {
              currentActiveTasks: clientStatus.currentActiveTasks,
              individualMaxActiveTasks: clientStatus.individualMaxActiveTasks,
              planMaxActiveTasks: clientStatus.planMaxActiveTasks,
            }),
          },
          event: 'createRecurrenceSubmitButtonSelected',
          topic: 'recurrences',
        });

        const data: CreateRecurrenceRequest['input'] = {
          ai_questionnaire: answers.length ? { responses: processAnswersForSubmission(answers) } : undefined,
          buffer,
          calendar_recurrence: calendarRecurrenceId,
          calendar_recurrence_task_suggestion_idx: calendarRecurrenceTaskSuggestionIdx,
          description,
          interval,
          interval_count: intervalCount,
          name: nameOrDefaultName,
          proceed_decision: nextSteps!,
          shared_with: sharedWithClientIds,
          start_date: startDate as DateISOString,
        };

        return createRecurrence(data).unwrap();
      },
      onComplete: closeCreateTaskModal,
      onSuccess: ({ id: recurrenceId, latestTaskDate }) => {
        const additionalMessage = latestTaskDate
          ? ` We have already started your task for ${formattedDateNeeded}.`
          : '';

        const toastProps: ShowToastProps = {
          message: (
            <LinkButton
              className="mr-lg"
              color="success"
              label="See repeating task"
              onClick={() => navigate({ params: { recurrenceId }, to: '/app/recurrence/$recurrenceId' })}
              type="h5"
              withUnderline
            />
          ),
          title: `Repeating task "${nameOrDefaultName}" created.${additionalMessage}`,
          variant: 'success',
        };

        showToast(toastProps);

        track({
          data: {
            exampleId,
            exampleType,
            recurrenceId,
            uniqueKey: state.uniqueKey,
            ...(clientStatus && {
              currentActiveTasks: clientStatus.currentActiveTasks,
              individualMaxActiveTasks: clientStatus.individualMaxActiveTasks,
              planMaxActiveTasks: clientStatus.planMaxActiveTasks,
            }),
          },
          event: 'createRecurrenceSuccessMessageViewed',
          topic: 'recurrences',
        });

        onCreateCallback?.();
      },
      showErrorToast: true,
      showSuccessToast: false,
    });
  };

  const handleSubmitCreateTask = async ({
    attachments = [],
    description,
    exampleId,
    exampleType,
    name,
    nextSteps,
    researchThreadId,
    researchThreadReferenceData,
    sharedWithClientIds,
  }: CreateTaskData) => {
    disconnectSocket();
    return handleMutation({
      mutation: async () => {
        track({
          data: {
            attachments,
            description,
            exampleId: exampleId,
            exampleType: exampleType,
            name,
            nextSteps,
            uniqueKey: state.uniqueKey,
            ...(clientStatus && {
              currentActiveTasks: clientStatus.currentActiveTasks,
              individualMaxActiveTasks: clientStatus.individualMaxActiveTasks,
              planMaxActiveTasks: clientStatus.planMaxActiveTasks,
            }),
          },
          event: 'createTaskSubmitButtonSelected',
          topic: 'tasks',
        });

        const formData = new WebTypedFormData<CreateTaskMutation['input']>();

        const basePayload: Omit<CreateTaskMutation['input']['data'], 'task_suggestion'> = {
          ai_questionnaire: answers.length ? { responses: processAnswersForSubmission(answers) } : undefined,
          description: description,
          example: exampleId,
          name,
          proceed_decision: nextSteps!,
          shared_with: sharedWithClientIds,
          task_source: 'task_source_web_app',
        };

        if (researchThreadId) {
          const suggestionLinkedPayload: CreateTaskMutation['input']['data'] = {
            ...basePayload,
            search_thread: {
              id: researchThreadId,
              message_idx: researchThreadReferenceData?.messageIdx,
              task_suggestion_idx: researchThreadReferenceData?.taskSuggestionIdx,
            },
          };
          formData.appendStringified('data', suggestionLinkedPayload);
        } else {
          formData.appendStringified('data', basePayload);
        }

        formData.appendLocalFiles('attachments', attachments);
        return createTask({ formData }).unwrap();
      },
      onComplete: closeCreateTaskModal,
      onSuccess: ({ id: taskId, status }) => {
        const toastProps: ShowToastProps = {
          message: (
            <LinkButton
              className="mr-lg"
              color={status === 'draft' ? 'warning' : 'success'}
              label="See task"
              onClick={() =>
                navigate({ params: { taskId }, to: `/app/${status === 'draft' ? 'draft-task' : 'task'}/${taskId}` })
              }
              type="h5"
              withUnderline
            />
          ),
          title: `"${name}" ${status === 'draft' ? 'was placed in drafts. Complete one of your active tasks, then we’ll start working on this task' : 'created'}`,
          variant: status === 'draft' ? 'warning' : 'success',
        };

        showToast(toastProps);

        track({
          data: {
            exampleId: exampleId,
            exampleType: exampleType,
            isDraft: status === 'draft',
            taskId,
            uniqueKey: state.uniqueKey,
            ...(clientStatus && {
              currentActiveTasks: clientStatus.currentActiveTasks,
              individualMaxActiveTasks: clientStatus.individualMaxActiveTasks,
              planMaxActiveTasks: clientStatus.planMaxActiveTasks,
            }),
          },
          event: 'createTaskSuccessMessageViewed',
          topic: 'tasks',
        });

        onCreateCallback?.();
      },
      showErrorToast: true,
      showSuccessToast: false,
    });
  };

  const handleInitialQuestionsUpdate = debounce<SocketMethod<'taskCreation', 'update_initial_questions'>>(
    (json) => {
      const data = deserializeJson(json);
      setInitialQuestions(
        data.content.questions.map((q) => ({
          ...q,
          choices: q.choices,
        })),
      );
    },
    250,
    { maxWait: 500 },
  );

  const setupSocket = ({
    description,
    skipTitleGeneration = false,
    token,
  }: {
    description: string;
    skipTitleGeneration?: boolean;
    token: string;
  }) => {
    disconnectSocket();

    const socket = initializeSocket({
      channelType: 'taskCreation',
      token,
    });

    const onConnect = () => {
      skipTitleGeneration || socket.emit('generate_task_title', description);
    };

    const onDisconnect = () => {
      setIsStreaming(false);
    };

    const onCompleted = () => {
      disconnectSocket();
      setIsStreaming(false);
    };

    const onFailed = () => {
      setIsStreaming(false);
      disconnectSocket();
    };

    const handleSetTaskTitle: SocketMethod<'taskCreation', 'set_task_title'> = (data) => {
      const { content } = deserializeJson(data);
      updateTaskData({ name: content });
    };

    socket.on('update_initial_questions', handleInitialQuestionsUpdate);
    socket.on('set_task_title', handleSetTaskTitle);
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('initial_questions_complete', onCompleted);
    socket.on('initial_questions_failed', onFailed);
    return socket;
  };

  const setStepByIndex = (index: number) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setState((prev) => ({ ...prev, currentStep: state.steps[index]! }));
  };

  // No special data validation for completion, trusting that consumer correctly dispatches increment/decrement
  const decrementStep = () => {
    const currentIndex = state.steps.indexOf(state.currentStep);
    // Can't decrement if already at the first step
    if (currentIndex === 0) return;
    const skipQuota =
      (state.isRecurrence && state.steps[currentIndex - 1] === 'quota') ||
      (state.steps[currentIndex - 1] === 'quota' &&
        clientStatus &&
        skipQuotaStep(clientStatus, isThrottlingTasksEnabled, hideIndividualTaskQuotaStep));
    setStepByIndex(currentIndex - (skipQuota ? 2 : 1));
  };

  const generateInitialQuestions = ({
    isRecurrence,
    skipTitleGeneration = false,
    taskData,
    token,
  }: {
    isRecurrence: boolean;
    skipTitleGeneration?: boolean;
    taskData: CreateTaskData;
    token: MaybeUndefined<string>;
  }) => {
    const { attachments, dateNeeded, description, interval, intervalCount, researchThreadId } = taskData;
    setInitialQuestions([]);
    setAnswers([]);
    setDescriptionSnapshot(description);
    if (token) {
      setIsStreaming(true);
      const socket = setupSocket({ description, skipTitleGeneration, token });
      socket.emit(
        'generate_initial_questions',
        serializeJson({
          description: description,
          has_attachment: !!attachments?.length,
          thread_id: researchThreadId,
          ...(isRecurrence && {
            recurrence_date_needed: dateNeeded,
            recurrence_interval: interval,
            recurrence_interval_count: intervalCount,
          }),
        }),
      );
    }
  };

  const incrementStep = () => {
    const currentIndex = state.steps.indexOf(state.currentStep);
    const nextStep = currentIndex + 1 < state.steps.length ? state.steps[currentIndex + 1] : '';

    // if final step, submit instead
    if (
      state.currentStep === state.steps[state.steps.length - 1] ||
      (state.steps[currentIndex + 1] === 'initialQuestions' && !initialQuestions.length)
    ) {
      if (isCreateTaskLoading || isCreateRecurrenceLoading || isUpdateDraftTaskLoading) return; // prevent double submits

      if (draftTask) {
        handleSubmitUpdateDraftTask({ ...state.taskData, id: draftTask.id });
      } else if (state.isRecurrence) {
        handleSubmitCreateRecurrence(state.taskData);
      } else {
        handleSubmitCreateTask(state.taskData);
      }
      return;
    }

    if (
      nextStep === 'nextSteps' &&
      state.steps.includes('initialQuestions') &&
      state.taskData.description !== descriptionSnapshot
    ) {
      setDescriptionSnapshot(state.taskData.description);
      generateInitialQuestions({
        isRecurrence: state.isRecurrence,
        skipTitleGeneration: shouldSkipTitleGeneration,
        taskData: state.taskData,
        token,
      });
    }

    // Can't increment if already at the last step
    if (currentIndex === state.steps.length - 1) return;
    setStepByIndex(currentIndex + 1);
  };

  const openCreateTaskModal = ({
    data,
    draftTask,
    isRecurrence = false,
    onCreate,
    skipSteps = [],
  }: InitializationOptions) => {
    const key = generateUUID();
    track({
      data: {
        uniqueKey: key,
        ...(clientStatus && {
          currentActiveTasks: clientStatus.currentActiveTasks,
          individualMaxActiveTasks: clientStatus.individualMaxActiveTasks,
          planMaxActiveTasks: clientStatus.planMaxActiveTasks,
        }),
        ...(draftTask && { entityId: draftTask.id, entityType: 'draft' }),
      },
      event: 'taskFlowStarted',
      topic: 'tasks',
    });
    refetchClientStatus();
    const flowSteps = allSteps.filter((step) => {
      // Filter out steps that are for repeating tasks only if it's not a recurrence
      if (stepsForRepeatingTasksOnly.includes(step) && !isRecurrence) return false;

      // Filter out steps that should be skipped
      if (skipSteps.includes(step as SkippableCreateTaskStep)) return false;

      // Filter out the 'quota' step if throttling tasks is not enabled or we are updating a draft task
      if ((!isThrottlingTasksEnabled || draftTask) && step === 'quota') return false;

      return true;
    });

    if (draftTask) {
      setDraftTask(draftTask);
      if (draftTask?.aiQuestionnaire) {
        const { answers, initialQuestions } = processAiQuestionnaire(draftTask.aiQuestionnaire);
        setInitialQuestions(initialQuestions);
        setAnswers(answers);
        setDescriptionSnapshot(draftTask.description);
      }
    }

    const taskData = {
      ...defaultTaskData,
      ...(draftTask
        ? {
            description: draftTask.description,
            name: draftTask.name,
            nextSteps: draftTask.proceedDecision as NextStep,
          }
        : data),
    };

    setOnCreateCallback(() => onCreate);
    setState({
      currentStep: flowSteps[0]!,
      isRecurrence: isRecurrence,
      steps: flowSteps,
      taskData: taskData,
      uniqueKey: key,
    });
    setShouldSkipTitleGeneration(!!data?.name);
    if (flowSteps[0] === 'nextSteps' && flowSteps.includes('initialQuestions') && data) {
      generateInitialQuestions({
        isRecurrence: isRecurrence,
        skipTitleGeneration: !!data?.name,
        taskData: taskData,
        token,
      });
    }
    setIsOpen(true);
  };

  const contextValue = useMemo(
    () => ({
      answers,
      closeCreateTaskModal,
      createTaskState: state,
      decrementStep,
      draftTask,
      incrementStep,
      initialQuestions,
      isOnLastStep: state.currentStep === state.steps[state.steps.length - 1],
      isOpen,
      isStreaming,
      isSubmitting: isCreateRecurrenceLoading || isCreateTaskLoading,
      onUpdateAnswer,
      openCreateTaskModal,
      updateTaskData,
    }),
    [answers, isStreaming, state, isCreateRecurrenceLoading, isCreateTaskLoading, draftTask, isThrottlingTasksEnabled],
  );

  return <CreateTaskContext.Provider value={contextValue}>{children}</CreateTaskContext.Provider>;
};
