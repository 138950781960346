import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type TaskReduxState = {
  hideIndividualTaskQuotaStep: boolean;
  pauseReviewPromptUntil: MaybeUndefined<DateTimeISOString>;
};

const initialState: TaskReduxState = {
  hideIndividualTaskQuotaStep: false,
  pauseReviewPromptUntil: undefined,
};

const taskSlice = createSlice({
  initialState,
  name: 'task',
  reducers: {
    clearTaskReview: (state) => {
      // eslint-disable-next-line fp/no-mutation
      state.pauseReviewPromptUntil = undefined;
    },
    // Adding hiding of individual quota step here for persistence
    setHideIndividualTaskQuotaStep: (state, { payload }: PayloadAction<boolean>) => {
      // eslint-disable-next-line fp/no-mutation
      state.hideIndividualTaskQuotaStep = payload;
    },
    setPauseReviewPromptUntil: (state, { payload }: PayloadAction<DateTimeISOString>) => {
      // eslint-disable-next-line fp/no-mutation
      state.pauseReviewPromptUntil = payload;
    },
  },
});

export const { reducer: taskReducer } = taskSlice;
export const { clearTaskReview, setHideIndividualTaskQuotaStep, setPauseReviewPromptUntil } = taskSlice.actions;
