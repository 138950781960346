import { formatEndpoint } from '@frontend/duck-tape';

const namespace = 'recurrences';

const ACCESS = 'access';

const RECURRENCES_ENDPOINTS = {
  // TODO: (Abdul) Currently share/unshare recurrences API doesn't have a trailing slash, would change it when the API is updated
  accessRecurrence: (id: string, clientId: string) => `${namespace}/${id}/${ACCESS}/${clientId}`,
  listRecurrences: () => formatEndpoint('client/v2', namespace),
  retrieveRecurrence: (id: string) => formatEndpoint(`client/v2/${id}`, namespace),
};

export default RECURRENCES_ENDPOINTS;
