import { createTag } from '@frontend/api-client/utils';
import type { BaseApi } from '@frontend/api-client/utils/types';
import { convertObjectKeysCase } from '@frontend/duck-tape';
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {
  DeleteSecureNoteRequest,
  DeleteSecureNoteResponse,
  ReplaceSecureNoteContentRequest,
  ReplaceSecureNoteContentResponse,
  RetrieveSecureNoteContentResponse,
} from '@frontend/api-types';
import { SECURE_NOTES_ENDPOINTS } from './endpoints';

export const getSecureNotesApi = (baseApi: BaseApi) =>
  baseApi.injectEndpoints({
    endpoints: (builder) => ({
      deleteSecureNote: builder.mutation<DeleteSecureNoteResponse, DeleteSecureNoteRequest>({
        invalidatesTags: (_result, _error, { secureNoteId }) => [
          createTag({ id: secureNoteId, prefix: 'secureNoteId', type: 'SecureNote' }),
        ],
        query: (payload) => ({
          method: 'DELETE',
          url: SECURE_NOTES_ENDPOINTS.deleteSecureNote(payload),
        }),
      }),
      replaceSecureNoteContent: builder.mutation<ReplaceSecureNoteContentResponse, ReplaceSecureNoteContentRequest>({
        invalidatesTags: (_result, _error, { secureNoteId }) => [
          createTag({ id: secureNoteId, prefix: 'secureNoteId', type: 'SecureNote' }),
        ],
        query: ({ formData, secureNoteId }) => ({
          body: formData,
          method: 'PUT',
          url: SECURE_NOTES_ENDPOINTS.replaceSecureNoteContent(secureNoteId),
        }),
        transformResponse: (review: GenericRecord) =>
          convertObjectKeysCase(review, 'camelCase') as ReplaceSecureNoteContentResponse,
      }),
      retrieveSecureNoteContent: builder.query<RetrieveSecureNoteContentResponse, string>({
        providesTags: (_result, _error, secureNoteId) => [
          createTag({ id: secureNoteId, prefix: 'secureNoteId', type: 'SecureNote' }),
        ],
        query: (secureNoteId) => SECURE_NOTES_ENDPOINTS.retrieveSecureNoteContent(secureNoteId),
        transformResponse: (response: GenericRecord) =>
          convertObjectKeysCase(response, 'camelCase') as RetrieveSecureNoteContentResponse,
      }),
    }),
    overrideExisting: true,
  });
