import type { CommonModalProps } from '@frontend/web-react';
import { ConfirmationModal } from '@frontend/web-react';
import { useAnalytics, useDeleteDraftTaskMutation } from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';

export type DeleteDraftTaskModalProps = CommonModalProps & {
  draftTaskId: string;
  onComplete?: () => void;
};

export const DeleteDraftTaskModal = ({ draftTaskId, isOpen, onClose, onComplete }: DeleteDraftTaskModalProps) => {
  const { track } = useAnalytics();
  const [deleteDraftTask, { isLoading: isDeleteDraftTaskLoading }] = useDeleteDraftTaskMutation();

  const onSelectDeleteDraftTask = async () => {
    track({ data: { draftTaskId }, event: 'deleteDraftTaskButtonSelected', topic: 'draftTasks' });
    return handleMutation({
      mutation: async () => {
        return deleteDraftTask(draftTaskId).unwrap();
      },
      onSuccess: () => {
        onClose();
        onComplete?.();
      },
      successMessage: 'Draft task deleted.',
    });
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      isSubmitLoading={isDeleteDraftTaskLoading}
      onClickCancel={onClose}
      onClickSubmit={onSelectDeleteDraftTask}
      onClose={onClose}
      submitLabel="Delete"
      subtitle=""
      title="Are you sure you want to delete this draft task?"
      variant="danger"
    />
  );
};
