import type { BaseApi } from '@frontend/api-client/utils/types';
import type { RetrieveMinAppVersionResponse } from '@frontend/api-types';
import { MIN_APP_VERSION_ENDPOINTS } from './endpoints';

export const getMinAppVersionApi = (baseApi: BaseApi) =>
  baseApi.injectEndpoints({
    endpoints: (builder) => ({
      retrieveMinAppVersion: builder.query<RetrieveMinAppVersionResponse, void>({
        query: () => ({
          method: 'GET',
          url: MIN_APP_VERSION_ENDPOINTS.retrieveMinAppVersion,
        }),
      }),
    }),
  });
