import type { BaseApi } from '@frontend/api-client/utils/types';
import type {
  ChangePasswordRequest,
  ChangePasswordResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
} from '@frontend/api-types';
import { convertObjectKeysCase } from '@frontend/duck-tape';
import { PASSWORD_ENDPOINTS } from './endpoints';

export const getPasswordApi = (baseApi: BaseApi) =>
  baseApi.injectEndpoints({
    endpoints: (builder) => ({
      changePassword: builder.mutation<ChangePasswordResponse, ChangePasswordRequest>({
        query: (payload) => ({
          body: convertObjectKeysCase(payload, 'snakeCase'),
          method: 'PATCH',
          url: PASSWORD_ENDPOINTS.changePassword(),
        }),
      }),
      forgotPassword: builder.mutation<ForgotPasswordResponse, ForgotPasswordRequest>({
        query: (payload) => ({
          body: convertObjectKeysCase(payload, 'snakeCase'),
          method: 'POST',
          url: PASSWORD_ENDPOINTS.forgotPassword(),
        }),
      }),
      resetPassword: builder.mutation<ResetPasswordResponse, ResetPasswordRequest>({
        query: (payload) => ({
          body: convertObjectKeysCase(payload, 'snakeCase'),
          method: 'POST',
          url: PASSWORD_ENDPOINTS.resetPassword(),
        }),
      }),
    }),
    overrideExisting: true,
  });
