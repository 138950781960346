import type { ClassName } from '@frontend/web-utils';
import { concatClasses } from '@frontend/web-utils';
import { YStack } from '../Containers';
import { SkeletonListItem } from './SkeletonListItem';

export type SkeletonListProps = { className?: ClassName; countSkeletons: number };

export const SkeletonList = ({ className, countSkeletons }: SkeletonListProps) => (
  <YStack className={concatClasses('gap-y-sm', className)}>
    {Array.from({ length: countSkeletons }).map((_, index) => (
      <SkeletonListItem key={index} />
    ))}
  </YStack>
);
