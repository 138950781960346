import { keys } from '@frontend/duck-tape';
import type { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';

// Rules of rtk caching:
// 1. Mutations cannot provide tags to the cache, only queries can
// 2. Queries cannot invalidate tags, only mutations can
// 3. When querying a *specific* resource, provide a tag with the resource's id, and a prefix for the type of id
// Example: {..., providesTags: (_result, _error, optionSheetId) => [ createTag({ id: optionSheetId, prefix: 'optionSheetId', type: 'Sheet' }) }
// 4. When querying a *list* of resources, provide a tag with the id 'LIST'
// Example: {..., providesTags: [ createTag({ id: 'LIST', type: 'Sheet' }) ] }

const API_TAG_CONFIG = {
  Account: {
    prefixes: ['accountId'] as const,
  },
  Address: {
    prefixes: ['addressId'] as const,
  },
  CalendarRecurrence: {
    prefixes: ['calendarRecurrenceId'] as const,
  },
  Card: {
    prefixes: ['paymentCardId'] as const,
  },
  Client: {
    prefixes: ['clientId'] as const,
  },
  ClientStatus: {
    prefixes: ['clientId'] as const,
  },
  Comment: {
    prefixes: ['taskId'] as const,
  },
  DraftTask: {
    prefixes: ['draftTaskId'] as const,
  },
  Example: {
    prefixes: ['exampleId'] as const,
  },
  Household: {
    prefixes: ['householdId'] as const,
  },
  Notifications: {
    prefixes: ['notificationId'] as const,
  },
  Payments: {
    prefixes: ['promoId'] as const,
  },
  Recurrence: {
    prefixes: ['recurrenceId'] as const,
  },
  // Can't rename this to just "thread", as it'll effect the cache of all currently deployed apps
  ResearchThread: {
    prefixes: ['researchThreadId'] as const,
  },
  SecureNote: {
    prefixes: ['secureNoteId'] as const,
  },
  Sheet: {
    prefixes: ['optionSheetId'] as const,
  },
  Story: {
    prefixes: ['storyId'] as const,
  },
  Task: {
    prefixes: ['taskId'] as const,
  },
  ThreadEntity: {
    prefixes: ['threadEntityId'] as const,
  },
} as const;

const getTagTypes = () => keys(API_TAG_CONFIG);

export type TagType = keyof typeof API_TAG_CONFIG;

export type ApiTagConfig = typeof API_TAG_CONFIG;

export const getApi = ({ baseQuery }: { baseQuery: ReturnType<typeof fetchBaseQuery> }) =>
  createApi({
    baseQuery,
    endpoints: () => ({}),
    tagTypes: getTagTypes(),
  });
