import { match } from '@frontend/duck-tape';
import type { ReactNode } from '@frontend/react';
import { concatClasses, safeCss, type ClassName } from '@frontend/web-utils';
import { ImageBackground } from '../Image';
import { XStack } from './XStack';

export type SplitImagePageContainerProps = {
  backgroundColor?: 'primary' | 'white';
  children: ReactNode;
  imageBackgroundChildren?: ReactNode;
  imageBackgroundClassName?: ClassName;
  imageSource: string;
};

export const SplitImagePageContainer = ({
  backgroundColor = 'primary',
  children,
  imageBackgroundChildren,
  imageBackgroundClassName = 'justify-center items-center flex flex-1',
  imageSource,
}: SplitImagePageContainerProps) => {
  return (
    <XStack
      className={concatClasses(
        'h-screen',
        match(backgroundColor)
          .with('primary', () => safeCss('bg-surfacePrimary'))
          .with('white', () => safeCss('bg-white'))
          .exhaustive(),
      )}
    >
      {children}
      <ImageBackground className={concatClasses(imageBackgroundClassName, 'hidden md:flex')} source={imageSource}>
        {imageBackgroundChildren}
      </ImageBackground>
    </XStack>
  );
};
