import type { BaseApi } from '@frontend/api-client/utils/types';
import type {
  CreateWaitlistContactRequest,
  CreateWaitlistContactResponse,
  WaitlistStatusResponse,
} from '@frontend/api-types';
import { convertObjectKeysCase } from '@frontend/duck-tape';
import { WAITLISTS_ENDPOINTS } from './endpoints';

export const getWaitlistsApi = (baseApi: BaseApi) =>
  baseApi.injectEndpoints({
    endpoints: (builder) => ({
      createWaitlistContact: builder.mutation<CreateWaitlistContactResponse, CreateWaitlistContactRequest>({
        query: (payload) => ({
          body: convertObjectKeysCase(payload, 'snakeCase'),
          method: 'POST',
          url: WAITLISTS_ENDPOINTS.createWaitlistContact,
        }),
        transformResponse: (task: GenericRecord) =>
          convertObjectKeysCase(task, 'camelCase') as CreateWaitlistContactResponse,
      }),
      retrieveWaitlistStatus: builder.query<WaitlistStatusResponse, void>({
        query: () => WAITLISTS_ENDPOINTS.waitlistStatus,
        transformResponse: (response: GenericRecord) =>
          convertObjectKeysCase(response, 'camelCase') as WaitlistStatusResponse,
      }),
    }),
    overrideExisting: true,
  });
