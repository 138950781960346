import type { SetOptional } from '@frontend/duck-tape';
import type { MakeInputProps } from '@frontend/forms';
import { safeCss } from '@frontend/web-utils';
import type { TextInputProps as MTextInputProps } from '@mantine/core';
import { TextInput as MTextInput } from '@mantine/core';
import { forwardRef } from 'react';
import type { InputLabelProps } from '../../InputLabel';
import { InputLabel } from '../../InputLabel';

export type TextInputProps = MakeInputProps<string, MTextInputProps, 'labelProps'> & {
  labelProps?: SetOptional<InputLabelProps, 'label'>;
};

export const textInputClassNames = {
  input: safeCss('px-md py-lg text-p-md h-[56px] rounded-sm focus-within:border-textPrimary'),
  root: safeCss('gap-y-sm flex flex-col flex-1'),
};

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, labelProps, name, onChange, showOptionalText, ...props }, ref) => {
    return (
      <MTextInput
        classNames={textInputClassNames}
        label={label ? <InputLabel label={label} showOptionalText={showOptionalText} {...labelProps} /> : null}
        name={name}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        ref={ref}
        {...props}
      />
    );
  },
);
