import { getDedicatedCalendarAccounts, getMembershipPlanType, type MembershipPlanType } from '@frontend/api-client';
import type { ClientStatus, PromotionDetails } from '@frontend/api-types';
import { P, match } from '@frontend/duck-tape';
import type { ReactNode } from '@frontend/react';
import { useState } from '@frontend/react';
import { LoadingSpinnerPanel } from '@frontend/web-react';
import { useNavigate } from '@tanstack/react-router';
import { createContext, useContext, useEffect } from 'react';
import {
  useListAddressesQuery,
  useListOAuthAccountsQuery,
  useListStoriesQuery,
  useRetrieveClientQuery,
  useRetrieveClientStatusQuery,
} from '../hooks';

const fullMembershipOnboardingSteps = [
  'selectMembership',
  'addPaymentMethod',
  'stories',
  'provideAddress',
  // Remove intro video step for the time being
  // 'introVideo',
  'faqs',
  'calendar',
  'downloadAppAndComplete',
] as const;

const householdMemberOnboardingSteps = [
  'addPaymentMethod',
  'stories',
  'provideAddress',
  // Remove intro video step for the time being
  // 'introVideo',
  'faqs',
  'calendar',
  'downloadAppAndComplete',
] as const;

// These states are mainly meant for hydration purposes. Usually the respective components will have their own state management,
// using these as initial states and only updating these values when the "submit" button of that page/screen is clicked
const initialStepStates = {
  selectMembership: {
    selectedPlan: undefined as MaybeUndefined<MembershipPlanType>,
  } satisfies SelectMembershipState,
} satisfies OnboardingStepGenericData;

type StepStates = typeof initialStepStates;

export type OnboardingState = StepStates & {
  currentOnboardingStep: OnboardingStep;
  isHouseholdMember: boolean;
  isHydrated: boolean;
  onboardingSteps: typeof fullMembershipOnboardingSteps | typeof householdMemberOnboardingSteps;
};

const initialState: OnboardingState = {
  currentOnboardingStep: 'selectMembership',
  isHouseholdMember: false,
  isHydrated: false,
  onboardingSteps: fullMembershipOnboardingSteps,
  ...initialStepStates,
};

type OnboardingContext = {
  decrementStep: () => void;
  incrementStep: () => void;
  onboardingState: OnboardingState;
  updateSelectMembership: (selectMembership: SelectMembershipState) => void;
};

export const OnboardingContext = createContext<OnboardingContext>({
  decrementStep: () => {},
  incrementStep: () => {},
  onboardingState: initialState,
  updateSelectMembership: (_selectMembership: SelectMembershipState) => {},
});

export const useOnboardingContext = () => useContext(OnboardingContext);

export type OnboardingProviderProps = {
  children: (context: OnboardingContext) => ReactNode;
};

export const OnboardingProvider = ({ children }: OnboardingProviderProps) => {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const { data: stories, isSuccess: isListStoriesSuccess } = useListStoriesQuery();
  const story = stories?.[0];
  const {
    data: clientStatus,
    isLoading: isRetrieveClientStatusLoading,
    isSuccess: isRetrieveClientStatusSuccess,
  } = useRetrieveClientStatusQuery();

  const {
    data: addresses,
    isLoading: isListAddressesLoading,
    isSuccess: isListAddressesSuccess,
  } = useListAddressesQuery();
  const {
    data: client,
    isLoading: isRetrieveClientLoading,
    isSuccess: isRetrieveClientSuccess,
  } = useRetrieveClientQuery();
  const {
    data: oAuthAccounts,
    isLoading: isListOAuthAccountsLoading,
    isSuccess: isListOAuthAccountsSuccess,
  } = useListOAuthAccountsQuery();

  const skipMembershipStep = !!client?.household && !client?.isHouseholdOwner;

  const isAnyLoading =
    isRetrieveClientStatusLoading || isRetrieveClientLoading || isListOAuthAccountsLoading || isListAddressesLoading;

  useEffect(() => {
    if (
      (clientStatus?.onboardingComplete || !state.currentOnboardingStep) &&
      getDedicatedCalendarAccounts(oAuthAccounts).length
    ) {
      navigate({
        search: { tab: 'waiting_on_you' },
        to: '/app',
      });
    }
  }, [clientStatus, addresses]);

  useEffect(() => {
    if (
      !state.isHydrated &&
      clientStatus &&
      isRetrieveClientSuccess &&
      isRetrieveClientStatusSuccess &&
      isListStoriesSuccess
    ) {
      const hydratedStep = match<{ clientStatus: ClientStatus; story: typeof story }, OnboardingStep>({
        clientStatus: clientStatus as NotNil<ClientStatus>,
        story,
      })
        .with({ clientStatus: { onboardingStatus: 'initiated' } }, () => {
          if (skipMembershipStep) {
            return 'addPaymentMethod';
          }
          return 'selectMembership';
        })
        .with({ clientStatus: { onboardingStatus: 'card_added' } }, () => 'stories')
        .with({ clientStatus: { onboardingStatus: 'address_provided' }, story: P.nullish }, () => 'faqs')
        .with({ clientStatus: { onboardingStatus: 'address_provided' }, story: P.nonNullable }, () => 'faqs')
        .with({ clientStatus: { onboardingStatus: 'plan_selected' } }, () => 'addPaymentMethod')
        .exhaustive();

      const selectedPlan = getMembershipPlanType(client);

      setState((prev) => ({
        ...prev,
        currentOnboardingStep: hydratedStep,
        isHouseholdMember: !!client?.household && !client?.isHouseholdOwner,
        isHydrated: true,
        onboardingSteps: getOnboardingSteps(!!client?.household && !client?.isHouseholdOwner),
        selectMembership: {
          selectedPlan,
        },
      }));
    }
  }, [
    clientStatus,
    isRetrieveClientSuccess,
    isRetrieveClientStatusSuccess,
    isListAddressesSuccess,
    isListOAuthAccountsSuccess,
    isListStoriesSuccess,
    client,
  ]);

  const setStepByIndex = (index: number) => {
    setState((prev) => ({ ...prev, currentOnboardingStep: getOnboardingSteps(state.isHouseholdMember)[index]! }));
  };

  // No special data validation for completion, trusting that consumer correctly dispatches increment/decrement
  const decrementStep = () => {
    const currentIndex = getOnboardingSteps(state.isHouseholdMember).indexOf(
      state.currentOnboardingStep as (typeof householdMemberOnboardingSteps)[number],
    );
    // Can't decrement if already at the first step
    if (currentIndex === 0) return;
    setStepByIndex(currentIndex - 1);
  };

  const incrementStep = () => {
    const currentIndex = getOnboardingSteps(state.isHouseholdMember).indexOf(
      state.currentOnboardingStep as (typeof householdMemberOnboardingSteps)[number],
    );
    // Can't increment if already at the last step
    if (currentIndex === getOnboardingSteps(state.isHouseholdMember).length - 1) return;
    setStepByIndex(currentIndex + 1);
  };

  const updateSelectMembership = (selectMembership: SelectMembershipState) => {
    setState((prev) => ({ ...prev, selectMembership: { ...prev.selectMembership, ...selectMembership } }));
  };

  const contextValue = {
    decrementStep,
    incrementStep,
    onboardingState: state,
    updateSelectMembership,
  };

  return (
    <OnboardingContext.Provider value={contextValue}>
      {isAnyLoading ? <LoadingSpinnerPanel className="w-screen h-screen" /> : children(contextValue)}
    </OnboardingContext.Provider>
  );
};

export type OnboardingStep =
  | (typeof fullMembershipOnboardingSteps)[number]
  | (typeof householdMemberOnboardingSteps)[number];

type OnboardingStepGenericData = Pick<Record<OnboardingStep, GenericRecord>, 'selectMembership'>;

export type SelectMembershipState = {
  selectedPlan: MaybeUndefined<MembershipPlanType>;
};

export type OnboardingPromoData = PromotionDetails & { promoCode: string };

const getOnboardingSteps = (isHouseholdMember: boolean) =>
  isHouseholdMember ? householdMemberOnboardingSteps : fullMembershipOnboardingSteps;
