import type { BaseApi } from '@frontend/api-client/utils/types';
import type {
  ChangeEmailRequest,
  ChangeEmailResponse,
  Client,
  ClientStatus,
  CreateClientRequest,
  EditProfileRequest,
  LogInRequest,
  LogInResponse,
  RetrieveClientResponse,
  RetrieveClientStatusResponse,
  RetrieveReferrerDetailsResponse,
} from '@frontend/api-types';
import { convertObjectKeysCase } from '@frontend/duck-tape';
import { createTag } from '../../utils/tags';
import { CLIENT_ENDPOINTS } from './endpoints';

export const getClientApi = (baseApi: BaseApi) => {
  const clientApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
      changeEmail: builder.mutation<ChangeEmailResponse, ChangeEmailRequest>({
        query: (payload) => ({
          body: convertObjectKeysCase(payload, 'snakeCase'),
          method: 'PATCH',
          url: CLIENT_ENDPOINTS.changeEmail(),
        }),
      }),
      createClient: builder.mutation<unknown, CreateClientRequest>({
        query: (payload) => ({
          body: convertObjectKeysCase(payload, 'snakeCase'),
          method: 'POST',
          url: CLIENT_ENDPOINTS.createClient(),
        }),
      }),
      editProfile: builder.mutation<void, EditProfileRequest>({
        invalidatesTags: [createTag('Client')],
        query: (payload) => ({
          body: convertObjectKeysCase(payload, 'snakeCase'),
          method: 'PATCH',
          url: CLIENT_ENDPOINTS.editProfile(),
        }),
      }),
      logIn: builder.mutation<LogInResponse, LogInRequest>({
        onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
          try {
            const { data } = await queryFulfilled;
            dispatch(clientApi.util.updateQueryData('retrieveClient', undefined, () => data.user));
          } catch {
            /* If there is an error, don't update the cache */
          }
        },
        query: (payload) => ({
          body: convertObjectKeysCase(payload, 'snakeCase'),
          method: 'POST',
          url: CLIENT_ENDPOINTS.logIn(),
        }),
      }),
      retrieveClient: builder.query<RetrieveClientResponse, void>({
        providesTags: [createTag('Client')],
        query: CLIENT_ENDPOINTS.retrieveClient,
        transformResponse: ({ user }) => convertObjectKeysCase(user, 'camelCase') as Client,
      }),
      retrieveClientStatus: builder.query<RetrieveClientStatusResponse, void>({
        providesTags: [createTag('Client')],
        query: CLIENT_ENDPOINTS.retrieveClientStatus,
        transformResponse: (response: GenericRecord) => convertObjectKeysCase(response, 'camelCase') as ClientStatus,
      }),
      retrieveReferrerDetails: builder.query<RetrieveReferrerDetailsResponse, string>({
        query: CLIENT_ENDPOINTS.retrieveReferrerDetails,
        transformResponse: (response: GenericRecord) =>
          convertObjectKeysCase(response, 'camelCase') as RetrieveReferrerDetailsResponse,
      }),
    }),
    overrideExisting: true,
  });

  return clientApi;
};
