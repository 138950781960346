import type { MakeInputProps } from '@frontend/forms';
import { safeCss } from '@frontend/web-utils';
import type { NumberInputProps as MNumberInputProps } from '@mantine/core';
import { NumberInput as MNumberInput } from '@mantine/core';
import { forwardRef } from 'react';
import type { InputLabelProps } from '../InputLabel';
import { InputLabel } from '../InputLabel';

// It must be string | number to account for leading "-" signs
export type NumberInputProps = MakeInputProps<
  MaybeUndefined<number | string>,
  MNumberInputProps,
  'labelProps' | 'type'
> & {
  labelProps?: Omit<InputLabelProps, 'children' | 'classNames'>;
};

export const numberInputClassNames = {
  input: safeCss('px-md py-lg text-p-md h-[56px] rounded-sm focus-within:border-textPrimary'),
  root: safeCss('gap-y-sm flex flex-col flex-1'),
};

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  ({ label, onChange, showOptionalText, ...props }, ref) => {
    return (
      <MNumberInput
        classNames={numberInputClassNames}
        inputMode="numeric"
        label={label ? <InputLabel label={label} showOptionalText={showOptionalText} /> : null}
        onChange={(event) => {
          onChange(event);
        }}
        ref={ref}
        {...props}
      />
    );
  },
);
