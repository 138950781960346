import { LoginHero } from '@frontend/assets';
import { useLoginForm, validators } from '@frontend/forms';
import { Fragment } from '@frontend/react';
import {
  AppShell,
  Button,
  EnvironmentDialog,
  Image,
  LinkButton,
  PasswordInput,
  ScrollableContentPanel,
  Text,
  TextInput,
  Title,
  XStack,
  YStack,
  isMobile,
} from '@frontend/web-react';
import { NavLink, NavLinkButton, UnauthenticatedAppHeader } from '@frontend/web/components';
import { useAnalytics, useAuthentication } from '@frontend/web/hooks';
import { Env, createFileRoute } from '@frontend/web/utils';
import { useEffect } from 'react';

const Login = () => {
  const navigate = Route.useNavigate();
  const { redirect } = Route.useSearch();
  const { isAuthenticated, isLoading, login, logout } = useAuthentication();
  const { track } = useAnalytics();
  const { dirty, getControl, handleSubmit, isValid } = useLoginForm({
    onSubmit: (values) => {
      track({ event: 'login', topic: 'auth' });
      login({ ...values, handleRedirect: true, redirect });
    },
  });

  useEffect(() => {
    if (isAuthenticated) {
      navigate({ to: isMobile ? '/app/settings/payments' : redirect || '/app' });
    }
  }, []);

  return (
    <AppShell>
      <UnauthenticatedAppHeader />
      <XStack className="h-screen">
        <ScrollableContentPanel>
          {/* Stops native form html tag from preventing submit for impersonation email inputs */}
          <form className="gap-y-md flex flex-col" noValidate onSubmit={handleSubmit}>
            <Title type="h5">Welcome back</Title>
            {isAuthenticated ? (
              <YStack gapY="md">
                <Title type="h5">{`You're already logged in.`}</Title>
                <XStack>
                  <NavLinkButton label="Go to Dashboard" to="/app" />
                </XStack>
                <XStack alignItems="center" gapX="xs">
                  <Text>Click here to</Text>
                  <LinkButton color="danger" onClick={logout} withUnderline>
                    logout
                  </LinkButton>
                </XStack>
              </YStack>
            ) : (
              <Fragment>
                <TextInput {...getControl('email')} autoComplete="email" label={'Email'} type="email" />
                <PasswordInput {...getControl('password')} />
                <XStack>
                  <NavLink to="/forgot-password">Forgot password</NavLink>
                </XStack>
                <Button className="mt-md" disabled={!isValid || !dirty} fullWidth isForm isLoading={isLoading}>
                  Login
                </Button>
                <XStack gapX="sm">
                  <Text>Not a member?</Text>
                  <NavLink to="/wait-list">Sign up</NavLink>
                </XStack>
              </Fragment>
            )}
          </form>
        </ScrollableContentPanel>
        <YStack className="justify-center flex-1 bg-surfaceBrand hidden md:flex">
          {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
          <Image className="h-[500px] w-[960px]" source={LoginHero} />
        </YStack>
      </XStack>
      <EnvironmentDialog apiUrl={Env.VITE_API_BASE_URL} env={Env.VITE_ENV} />
    </AppShell>
  );
};

export const Route = createFileRoute('/login')({
  component: Login,
  validateSearch: (search) =>
    validators
      .object({
        redirect: validators.encodedURI().optional(),
      })
      .parse(search),
});
