import { middleware, slices } from '@frontend/redux';
import { authReducer, clearAuth, clearPromo, clearTaskReview } from '@frontend/redux/slices';
import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Env } from '../utils';

export const logoutMiddleware: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    if (clearAuth.match(action)) {
      dispatch(baseApi.util.resetApiState());
      dispatch(clearPromo());
      dispatch(clearTaskReview());
      authReducer(undefined, action);
    }

    return next(action);
  };

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'promo', 'task'],
};

export const baseApi = slices.getBaseApiSlice(Env.VITE_API_BASE_URL);

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  auth: slices.authReducer,
  common: slices.commonReducer,
  promo: slices.promoReducer,
  task: slices.taskReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(middleware.loggingMiddleware(Env.VITE_ENV))
      .concat(baseApi.middleware)
      .concat(middleware.errorMiddleware)
      .concat(logoutMiddleware),
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
