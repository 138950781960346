import { createTag } from '@frontend/api-client/utils';
import type { BaseApi } from '@frontend/api-client/utils/types';
import type {
  CreateSetupIntentResponse,
  CreateSubscriptionRequest,
  CreateSubscriptionResponse,
  ListCardsRequest,
  RetrievePromotionResponse,
  SetupIntent,
  SetupIntentConfirmedRequest,
  UpdateCardRequest,
} from '@frontend/api-types';
import { convertArrayObjectKeysCase, convertObjectKeysCase } from '@frontend/duck-tape';
import { PAYMENTS_ENDPOINTS } from './endpoints';

export const getPaymentsApi = (baseApi: BaseApi) =>
  baseApi.injectEndpoints({
    endpoints: (builder) => ({
      createSetupIntent: builder.mutation<CreateSetupIntentResponse, void>({
        query: () => ({
          method: 'POST',
          url: PAYMENTS_ENDPOINTS.createSetupIntent(),
        }),
        transformResponse: (response: GenericRecord) => convertObjectKeysCase(response, 'camelCase') as SetupIntent,
      }),
      createSubscription: builder.mutation<CreateSubscriptionResponse, CreateSubscriptionRequest>({
        invalidatesTags: [createTag('Card'), createTag('Client'), createTag('Story')],
        query: (payload) => ({
          body: convertObjectKeysCase(payload, 'snakeCase'),
          method: 'POST',
          url: PAYMENTS_ENDPOINTS.createSubscription,
        }),
      }),
      deleteCard: builder.mutation<void, string>({
        invalidatesTags: [createTag('Card')],
        query: (id) => ({
          method: 'DELETE',
          url: PAYMENTS_ENDPOINTS.deleteCard(id),
        }),
      }),
      listCards: builder.query<ListCardsRequest, void>({
        providesTags: [createTag({ id: 'LIST', type: 'Card' })],
        query: PAYMENTS_ENDPOINTS.listCards,
        transformResponse: (response: GenericRecord[]) =>
          convertArrayObjectKeysCase(response, 'camelCase') as ListCardsRequest,
      }),
      retrievePromotionDetails: builder.query<RetrievePromotionResponse, string>({
        providesTags: (_result, _error, promoId) => [createTag({ id: promoId, prefix: 'promoId', type: 'Payments' })],
        query: (promoId) => PAYMENTS_ENDPOINTS.retrievePromotionDetails(promoId),
        transformResponse: (response: GenericRecord) =>
          convertObjectKeysCase(response, 'camelCase') as RetrievePromotionResponse,
      }),
      setupIntentConfirmed: builder.mutation<void, SetupIntentConfirmedRequest>({
        invalidatesTags: [createTag('Card'), createTag('Client'), createTag('Story')],
        query: (payload) => ({
          body: convertObjectKeysCase(payload, 'snakeCase'),
          method: 'POST',
          url: PAYMENTS_ENDPOINTS.setupIntentConfirmed(),
        }),
      }),
      updateCard: builder.mutation<void, UpdateCardRequest>({
        invalidatesTags: [createTag('Card'), createTag({ id: 'LIST', type: 'Card' }), createTag('Client')],
        query: ({ id, setupIntentId }) => ({
          body: { setup_intent_id: setupIntentId },
          method: 'POST',
          url: PAYMENTS_ENDPOINTS.updateCard(id),
        }),
      }),
      updateDefaultCard: builder.mutation<void, string>({
        invalidatesTags: [createTag('Card'), createTag({ id: 'LIST', type: 'Card' }), createTag('Client')],
        query: (id) => ({
          method: 'POST',
          url: PAYMENTS_ENDPOINTS.updateDefaultCard(id),
        }),
      }),
    }),
    overrideExisting: true,
  });
