import { match } from '@frontend/duck-tape';
import type { ClassName } from '@frontend/web-utils';
import { applyClass, concatClasses, safeCss } from '@frontend/web-utils';
import type { ReactNode } from 'react';
import { YStack } from './YStack';

export type LinearGradientWrapperProps = {
  children?: ReactNode;
  className?: ClassName;
  disabled?: boolean;
  variant?: 'blue-to-yellow' | 'yellow-to-blue';
};

const getGradientClassName = (variant: NotNil<LinearGradientWrapperProps['variant']>) =>
  match(variant)
    .with('blue-to-yellow', () => safeCss('bg-gradient-to-r from-surfaceAccent to-surfaceAccentPrimary'))
    .with('yellow-to-blue', () => safeCss('bg-gradient-to-r from-surfaceAccentPrimary to-surfaceAccent'))
    .exhaustive();

export const LinearGradientWrapper = ({
  children,
  className,
  disabled = false,
  variant = 'blue-to-yellow',
}: LinearGradientWrapperProps) => {
  return (
    <YStack className={concatClasses(className, applyClass(disabled, 'bg-transparent', getGradientClassName(variant)))}>
      {children}
    </YStack>
  );
};
