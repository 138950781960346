import { identity, match } from '@frontend/duck-tape';
import {
  LEARNED_ABOUT_DUCKBILL_SOURCES,
  useWaitlistForm,
  validators,
  waitlistFormDefaultValues,
} from '@frontend/forms';
import { useEffect, useState } from '@frontend/react';
import {
  Button,
  Icon,
  LoadingSpinnerPanel,
  PhoneNumberInput,
  SelectInput,
  Text,
  TextInput,
  Title,
  XStack,
  YStack,
  isMobile,
  useHotkeys,
} from '@frontend/web-react';
import { applyClass, concatClasses } from '@frontend/web-utils';
import { useCreateWaitlistContactMutation, useRetrieveWaitlistStatusQuery } from '@frontend/web/hooks';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { UnauthenticatedAppHeader } from '../components';
import { handleMutation } from '../utils';

const waitlistSteps = ['nameAndEmail', 'zipAndPhone', 'finish'] as const;

type WaitlistStep = (typeof waitlistSteps)[number];

const Waitlist = () => {
  const navigate = Route.useNavigate();
  const searchParams = Route.useSearch();
  // Named this referrer instead of referredBy for backwards compatibility purposes
  const { promo, referrer, utm_campaign, utm_content, utm_medium, utm_source, utm_term } = searchParams;
  const [step, setStep] = useState<WaitlistStep>('nameAndEmail');
  const currentStepIndex = waitlistSteps.indexOf(step);

  const [createWaitlistContact, { isLoading }] = useCreateWaitlistContactMutation();
  const { data: waitlistStatus, isLoading: isWaitListStatusLoading } = useRetrieveWaitlistStatusQuery();

  useEffect(() => {
    if (waitlistStatus && !waitlistStatus?.isWaitlistOn) {
      navigate({ search: searchParams, to: '/sign-up' });
    }
  }, [waitlistStatus]);

  const { getControl, getFieldMeta, getFieldProps, isValid, submitForm, values } = useWaitlistForm({
    initialValues: {
      ...waitlistFormDefaultValues,
      promoCode: promo,
    },
    onSubmit: (values) =>
      handleMutation({
        mutation: () =>
          createWaitlistContact({
            ...values,
            referredBy: referrer,
            utmParams: {
              utm_campaign,
              utm_content,
              utm_medium,
              utm_source,
              utm_term,
            },
          }).unwrap(),
        onSuccess: incrementStep,
        showSuccessToast: false,
      }),
  });

  const validateNameAndEmail = async () => {
    const isFirstNameValid = !getFieldMeta('firstName').error;
    const isLastNameValid = !getFieldMeta('lastName').error;
    const isEmailValid = !getFieldMeta('email').error;
    getFieldProps('firstName').onBlur({ target: { name: 'firstName' } });
    getFieldProps('lastName').onBlur({ target: { name: 'lastName' } });
    getFieldProps('email').onBlur({ target: { name: 'email' } });

    if (isFirstNameValid && isLastNameValid && isEmailValid) {
      incrementStep();
    }
  };

  const incrementStep = () => {
    const currentStepIndex = waitlistSteps.indexOf(step);
    // Can't increment from the last step
    if (currentStepIndex === -1 || currentStepIndex === waitlistSteps.length - 1) {
      return;
    }
    const nextStep = waitlistSteps[currentStepIndex + 1]!;
    setStep(nextStep);
  };
  const decrementStep = () => {
    const currentStepIndex = waitlistSteps.indexOf(step);
    // Can't decrement from the first step
    if (currentStepIndex === -1 || currentStepIndex === 0) {
      return;
    }
    const previousStep = waitlistSteps[currentStepIndex - 1]!;
    setStep(previousStep);
  };

  const onTypeEnter = match(step)
    .with('nameAndEmail', () => validateNameAndEmail)
    .with('zipAndPhone', () => submitForm)
    .with('finish', () => identity)
    .exhaustive();

  useHotkeys(
    [
      ['Enter', onTypeEnter],
      ['mod+Enter', onTypeEnter],
    ],
    [],
  );

  if (isWaitListStatusLoading) {
    return <LoadingSpinnerPanel className="h-screen w-screen" />;
  }

  return (
    <YStack className="min-h-screen bg-surfaceBrand">
      <UnauthenticatedAppHeader asAppShell={false} color="yellow" />
      <YStack className="flex-1 grid grid-cols-1 md:grid-cols-5 xl:grid-cols-6">
        <YStack className="col-span-1 md:col-start-2 md:col-span-3 xl:col-span-2 xl:col-start-3 justify-center pb-lg">
          {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
          <YStack className="px-md" gapY="md">
            <Title
              className={concatClasses(
                // 'text-[24px] leading-[40px] md:text-[24px]',
                applyClass(step === 'finish', 'hidden', ''),
              )}
              color="inverse"
              type="h1"
              uppercase
            >
              Sign up here to be invited to join Duckbill
            </Title>
            <Text color="inverse" type="p-lg">
              Duckbill is an executive assistant for your personal life, led by expert humans and enhanced with AI
              superpowers.
            </Text>
            <XStack alignItems="center" justifyContent="spaceBetween">
              {step === 'zipAndPhone' ? (
                <Icon color="inverse" name="IconArrowLeft" onClick={decrementStep} rounded />
              ) : (
                <YStack />
              )}
              {step !== 'finish' ? (
                <Text color="inverse" type="caption">
                  {currentStepIndex + 1}/{waitlistSteps.length - 1}
                </Text>
              ) : null}
            </XStack>
            {match(step)
              .with('nameAndEmail', () => (
                <YStack className="gap-y-md z-10">
                  <TextInput
                    {...getControl('firstName')}
                    autoComplete="given-name"
                    label="First name"
                    labelProps={{ inverse: true }}
                  />
                  <TextInput
                    {...getControl('lastName')}
                    autoComplete="family-name"
                    label="Last name"
                    labelProps={{ inverse: true }}
                  />
                  <TextInput
                    {...getControl('email')}
                    autoComplete="email"
                    label="Email"
                    labelProps={{ inverse: true }}
                  />
                  {isMobile ? (
                    <Button className="mt-lg" label="Next" onClick={onTypeEnter} />
                  ) : (
                    <XStack className="self-end" gapX="xs">
                      <Icon color="inverse" name="IconCornerDownLeft" />
                      <Text color="inverse" type="h6">
                        Hit enter
                      </Text>
                    </XStack>
                  )}
                </YStack>
              ))
              .with('zipAndPhone', () => (
                <YStack className="z-10">
                  <YStack className="gap-y-md">
                    <TextInput
                      autoFocus
                      {...getControl('promoCode')}
                      label="Promo code"
                      labelProps={{ inverse: true }}
                    />
                    <SelectInput
                      {...getControl('hdyhau')}
                      label="How did you learn about Duckbill?"
                      labelProps={{ inverse: true }}
                      // @ts-expect-error Need to figure out how to resolve this type error across all SelectInputs
                      options={LEARNED_ABOUT_DUCKBILL_SOURCES}
                    />
                    {['friendReferral', 'podcast', 'other'].includes(values.hdyhau ?? '') ? (
                      <TextInput
                        {...getControl('hdyhauDetails')}
                        label={match(values.hdyhau)
                          .with('friendReferral', () => "Referrer's name")
                          .with('podcast', () => 'Podcast name')
                          .otherwise(() => 'Tell us more')}
                        labelProps={{ inverse: true }}
                        showOptionalText
                      />
                    ) : null}
                    <YStack gapY="sm">
                      <PhoneNumberInput {...getControl('phoneNumber')} labelProps={{ inverse: true }} />
                      <Text color="inverse">{"We will send you a text message when we're ready for you to join."}</Text>
                    </YStack>
                  </YStack>
                  <Button
                    className="mt-lg"
                    disabled={!isValid}
                    isLoading={isLoading}
                    label="Join waitlist"
                    onClick={submitForm}
                  />
                </YStack>
              ))
              .with('finish', () => (
                <YStack gapY="lg">
                  <Title color="inverse" textAlign="center" type="h4">
                    {"Thank you! You're on the list. Your invite will come via SMS, so stay tuned."}
                  </Title>
                  <Text color="inverse" textAlign="center" type="p-sm">
                    Take our quick survey to tell us a bit about yourself, and we just might move you to the front of
                    the line.
                  </Text>
                  <Button
                    label="Take survey"
                    onClick={() => window.open(`https://form.typeform.com/to/pLVBKpMF#email=${values.email}`)}
                  />
                </YStack>
              ))
              .exhaustive()}
          </YStack>
        </YStack>
      </YStack>
    </YStack>
  );
};

export const Route = createFileRoute('/wait-list')({
  beforeLoad: async ({ context }) => {
    if (context.authentication?.isAuthenticated) {
      throw redirect({
        search: { tab: 'waiting_on_you' },
        to: '/app',
      });
    }
  },
  component: Waitlist,
  validateSearch: validators.object({
    promo: validators.optionalString(),
    referrer: validators.uuid().optional(),
    utm_campaign: validators.optionalString(),
    utm_content: validators.optionalString(),
    utm_medium: validators.optionalString(),
    utm_source: validators.optionalString(),
    utm_term: validators.optionalString(),
  }),
});
