import { Text } from '@frontend/web-react';
import { useNavigate } from '@tanstack/react-router';

export type TaskChatResearchThreadWhisperProps = {
  searchThread: {
    id: string;
    title: string;
  };
};

export const TaskChatResearchThreadWhisper = ({ searchThread }: TaskChatResearchThreadWhisperProps) => {
  const navigate = useNavigate();

  return (
    <Text color="tertiary" textAlign="center" type="caption">
      Created from{' '}
      <Text
        className="underline"
        color="tertiary"
        onClick={() =>
          navigate({
            params: { threadId: searchThread.id },
            to: '/app/thread/$threadId',
          })
        }
        span
        type="captionBold"
      >
        {searchThread.title}
      </Text>
    </Text>
  );
};
