import type { CommonSVGProps } from './helpers';

export const DuckbillLogo = (props: CommonSVGProps) => (
  <svg fill="none" viewBox="0 0 12 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)" fill="#032B49">
      <path d="M10.043 10.542a.428.428 0 0 0-.321-.113H7.713V2.14A1.702 1.702 0 0 0 6.011.438H2.545a1.702 1.702 0 1 0 0 3.403h1.78v6.588H2.317a.435.435 0 0 0-.323.113.424.424 0 0 0-.116.323v.169a.43.43 0 0 0 .113.32.432.432 0 0 0 .322.114h7.408a.424.424 0 0 0 .32-.113.413.413 0 0 0 .114-.321v-.169a.425.425 0 0 0-.113-.323ZM2.545 3.321a1.182 1.182 0 1 1 0-2.364 1.182 1.182 0 0 1 0 2.364ZM11.194 12.942v.168a.413.413 0 0 1-.113.322.423.423 0 0 1-.32.112H1.278a.43.43 0 0 1-.322-.112.43.43 0 0 1-.113-.322v-.168a.424.424 0 0 1 .113-.323.436.436 0 0 1 .322-.113h9.481a.393.393 0 0 1 .434.436Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M.844.438h10.35v13.105H.844z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
