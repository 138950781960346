import { changeCase } from '../case/case';
import { isString } from '../hidash/hidash';
import { P, match } from '../match';
import type { Asyncify } from '../types';

type APICustomError = { data: { code: number; detail: string }; status: number };

type APIValidationError = {
  data: {
    code: number;
    detail: string;
    errors: [{ field: string; message: string }];
  };
  status: number;
};

export const isAPICustomError = (error: unknown): error is APICustomError =>
  match(error)
    .with(
      {
        data: { code: P.number, detail: P.string },
        status: P.number,
      },
      () => true,
    )
    .otherwise(() => false);

export const isAPIValidationError = (error: unknown): error is APIValidationError =>
  match(error)
    .with(
      {
        data: {
          code: P.number,
          detail: P.string,
          errors: P.array({ field: P.string, message: P.string }),
        },
        status: P.number,
      },
      () => true,
    )
    .otherwise(() => false);

export const extractApiError = (error: unknown): MaybeUndefined<string> => {
  if (isAPIValidationError(error)) {
    return error.data.errors.reduce(
      (acc, { field, message }) => acc.concat(`${changeCase(field, 'sentenceCase')}: ${message}\n`),
      '',
    );
  } else if (isAPICustomError(error)) {
    return error.data.detail;
  } else if (isString(error)) {
    return String(error);
  }
  return undefined;
};

export const FALLBACK_ERROR_MESSAGE = 'Something went wrong. Try again later.';
export const FALLBACK_SUCCESS_MESSAGE = 'Success!';

type HandleMutationOptions<Result> = {
  errorMessage?: string;
  fallbackErrorMessage?: string;
  mutation: Asyncify<() => Result>;
  onComplete?: () => void;
  onError?: (error: unknown) => void;
  onSuccess?: (result: Result) => void;
  showErrorToast?: boolean;
  showSuccessToast?: boolean;
  successMessage?: string;
};

// At a bare minimum all clients should have toast function that takes a superset of these props
type ToastConfig = {
  title: string;
  variant: 'danger' | 'success';
};

type MutationHandlerConfig = {
  showToast: (config: ToastConfig) => string | void;
};

export const getMutationHandler =
  ({ showToast }: MutationHandlerConfig) =>
  async <Result>({
    errorMessage,
    fallbackErrorMessage = FALLBACK_ERROR_MESSAGE,
    mutation,
    onComplete,
    onError,
    onSuccess,
    showErrorToast = true,
    showSuccessToast = true,
    successMessage,
  }: HandleMutationOptions<Result>) => {
    try {
      const result = await mutation();
      // Sometimes for some reason, no error will be thrown even though api is returning an error
      // @ts-expect-error Ignorable
      if (result && 'error' in result) {
        const extractedErrorMessage = extractApiError(result.error);
        await onError?.(result.error);
        if (showErrorToast) {
          showToast({
            title: errorMessage ?? extractedErrorMessage ?? fallbackErrorMessage,
            variant: 'danger',
          });
        }
        await onComplete?.();
        return;
      }
      await onSuccess?.(result);
      if (showSuccessToast) {
        showToast({
          title: successMessage ?? FALLBACK_SUCCESS_MESSAGE,
          variant: 'success',
        });
      }
      await onComplete?.();
      return result;
    } catch (error) {
      await onError?.(error);
      const extractedErrorMessage = extractApiError(error);
      if (showErrorToast) {
        showToast({
          title: errorMessage ?? extractedErrorMessage ?? fallbackErrorMessage,
          variant: 'danger',
        });
      }
      await onComplete?.();
      return;
    }
  };
