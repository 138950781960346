import type { TaskReduxState } from '@frontend/redux/slices/taskSlice';
import * as TaskSlice from '@frontend/redux/slices/taskSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from './useSelector';

export const useTaskSlice = <TRootState extends { task: TaskReduxState }>() => {
  const dispatch = useDispatch();

  const { hideIndividualTaskQuotaStep, pauseReviewPromptUntil } = useSelector<TRootState>()(({ task }) => task);

  const setPauseReviewPromptUntil = (datetime: Parameters<(typeof TaskSlice)['setPauseReviewPromptUntil']>[0]) =>
    dispatch(TaskSlice.setPauseReviewPromptUntil(datetime));

  const clearTaskReview = () => dispatch(TaskSlice.clearTaskReview());

  const setHideIndividualTaskQuotaStep = (hide: boolean) => dispatch(TaskSlice.setHideIndividualTaskQuotaStep(hide));

  return {
    clearTaskReview,
    hideIndividualTaskQuotaStep,
    pauseReviewPromptUntil,
    setHideIndividualTaskQuotaStep,
    setPauseReviewPromptUntil,
  };
};
