import { applyClass, concatClasses } from '@frontend/web-utils';
import { Link as TLink } from '@tanstack/react-router';
import type { LinkComponentProps } from '@tanstack/react-router/dist/esm/link';

export const NavLink = ({ bold = true, ...props }: LinkComponentProps<'a'> & { bold?: boolean }) => {
  return (
    <TLink
      className={concatClasses(
        'text-h5 text-textPrimary hover-opacity-effect shrink-0 grow-0 underline underline-offset-4',
        applyClass(!bold, 'font-normal'),
      )}
      {...props}
    />
  );
};
