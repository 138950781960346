import { validators } from '../utils';
import { makeFormComponent, makeFormHook } from '../utils/factory';
import type { InferFormType } from '../utils/types';

const onboardingUserInfoFormSchema = validators.object({
  birthday: validators.dateISOString(),
  email: validators.email(),
  firstName: validators.requiredString('First name'),
  lastName: validators.requiredString('Last name'),
  nickname: validators.optionalString(),
  phoneNumber: validators.e164PhoneNumber(),
});

export type OnboardingUserInfoFormSchema = InferFormType<typeof onboardingUserInfoFormSchema>;

export const onboardingUserFormDefaultValues: OnboardingUserInfoFormSchema = {
  // @ts-expect-error Tedious types
  birthday: '',
  email: '',
  firstName: '',
  lastName: '',
  nickname: '',
  phoneNumber: '',
};

export const useOnboardingUserInfoForm = makeFormHook(onboardingUserInfoFormSchema, onboardingUserFormDefaultValues);

export const OnboardingUserInfoForm = makeFormComponent(onboardingUserInfoFormSchema, onboardingUserFormDefaultValues);
