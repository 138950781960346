import type { SecureNoteContent } from '@frontend/api-types';
import { isEqual, omit, removeNullishAndEmptyStringValues } from '@frontend/duck-tape';
import { makeSavedFileList, validators } from '../utils';
import { makeFormComponent, makeFormHook } from '../utils/factory';
import type { InferFormType } from '../utils/types';

export const secureNoteFormSchema = validators.object({
  attachments: validators.fileArrayValidator('localOrSavedFile'),
  notes: validators.optionalString(),
  password: validators.optionalString(),
  username: validators.optionalString(),
  website: validators.looseUrlValidator().optional(),
});

export type SecureNoteFormSchema = InferFormType<typeof secureNoteFormSchema>;

export const getSecureNoteFormDefaultValues = (
  secureNote?: MaybeUndefined<SecureNoteContent>,
): SecureNoteFormSchema => ({
  attachments: makeSavedFileList(secureNote?.attachments ?? [], (a) => a),
  notes: secureNote?.notes ?? '',
  password: secureNote?.password ?? '',
  username: secureNote?.username ?? '',
  website: secureNote?.website ?? undefined,
});

export const useSecureNoteForm = makeFormHook(secureNoteFormSchema, getSecureNoteFormDefaultValues());

export const SecureNoteForm = makeFormComponent(secureNoteFormSchema, getSecureNoteFormDefaultValues());

export const checkDidSecureNoteFormValuesChange = (
  originalValues: SecureNoteContent,
  newValues: SecureNoteFormSchema,
): boolean => {
  const newAttachmentKeys = newValues.attachments.map((a) => (a.__typename === 'SavedFile' ? a.key : a.name));
  const originalAttachmentKeys = originalValues?.attachments?.map((a) => a.key) ?? [];
  if (newValues.attachments.find((a) => a.__typename === 'LocalFile')) return true;
  return (
    !isEqual(newAttachmentKeys, originalAttachmentKeys) ||
    !isEqual(removeNullishAndEmptyStringValues(omit(newValues, ['attachments'])), omit(originalValues, ['attachments']))
  );
};
