import { Fragment, useVisibilityController } from '@frontend/react';
import { Card, Icon, Text, XStack } from '@frontend/web-react';
import type { ClassName } from '@frontend/web-utils';
import { concatClasses } from '@frontend/web-utils';
import { SecureNoteModal } from '@frontend/web/components/DomainSpecific/Tasks/SecureNoteModal';

export type TaskChatSecureNoteCardProps = {
  className?: ClassName;
  isActive: boolean;
  secureNoteId: string;
  taskId: string;
  title: string;
};
export const TaskChatSecureNoteCard = ({
  className,
  isActive,
  secureNoteId,
  taskId,
  title,
}: TaskChatSecureNoteCardProps) => {
  const [isSecureNoteCardModalOpen, { close: closeSecureNoteModal, open: openSecureNoteModal }] =
    useVisibilityController();
  return (
    <Fragment>
      <Card
        className={concatClasses('px-lg py-md gap-x-md rounded-lg bg-surfaceSecondary', className)}
        disabled={!isActive}
        onClick={openSecureNoteModal}
      >
        {/* Title */}
        <XStack alignItems="center" justifyContent="spaceBetween">
          <XStack gapX="sm">
            <Icon color={isActive ? 'primary' : 'tertiary'} name="IconLock" />
            <Text type={isActive ? 'h5' : 'p-sm'}>
              {isActive ? '' : 'Deleted'} {title}
            </Text>
          </XStack>
          {isActive ? <Icon color="primary" name="IconArrowRight" /> : null}
        </XStack>
        {/* Disclaimer */}
        {isActive ? (
          <Text color="tertiary">
            We keep your sensitive info safe and sound, using top-notch encryption and strict access rules – only our
            trained copilots get a peek.
          </Text>
        ) : null}
      </Card>
      {isActive ? (
        <SecureNoteModal
          isOpen={isSecureNoteCardModalOpen}
          onClose={closeSecureNoteModal}
          secureNoteId={secureNoteId}
          taskId={taskId}
          title={title}
        />
      ) : null}
    </Fragment>
  );
};
