import { validators, z } from '../utils';
import { makeFormComponent, makeFormHook } from '../utils/factory';
import type { InferFormType } from '../utils/types';

const resetPasswordFormSchema = z
  .object({
    confirmPassword: validators.password(),
    email: validators.email(),
    password: validators.password(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword'],
  });

export type ResetPasswordFormSchema = InferFormType<typeof resetPasswordFormSchema>;

export const resetPasswordFormDefaultValues: ResetPasswordFormSchema = {
  confirmPassword: '',
  email: '',
  password: '',
};

export const useResetPasswordForm = makeFormHook(resetPasswordFormSchema, resetPasswordFormDefaultValues);

export const ResetPasswordForm = makeFormComponent(resetPasswordFormSchema, resetPasswordFormDefaultValues);
