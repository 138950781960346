import type { ParsedLocation } from '@tanstack/react-router';
import { redirect } from '@tanstack/react-router';
import type { RouterContext } from '../routes/__root';

export { createFileRoute, createRootRouteWithContext, createRouter, redirect, useRouter } from '@tanstack/react-router';

export const redirectUnauthenticated = ({
  context,
  location,
}: {
  context: RouterContext;
  location: ParsedLocation;
}) => {
  if (!context.authentication?.isAuthenticated) {
    throw redirect({
      search: { redirect: location.href },
      to: '/login',
    });
  }
};
