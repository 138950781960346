import { recurrenceIntervalString } from '@frontend/api-client';
import type { Recurrence } from '@frontend/api-types';
import { identity } from '@frontend/duck-tape';
import { DropdownMenu, Icon, Indicator, Text, XStack, YStack } from '@frontend/web-react';
import type { ClassName } from '@frontend/web-utils';
import { concatClasses } from '@frontend/web-utils';
import { NavLinkWrapper, RecurrenceDueDateTag } from '@frontend/web/components/';
import { useAnalytics, useRetrieveClientQuery } from '@frontend/web/hooks';

export type RecurrenceCardProps = {
  className?: ClassName;
  onClickDelete: (recurrenceId: string) => void;
  recurrence: Recurrence;
};
export const RecurrenceCard = ({ className, onClickDelete, recurrence }: RecurrenceCardProps) => {
  const { data: client } = useRetrieveClientQuery();
  const { track } = useAnalytics();
  const onClickDeleteRecurrence = () => {
    onClickDelete(recurrence.id);
    track({ data: { recurrenceId: recurrence.id }, event: 'deleteRecurrenceButtonSelected', topic: 'recurrences' });
  };

  return (
    <NavLinkWrapper
      className={concatClasses('flex flex-row gap-x-sm items-center px-sm py-md hover-background-effect', className)}
      params={{ recurrenceId: recurrence.id }}
      to={'/app/recurrence/$recurrenceId'}
    >
      <Indicator isVisible={false} />
      <YStack className="flex-1">
        <YStack className="flex-1" gapY="sm">
          <XStack alignItems="center" justifyContent="spaceBetween">
            <XStack alignItems="center" gapX="sm">
              <Icon color="secondary" name="IconRepeat" />
              <Text type="h6">{recurrence.name}</Text>
            </XStack>
            <DropdownMenu
              disabled={client?.id !== recurrence.ownerId}
              layout={[
                {
                  color: 'danger',
                  icon: 'IconTrash',
                  label: 'Delete this repeating task',
                  onClick: onClickDeleteRecurrence,
                  type: 'item',
                },
              ]}
            >
              <Icon
                color={client?.id !== recurrence.ownerId ? 'tertiary' : 'primary'}
                name="IconDots"
                onClick={identity}
              />
            </DropdownMenu>
          </XStack>
          <XStack justifyContent="spaceBetween">
            <Text color="tertiary" type="caption">
              {recurrenceIntervalString(recurrence)}
            </Text>
            {recurrence.nextTaskDueDate ? <RecurrenceDueDateTag nextTaskDueDate={recurrence.nextTaskDueDate} /> : null}
          </XStack>
          <Text type="p-sm">{recurrence.description}</Text>
        </YStack>
      </YStack>
    </NavLinkWrapper>
  );
};
