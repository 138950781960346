import { type SetOptional } from '@frontend/duck-tape';
import type { MakeInputProps, SelectOption } from '@frontend/forms';
import type { ClassName } from '@frontend/web-utils';
import { concatClasses, safeCss } from '@frontend/web-utils';
import type { SelectProps as MSelectProps } from '@mantine/core';
import { Select as MSelect, useCombobox } from '@mantine/core';
import { XStack } from '../../../Containers';
import { Icon } from '../../../Icon/Icon';
import { Emoji } from '../../../Texts';
import type { EmojiShortcode } from '../../../Texts/Emoji/emojiData';
import { Text } from '../../../Texts/Text';
import type { InputLabelProps } from '../../InputLabel';
import { InputLabel } from '../../InputLabel';

type SelectInputProps<
  TValue extends string,
  TOption extends SelectOption<TValue, never, { emojiName?: EmojiShortcode; label: string }>,
> = MakeInputProps<
  TOption['value'],
  Pick<MSelectProps, 'autoComplete' | 'autoFocus' | 'onSearchChange' | 'searchable' | 'searchValue'> & {
    className?: ClassName;
    isDeselectable?: boolean;
    labelProps?: SetOptional<InputLabelProps, 'label'>;
    name: string;
    options: TOption[];
    placeholder?: string;
  }
>;

export const SelectInput = <
  TValue extends string,
  TOption extends SelectOption<TValue, never, { emojiName?: EmojiShortcode; label: string }>,
>({
  className,
  error,
  isDeselectable = false,
  label,
  labelProps,
  name,
  onBlur,
  onChange,
  options,
  placeholder = 'Select an option',
  showOptionalText,
  value,
  ...props
}: SelectInputProps<TValue, TOption>) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const classNames: MSelectProps['classNames'] = {
    input: concatClasses(safeCss('p-md text-p-md h-[56px] rounded-sm focus-within:border-textPrimary'), className),
    option: safeCss('p-none data-[combobox-selected]:bg-neutral-100'),
    root: concatClasses('flex flex-col gap-y-sm', className),
  };

  return (
    <MSelect
      classNames={classNames}
      data={options}
      error={error}
      label={label ? <InputLabel label={label} showOptionalText={showOptionalText} {...labelProps} /> : null}
      maxDropdownHeight={300}
      name={name}
      onBlur={onBlur}
      onChange={(value) => {
        if (isDeselectable || value) {
          onChange(value as SelectOption<TValue>['value']);
        }
      }}
      placeholder={placeholder}
      renderOption={(item) => {
        const userProvidedOption = options.find((option) => option.value === item.option.value);
        return (
          <XStack className="p-md  items-center gap-x-xs" key={item.option.value}>
            {userProvidedOption?.emojiName ? <Emoji emoji={userProvidedOption?.emojiName} /> : null}
            <Text>{item.option.label}</Text>
          </XStack>
        );
      }}
      rightSection={<Icon name="IconChevronDown" />}
      value={value}
      {...props}
    />
  );
};
