import { useEffect, useState } from 'react';

export const useImagePreloader = (imageUrls: string[]) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<unknown>(null);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    const preloadImages = async () => {
      const promises = imageUrls.map(
        (url) =>
          new Promise((resolve, reject) => {
            const img = new Image();
            // eslint-disable-next-line fp/no-mutation
            img.src = url;
            // eslint-disable-next-line fp/no-mutation
            img.onload = () => resolve(url);
            // eslint-disable-next-line fp/no-mutation
            img.onerror = () => reject(new Error(`Failed to preload image ${url}`));
          }),
      );

      try {
        await Promise.all(promises);
        if (isMounted) setIsLoaded(true);
      } catch (e) {
        if (isMounted) setError(e);
      }
    };

    preloadImages();

    return () => {
      setIsMounted(false);
    };
  }, [imageUrls, isMounted]);

  return { error, isLoaded };
};
