import { Mailboxes } from '@frontend/assets';
import { useAddressForm } from '@frontend/forms';
import {
  AddressInputGroup,
  Button,
  ScrollableContentPanel,
  SplitImagePageContainer,
  Text,
  YStack,
} from '@frontend/web-react';
import { useOnboardingContext } from '@frontend/web/context/OnboardingContext';
import { useCreateAddressMutation } from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';
import { OnboardingStepHeader } from './OnboardingStepHeader';

export const ProvideAddress = () => {
  const { incrementStep } = useOnboardingContext();
  const autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
  const addressForm = useAddressForm({
    onSubmit: async (values) =>
      handleMutation({
        mutation: () => {
          return createAddress({
            ...values,
            alias: values.alias || 'Home',
          }).unwrap();
        },
        onSuccess: incrementStep,
        showSuccessToast: false,
      }),
  });

  const [createAddress, { isLoading: isCreateAddressLoading }] = useCreateAddressMutation();

  const { isValid, submitForm } = addressForm;

  return (
    <SplitImagePageContainer imageSource={Mailboxes}>
      <ScrollableContentPanel
        footer={
          <YStack gapY="md">
            <Button disabled={!isValid} isLoading={isCreateAddressLoading} label="Continue" onClick={submitForm} />
          </YStack>
        }
        header={
          <YStack gapY="md">
            <OnboardingStepHeader title="What's your mailing address?" />
            <Text type="p-sm">
              {"If we need to send you anything, this is where we'll send it. We'll keep it private and secure."}
            </Text>
          </YStack>
        }
        justifyContent="start"
      >
        <YStack gapY="md">
          <AddressInputGroup autoFocus autocompleteSessionToken={autocompleteSessionToken} form={addressForm} />
        </YStack>
      </ScrollableContentPanel>
    </SplitImagePageContainer>
  );
};
