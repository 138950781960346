import { createTag } from '@frontend/api-client/utils';
import type { BaseApi } from '@frontend/api-client/utils/types';
import type {
  ConvertDraftTaskToTaskResponse,
  ListDraftTasksResponse,
  RetrieveDraftTaskResponse,
  UpdateDraftTaskMutation,
} from '@frontend/api-types';
import { convertArrayObjectKeysCase, convertObjectKeysCase } from '@frontend/duck-tape';
import DRAFT_TASKS_ENDPOINTS from './endpoint';

export const getDraftTasksApi = (baseApi: BaseApi) =>
  baseApi.injectEndpoints({
    endpoints: (builder) => ({
      convertDraftTaskToTask: builder.mutation<ConvertDraftTaskToTaskResponse, string>({
        invalidatesTags: [
          createTag({ id: 'LIST', type: 'DraftTask' }),
          createTag({ id: 'LIST', type: 'Task' }),
          createTag('Client'),
        ],
        query: (id) => ({
          method: 'POST',
          url: DRAFT_TASKS_ENDPOINTS.convertDraftTaskToTask(id),
        }),
        transformResponse: (draftTask: GenericRecord) =>
          convertObjectKeysCase(draftTask, 'camelCase') as ConvertDraftTaskToTaskResponse,
      }),
      deleteDraftTask: builder.mutation<void, string>({
        invalidatesTags: (_result, _error, id) => [
          createTag({ id, prefix: 'draftTaskId', type: 'DraftTask' }),
          createTag({ id: 'LIST', type: 'DraftTask' }),
        ],
        query: (id) => ({
          method: 'DELETE',
          url: DRAFT_TASKS_ENDPOINTS.retrieveDraftTask(id),
        }),
      }),
      listDraftTasks: builder.query<ListDraftTasksResponse, void>({
        providesTags: [createTag({ id: 'LIST', type: 'DraftTask' })],
        query: DRAFT_TASKS_ENDPOINTS.listDraftTasks,
        transformResponse: (response: GenericRecord[]) =>
          convertArrayObjectKeysCase(response, 'camelCase') as ListDraftTasksResponse,
      }),
      retrieveDraftTask: builder.query<RetrieveDraftTaskResponse, string>({
        providesTags: (_result, _error, id) => [createTag({ id, prefix: 'draftTaskId', type: 'DraftTask' })],
        query: (id) => ({
          url: DRAFT_TASKS_ENDPOINTS.retrieveDraftTask(id),
        }),
        transformResponse: (response: GenericRecord) =>
          convertObjectKeysCase(response, 'camelCase') as RetrieveDraftTaskResponse,
      }),
      updateDraftTask: builder.mutation<
        RetrieveDraftTaskResponse,
        { data: UpdateDraftTaskMutation['payload']; id: string }
      >({
        invalidatesTags: (_result, _error, { id }) => [
          createTag({ id, prefix: 'draftTaskId', type: 'DraftTask' }),
          createTag({ id: 'LIST', type: 'DraftTask' }),
        ],
        query: ({ data: { formData }, id }) => ({
          body: formData,
          method: 'PATCH',
          url: DRAFT_TASKS_ENDPOINTS.retrieveDraftTask(id),
        }),
      }),
    }),
    overrideExisting: true,
  });
