import { formatEndpoint } from '@frontend/duck-tape';

const namespace = 'stories';

const STORIES_ENDPOINTS = {
  completeStory: (storyId: string) => formatEndpoint(`${storyId}/complete`, namespace),
  listStories: () => formatEndpoint('', namespace),
};

export default STORIES_ENDPOINTS;
