import { make } from 'ts-brand';
import type { FormattableDate } from '../date/date';
import { ISO_8601_FORMAT, dateFormatters, validateDate } from '../date/date';

export const makeDateTimeISOString = make<DateTimeISOString>();

export const convertDateTimeISOStringToDate = (d: DateTimeISOString): Date => new Date(d);

const formatDateTimeISOString = (d: FormattableDate): string => dateFormatters.iso8601(d);

export const convertDateLikeToDateTimeISOString = (input: FormattableDate): DateTimeISOString =>
  makeDateTimeISOString(formatDateTimeISOString(input));

export const isStringValidDateTimeISOString = (dateTimeString: string): boolean =>
  validateDate(dateTimeString, ISO_8601_FORMAT, false);
