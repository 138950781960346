import { useForgotPasswordForm } from '@frontend/forms/client/forgotPasswordForm';
import { AppShell, Button, Text, TextInput, YStack } from '@frontend/web-react';
import { AppContentWrapper, UnauthenticatedAppHeader } from '@frontend/web/components';
import { useForgotPasswordMutation } from '@frontend/web/hooks';
import { router } from '@frontend/web/router';
import { handleMutation } from '@frontend/web/utils';
import { createFileRoute } from '@tanstack/react-router';

const ForgotPassword = () => {
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const handleForgotPassword = ({ email }: { email: string }) => {
    handleMutation({
      mutation: () => forgotPassword({ email }).unwrap(),
      onSuccess: () => {
        router.navigate({ to: '/login' });
      },
      showSuccessToast: true,
      successMessage: 'Password reset email sent if email is connected to an account',
    });
  };

  const { getControl, handleSubmit, isValid } = useForgotPasswordForm({
    onSubmit: handleForgotPassword,
  });
  return (
    <AppShell>
      <UnauthenticatedAppHeader />
      <AppContentWrapper className="justify-center items-center flex flex-col place-items-center">
        {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
        <YStack className="space-y-md px-md lg:justify-center min-w-full sm:min-w-[358px] sm:max-w-[358px]">
          <Text type="h4">Reset password</Text>
          <Text>Enter your current email address below.</Text>
          <form className="space-y-md" onSubmit={handleSubmit}>
            <TextInput {...getControl('email')} label={'Email'} />
            <Button className="mt-md" disabled={!isValid} fullWidth isForm isLoading={isLoading} label="Submit" />
          </form>
        </YStack>
      </AppContentWrapper>
    </AppShell>
  );
};

export const Route = createFileRoute('/forgot-password')({
  component: ForgotPassword,
});
