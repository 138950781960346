import type { Client } from '@frontend/api-types';
import { applyClass, concatClasses } from '@frontend/web-utils';
import { Avatar } from './Avatar';
import { YStack } from './Containers/YStack';
import { Text } from './Texts/Text';
import { Title } from './Texts/Title';

export type UserInfoProps = {
  isCollapsed: boolean;
  onClick?: () => void;
  userInfo: Client;
};

export const UserInfo = ({ isCollapsed, onClick, userInfo }: UserInfoProps) => {
  return (
    <button
      className={concatClasses(
        'hover-background-effect gap-x-md px-sm py-md flex flex-1 flex-row rounded-md contain-content',
        applyClass(isCollapsed, 'justify-center'),
      )}
      disabled={!onClick}
      onClick={onClick}
    >
      <Avatar userInfo={userInfo} />
      {isCollapsed ? null : (
        <YStack className="grow-1 overflow-hidden">
          <Title className="truncate" title={`${userInfo.firstName} ${userInfo.lastName}`} type="h6">
            {userInfo.firstName} {userInfo.lastName}
          </Title>
          <Text className="truncate" title={userInfo.email} type="p-sm">
            {userInfo.email}
          </Text>
        </YStack>
      )}
    </button>
  );
};
