import { createTag } from '@frontend/api-client/utils';
import type { BaseApi } from '@frontend/api-client/utils/types';
import type {
  CreateThreadResponse,
  ListThreadsResponse,
  RetrieveThreadEntityLinksResponse,
  RetrieveThreadResponse,
} from '@frontend/api-types';
import { convertArrayObjectKeysCase, convertObjectKeysCase } from '@frontend/duck-tape';
import { RESEARCH_ENDPOINTS } from './endpoint';

export const getThreadsApi = (baseApi: BaseApi) =>
  baseApi.injectEndpoints({
    endpoints: (builder) => ({
      // This endpoint intentionally has no input-- the first research method gets emitted via websocket
      createThread: builder.mutation<CreateThreadResponse, void>({
        invalidatesTags: [createTag({ id: 'LIST', type: 'ResearchThread' })],
        query: () => ({
          method: 'POST',
          url: RESEARCH_ENDPOINTS.createThread(),
        }),
      }),
      deleteThread: builder.mutation<void, string>({
        invalidatesTags: (_result, _error, researchThreadId) => [
          createTag({ id: researchThreadId, prefix: 'researchThreadId', type: 'ResearchThread' }),
        ],
        query: (id) => ({
          method: 'DELETE',
          url: RESEARCH_ENDPOINTS.deleteThread(id),
        }),
      }),
      listThreads: builder.query<ListThreadsResponse, void>({
        providesTags: [createTag({ id: 'LIST', type: 'ResearchThread' })],
        query: RESEARCH_ENDPOINTS.listThreads,
        transformResponse: (response: GenericRecord[]) =>
          convertArrayObjectKeysCase(response, 'camelCase') as ListThreadsResponse,
      }),
      retrieveThread: builder.query<RetrieveThreadResponse, string>({
        providesTags: (_result, _error, researchThreadId) => [
          createTag({ id: researchThreadId, prefix: 'researchThreadId', type: 'ResearchThread' }),
        ],
        query: (id) => RESEARCH_ENDPOINTS.retrieveThread(id),
        transformResponse: (response: GenericRecord) =>
          convertObjectKeysCase(response, 'camelCase') as RetrieveThreadResponse,
      }),
      retrieveThreadEntityLinks: builder.query<RetrieveThreadEntityLinksResponse, string>({
        providesTags: (_result, _error, threadEntityId) => [
          createTag({ id: threadEntityId, prefix: 'threadEntityId', type: 'ThreadEntity' }),
        ],
        query: (id) => RESEARCH_ENDPOINTS.retrieveThreadEntityLinks(id),
        transformResponse: (response: GenericRecord) =>
          convertObjectKeysCase(response, 'camelCase') as RetrieveThreadEntityLinksResponse,
      }),
    }),
  });
