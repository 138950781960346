import { formatEndpoint } from '@frontend/duck-tape';

const namespace = 'payments';

const CARDS = 'cards';

export const PAYMENTS_ENDPOINTS = {
  createSetupIntent: () => formatEndpoint('setup', namespace),
  createSubscription: formatEndpoint('subscribe', namespace),
  deleteCard: (id: string) => formatEndpoint(`${CARDS}/${id}`, namespace),
  listCards: () => formatEndpoint(CARDS, namespace),
  retrievePromotionDetails: (promoCode: string) =>
    formatEndpoint(`promotion-codes/?code=${promoCode}`, namespace, { removeTrailingSlash: true }),
  setupIntentConfirmed: () => formatEndpoint('setup/confirmed', namespace),
  updateCard: (id: string) => formatEndpoint(`${CARDS}/${id}/swap`, namespace),
  updateDefaultCard: (id: string) => formatEndpoint(`${CARDS}/${id}/default`, namespace),
};
