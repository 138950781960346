import type { TaskChatAuthorInfo } from '@frontend/api-client';
import { BlinkingDot, YStack } from '@frontend/web-react';
import { TaskChatAuthor } from '../Tasks';
import type { ThreadMarkdownRendererProps } from './ThreadMarkdownRenderer';
import { ThreadMarkdownRenderer } from './ThreadMarkdownRenderer';

export type ThreadChatMessageBubbleProps = Pick<ThreadMarkdownRendererProps, 'onClickLink'> & {
  authorInfo: TaskChatAuthorInfo;
  content: string;
  isStreaming?: boolean;
  threadId: string;
};

export const ThreadChatMessageBubble = ({
  authorInfo,
  content,
  isStreaming,
  onClickLink,
  threadId,
}: ThreadChatMessageBubbleProps) => {
  return (
    <YStack gapY="md">
      <TaskChatAuthor {...authorInfo} />
      <YStack className="pl-xl" gapY="md">
        <ThreadMarkdownRenderer markdownContent={content} onClickLink={onClickLink} threadId={threadId} />
        {isStreaming ? <BlinkingDot /> : null}
      </YStack>
    </YStack>
  );
};
