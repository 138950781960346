import { formatEndpoint } from '@frontend/duck-tape';

const namespace = 'tasks';
const v2_namespace = 'tasks/v2';

const LIST_COMMENTS = 'comments';
const CREATE_COMMENT = 'comments';
const SNOOZE = 'snooze';
const UNSNOOZE = 'unsnooze';
const CLOSE = 'close';
const START = 'start';
const SKIP = 'skip';
const ACCESS = 'access';

export const TASKS_ENDPOINTS = {
  accessTask: (id: string, clientId: string) => formatEndpoint(`${id}/${ACCESS}/${clientId}`, namespace),
  closeTask: (id: string) => formatEndpoint(`${id}/${CLOSE}`, v2_namespace),
  createComment: (id: string) => formatEndpoint(`${id}/${CREATE_COMMENT}`, namespace),
  createTask: () => formatEndpoint('', v2_namespace),
  listComments: (id: string) => formatEndpoint(`${id}/${LIST_COMMENTS}`, namespace),
  listTasks: () => formatEndpoint('', v2_namespace),
  retrieveTask: (id: string) => formatEndpoint(`${id}`, v2_namespace),
  skipTask: (id: string) => formatEndpoint(`${id}/${SKIP}`, v2_namespace),
  snoozeTask: (id: string) => formatEndpoint(`${id}/${SNOOZE}`, v2_namespace),
  startTask: (id: string) => formatEndpoint(`${id}/${START}`, v2_namespace),
  unsnoozeTask: (id: string) => formatEndpoint(`${id}/${UNSNOOZE}`, v2_namespace),
  updateTask: (id: string) => formatEndpoint(`${id}`, v2_namespace),
};
