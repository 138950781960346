import dayjs, { type Dayjs as DayjsType } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isToday from 'dayjs/plugin/isToday';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { entries, groupBy, sortBy } from '../hidash/hidash';

dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isToday);
dayjs.extend(quarterOfYear);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(utc);

export type FormattableDate = Date | DateISOString | DayjsType | string;

type FormatDateFunction = (date: FormattableDate, options?: DateOptions) => string;

type DateOptions = {
  strict?: boolean;
  utc?: boolean;
};

export type SupportedDateFormat = keyof typeof dateFormatters;

// Intentionally not exported
const formatDate = (date: FormattableDate, format: string): string => dayjs(date).format(format);

export const ISO_8601_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const DATE_ISO_STRING_FORMAT = 'YYYY-MM-DD';

export const validateDate = (date: string, format: string, strict = true): boolean =>
  dayjs(date, format, strict).isValid();

export const dateFormatters = {
  /** @example 2018/08/16 */
  DATE_ISO_STRING_FORMAT: ((date) => formatDate(date, DATE_ISO_STRING_FORMAT)) as FormatDateFunction,
  'M/D': ((date) => formatDate(date, 'M/D')) as FormatDateFunction,
  /** @example 08/16/2018 */
  'MM/DD/YYYY': ((date) => formatDate(date, 'L')) as FormatDateFunction,
  /** @example Aug 16 */
  'MMM D': ((date) => formatDate(date, 'MMM D')) as FormatDateFunction,
  /** @example Aug 16, 12:00am */
  'MMM D, h:mma': ((date) => formatDate(date, 'MMM D, h:mma')) as FormatDateFunction,
  /** @example 2018-08-16T12:00:00.000Z */
  iso8601: ((date) => dayjs(date).toISOString()) as FormatDateFunction,
  message: ((date) => {
    const then = dayjs(date);
    const now = dayjs();
    if (then.isSame(now, 'day')) {
      return formatDate(date, '[Today] [at] h:mma');
    } else if (then.isSame(now.subtract(1, 'day'), 'day')) {
      return formatDate(date, '[Yesterday] [at] h:mma');
    } else if (then.isAfter(now.subtract(7, 'day'), 'day')) {
      return formatDate(date, 'dddd [at] h:mma');
    } else {
      return formatDate(date, 'ddd, MMM D [at] h:mma');
    }
  }) as FormatDateFunction,
  roundedTimeSince: ((date: FormattableDate): string => {
    const then = dayjs(date);
    const now = dayjs();
    const timeDifference = now.diff(then);
    const durationObject = dayjs.duration(timeDifference);

    // Get the largest unit of time present in the duration
    if (durationObject.years() > 0) {
      return durationObject.years() + 'y';
    } else if (durationObject.months() > 0) {
      return durationObject.months() + 'mo';
    } else if (durationObject.days() > 0) {
      return durationObject.days() + 'd';
    } else if (durationObject.hours() > 0) {
      return durationObject.hours() + 'h';
    } else if (durationObject.minutes() > 0) {
      return durationObject.minutes() + 'm';
    } else if (durationObject.seconds() > 0) {
      return durationObject.seconds() + 's';
    } else {
      return '0s';
    }
  }) as FormatDateFunction,
} as const;

const groupByDateBuckets = {
  '10 months ago': [],
  '11 months ago': [],
  '2 days ago': [],
  '2 months ago': [],
  '2 weeks ago': [],
  '3 days ago': [],
  '3 months ago': [],
  '3 weeks ago': [],
  '4 days ago': [],
  '4 months ago': [],
  '5 days ago': [],
  '5 months ago': [],
  '6 days ago': [],
  '6 months ago': [],
  '7 months ago': [],
  '8 months ago': [],
  '9 months ago': [],
  'A month ago': [],
  'Last week': [],
  'Last year': [],
  Today: [],
  Yesterday: [],
};

type GroupedByDate<T> = Record<keyof typeof groupByDateBuckets, T[]>;

const parseBucketKey = (key: string): number => {
  if (key === 'Today') return 0;
  if (key === 'Yesterday') return 1;
  if (key === 'Last week') return 7;
  if (key === 'Last year') return 365;
  if (key === 'A month ago') return 30;

  const [value, unit] = key.split(' ');
  const numberValue = parseInt(value ?? '', 10);

  switch (unit) {
    case 'days':
      return numberValue;
    case 'weeks':
      return numberValue * 7;
    case 'months':
      return numberValue * 30;
    case 'years':
      return numberValue * 365;
    default:
      return Infinity;
  }
};

export const groupByDate = <T>(data: T[], getDate: (t: T) => FormattableDate): GroupedByDate<T> => {
  const getBucket = (item: T) => {
    const today = dayjs();
    const createdAt = dayjs(getDate(item));
    const diffDays = today.diff(createdAt, 'day');
    const diffWeeks = today.diff(createdAt, 'week');
    const diffMonths = today.diff(createdAt, 'month');
    const diffYears = today.diff(createdAt, 'year');

    if (diffDays === 0) {
      return 'Today';
    } else if (diffDays === 1) {
      return 'Yesterday';
    } else if (diffDays >= 2 && diffDays <= 6) {
      return `${diffDays} days ago`;
    } else if (diffWeeks === 1) {
      return 'Last week';
    } else if (diffWeeks === 2 || diffWeeks === 3) {
      return `${diffWeeks} weeks ago`;
    } else if (diffMonths === 1) {
      return 'A month ago';
    } else if (diffMonths >= 2 && diffMonths <= 11) {
      return `${diffMonths} months ago`;
    } else if (diffYears >= 1) {
      return 'Last year';
    }

    return 'Other';
  };

  return { ...groupByDateBuckets, ...groupBy(data, getBucket) };
};

export const sortedGroupByDate = <T>(groupedData: GroupedByDate<T>) => {
  // Convert groupedData to an array of [key, value] pairs
  const dataEntries = entries(groupedData) as [keyof typeof groupByDateBuckets, T[]][];

  // Sort the entries based on the parsed bucket key in descending order
  const sortedEntries = sortBy(dataEntries, ([key]) => parseBucketKey(key));

  return sortedEntries;
};

export const getIsLessThanTwoWeeksOld = (datetime: string) => dayjs().diff(dayjs(datetime), 'd', true) < 14;

export { dayjs };
