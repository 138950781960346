import { Icon, Text } from '@frontend/web-react';
import type { IconName } from '@frontend/web-react/components/Primitives/Icon/helpers';
import type { ClassName } from '@frontend/web-utils';
import { concatClasses, safeCss } from '@frontend/web-utils';
import type { SidebarItemColor } from '@frontend/web/components/Navigation/SidebarItems/types';

type SidebarButtonProps = {
  className?: ClassName;
  color?: SidebarItemColor;
  isCollapsed?: boolean;
  label: string;
  leftIconName?: IconName;
  onClick: () => void;
  rightIconName?: IconName;
};

export const SidebarButton = ({
  className,
  color = 'primary',
  isCollapsed = false,
  label,
  leftIconName,
  rightIconName,
  ...props
}: SidebarButtonProps) => {
  return (
    <button className={concatClasses(getClassName({ color }), className)} {...props}>
      {leftIconName ? <Icon color={color === 'primary' ? 'tertiary' : color} name={leftIconName} /> : undefined}
      {isCollapsed ? null : (
        <Text color={color === 'primary' ? 'tertiary' : color} type="h5">
          {label}
        </Text>
      )}
      {rightIconName ? <Icon color={color === 'primary' ? 'tertiary' : color} name={rightIconName} /> : undefined}
    </button>
  );
};

const getClassName = ({ color }: PickRequired<SidebarButtonProps, 'color'>) => {
  const labelClassMap = {
    danger: safeCss('text-textError'),
    primary: safeCss('text-textTertiary'),
  };

  return concatClasses(
    safeCss('flex hover-background-effect rounded-md p-sm gap-x-[12px] items-center'),
    labelClassMap[color],
  );
};
