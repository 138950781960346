import type { TaskComment } from '@frontend/api-types';
import { RenderHTML, YStack } from '@frontend/web-react';
import { safeCss } from '@frontend/web-utils';

export type TaskChatWhisperProps = Pick<TaskComment, 'body'>;

export const TaskChatWhisper = ({ body }: TaskChatWhisperProps) => {
  return (
    <YStack alignItems="center">
      <RenderHTML html={body.replaceAll('zd-comment', safeCss('text-caption text-textTertiary'))} />
    </YStack>
  );
};
