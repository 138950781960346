import type { RetrieveThreadEntityLinksResponse, SiteData } from '@frontend/api-types';
import { useCallback, useState } from 'react';

type UseThreadLinksProps = {
  retrieveThreadEntityLinks: (
    uuid: string,
    useCache: boolean,
  ) => Promise<MaybeUndefined<RetrieveThreadEntityLinksResponse>>;
};

const maxAttempts = 5;
const pollingInterval = 3000;

export const useThreadLinks = ({ retrieveThreadEntityLinks }: UseThreadLinksProps) => {
  const [linksData, setLinksData] = useState<{
    selectedText: string | undefined;
    siteData: SiteData[];
  }>({
    selectedText: undefined,
    siteData: [],
  });
  const [isThreadEntityLinksPollingLoading, setIsThreadEntityLinksPollingLoading] = useState(false);

  const pollThreadEntityLinks = useCallback(
    async (uuid: string, currentAttempt: number): Promise<void> => {
      const threadEntityLinks = await retrieveThreadEntityLinks(uuid, currentAttempt === 1);

      if (threadEntityLinks?.status !== 'in_progress') {
        setIsThreadEntityLinksPollingLoading(false);
        setLinksData((prev) => ({ ...prev, siteData: threadEntityLinks?.results ?? prev.siteData }));
      } else if (currentAttempt < maxAttempts) {
        setTimeout(() => pollThreadEntityLinks(uuid, currentAttempt + 1), pollingInterval);
      } else {
        setIsThreadEntityLinksPollingLoading(false);
        setLinksData((prev) => ({ ...prev, siteData: threadEntityLinks.results ?? prev.siteData }));
      }
    },
    [retrieveThreadEntityLinks, maxAttempts, pollingInterval],
  );

  const queryThreadEntityLinks = useCallback(
    async ({ text, uuid }: { text: string; uuid: string }) => {
      setLinksData((prev) => ({ selectedText: text, siteData: prev.siteData }));
      setIsThreadEntityLinksPollingLoading(true);
      pollThreadEntityLinks(uuid, 1);
    },
    [pollThreadEntityLinks],
  );

  return {
    isThreadEntityLinksPollingLoading,
    linksData,
    queryThreadEntityLinks,
    setLinksData,
  };
};
