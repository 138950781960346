import { createFileRoute, redirect } from '@tanstack/react-router';

// This route handles redirects for backwards compatibility purposes
export const Route = createFileRoute('/settings')({
  beforeLoad: () => {
    throw redirect({
      to: '/app/settings',
    });
  },
  component: () => <div />,
});
