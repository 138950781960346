// Any new emojis that need to be added here can be referenced from https://emojipedia.org/ > Technical Information
export const emojis = [
  {
    code: 'U+2728',
    emoji: '✨',
    name: 'sparkles',
    shortcode: ':sparkles:',
  },
  {
    code: 'U+1F986',
    emoji: '🦆',
    name: 'duck',
    shortcode: ':duck:',
  },
  {
    code: 'U+1F1F8',
    emoji: '🇺🇸',
    name: 'flag-us',
    shortcode: ':flag-us:',
  },
] as const;

export type EmojiShortcode = (typeof emojis)[number]['shortcode'];
