import { evaluateClientQuota, getInitialComment, getInitials } from '@frontend/api-client';
import { identity } from '@frontend/duck-tape';
import { useVisibilityController } from '@frontend/react';
import type { ShowToastProps } from '@frontend/web-react';
import {
  Divider,
  DropdownMenu,
  Header,
  Icon,
  LoadingSpinnerPanel,
  PageContainer,
  showToast,
  YStack,
} from '@frontend/web-react';
import { safeCss } from '@frontend/web-utils';
import {
  DeleteDraftTaskModal,
  DraftTaskChatInitialActions,
  TaskChatMessageBubble,
  TaskChatResearchThreadWhisper,
} from '@frontend/web/components';
import {
  useConvertDraftTaskToTaskMutation,
  useCreateTaskContext,
  useRetrieveClientQuery,
  useRetrieveClientStatusQuery,
  useRetrieveDraftTaskQuery,
} from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';
import { createFileRoute, useNavigate, useRouter } from '@tanstack/react-router';

const DraftTask = () => {
  const { draftTaskId } = Route.useParams();
  const router = useRouter();
  const navigate = useNavigate();
  const { data: client } = useRetrieveClientQuery();
  const { data: draftTask, isLoading: isDraftTaskLoading } = useRetrieveDraftTaskQuery(draftTaskId);
  const [isDeleteDraftTaskModalOpen, { close: closeDeleteDraftTaskModal, open: openDeleteDraftTaskModal }] =
    useVisibilityController();
  const [convertDraftTaskToTask, { isLoading: isConvertDraftTaskToTaskLoading }] = useConvertDraftTaskToTaskMutation();
  const { data: clientStatus } = useRetrieveClientStatusQuery();
  const { openCreateTaskModal } = useCreateTaskContext();
  const initialComment = draftTask
    ? getInitialComment(draftTask, client, {
        labelClassName: safeCss('text-caption text-textPrimary'),
        textClassName: safeCss('text-p-md text-textPrimary'),
      })
    : undefined;

  const onConvertDraftTaskToTask = () => {
    handleMutation({
      mutation: () => convertDraftTaskToTask(draftTaskId).unwrap(),
      onSuccess: ({ id: taskId, name }) => {
        navigate({ params: { taskId }, replace: true, to: `/app/task/$taskId` });
        const toastProps: ShowToastProps = {
          title: `"${name}" is now in-progress`,
          variant: 'success',
        };
        showToast(toastProps);
      },
      showSuccessToast: false,
    });
  };

  return (
    <PageContainer isScrollable={false} padding="none">
      <Header
        isLoading={isDraftTaskLoading}
        onClickBack={router.history.back}
        rightContent={
          <DropdownMenu
            layout={[
              {
                color: 'primary',
                icon: 'IconPencil',
                label: 'Edit this draft task',
                onClick: () => {
                  openCreateTaskModal({ draftTask });
                },
                type: 'item',
              },
              {
                color: 'danger',
                icon: 'IconTrash',
                label: 'Delete this draft task',
                onClick: openDeleteDraftTaskModal,
                type: 'item',
              },
            ]}
          >
            <Icon color={'primary'} name="IconDots" onClick={identity} />
          </DropdownMenu>
        }
        title={draftTask?.name ?? 'Draft task'}
      />
      {isDraftTaskLoading || !draftTask ? (
        <LoadingSpinnerPanel fullScreen />
      ) : (
        <YStack className="flex-1 justify-end px-lg pb-sm gap-y-xl">
          {draftTask.searchThread ? <TaskChatResearchThreadWhisper searchThread={draftTask.searchThread} /> : null}
          {initialComment && client ? (
            <TaskChatMessageBubble
              {...initialComment}
              authorInfo={{ initials: getInitials(client), label: 'You', type: 'client' }}
              isAuthorVisible
            />
          ) : null}
          {draftTask.attachments.length > 0 && client ? (
            <TaskChatMessageBubble
              attachments={draftTask.attachments}
              authorInfo={{ initials: getInitials(client), label: 'You', type: 'client' }}
              body=""
              isAuthorVisible
            />
          ) : null}
          <YStack>
            <Divider />
            {clientStatus ? (
              <DraftTaskChatInitialActions
                draftTask={draftTask}
                isConvertDraftTaskToTaskLoading={isConvertDraftTaskToTaskLoading}
                onConvertDraftTaskToTask={onConvertDraftTaskToTask}
                remainingActiveTasksQuota={evaluateClientQuota(clientStatus).remainingTasks}
              />
            ) : null}
          </YStack>
        </YStack>
      )}
      <DeleteDraftTaskModal
        draftTaskId={draftTaskId}
        isOpen={isDeleteDraftTaskModalOpen}
        onClose={closeDeleteDraftTaskModal}
        onComplete={router.history.back}
      />
    </PageContainer>
  );
};

export const Route = createFileRoute('/app/draft-task/$draftTaskId')({
  component: DraftTask,
});
