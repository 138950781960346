import type { ReferrerDetails, RetrieveInviteResponse } from '@frontend/api-types';

import { Fragment, useState } from '@frontend/react';
import { Button, Text, Title, YStack } from '@frontend/web-react';
import { motion } from 'framer-motion';

export type WelcomeProps = {
  incrementStep: () => void;
  inviteDetails?: RetrieveInviteResponse;
  referrerDetails?: MaybeUndefined<ReferrerDetails>;
};

export const Welcome = ({ incrementStep, inviteDetails, referrerDetails }: WelcomeProps) => {
  const [displayReferrerDetails, setDisplayReferrerDetails] = useState(!!referrerDetails);
  const bubbleVariants = {
    animate: {
      transition: {
        duration: 6,
        ease: 'linear',
        repeat: Infinity,
      },
      x: Array.from({ length: 361 }, (_, i) => 50 * Math.cos(i * (Math.PI / 180))),
      y: Array.from({ length: 361 }, (_, i) => 50 * Math.sin(i * (Math.PI / 180))),
    },
  };

  return (
    <Fragment>
      <YStack className="grid grid-cols-1 md:grid-cols-5 xl:grid-cols-10 justify-center items-center z-10 h-screen">
        <YStack className="col-span-1 px-xl md:px-none md:col-span-3 md:col-start-2 xl:col-span-2 xl:col-start-5 justify-center gap-y-md">
          {displayReferrerDetails && referrerDetails?.preferredName ? (
            <YStack gapY="lg">
              <Text type="h3">
                {' '}
                {inviteDetails?.firstName ? `${inviteDetails.firstName}, You're in!` : "You're in!"}
              </Text>
              <Text
                color="secondary"
                type="h4"
              >{`${referrerDetails.preferredName} invited you to join a community of early adopters, outsourcers, movers, and shakers who have discovered the key to managing life admin.`}</Text>
              <Text color="secondary" type="h4">
                Welcome to Duckbill.
              </Text>
            </YStack>
          ) : (
            <YStack gapY="md">
              <Title type="h1" uppercase>
                Duckbill is an executive assistant for your personal life
              </Title>
              <Text type="h4">Powered by expert humans and enhanced by AI.</Text>
            </YStack>
          )}
          <Button
            label="Continue"
            onClick={displayReferrerDetails ? () => setDisplayReferrerDetails(false) : incrementStep}
          />
        </YStack>
      </YStack>
      <motion.div
        animate="animate"
        // eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values, tailwindcss/no-arbitrary-value
        className="absolute rounded-[50%] bg-surfaceAccent opacity-50 size-[500px] lg:size-[670px] -top-[20%] -right-[20%] md:-top-[20%] md:-right-[5%] blur-lg -z-10"
        variants={bubbleVariants}
      />
      <motion.div
        animate="animate"
        // eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values, tailwindcss/no-arbitrary-value
        className="absolute rounded-[50%] bg-surfaceAccent opacity-50 size-[500px] lg:size-[670px] -bottom-[30%] -left-[20%] md:-bottom-[20%] md:-left-[5%] blur-md -z-10"
        variants={bubbleVariants}
      />
    </Fragment>
  );
};
