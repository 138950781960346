import type { LocalFile } from './files';

export type TypedFormDataValue = LocalFile | LocalFile[] | Record<string, unknown> | unknown[] | number | string;

/**
 * Important: This class should never be used directly. Instead, use either MobileTypedFormData, or WebTypedFormData
 */
// eslint-disable-next-line fp/no-class
export class TypedFormData<T extends Record<string, TypedFormDataValue>> extends FormData {
  constructor(data?: T) {
    super();

    if (data) {
      Object.entries(data).forEach(([key, value]) => {
        // @ts-expect-error can't figure out type
        // eslint-disable-next-line fp/no-this
        this.append(key, value);
      });
    }
  }

  /**
   * @description
   * This method should automatically handle (flat) arrays. Ideally we shouldn't even need to support arrays of
   * arbitrary depth because FormData was never intended to be used that way */
  // @ts-expect-error can't figure out type
  append<K extends keyof T>(key: K, value: T[K]): void {
    if (Array.isArray(value)) {
      return value.forEach((item) => {
        // @ts-expect-error can't figure out type
        super.append(key, item);
      });
    }
    // @ts-expect-error can't figure out type
    return super.append(key as string, value);
  }

  appendStringified<K extends keyof T>(key: K, value: T[K]): void {
    // @ts-expect-error can't figure out type
    super.append(key, JSON.stringify(value));
  }

  // @ts-expect-error can't figure out type
  getAll<K extends keyof T>(key: K): T[K][] {
    // @ts-expect-error can't figure out type
    return super.getAll(key);
  }
}
