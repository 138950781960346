import type { LocalOrSavedFile } from '@frontend/api-types';
import { getCommonFileFields } from '@frontend/forms';
import { Clickable, Text, YStack } from '@frontend/web-react';

type TaskChatPdfThumbnailProps = {
  file: LocalOrSavedFile;
};

export const TaskChatPdfThumbnail = ({ file }: TaskChatPdfThumbnailProps) => {
  const { name, url } = getCommonFileFields(file);

  const onClick = () => {
    // Pdfs are tedious to preview because of CORS issues, just opening in new tab for now
    window.open(url, '_blank');
    // openFilePreviewModal();
  };

  return (
    <Clickable onClick={onClick}>
      {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
      <YStack className="rounded-md border-1 border-borderPrimary self-start gap-sm w-[158px] p-sm">
        <YStack className="border-1 border-borderPrimary rounded-md justify-center items-center px-lg py-md">
          {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
          <Text className="italic self-center rotate-[-10.582deg]" type="h6">
            PDF
          </Text>
        </YStack>
        <Text lineClamp={1} type="p-sm">
          {name}
        </Text>
      </YStack>
    </Clickable>
  );
};
