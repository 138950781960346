import type { StrictExtract } from '@frontend/duck-tape';
import { match } from '@frontend/duck-tape';
import type { ClassName } from '@frontend/web-utils';
import { concatClasses, safeCss } from '@frontend/web-utils';
import type { TextColorVariant } from '../Primitives/Texts/Text';
import { Text } from '../Primitives/Texts/Text';
import type { ClickableProps } from './Containers';
import { Clickable, YStack } from './Containers';
import { Icon } from './Icon/Icon';
import type { IconName } from './Icon/helpers';

export type BannerProps = {
  className?: ClassName;
  color: BannerColorVariant;
  content?: string;
  leftIconName?: IconName;
  onClick?: ClickableProps['onClick'];
  rightIconName?: IconName;
  title: string;
};

export type BannerColorVariant = StrictExtract<TextColorVariant, 'danger' | 'success'> | 'white';

const getColorVariant = (color: BannerColorVariant) =>
  match<BannerColorVariant, TextColorVariant>(color)
    .with('white', () => 'primary')
    .with('danger', () => 'danger')
    .with('success', () => 'success')
    .exhaustive();

const getBannerColorsClassNames = (color: BannerColorVariant) =>
  match<BannerColorVariant, ClassName>(color)
    .with('white', () => safeCss('bg-white border-1 border-borderPrimary'))
    .with('danger', () => safeCss('bg-surfaceError '))
    .with('success', () => safeCss('bg-surfaceSuccess'))
    .exhaustive();

export const Banner = ({ className, color, content, leftIconName, onClick, rightIconName, title }: BannerProps) => {
  const colorVariant = getColorVariant(color);

  return (
    <Clickable
      className={concatClasses(
        safeCss('p-md rounded-md items-center gap-x-md'),
        getBannerColorsClassNames(color),
        className,
      )}
      flexDirection="row"
      onClick={onClick}
    >
      {leftIconName ? <Icon color={color === 'white' ? 'primary' : color} name={leftIconName} /> : null}
      <YStack className="flex-1">
        <Text color={colorVariant} type="h6">
          {title}
        </Text>
        {content ? (
          <Text color={colorVariant} type="p-sm">
            {content}
          </Text>
        ) : null}
      </YStack>
      {rightIconName ? <Icon color={color === 'white' ? 'primary' : color} name={rightIconName} /> : null}
    </Clickable>
  );
};
