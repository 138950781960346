import type { LocalOrSavedFile } from '@frontend/api-types';
import { getCommonFileFields } from '@frontend/forms';
import { Fragment, useVisibilityController } from '@frontend/react';
import { Clickable, FilePreviewModal, Image } from '@frontend/web-react';
import type { ClassName } from '@frontend/web-utils';
import { concatClasses } from '@frontend/web-utils';

type TaskChatImageThumbnailProps = {
  file: LocalOrSavedFile;
  imageClassName?: ClassName;
};

export const TaskChatImageThumbnail = ({ file, imageClassName }: TaskChatImageThumbnailProps) => {
  const [isPreviewFileModalOpen, { close: closeFilePreviewModal, open: openFilePreviewModal }] =
    useVisibilityController();

  const onClick = () => {
    openFilePreviewModal();
  };

  const { name, url } = getCommonFileFields(file);

  return (
    <Fragment>
      <Clickable onClick={onClick}>
        {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
        <Image className={concatClasses('flex-1 rounded-lg size-[114px]', imageClassName)} source={file.url} />
      </Clickable>
      <FilePreviewModal
        file={file}
        isOpen={isPreviewFileModalOpen}
        onClose={closeFilePreviewModal}
        title={name}
        url={url}
      />
    </Fragment>
  );
};
