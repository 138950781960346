import { useFeatureIsOn as gbUseFeatureIsOn, useFeatureValue as gbUseFeatureValue } from '@growthbook/growthbook-react';

export type FeatureFlag =
  | 'directory-banner'
  | 'is_duckbill_research_enabled'
  | 'is_find_repeating_tasks_enabled'
  | 'is_repeated_tasks_enabled'
  | 'is_throttling_tasks_enabled'
  | 'throttling_tasks_migration_date';

export const useFeatureIsOn = (flag: FeatureFlag) => {
  const enabled = gbUseFeatureIsOn(flag);

  return { enabled };
};

export const useFeatureValue = (flag: FeatureFlag, defaultValue?: GenericRecord | boolean | number | string) => {
  const value = gbUseFeatureValue(flag, defaultValue || null);

  return { value };
};
