import type { MutableRefObject } from 'react';
import { useEffect, useState } from 'react';

export const useScrollTracker = (ref: MutableRefObject<MaybeNull<HTMLDivElement>>) => {
  const [isScrollable, setIsScrollable] = useState(false);
  const [scrollPosition, setScrollPosition] = useState({ left: 0, top: 0 });
  const [isHorizontalScrollTerminal, setIsHorizontalScrollTerminal] = useState(false);
  const [isVerticalScrollTerminal, setIsVerticalScrollTerminal] = useState(false);
  const [isAtStart, setIsAtStart] = useState(true);

  useEffect(() => {
    const element = ref?.current;

    const checkScrollable = () => {
      if (element) {
        const isElementScrollable =
          element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
        setIsScrollable(isElementScrollable);
      }
    };

    const handleScroll = () => {
      if (element) {
        setScrollPosition({
          left: element.scrollLeft,
          top: element.scrollTop,
        });

        const isVerticalTerminal = element.scrollTop + element.clientHeight >= element.scrollHeight - 1;
        const isHorizontalTerminal = element.scrollLeft + element.clientWidth >= element.scrollWidth - 1;

        setIsHorizontalScrollTerminal(isHorizontalTerminal);
        setIsVerticalScrollTerminal(isVerticalTerminal);
        setIsAtStart(element.scrollLeft === 0);
      }
    };

    if (element) {
      checkScrollable();
      element.addEventListener('scroll', handleScroll);
    }

    window.addEventListener('resize', checkScrollable);

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
      window.removeEventListener('resize', checkScrollable);
    };
  }, [ref]);

  return { isAtStart, isHorizontalScrollTerminal, isScrollable, isVerticalScrollTerminal, scrollPosition };
};
