import type { MouseEvent, ReactNode } from '@frontend/react';
import { forwardRef } from '@frontend/react';
import { applyClass, concatClasses } from '@frontend/web-utils';
import { getTextAlignClassName, getTextColorClassName, getTextSizeClassName, type TextProps } from './Texts';

export type AnchorProps = Pick<TextProps, 'color' | 'textAlign' | 'type' | 'uppercase'> & {
  children: ReactNode;
  href: string;
  newTab?: boolean;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  withUnderline?: boolean;
};

export const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(
  (
    {
      color = 'primary',
      newTab = true,
      textAlign = 'start',
      type = 'p-md',
      uppercase = false,
      withUnderline = false,
      ...props
    },
    ref,
  ) => {
    const className = concatClasses(
      'hover-opacity-effect cursor-pointer',
      applyClass(uppercase, 'uppercase'),
      getTextAlignClassName({ textAlign }),
      applyClass(withUnderline, 'underline underline-offset-4'),
      getTextColorClassName({ color }),
      getTextSizeClassName({ type }),
    );

    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a className={className} ref={ref} target={newTab ? '_blank' : '_self'} {...props} />;
  },
);
