import { createTag } from '@frontend/api-client/utils';
import type { BaseApi } from '@frontend/api-client/utils/types';
import type {
  CreateRecurrenceRequest,
  CreateRecurrenceResponse,
  ListRecurrencesResponse,
  RetrieveRecurrenceResponse,
  ShareRecurrenceRequest,
  UnshareRecurrenceRequest,
} from '@frontend/api-types';
import { convertArrayObjectKeysCase, convertObjectKeysCase } from '@frontend/duck-tape';
import RECURRENCES_ENDPOINTS from './endpoint';

export const getRecurrencesApi = (baseApi: BaseApi) =>
  baseApi.injectEndpoints({
    endpoints: (builder) => ({
      createRecurrence: builder.mutation<CreateRecurrenceResponse, CreateRecurrenceRequest['input']>({
        invalidatesTags: [createTag({ id: 'LIST', type: 'Recurrence' }), createTag({ id: 'LIST', type: 'Task' })],
        query: (body) => ({
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          url: RECURRENCES_ENDPOINTS.listRecurrences(),
        }),
        transformResponse: (response: GenericRecord) =>
          convertObjectKeysCase(response, 'camelCase') as CreateRecurrenceResponse,
      }),
      deleteRecurrence: builder.mutation<void, string>({
        invalidatesTags: (_result, _error, recurrenceId) => [
          createTag({ id: recurrenceId, prefix: 'recurrenceId', type: 'Recurrence' }),
          createTag({ id: 'LIST', type: 'Recurrence' }),
        ],
        query: (recurrenceId) => ({
          method: 'DELETE',
          url: RECURRENCES_ENDPOINTS.retrieveRecurrence(recurrenceId),
        }),
      }),
      listRecurrences: builder.query<ListRecurrencesResponse, void>({
        providesTags: [createTag({ id: 'LIST', type: 'Recurrence' })],
        query: RECURRENCES_ENDPOINTS.listRecurrences,
        transformResponse: (response: GenericRecord[]) =>
          convertArrayObjectKeysCase(response, 'camelCase') as ListRecurrencesResponse,
      }),
      retrieveRecurrence: builder.query<RetrieveRecurrenceResponse, string>({
        providesTags: (_result, _error, recurrenceId) => [
          createTag({ id: recurrenceId, prefix: 'recurrenceId', type: 'Recurrence' }),
        ],
        query: RECURRENCES_ENDPOINTS.retrieveRecurrence,
        transformResponse: (response: GenericRecord) =>
          convertObjectKeysCase(response, 'camelCase') as RetrieveRecurrenceResponse,
      }),
      shareRecurrence: builder.mutation<void, ShareRecurrenceRequest>({
        invalidatesTags: (_result, _error, { id }) => [createTag({ id, prefix: 'recurrenceId', type: 'Recurrence' })],
        query: ({ clientId, id }) => ({
          method: 'POST',
          url: RECURRENCES_ENDPOINTS.accessRecurrence(id, clientId),
        }),
      }),
      unshareRecurrence: builder.mutation<void, UnshareRecurrenceRequest>({
        invalidatesTags: (_result, _error, { id }) => [createTag({ id, prefix: 'recurrenceId', type: 'Recurrence' })],
        query: ({ clientId, id }) => ({
          method: 'DELETE',
          url: RECURRENCES_ENDPOINTS.accessRecurrence(id, clientId),
        }),
      }),
    }),
    overrideExisting: true,
  });
