import { Anchor, Button, Card, Text, XStack, YStack } from '@frontend/web-react';

export type DirectoryCardProps = {
  directoryUrl: string;
  onClickUrl: () => void;
};
export const DirectoryCard = ({ directoryUrl, onClickUrl }: DirectoryCardProps) => {
  return (
    <Card color="secondary">
      <YStack>
        <Text className="italic" type="h2">
          DUCKBILL DIRECTORY
        </Text>
        <Text>We created a searchable directory of all our most common tasks.</Text>
      </YStack>
      <XStack>
        <Anchor href={directoryUrl} onClick={onClickUrl}>
          {/* Setting is form will disable stopPropagation */}
          <Button color="primary" isForm label="Take me there" rightIconName="IconExternalLink" size="sm" />
        </Anchor>
      </XStack>
    </Card>
  );
};
