import { colors, fontStyles, sansFontFamily } from '@frontend/constants';
import { safeCss } from '@frontend/web-utils';
import type { StripeCardNumberElementOptions } from '@stripe/stripe-js';

export const creditCardInputClassNames = safeCss(
  'p-md rounded-sm border-1 border-borderPrimary focus-within:border-textPrimary focus:border-textPrimary bg-white',
);

export const creditCardInputStyle: StripeCardNumberElementOptions = {
  style: {
    base: {
      color: colors.textPrimary,
      ...fontStyles.rem['p-md'],
      fontFamily: sansFontFamily[0],
      fontSmoothing: 'antialiased',
    },
    invalid: {
      color: colors.textError,
      iconColor: colors.textError,
    },
  },
};
