import type { LocalOrSavedFile } from '@frontend/api-types';
import { isImage } from '@frontend/forms';
import { useState } from '@frontend/react';
import { Header, Image, LoadingSpinnerPanel, Modal, SkeletonList, Text, YStack } from '@frontend/web-react';
import { Page, Document as Pdf, pdfjs } from 'react-pdf';
import type { CommonModalProps } from './types';

// eslint-disable-next-line fp/no-mutation
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export type FilePreviewModalProps = CommonModalProps & {
  file: LocalOrSavedFile;
  title: string;
  url: string;
};

const options = {
  cMapUrl: '/cmaps/',
};

export const FilePreviewModal = ({ file, isOpen, onClose, title, url }: FilePreviewModalProps) => {
  const [numPages, setNumPages] = useState<number>();
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <Modal
      Header={
        <Header
          onClickBack={onClose}
          // eslint-disable-next-line no-restricted-globals
          onClickRightIcon={() => open(url, '_blank')}
          rightIconName={
            url && file.__typename === 'SavedFile'
              ? file.contentType.includes('pdf')
                ? 'IconDownload'
                : 'IconExternalLink'
              : undefined
          }
          title={title}
        />
      }
      className="justify-center"
      isOpen={isOpen}
      onClose={onClose}
      size={'600px'}
      title={title}
    >
      {
        <YStack>
          {isImage(file) ? (
            // eslint-disable-next-line tailwindcss/no-arbitrary-value
            <Image className="min-h-[250px] max-h-[600px] object-contain" source={url} />
          ) : (
            <Pdf
              error={() => <Text>The pdf could not be loaded.</Text>}
              file={url}
              loading={<LoadingSpinnerPanel />}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
            >
              <YStack gapY="sm">
                {Array.from({ length: numPages || 0 }).map((_, index, array) => (
                  <YStack className="p-md border-1 border-borderPrimary" key={index}>
                    <Text color="tertiary" textAlign="end" type="captionBold">
                      {`${index + 1} of ${array.length}`}
                    </Text>
                    <Page loading={<SkeletonList countSkeletons={10} />} pageNumber={index + 1} width={480} />
                  </YStack>
                ))}
              </YStack>
            </Pdf>
          )}
        </YStack>
      }
    </Modal>
  );
};
