import type { Client } from '@frontend/api-types';
import { convertObjectKeysCase } from '@frontend/duck-tape';
import type { AnalyticsBrowser } from '@segment/analytics-next';

/**
 * This hook should never be used directly. It should only ever be used by the `useAnalytics` hook from the `@frontend/react` package.
 */
export const useSegment = ({
  analytics,
  env,
}: {
  analytics: ReturnType<typeof AnalyticsBrowser.load>;
  env: EnvType;
}) => {
  const track = (object: string, action: string, data: Record<string, unknown> = {}, client?: Client) => {
    const clientData = client ? { clientId: client.id } : {};
    const snakeCasedData = {
      ...convertObjectKeysCase(clientData, 'snakeCase'),
      ...convertObjectKeysCase(data, 'snakeCase'),
    };
    const label = `${object} ${action}`;
    if (env === 'local') {
      // eslint-disable-next-line no-console
      console.info('Mock Segment track:', {
        action,
        data: snakeCasedData,
        label,
      });
      return;
    }
    analytics.track(label, snakeCasedData);
    return;
  };

  const trackRouteChange = (params: {
    category?: string;
    name?: string;
    properties?: {
      path?: string;
      referrer?: string;
      search?: Record<string, string>;
      searchStr?: string;
      title?: string;
      url?: string;
    };
  }) => {
    if (env === 'local') {
      // eslint-disable-next-line no-console
      console.info('Mock Segment page track:', params);
      return;
    }
    analytics.page(params.category, params.name, params.properties);
    return;
  };

  return { track, trackRouteChange };
};
