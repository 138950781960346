import type { ReactNode } from '@frontend/react';
import { useEffect, useState } from '@frontend/react';
import { concatClasses } from '@frontend/web-utils';
import { useRef } from 'react';
import { XStack, YStack } from '../Containers';
import type { TextProps } from './Text';
import { Text } from './Text';
import { Title } from './Title';

export type ExpandableText = {
  breakWord?: boolean;
  children: ReactNode;
  className?: string;
  labelDirection?: 'left' | 'right';
  maxLines?: number;
  showLessLabel?: string;
  showMoreLabel?: string;
  textProps?: Omit<TextProps, 'children'>;
};

export const ExpandableText = ({
  breakWord,
  children,
  className,
  maxLines = 3,
  showLessLabel = 'Hide',
  showMoreLabel = 'See More',
  textProps,
}: ExpandableText) => {
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const [showFooter, setShowFooter] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const descriptionElement = descriptionRef.current;

    if (descriptionElement) {
      const computedStyle = getComputedStyle(descriptionElement);
      const lineHeight = parseFloat(computedStyle.lineHeight);
      const textHeight = descriptionElement.scrollHeight;

      if (textHeight > maxLines * lineHeight) {
        setShowFooter(true);
      } else if (children) {
        setIsExpanded(true);
      }
    }
  }, [maxLines, children]);

  return (
    <YStack className={concatClasses('gap-y-md', className)}>
      <Text
        className={concatClasses(breakWord ? 'break-words' : 'break-all')}
        lineClamp={isExpanded ? undefined : maxLines}
        ref={descriptionRef}
        {...textProps}
      >
        {children}
      </Text>
      {showFooter ? (
        <XStack>
          <Title
            className="underline-offset-4 break-all"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsExpanded((prev) => !prev);
            }}
            td="underline"
            type="h5"
          >
            {isExpanded ? showLessLabel : showMoreLabel}
          </Title>
        </XStack>
      ) : null}
    </YStack>
  );
};
