import { dateFormatters } from '@frontend/duck-tape';
import { Icon, Text, XStack } from '@frontend/web-react';

export type RecurrenceDueDateTagProps = {
  nextTaskDueDate: DateISOString;
};

export const RecurrenceDueDateTag = ({ nextTaskDueDate }: RecurrenceDueDateTagProps) => {
  return (
    <XStack alignItems="center" className="p-xs rounded-sm bg-surfacePrimary" gapX="xs">
      <Text color="secondary" type="p-sm">
        {dateFormatters['M/D'](nextTaskDueDate)}
      </Text>
      <Icon color="secondary" name="IconCalendarEvent" />
    </XStack>
  );
};
