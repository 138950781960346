import type { MembershipPlanType } from '@frontend/api-client';
import { formatPromoPricingAffect, MEMBERSHIP_PLANS } from '@frontend/api-client';
import type { Household, PromotionDetails } from '@frontend/api-types';
import type { StrictExtract } from '@frontend/duck-tape';
import { formatCurrencyNatural, match, pluralize } from '@frontend/duck-tape';
import type { TextColorVariant } from '@frontend/web-react';
import { Card, getTextColorClassName, Icon, List, Text, XStack, YStack } from '@frontend/web-react';
import type { IconName } from '@frontend/web-react/components/Primitives/Icon/helpers';
import type { ClassName } from '@frontend/web-utils';
import { applyClass, concatClasses, safeCss } from '@frontend/web-utils';

export type MembershipPlanCardProps = {
  className?: ClassName;
  currentClientId?: string;
  hideBenefitDetails?: boolean;
  hidePrice?: boolean;
  household?: Household;
  isSelected: boolean;
  onClick?: (plan: MembershipPlanType) => void;
  plan: MembershipPlanType;
  promoDetails: MaybeUndefined<PromotionDetails>;
  showCurrentUsers?: boolean;
  showOwner?: boolean;
};

export const MembershipPlanCard = ({
  className,
  currentClientId,
  hideBenefitDetails = false,
  hidePrice,
  household,
  isSelected,
  onClick,
  plan,
  promoDetails,
  showCurrentUsers,
  showOwner,
}: MembershipPlanCardProps) => {
  const { color, name } = match<
    MembershipPlanType,
    { color: StrictExtract<TextColorVariant, 'pink' | 'purple' | 'yellow'>; name: IconName }
  >(plan)
    .with('household', () => ({ color: 'pink', name: 'IconUsers' }))
    .with('individual', () => ({
      color: 'purple',
      name: 'IconUser',
    }))
    .with('vip', () => ({ color: 'yellow', name: 'IconUserStar' }))
    .with('champagneHousehold', () => ({ color: 'yellow', name: 'DuckbillLogo' }))
    .with('champagneIndividual', () => ({ color: 'yellow', name: 'DuckbillLogo' }))
    .exhaustive();

  const planData = MEMBERSHIP_PLANS[plan];

  const hasPromo = !!(promoDetails?.percentOff || promoDetails?.amountOff);

  return (
    <Card
      className={concatClasses(
        'px-xl py-md border-[3px] rounded-lg',
        applyClass(
          isSelected,
          match(color)
            .with('purple', () => safeCss('border-violet-950'))
            .with('pink', () => safeCss('border-pink-950'))
            .with('yellow', () => safeCss('border-yellow-900'))
            .exhaustive(),
          'border-transparent',
        ),
        className,
      )}
      color={color}
      onClick={onClick ? () => onClick?.(plan) : undefined}
    >
      <XStack gapX="md" justifyContent="spaceBetween">
        <XStack alignItems="center" gapX="md">
          <Icon color={color} name={name} />
          <YStack>
            <Text color={color} type="h6">
              {planData.label}
            </Text>
            {showCurrentUsers && household?.currentUsers ? (
              <Text color={color} type="h6">
                {household.currentUsers} {pluralize('member', household.currentUsers)}
              </Text>
            ) : null}
            {hidePrice ? null : (
              <Text color={color} type="h6">
                {promoDetails?.freeTasksCap ? `Up to ${promoDetails.freeTasksCap} free tasks, then ` : null}
                {hasPromo
                  ? formatPromoPricingAffect(planData.price, promoDetails)
                  : `${formatCurrencyNatural(planData.price)}/month`}
              </Text>
            )}
            {hasPromo ? (
              <Text
                className={concatClasses(
                  'line-through',
                  match(color)
                    .with('purple', () => safeCss('text-violet-600'))
                    .with('pink', () => safeCss('text-pink-600'))
                    .with('yellow', () => safeCss('text-yellow-600'))
                    .exhaustive(),
                )}
                type="p-sm"
              >
                {formatCurrencyNatural(planData.price)}/month
              </Text>
            ) : null}
            {showOwner && household?.ownerName && household.ownerId !== currentClientId ? (
              <Text color={color} type="caption">
                {`You are under ${household.ownerName}'s household`}
              </Text>
            ) : null}
          </YStack>
        </XStack>
        <Icon color={isSelected ? color : 'transparent'} name="IconCheck" size="lg" />
      </XStack>
      {hideBenefitDetails ? null : (
        <List className={concatClasses('list-disc text-p-sm ', getTextColorClassName({ color }))}>
          {planData.benefits.map((benefit, index) => (
            <List.Item key={index}>
              <Text color={color} type="p-sm">
                {benefit}
              </Text>
            </List.Item>
          ))}
        </List>
      )}
    </Card>
  );
};
