import { identity } from '@frontend/duck-tape';
import { Icon, LinearGradientWrapper, XStack, YStack, useHotkeys } from '@frontend/web-react';
import type { ClassName } from '@frontend/web-utils';
import { concatClasses, safeCss } from '@frontend/web-utils';
import { Textarea } from '@mantine/core';
import { forwardRef } from 'react';

type ThreadChatFooterProps = {
  className?: ClassName;
  hasBottomTab?: boolean;
  inputClassName?: ClassName;
  inputDisabled?: boolean;
  isAIResponseLoading?: boolean;
  isCreateCommentLoading: boolean;
  onChangeText: (text: string) => void;
  onClickInterruptAIResponse?: () => void;
  onClickSubmit: () => Promise<void> | void;
  placeholder?: string;
  submitDisabled?: boolean;
  textValue: string;
};

export const ThreadChatFooter = forwardRef<HTMLTextAreaElement, ThreadChatFooterProps>(
  (
    {
      className,
      inputClassName,
      inputDisabled,
      isAIResponseLoading = false,
      isCreateCommentLoading,
      onChangeText,
      onClickInterruptAIResponse = identity,
      onClickSubmit,
      placeholder,
      submitDisabled,
      textValue,
    },
    ref,
  ) => {
    const onCommandEnterSubmit = () => {
      if (!inputDisabled && !isAIResponseLoading && !isCreateCommentLoading && !submitDisabled && textValue) {
        onClickSubmit();
      }
    };

    useHotkeys(
      [
        ['Enter', onCommandEnterSubmit],
        ['mod+Enter', () => onChangeText(textValue + '\n')],
      ],
      [],
    );

    return (
      <YStack className="px-md pb-lg gap-y-sm">
        {/* eslint-disable-next-line tailwindcss/no-arbitrary-value, tailwindcss/no-unnecessary-arbitrary-value */}
        <LinearGradientWrapper className={concatClasses('p-[2px] rounded-md', className)}>
          <YStack className="rounded-md py-sm bg-white">
            <YStack className="gap-y-md bg-white rounded-md px-sm">
              <XStack alignItems="end" gapX="sm">
                {/* eslint-disable-next-line tailwindcss/no-arbitrary-value, tailwindcss/no-unnecessary-arbitrary-value */}
                <Textarea
                  autosize
                  className="flex-1"
                  classNames={{
                    input: concatClasses(safeCss('border-none rounded-md'), inputClassName),
                  }}
                  disabled={inputDisabled}
                  maxRows={10}
                  minRows={1}
                  name="message"
                  onChange={(event) => onChangeText(event.currentTarget.value)}
                  placeholder={placeholder}
                  ref={ref}
                  value={textValue}
                />
                <YStack>
                  <LinearGradientWrapper
                    className="rounded-xl border-1 border-textPrimary"
                    disabled={isAIResponseLoading || submitDisabled}
                  >
                    <Icon
                      className={isAIResponseLoading || submitDisabled ? 'bg-buttonPrimary' : ''}
                      disabled={submitDisabled}
                      isLoading={isCreateCommentLoading}
                      name={isAIResponseLoading ? 'IconPlayerPauseFilled' : 'IconArrowUp'}
                      onClick={isAIResponseLoading ? onClickInterruptAIResponse : onClickSubmit}
                      rounded
                    />
                  </LinearGradientWrapper>
                </YStack>
              </XStack>
            </YStack>
          </YStack>
        </LinearGradientWrapper>
      </YStack>
    );
  },
);
