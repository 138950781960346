/* eslint-disable react/jsx-no-undef */
import { isClientSubscribed, shouldPromptTaskReview } from '@frontend/api-client';
import { type Task } from '@frontend/api-types';
import { convertDateLikeToDateTimeISOString, dayjs, findFirstN } from '@frontend/duck-tape';
import { Fragment, useEffect, useListState, useState, useTaskSlice, useVisibilityController } from '@frontend/react';
import {
  AppShell,
  DesktopOnly,
  EnvironmentDialog,
  YStack,
  isMobile,
  useIsOnMobile,
  useIsOnTablet,
} from '@frontend/web-react';
import { AppContentWrapper, AppSidebar, Outlet, ReviewTaskModal } from '@frontend/web/components';
import { QuackActionsProvider } from '@frontend/web/context';
import {
  useAnalytics,
  useListStoriesQuery,
  useListTasksQuery,
  useRetrieveClientStatusQuery,
} from '@frontend/web/hooks';
import { Env, createFileRoute, redirectUnauthenticated, useRouter } from '@frontend/web/utils';
import { useMatchRoute } from '@tanstack/react-router';
import { CreateTaskModal } from '../components/DomainSpecific/Tasks/CreateTaskModal';
import { QuackActions } from '../components/QuackActions/QuackActions';
import { CreateTaskProvider } from '../context/CreateTaskContext/CreateTaskProvider';

const App = () => {
  const router = useRouter();
  const matchRoute = useMatchRoute();
  const matchedSettings = !!matchRoute({ fuzzy: true, to: '/app/settings' });
  const matchedPayments = !!matchRoute({ fuzzy: false, to: '/app/settings/payments' });
  const matchedOptionSheets = !!matchRoute({ fuzzy: true, to: '/app/option-sheet/$optionSheetId' });
  const matchedThreads = !!matchRoute({ fuzzy: true, to: '/app/thread' });
  const matchedFeed = !!matchRoute({ fuzzy: true, to: '/app/feed' });
  const { track } = useAnalytics();
  const isOnTablet = useIsOnTablet();
  const isOnMobile = useIsOnMobile();
  const isOnMobileOrTablet = isOnTablet || isOnMobile;
  const shouldMinimize = Boolean(matchedSettings || isOnMobileOrTablet || matchedThreads || matchedFeed);
  const blockUI = !matchedPayments && isMobile;
  const [forcedReviewTasks, { remove, setState: setForcedReviewTasks }] = useListState<Task>();
  const { data: tasksData = [] } = useListTasksQuery();
  const { data: clientStatus } = useRetrieveClientStatusQuery();
  const [hasCheckedForReviews, setHasCheckedForReviews] = useState(false);
  const forcedReview = forcedReviewTasks?.[0];
  const sidebarWidth = shouldMinimize ? 86 : 300;
  const [isReviewModalOpen, { close: closeReviewModal, open: openReviewModal }] = useVisibilityController();
  const { pauseReviewPromptUntil, setPauseReviewPromptUntil } = useTaskSlice();
  const { data: stories } = useListStoriesQuery();

  useEffect(() => {
    if (clientStatus && stories) {
      if (!clientStatus?.onboardingComplete) {
        router.navigate({
          to: '/onboarding',
        });
      } else if (!isClientSubscribed(clientStatus)) {
        if (clientStatus?.membershipStatus === 'last_payment_failed') {
          router.navigate({
            to: '/app/settings/payments',
          });
        } else {
          router.navigate({
            to: '/unsubscribed',
          });
        }
      } else if (stories.length) {
        router.navigate({
          to: '/stories',
        });
      }
    }
  }, [clientStatus, stories]);

  useEffect(() => {
    if (forcedReview) {
      openReviewModal();
    }
  }, [forcedReview]);

  useEffect(() => {
    const shouldPromptReviews = !pauseReviewPromptUntil || dayjs(pauseReviewPromptUntil).isBefore(dayjs());

    if (tasksData.length && !hasCheckedForReviews && shouldPromptReviews) {
      const oneHourLater = dayjs().add(1, 'hour').toISOString();
      setPauseReviewPromptUntil(convertDateLikeToDateTimeISOString(oneHourLater));
      setForcedReviewTasks(findFirstN(tasksData, shouldPromptTaskReview, 3));
      setHasCheckedForReviews(true);
      openReviewModal();
      // If tasksData updates, we don't want to prompt the user again for reviews
    } else if (!shouldPromptReviews && tasksData?.length) {
      setHasCheckedForReviews(true);
    }
  }, [tasksData, hasCheckedForReviews]);

  const onCompleteTaskReview = (taskId: string) => {
    remove((item) => item.id === taskId);
  };

  const onTrackAppStoreButton = (platform: MobilePlatform) => {
    track({
      event: platform === 'android' ? 'androidDownloadLinkSelected' : 'iosDownloadLinkSelected',
      topic: 'onboarding',
    });
  };

  if (blockUI) {
    return (
      <DesktopOnly
        onClickNavigateAccount={() => router.navigate({ to: '/app/settings/payments' })}
        onTrackAppStoreButton={onTrackAppStoreButton}
      />
    );
  } else if (matchedOptionSheets) {
    return (
      <AppShell
        navbar={{
          breakpoint: 'sm',
          width: sidebarWidth,
        }}
        padding="md"
      >
        <YStack className="flex h-screen overflow-y-hidden">
          <Outlet />
        </YStack>
        <EnvironmentDialog apiUrl={Env.VITE_API_BASE_URL} env={Env.VITE_ENV} />
      </AppShell>
    );
  }
  // On mobile devices, we only allow visibility of the settings/payments screen
  else if (isMobile) {
    return (
      <AppShell className="flex flex-col">
        <AppContentWrapper>
          <Outlet />
        </AppContentWrapper>
        <EnvironmentDialog apiUrl={Env.VITE_API_BASE_URL} env={Env.VITE_ENV} />
      </AppShell>
    );
  }
  return (
    <AppShell
      className="flex flex-col"
      navbar={{
        breakpoint: 0,
        width: sidebarWidth,
      }}
    >
      <CreateTaskProvider>
        <QuackActionsProvider>
          {({ actions, isSearchLoading, onStartSearch, resetActions }) => (
            <Fragment>
              <QuackActions
                actionGroups={actions}
                isSearchLoading={isSearchLoading}
                onQueryChange={onStartSearch}
                onResetQuery={resetActions}
              />
              <AppSidebar />
              {forcedReview ? (
                <ReviewTaskModal
                  isOpen={isReviewModalOpen}
                  onClose={closeReviewModal}
                  onComplete={onCompleteTaskReview}
                  redirectToTasksListOnSuccess
                  taskId={forcedReview.id}
                />
              ) : null}
              <CreateTaskModal />
              <AppContentWrapper>
                <Outlet />
              </AppContentWrapper>
            </Fragment>
          )}
        </QuackActionsProvider>
      </CreateTaskProvider>
      <EnvironmentDialog apiUrl={Env.VITE_API_BASE_URL} env={Env.VITE_ENV} />
    </AppShell>
  );
};

export const Route = createFileRoute('/app')({
  beforeLoad: async ({ context, location }) => {
    redirectUnauthenticated({ context, location });
  },
  component: App,
});
