import { useEffect, useState } from 'react';

type UseAnimationProps = {
  duration: number;
};

/**
 * @description Meant to be used with a component under AnimatedViews/, see examples in other places
 */
export const useTimedAnimation = ({ duration }: UseAnimationProps) => {
  const [isAnimationOn, setIsAnimationOn] = useState(false);
  const turnOffAnimation = () => setIsAnimationOn(false);
  const turnOnAnimation = async (callback?: () => void) => {
    setIsAnimationOn(true);
    if (callback) {
      setTimeout(callback, duration);
    }
  };

  useEffect(() => {
    if (isAnimationOn) {
      setTimeout(turnOffAnimation, duration);
    }
  }, [isAnimationOn]);

  return { animationDuration: duration, isAnimationOn, turnOffAnimation, turnOnAnimation };
};
