import { extractReadonlyValues } from '@frontend/duck-tape';
import { validators } from '../utils';
import { makeFormComponent, makeFormHook } from '../utils/factory';
import type { InferFormType, SelectOption } from '../utils/types';

export const NEGATIVE_REVIEW_CANNED_OPTIONS = [
  {
    id: 'technology',
    label: 'Technology issue',
    value: 'Technology issue',
  },
  {
    id: 'taste',
    label: 'Options not my taste',
    value: 'Options not my taste',
  },
  {
    id: 'back&forth',
    label: 'Too much back and forth',
    value: 'Too much back and forth',
  },
  {
    id: 'duckbillCant',
    label: 'Duckbill can’t do this on my behalf',
    value: 'Duckbill can’t do this on my behalf',
  },
  {
    id: 'taskError',
    label: 'Error with my task',
    value: 'Error with my task',
  },
  {
    id: 'other',
    label: 'Other',
    value: 'Other',
  },
] as const satisfies SelectOption<string>[];

export const POSITIVE_REVIEW_CANNED_OPTIONS = [
  {
    id: 'fastResponse',
    label: 'Fast response',
    value: 'Fast response',
  },
  {
    id: 'greatQuality',
    label: 'Great quality',
    value: 'Great quality',
  },
  {
    id: 'multipleOptions',
    label: 'Multiple options',
    value: 'Multiple options',
  },
  {
    id: 'kindAndCaring',
    label: 'Kind and caring',
    value: 'Kind and caring',
  },
  {
    id: 'understanding',
    label: 'Understanding',
    value: 'Understanding',
  },
] as const satisfies SelectOption<string>[];

const negativeCannedResponsesSchema = validators.readonlyStringEnum(
  extractReadonlyValues(NEGATIVE_REVIEW_CANNED_OPTIONS, 'value'),
);

const positiveCannedResponsesSchema = validators.readonlyStringEnum(
  extractReadonlyValues(POSITIVE_REVIEW_CANNED_OPTIONS, 'value'),
);

export type taskReviewFormSchema = InferFormType<typeof taskReviewFormSchema>;

export type RatingValue = 1 | 2 | 3 | 4 | 5;

export const taskReviewFormSchema = validators
  .object({
    description: validators.optionalString(),
    negativeCannedResponses: validators.array(negativeCannedResponsesSchema),
    positiveCannedResponses: validators.array(positiveCannedResponsesSchema),
    rating: validators.fiveStarReview(),
  })
  .refine((data) => !(data.negativeCannedResponses?.includes('Other') && data.description === '' && data.rating < 5), {
    message: 'Please provide more detailed feedback.',
    path: ['description'],
  });

export const taskReviewFormDefaultValues: taskReviewFormSchema = {
  description: '',
  negativeCannedResponses: [],
  positiveCannedResponses: [],
  // @ts-expect-error Tedious types
  rating: undefined,
};

export const useTaskReviewForm = makeFormHook(taskReviewFormSchema, taskReviewFormDefaultValues);

export const TaskReviewForm = makeFormComponent(taskReviewFormSchema, taskReviewFormDefaultValues);
