import { createTag } from '@frontend/api-client/utils';
import type { BaseApi } from '@frontend/api-client/utils/types';
import type { CreateReviewRequest, CreateTaskReview, Task } from '@frontend/api-types';
import { convertObjectKeysCase } from '@frontend/duck-tape';
import type { getTasksApi } from '../tasks/slice';
import { REVIEWS_ENDPOINTS } from './endpoints';

export const getTaskReviewsApi = (baseApi: BaseApi, tasksApi: ReturnType<typeof getTasksApi>) =>
  baseApi.injectEndpoints({
    endpoints: (builder) => ({
      createTaskReview: builder.mutation<CreateTaskReview, CreateReviewRequest>({
        invalidatesTags: (_result, _error, { task: taskId }) => [
          createTag({ id: 'LIST', type: 'Task' }),
          createTag({ id: taskId, prefix: 'taskId', type: 'Task' }),
        ],
        onQueryStarted: async ({ task: taskId }, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled;
            dispatch(
              tasksApi.util.updateQueryData('retrieveTask', taskId, (draft) => ({
                ...draft,
                isReviewSubmitted: true,
                isReviewed: true,
              })),
            );
            dispatch(
              tasksApi.util.updateQueryData('listTasks', undefined, (draft) =>
                draft.reduce((agg: Task[], task) => {
                  const { id } = task;
                  return id === taskId ? [...agg, { ...task, isReviewSubmitted: true }] : [...agg, task];
                }, []),
              ),
            );
          } catch {
            /* If there is an error, don't update the cache */
          }
        },
        query: (payload) => ({
          body: convertObjectKeysCase(payload, 'snakeCase'),
          method: 'POST',
          url: REVIEWS_ENDPOINTS.createReview(),
        }),
        transformResponse: (review: GenericRecord) => convertObjectKeysCase(review, 'camelCase') as CreateTaskReview,
      }),
    }),
    overrideExisting: true,
  });
