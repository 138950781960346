import type { FontSize } from '@frontend/constants';
import type { StrictExtract } from '@frontend/duck-tape';
import { match } from '@frontend/duck-tape';
import { applyClass, concatClasses, safeCss } from '@frontend/web-utils';
import type { TitleProps as MTitleProps } from '@mantine/core';
import { Title as MTitle } from '@mantine/core';
import { getTextAlignClassName, getTextColorClassName, type TextColorVariant } from './Text';

export type TitleProps = Omit<MTitleProps, 'color' | 'order'> & {
  color?: TextColorVariant;
  textAlign?: 'center' | 'end' | 'start';
  type: StrictExtract<FontSize, 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>;
  uppercase?: boolean;
};

export const Title = ({
  className,
  color = 'primary',
  onClick,
  textAlign = 'start',
  type,
  uppercase = false,
  ...props
}: TitleProps) => {
  const baseClassName = safeCss(onClick ? 'cursor-pointer hover-opacity-effect' : '');

  const resolvedClassName = concatClasses(
    baseClassName,
    getTextColorClassName({ color }),
    applyClass(uppercase, 'uppercase'),
    getTextAlignClassName({ textAlign }),
    className,
  );

  return <MTitle className={resolvedClassName} onClick={onClick} order={resolveOrder(type)} {...props} />;
};

const resolveOrder = (type: TitleProps['type']): MTitleProps['order'] =>
  match<TitleProps['type'], MTitleProps['order']>(type)
    .with('h1', () => 1)
    .with('h2', () => 2)
    .with('h3', () => 3)
    .with('h4', () => 4)
    .with('h5', () => 5)
    .with('h6', () => 6)
    .exhaustive();
