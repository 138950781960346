import type { TypedFormDataValue } from '@frontend/api-types';
import { TypedFormData, type LocalFile } from '@frontend/api-types';

// The types in FormData are misleading-- I can't figure out how to overwrite the FormData typings
// so that only append, getAll, getParts, and appendLocalFiles are valid methods
// eslint-disable-next-line fp/no-class
export class WebTypedFormData<T extends Record<string, TypedFormDataValue>> extends TypedFormData<T> {
  /**
   * @description
   * This method should automatically handle (flat) arrays. Ideally we shouldn't even need to support arrays of
   * arbitrary depth because FormData was never intended to be used that way */
  appendLocalFiles<K extends keyof T>(key: K, files: LocalFile[]): void {
    // eslint-disable-next-line fp/no-loops
    for (const file of files) {
      // @ts-expect-error can't figure out type
      // eslint-disable-next-line fp/no-this
      this.append(key, file);
    }
  }
}
