import { getApi } from '@frontend/api-client';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';

export const getBaseApiSlice = <TRootState extends { auth: { token: string } }>(apiUrl: string) =>
  getApi({
    baseQuery: fetchBaseQuery({
      baseUrl: apiUrl,
      prepareHeaders: (headers, { getState }) => {
        const {
          auth: { token },
        } = getState() as TRootState;

        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }

        return headers;
      },
    }),
  });
