import { isLast } from '@frontend/duck-tape';
import { Icon, LinkButton, useScrollTracker } from '@frontend/web-react';
import type { IconName } from '@frontend/web-react/components/Primitives/Icon/helpers';
import type { ClassName } from '@frontend/web-utils';
import { applyClass, concatClasses, safeCss } from '@frontend/web-utils';
import { Tabs } from '@mantine/core';
import { useRef } from 'react';

type TabBarHeaderProps<T extends Tab> = {
  activeTab: T['key'];
  className?: ClassName;
  onSetActiveTab: (tab: T['key']) => void;
  tabs: T[];
};

type Tab = {
  className?: ClassName;
  iconName?: IconName;
  key: string;
  title: string;
};

export const TabBarHeader = <T extends Tab>({ activeTab, className, onSetActiveTab, tabs }: TabBarHeaderProps<T>) => {
  const listContainerRef = useRef<HTMLDivElement>(null);
  const { isAtStart, isHorizontalScrollTerminal, isScrollable } = useScrollTracker(listContainerRef);

  const scrollLeft = () => {
    if (listContainerRef.current) {
      listContainerRef.current.scrollBy({ behavior: 'smooth', left: -300 });
    }
  };

  const scrollRight = () => {
    if (listContainerRef.current) {
      listContainerRef.current.scrollBy({ behavior: 'smooth', left: 300 });
    }
  };

  return (
    <div className="relative">
      <Tabs className={className} onChange={(key) => onSetActiveTab(key as T['key'])} value={activeTab} variant="pills">
        <Tabs.List
          className="flex flex-row overflow-x-auto flex-nowrap no-scroll-bar py-lg justify-between items-center"
          ref={listContainerRef}
        >
          {tabs.map(({ className: tabClassName, iconName, key, title }, index, array) => (
            <LinkButton
              className={concatClasses(
                'py-sm px-xl rounded-sm text-nowrap',
                applyClass(index === 0, safeCss('ml-lg')),
                applyClass(isLast(array, index), safeCss('mr-lg')),
                applyClass(tabClassName !== undefined, tabClassName as ClassName),
              )}
              color={activeTab === key ? 'primary' : 'tertiary'}
              hoverEffect="background"
              key={key}
              label={title}
              leftIconName={iconName}
              onClick={() => onSetActiveTab(key)}
              textClassName={safeCss(activeTab === key ? 'font-semibold' : 'font-normal')}
              type={activeTab === key ? 'h5' : 'p-md'}
            />
          ))}
          {isScrollable && !isHorizontalScrollTerminal ? (
            <Icon
              className="absolute right-lg"
              color="primary"
              enableBackgroundColor
              name="IconArrowRight"
              onClick={scrollRight}
              rounded
              size="sm"
            />
          ) : null}
          {isScrollable && !isAtStart ? (
            <Icon
              className="absolute left-lg"
              color="primary"
              enableBackgroundColor
              name="IconArrowLeft"
              onClick={scrollLeft}
              rounded
              size="sm"
            />
          ) : null}
        </Tabs.List>
      </Tabs>
    </div>
  );
};
