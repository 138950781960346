import { canReviewOptionSheet } from '@frontend/api-client';
import type { OptionSheet, OptionSheetOption, ReviewOptionSheetRequest, TaskStatusType } from '@frontend/api-types';
import { useEffect, useState } from '@frontend/react';
import { Drawer, Icon, Text, TextareaInput, XStack, YStack, type CommonModalProps } from '@frontend/web-react';
import { useAnalytics, useReviewOptionSheetMutation } from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';
import { useNavigate } from '@tanstack/react-router';

export type OptionSheetReviewDrawerProps = CommonModalProps & {
  optionSheet: OptionSheet;
  selectedOptions: OptionSheetOption[];
  taskStatus: TaskStatusType;
};

export const OptionSheetReviewDrawer = ({
  isOpen,
  onClose,
  optionSheet,
  selectedOptions,
  taskStatus,
}: OptionSheetReviewDrawerProps) => {
  const navigate = useNavigate();
  const [reviewFeedback, setReviewFeedback] = useState('');
  const [reviewOptionSheet, { isLoading: isReviewOptionSheetLoading }] = useReviewOptionSheetMutation();
  const { track } = useAnalytics();
  const onClickSendToCopilot = async (body: ReviewOptionSheetRequest) => {
    if (optionSheet?.task) {
      track({ data: { sheetId: optionSheet.id }, event: 'submitSelectedOptions', topic: 'options' });
      handleMutation({
        mutation: async () => reviewOptionSheet(body).unwrap(),
        onSuccess: () => {
          navigate({ params: { taskId: optionSheet?.task }, to: '/app/task/$taskId' });
        },
      });
    }
  };

  useEffect(() => {
    setReviewFeedback(optionSheet.reviewFeedback ?? '');
  }, [optionSheet]);

  return (
    <Drawer
      headerButtonLabel="Send to copilot"
      headerButtonProps={{ isLoading: isReviewOptionSheetLoading }}
      isOpen={isOpen}
      onClickHeaderButton={
        canReviewOptionSheet(taskStatus)
          ? () =>
              onClickSendToCopilot({
                optionSheetId: optionSheet.id,
                reviewFeedback: reviewFeedback,
                selectedOptionIds: selectedOptions.map(({ id }) => id),
                taskId: optionSheet?.task ?? '',
              })
          : undefined
      }
      onClose={onClose}
      position="right"
    >
      <YStack className="border-1 rounded-md border-borderSecondary p-md gap-md">
        <Text type="h4">{selectedOptions.length ? 'You selected' : 'You did not select any option.'}</Text>
        {selectedOptions.length ? (
          selectedOptions.map(({ id: selectedId, title }) => {
            return (
              <XStack className="items-center gap-sm" key={selectedId}>
                <Icon color="primary" name="IconCircleCheck" size="lg" />
                <Text className="flex-1" lineClamp={2} type="h5">
                  {title}
                </Text>
              </XStack>
            );
          })
        ) : (
          <Text color="secondary">{"We're"} sorry that you did not like any option provided.</Text>
        )}
      </YStack>
      <YStack className="gap-sm">
        <Text type="h6">
          {selectedOptions.length
            ? 'Anything we should know about your selections?'
            : 'Any feedback for us on the choices we sent?'}
        </Text>
        <TextareaInput
          label=""
          onChange={(text) => setReviewFeedback(text)}
          placeholder={
            selectedOptions.length
              ? 'How to proceed, any dietary restrictions, if shopping, the colors or material.'
              : 'Your thoughts, preferences, and tastes can help us improve our next steps.'
          }
          value={reviewFeedback}
        />
      </YStack>
    </Drawer>
  );
};
