import { getDedicatedCalendarAccounts } from '@frontend/api-client';
import { useMemo, useState } from '@frontend/react';
import { GoogleCalendarIcon } from '@frontend/web-icons';
import { Button, Card, List, PageContainer, Text, XStack, YStack, showToast } from '@frontend/web-react';
import {
  useDeactivateOAuthAccountMutation,
  useListOAuthAccountsQuery,
  useRetrieveClientQuery,
  useUpdateOAuthCodeMutation,
} from '@frontend/web/hooks';
import { Env, handleMutation } from '@frontend/web/utils';
import { createFileRoute } from '@tanstack/react-router';

export const calendarBlockedPopUpErrorMessage =
  "Oops, it looks like you have popup blockers that prevent us to view the consent screen.\n\nTo resolve this issue, you can usually do this by clicking on the blocker icon in your browser's address bar and allowing popups for our site.";

export const calendarAccessDeniedErrorMessage =
  "We were not able to access your calendar. If you'd like to use this feature, please enable calendar access in the settings. You can do this by going to Settings > Calendar";

export const calendarScopes = [
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/calendar',
];

const Calendar = () => {
  const { data: client } = useRetrieveClientQuery();
  const [deactivateOAuthAccount, { isLoading: isDeactivateOAuthAccountLoading }] = useDeactivateOAuthAccountMutation();
  // We can't update the cache here because api doesn't return anything on update account
  const {
    data: oAuthAccounts,
    isLoading: isListOAuthAccountsLoading,
    refetch: refetchOAuthAccounts,
  } = useListOAuthAccountsQuery();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateOAuthCode, { isLoading: _isUpdateOAuthCodeLoading }] = useUpdateOAuthCodeMutation();
  const [isConnectCalendarLoading, setIsConnectCalendarLoading] = useState(false);
  const dedicatedCalendarAccounts = getDedicatedCalendarAccounts(oAuthAccounts);

  const integratedCalendar = useMemo(
    () => dedicatedCalendarAccounts?.find((o) => o.client === client?.id),
    [dedicatedCalendarAccounts, client],
  );

  const onClickConnectCalendar = async () => {
    setIsConnectCalendarLoading(true);
    const client = google.accounts.oauth2.initCodeClient({
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      callback: async (response: { code?: string; error?: string }) => {
        if (response.error) {
          showToast({ title: calendarAccessDeniedErrorMessage, variant: 'danger' });
        } else if (response.code) {
          await handleMutation({
            mutation: () =>
              updateOAuthCode({
                code: response.code!,
                provider: 'Google',
                purposes: ['calendar'],
                scopes: calendarScopes,
              }).unwrap(),
            onComplete: () => setIsConnectCalendarLoading(false),
            onSuccess: refetchOAuthAccounts,
            successMessage: 'Calendar connected successfully',
          });
        }
      },
      client_id: Env.VITE_GOOGLE_OAUTH_CLIENT_ID as string,
      error_callback: (response: { type?: string }) => {
        setIsConnectCalendarLoading(false);
        if (response.type === 'popup_closed') {
          showToast({ title: calendarAccessDeniedErrorMessage, variant: 'danger' });
        } else if (response.type === 'popup_failed_to_open') {
          showToast({ title: calendarBlockedPopUpErrorMessage, variant: 'danger' });
        }
      },
      scope: calendarScopes.join(' '),
      ux_mode: 'popup',
    });
    client.requestCode();
  };

  const onClickDisconnectCalendar = async () => {
    // We specifically need to remove the non-shared calendar
    if (integratedCalendar) {
      handleMutation({
        mutation: () => deactivateOAuthAccount({ id: integratedCalendar.id }).unwrap(),
        onSuccess: refetchOAuthAccounts,
        successMessage: 'Calendar disconnected successfully',
      });
    }
  };

  return (
    <PageContainer headerTitle="Calendar" isLoading={isListOAuthAccountsLoading}>
      <YStack className="grid grid-cols-5">
        <Card className="col-span-5 lg:col-span-4 xl:col-span-2 bg-white" withBorder>
          <XStack gapX="md">
            <YStack className="p-sm">
              <GoogleCalendarIcon />
            </YStack>
            <YStack>
              <Text type="h5">Google Calendar</Text>
              <List className="list-disc text-textTertiary px-md">
                <List.Item>
                  <Text color="tertiary" type="p-sm">
                    Avoid the dreaded scheduling back-and-forth by letting us see your availability and add events on
                    your behalf
                  </Text>
                </List.Item>
                <List.Item>
                  <Text color="tertiary" type="p-sm">
                    {"We'll use your calendar to identify opportunities to better support you"}
                  </Text>
                </List.Item>
              </List>
            </YStack>
          </XStack>
          <XStack>
            <Button
              isLoading={integratedCalendar ? isDeactivateOAuthAccountLoading : isConnectCalendarLoading}
              label={integratedCalendar ? 'Disconnect' : 'Connect'}
              onClick={integratedCalendar ? onClickDisconnectCalendar : onClickConnectCalendar}
              size="sm"
            />
          </XStack>
        </Card>
      </YStack>
    </PageContainer>
  );
};

export const Route = createFileRoute('/app/settings/calendar')({
  component: Calendar,
});
