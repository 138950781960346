import { validators } from '../utils';
import { makeFormComponent, makeFormHook } from '../utils/factory';
import type { InferFormType } from '../utils/types';

const forgotPasswordFormSchema = validators.object({
  email: validators.email(),
});

export type ForgotPasswordFormSchema = InferFormType<typeof forgotPasswordFormSchema>;

const forgotPasswordFormDefaultValues: ForgotPasswordFormSchema = {
  email: '',
};

export const useForgotPasswordForm = makeFormHook(forgotPasswordFormSchema, forgotPasswordFormDefaultValues);

export const ForgotPasswordForm = makeFormComponent(forgotPasswordFormSchema, forgotPasswordFormDefaultValues);
