import { isFunction, omit } from '@frontend/duck-tape';
import type { FormikProps } from 'formik';
import { Formik, useFormik } from 'formik';
import type { ReactNode } from 'react';
import type { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { flattenErrors } from './errors';
import type { FormComponentProps, FormHookProps, InferFormType } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const makeFormHook = <TSchema extends z.ZodType<any, any>>(
  schema: TSchema,
  // If hook consumer wants to provide their own initial values, they can do so
  factoryInitialValues: InferFormType<TSchema>,
) => {
  return (props: FormHookProps<InferFormType<TSchema>>) => {
    // validateOnChange is worse performance for better ux
    const {
      displayErrorsOnlyWhenTouched = true,
      initialValues = factoryInitialValues,
      validateOnBlur = true,
      validateOnChange = true,
      validateOnMount = true,
      ...restProps
    } = props || {};

    const { errors, touched, ...form } = useFormik<InferFormType<TSchema>>({
      initialValues,
      validateOnBlur,
      validateOnChange,
      validateOnMount,
      validationSchema: toFormikValidationSchema(schema),
      ...restProps,
    });

    const flattenedErrors = flattenErrors(errors);

    /**
     * `getControl` is a convenience function to get the error, onBlur, onChange, and value for a given form control.
     * You can spread it into your input components to make your form more readable like so:
     * ```tsx
     * <TextInput {...getControl('email')} label="Email" />
     * ```
     */
    const getControl = <T extends keyof InferFormType<TSchema>, OmitKey extends keyof typeof control = never>(
      key: T,
      omitFields: OmitKey[] = [],
    ) => {
      const control = {
        error: displayErrorsOnlyWhenTouched && !touched?.[key] ? undefined : flattenedErrors[key],
        onBlur: form.handleBlur(key),
        onChange: (val: InferFormType<TSchema>[T]) => form.setFieldValue(key, val),
        value: form.values[key],
      };
      return omit(control, omitFields);
    };

    return { ...form, errors: flattenedErrors, getControl, initialValues, touched };
  };
};
/** If you use the form component this returns, you don't need to wrap it in another <form> */

// For some reason eslint autoformatting is making it impossible to add the eslint-disable
// comment, so I'm aliasing here
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyAlias = any;

export const makeFormComponent =
  <TSchema extends z.ZodType<AnyAlias, AnyAlias>>(
    schema: TSchema,
    // If hook consumer wants to provide their own initial values, they can do so
    factoryInitialValues: InferFormType<TSchema>,
  ) =>
  (
    props: FormComponentProps<TSchema> & {
      children: (
        props: FormikProps<InferFormType<TSchema>> & {
          errors: Record<keyof InferFormType<TSchema>, MaybeUndefined<string>>;
          getControl: <T extends keyof InferFormType<TSchema>>(
            key: T,
          ) => {
            error: MaybeUndefined<string>;
            onBlur: () => void;
            onChange: (val: InferFormType<TSchema>[T]) => void;
            value: InferFormType<TSchema>[T];
          };
        },
      ) => ReactNode;
      isMobile?: boolean;
    },
  ) => {
    const {
      children,
      initialValues = factoryInitialValues,
      isMobile,
      validateOnChange = true,
      ...restProps
    } = props || {};

    return (
      <Formik
        initialValues={initialValues}
        validateOnChange={validateOnChange}
        validationSchema={toFormikValidationSchema(schema)}
        {...restProps}
      >
        {({ errors, handleBlur, handleSubmit, setFieldValue, values, ...formikProps }) => {
          const flattenedErrors = flattenErrors(errors);

          /**
           * `getControl` is a convenience function to get the error, onBlur, onChange, and value for a given form control.
           * You can spread it into your input components to make your form more readable like so:
           * ```tsx
           * <TextInput {...getControl('email')} label="Email" />
           * ```
           */
          const getControl = <T extends keyof InferFormType<TSchema>, OmitKey extends keyof typeof control = never>(
            key: T,
            omitFields: OmitKey[] = [],
          ) => {
            const control = {
              error: flattenedErrors[key],
              onBlur: handleBlur(key),
              onChange: (val: InferFormType<TSchema>[T]) => setFieldValue(key as string, val),
              value: values[key],
            };
            return omit(control, omitFields);
          };

          const props = { ...formikProps, errors: flattenErrors, getControl };

          const RenderedChildren = isFunction(children)
            ? children({
                ...props,
                // @ts-expect-error Tedious types
                errors: flattenedErrors,
                // @ts-expect-error Tedious types
                getControl,
                handleBlur,
                handleSubmit,
                setFieldValue,
                values,
              })
            : children;

          return isMobile ? RenderedChildren : <form onSubmit={handleSubmit}>{RenderedChildren}</form>;
        }}
      </Formik>
    );
  };
