import { type SetOptional } from '@frontend/duck-tape';
import { safeCss } from '@frontend/web-utils';
import type { InputBaseProps as MInputBaseProps } from '@mantine/core';
import { InputBase } from '@mantine/core';
import * as LPN from 'libphonenumber-js';
import { IMaskInput } from 'react-imask';
import type { InputLabelProps } from '../../InputLabel';
import { InputLabel } from '../../InputLabel';
import type { MakeInputProps } from '../types';

export type PhoneNumberInputProps = MakeInputProps<string, MInputBaseProps, 'labelProps'> & {
  labelProps?: SetOptional<InputLabelProps, 'label'>;
  showOptionalText?: boolean;
};

export const PhoneNumberInput = ({
  label = 'Phone number',
  labelProps,
  onChange,
  showOptionalText,
  ...props
}: PhoneNumberInputProps) => {
  const classNames: MInputBaseProps['classNames'] = {
    input: safeCss('px-md py-lg text-p-md h-[56px] rounded-sm focus-within:border-textPrimary'),
    root: safeCss('gap-y-sm flex flex-col flex-1'),
  };

  return (
    <InputBase
      autoComplete="tel"
      classNames={classNames}
      component={IMaskInput}
      inputMode="numeric"
      label={label ? <InputLabel label={label} showOptionalText={showOptionalText} {...labelProps} /> : null}
      mask="+1 000-000-0000"
      // Using `onAccept` instead of `onChange` because `react-imask` reliably triggers `onAccept` for both typing
      // and pasting, ensuring the phone number handling logic always runs correctly.
      onAccept={(value) => {
        try {
          const phoneNumber = LPN.parsePhoneNumber(value, 'US');
          onChange(phoneNumber.format('E.164'));
        } catch (error) {
          onChange(value);
        }
      }}
      placeholder="+1 000-000-0000"
      {...props}
    />
  );
};
