import { isClientSubscribed } from '@frontend/api-client';
import { contactUrl } from '@frontend/constants';
import { Anchor, AppShell, LoadingSpinnerPanel, Text, Title, YStack } from '@frontend/web-react';
import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';
import { AppContentWrapper, UnauthenticatedAppHeader } from '../components';
import { useRetrieveClientStatusQuery } from '../hooks';
import { redirectUnauthenticated } from '../utils';

const Unsubscribed = () => {
  const { data: clientStatus, isLoading: isClientStatusLoading } = useRetrieveClientStatusQuery();
  const navigate = Route.useNavigate();
  useEffect(() => {
    if (clientStatus && isClientSubscribed(clientStatus)) {
      navigate({ to: '/app' });
      return;
    }
  }, [clientStatus]);
  return (
    <AppShell>
      <UnauthenticatedAppHeader />
      <AppContentWrapper className="justify-center items-center flex flex-col place-items-center">
        {isClientStatusLoading ? (
          <LoadingSpinnerPanel fullScreen />
        ) : (
          <YStack alignItems="center" gapY="md">
            <Title type="h1">{"Your subscription doesn't seem to be active."}</Title>
            <Text type="p-md">
              If you need help or want to resubscribe, please{' '}
              <Anchor href={contactUrl} type="h5" withUnderline>
                contact support
              </Anchor>
              .
            </Text>
          </YStack>
        )}
      </AppContentWrapper>
    </AppShell>
  );
};

export const Route = createFileRoute('/unsubscribed')({
  beforeLoad: async ({ context, location }) => {
    redirectUnauthenticated({ context, location });
  },
  component: Unsubscribed,
});
