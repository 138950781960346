import { canTaskBeReviewed, canTaskBeSkipped, taskStatuses } from '@frontend/api-client';
import type { Task } from '@frontend/api-types';
import { dateFormatters } from '@frontend/duck-tape';
import { Icon, Indicator, LinkButton, Text, XStack, YStack } from '@frontend/web-react';
import type { ClassName } from '@frontend/web-utils';
import { concatClasses } from '@frontend/web-utils';
import { NavLinkWrapper } from '@frontend/web/components/';
import { useAnalytics } from '@frontend/web/hooks';
import { appendRouteHistoryStack } from '@frontend/web/router';
import type { TaskModalType } from '@frontend/web/routes/app/task';

export type TaskCardProps = {
  className?: ClassName;
  onOpenModal: (taskId: string, type: TaskModalType) => void;
  task: Task;
};
export const TaskCard = ({ className, onOpenModal, task }: TaskCardProps) => {
  const { track } = useAnalytics();
  const isReviewable = canTaskBeReviewed(task);
  const isSkippable = canTaskBeSkipped(task);
  const isSnoozed = taskStatuses.snoozed.includes(task.status);

  const onClickLeaveReviewButton = () => {
    track({ data: { taskId: task.id }, event: 'reviewTaskButtonSelected', topic: 'tasks' });
    onOpenModal(task.id, 'review');
  };
  const onClickUnsnoozeButton = () => {
    track({ data: { taskId: task.id }, event: 'unsnoozeTaskButtonInCardSelected', topic: 'tasks' });
    onOpenModal(task.id, 'unsnooze');
  };
  const onClickSkipButton = () => {
    track({ data: { taskId: task.id }, event: 'skipTaskButtonInCardSelected', topic: 'tasks' });
    onOpenModal(task.id, 'skip');
  };

  return (
    <NavLinkWrapper
      className={concatClasses('flex flex-row gap-x-sm items-center px-sm py-md hover-background-effect', className)}
      params={{ taskId: task.id }}
      state={{ routeHistoryStack: appendRouteHistoryStack('/app') }}
      to={'/app/task/$taskId'}
    >
      <Indicator isVisible={task.isUnread} />
      <YStack className="flex-1">
        <YStack className="flex-1" gapY="sm">
          <XStack alignItems="center" gapX="sm">
            {task.recurrenceDate ? <Icon color="secondary" name="IconRepeat" /> : null}
            <Text type="h6">{task.name}</Text>
          </XStack>
          <XStack justifyContent="spaceBetween">
            <Text color="tertiary" type="caption">
              Last update
            </Text>
            <Text color="tertiary" type="caption">
              {dateFormatters.message(task.updatedAt)}
            </Text>
          </XStack>
          <Text type="p-sm">{task.statusSummary}</Text>
        </YStack>
        <XStack justifyContent="end">
          {isSkippable ? (
            <LinkButton color="danger" label="Skip" leftIconName="IconX" onClick={onClickSkipButton} />
          ) : null}
          {task.snoozeDate ? (
            <XStack alignItems="center">
              <Icon color="danger" name="IconAlarmSnooze" />
              <Text color="danger">{dateFormatters['MM/DD/YYYY'](task.snoozeDate)}</Text>
            </XStack>
          ) : null}
          {isSnoozed || isReviewable ? (
            <YStack>
              {isReviewable ? (
                <LinkButton
                  label="Leave a review"
                  leftIconName="IconStar"
                  onClick={onClickLeaveReviewButton}
                  type="h6"
                />
              ) : null}
              {isSnoozed ? (
                <LinkButton label="Unsnooze" leftIconName="IconAlarmOff" onClick={onClickUnsnoozeButton} type="h6" />
              ) : null}
            </YStack>
          ) : null}
        </XStack>
      </YStack>
    </NavLinkWrapper>
  );
};
