import { calculateDefaultRecurrenceBuffer } from '@frontend/api-client';
import type { RecurrenceInterval } from '@frontend/api-types';
import { useEffect, useState } from '@frontend/react';
import { Icon, NumberInput, SelectInput, Text, XStack, YStack, useShortcut } from '@frontend/web-react';
import { RecurrenceBufferInputBanner } from '@frontend/web/components';
import { useCreateTaskContext } from '@frontend/web/hooks/context/useCreateTaskContext';

type RecurrenceIntervalOption = {
  label: string;
  value: RecurrenceInterval;
};

const INTERVAL_OPTIONS: RecurrenceIntervalOption[] = [
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
  { label: 'Year', value: 'year' },
];

export const Interval = () => {
  const { createTaskState, decrementStep, incrementStep, isOnLastStep, isSubmitting, updateTaskData } =
    useCreateTaskContext();
  const { buffer, interval, intervalCount } = createTaskState.taskData;
  const [isEditingBuffer, setIsEditingBuffer] = useState(false);
  const [isStepComplete, setIsStepComplete] = useState(
    !!intervalCount && intervalCount > 0 && !!buffer && !isEditingBuffer,
  );
  const isSubmitDisabled = !isStepComplete;

  useEffect(() => {
    setIsStepComplete(!!intervalCount && intervalCount > 0 && !!buffer && interval && !isEditingBuffer);
  }, [interval, intervalCount, buffer, isEditingBuffer]);

  useShortcut({
    Enter: {
      isDisabled: isSubmitDisabled,
      onSubmit: incrementStep,
    },
  });

  return (
    <YStack gapY="md">
      <XStack>
        {createTaskState.currentStep !== createTaskState.steps[0] ? (
          <Icon name="IconArrowLeft" onClick={decrementStep} size={18} />
        ) : null}
      </XStack>
      <YStack gapY="xs">
        <Text type="captionBold">Repeat Every</Text>
        <YStack className="gap-x-md grid lg:grid-cols-6 grid-cols-2">
          <NumberInput
            className="col-span-1"
            max={365}
            min={1}
            onChange={(intervalCount) =>
              updateTaskData({
                buffer: calculateDefaultRecurrenceBuffer(interval, parseInt('' + intervalCount)),
                intervalCount: parseInt('' + intervalCount),
              })
            }
            value={intervalCount}
          />
          <SelectInput
            className="lg:col-span-5 col-span-1"
            name="interval"
            onChange={(interval) => {
              updateTaskData({ buffer: calculateDefaultRecurrenceBuffer(interval, intervalCount), interval });
            }}
            options={INTERVAL_OPTIONS}
            value={interval}
          />
        </YStack>
      </YStack>
      <RecurrenceBufferInputBanner
        buffer={buffer}
        isEditing={isEditingBuffer}
        setBuffer={(buffer) => updateTaskData({ buffer: parseInt('' + buffer) })}
        setIsEditing={setIsEditingBuffer}
      />
      <XStack justifyContent="end">
        <Icon
          color="primary"
          disabled={isSubmitDisabled}
          enableBackgroundColor
          isLoading={isSubmitting}
          name={isOnLastStep ? 'IconSend' : 'IconArrowRight'}
          onClick={incrementStep}
          rounded
          size="lg"
        />
      </XStack>
    </YStack>
  );
};
