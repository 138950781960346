import { contactUrl } from '@frontend/constants';
import type { CommonModalProps } from '@frontend/web-react';
import { Anchor, ConfirmationModal, Text } from '@frontend/web-react';

export const ContactSupportModal = ({ isOpen, onClose }: CommonModalProps) => {
  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClickSubmit={onClose}
      onClose={onClose}
      submitLabel="Got it"
      subtitle={
        <Text className="break-all" type="p-sm">
          To contact support, send an email to{' '}
          <Anchor href={contactUrl} type="p-sm" withUnderline>
            support@getduckbill.com
          </Anchor>
        </Text>
      }
      title="Contact Support"
    />
  );
};
