import { evaluateClientQuota, getQuotaContent, skipQuotaStep } from '@frontend/api-client';
import {
  BeforeMigrationDateTaskQuota,
  DraftsIllustration,
  IndividualTaskQuota,
} from '@frontend/assets/images/imageKit';
import { colors } from '@frontend/constants';
import { useEffect, useFeatureIsOn, useFeatureValue, useMemo, useTaskSlice } from '@frontend/react';
import { UnderlineVector } from '@frontend/web-icons';
import { Icon, ImageBackground, LinkButton, Text, XStack, YStack } from '@frontend/web-react';
import { applyClass, concatClasses, safeCss } from '@frontend/web-utils';
import { useCreateTaskContext, useRetrieveClientQuery, useRetrieveClientStatusQuery } from '@frontend/web/hooks';

export const Quota = () => {
  const { enabled: isThrottlingTasksEnabled } = useFeatureIsOn('is_throttling_tasks_enabled');
  const { value: throttlingTaskMigrationDate } = useFeatureValue('throttling_tasks_migration_date');
  const { data: clientStatus, isFetching: isRetrieveClientFetching } = useRetrieveClientStatusQuery();
  const { data: client } = useRetrieveClientQuery();
  const { createTaskState, decrementStep, incrementStep } = useCreateTaskContext();
  const { hideIndividualTaskQuotaStep, setHideIndividualTaskQuotaStep } = useTaskSlice();

  const isBeforeMigrationDate = throttlingTaskMigrationDate
    ? new Date() < new Date(throttlingTaskMigrationDate as number)
    : false;
  const clientQuota = clientStatus && evaluateClientQuota(clientStatus);

  const handleQuota = () => {
    if (clientStatus && skipQuotaStep(clientStatus, isThrottlingTasksEnabled, hideIndividualTaskQuotaStep)) {
      incrementStep();
    }
  };

  useEffect(() => {
    if (isThrottlingTasksEnabled && !createTaskState.isRecurrence && !isRetrieveClientFetching) {
      handleQuota();
    }
  }, [clientStatus, isRetrieveClientFetching]);

  useEffect(() => {
    if (!isThrottlingTasksEnabled || createTaskState.isRecurrence) {
      incrementStep();
    }
  }, []);

  const content = useMemo(
    () =>
      clientStatus && client?.plan && throttlingTaskMigrationDate
        ? getQuotaContent(clientStatus, new Date(throttlingTaskMigrationDate as number), client?.plan?.title)
        : null,
    [clientStatus, throttlingTaskMigrationDate, client?.plan],
  );

  const handleHideIndividualTaskQuotaStep = () => {
    setHideIndividualTaskQuotaStep(true);
    incrementStep();
  };

  if (isRetrieveClientFetching) {
    return null;
  }

  const getTitleWithUnderlinedText = (title: string, split: string) => {
    const splitTitle = title.split(split);
    return (
      <Text type="h5">
        {splitTitle[0]}
        <Text className="inline-flex items-center" type="h5">
          {/* Remove the added <underline-marker> hack that made the highlight unique */}
          <Text type="h5">{split.replace('<underline-marker>', '')}</Text>
          <UnderlineVector
            // eslint-disable-next-line tailwindcss/no-arbitrary-value
            className="absolute mt-[32px] ml-[-12px]"
            color={isBeforeMigrationDate && !clientQuota?.isThrottled ? colors.rose['300'] : colors.violet['300']}
          />
        </Text>
        {splitTitle[1]}
      </Text>
    );
  };

  const formatDescription = (description: string, boldHighlight: string) => {
    const parts = description.split(boldHighlight);
    return (
      <Text color="tertiary" type="p-sm">
        {parts[0]}
        <Text className="inline" type="h6">
          {boldHighlight}
        </Text>
        {parts[1]}
      </Text>
    );
  };

  return (
    <YStack gap="md">
      <XStack justifyContent="spaceBetween">
        <XStack>
          {createTaskState.currentStep !== createTaskState.steps[0] ? (
            <Icon name="IconArrowLeft" onClick={decrementStep} size={18} />
          ) : null}
        </XStack>
        {clientQuota?.exceedsPlanButWithIndividualLimit && !isBeforeMigrationDate ? (
          <LinkButton color="tertiary" label="Don't show this again" onClick={handleHideIndividualTaskQuotaStep} />
        ) : null}
      </XStack>
      <XStack className="px-xl gap-lg items-center">
        <YStack className="flex-1" gap="sm">
          {getTitleWithUnderlinedText(content?.title as string, content?.titleHighlight as string)}
          {content?.boldHighlight ? (
            formatDescription(content.description, content.boldHighlight)
          ) : (
            <Text color="tertiary" type="p-sm">
              {content?.description}
            </Text>
          )}
        </YStack>
        <YStack className="flex-1">
          {clientQuota?.exceedsPlanButWithIndividualLimit ? (
            <ImageBackground
              className={concatClasses(safeCss('h-[132px]'), applyClass(isBeforeMigrationDate, 'h-[158px]'))}
              radius="sm"
              source={isBeforeMigrationDate ? BeforeMigrationDateTaskQuota : IndividualTaskQuota}
            />
          ) : null}
          {clientQuota?.exceedsPlanWithoutIndividualLimit || clientQuota?.exceedsBothPlanAndIndividualLimit ? (
            // eslint-disable-next-line tailwindcss/no-arbitrary-value
            <ImageBackground className="h-[158px]" radius="sm" source={DraftsIllustration} />
          ) : null}
        </YStack>
      </XStack>
      <YStack gap="xs">
        <XStack justifyContent="end">
          <Icon
            color="primary"
            enableBackgroundColor
            isLoading={isRetrieveClientFetching}
            name="IconArrowRight"
            onClick={incrementStep}
            radius="lg"
          />
        </XStack>
      </YStack>
    </YStack>
  );
};
