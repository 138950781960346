import type { Spacing } from '@frontend/constants';
import { match } from '@frontend/duck-tape';
import type { ReactNode } from '@frontend/react';
import { Header, ScrollView } from '@frontend/web-react';
import type { ClassName } from '@frontend/web-utils';
import { concatClasses, safeCss } from '@frontend/web-utils';
import { SkeletonList } from '../Loading/SkeletonList';
import type { YStackProps } from './YStack';
import { YStack } from './YStack';

export type PageContainerProps = YStackProps & {
  HeaderComponent?: ReactNode;
  LoadingComponent?: ReactNode;
  contentContainerClassName?: ClassName;
  countSkeletons?: number;
  headerTitle?: string;
  isLoading?: boolean;
  isScrollable?: boolean;
  padding?: Spacing;
};
// When we eventually make a FlatList for web component we can remove the UI/logic related to isLoading
export const PageContainer = ({
  className,
  contentContainerClassName,
  isLoading = false,
  padding = 'lg',
  children,
  headerTitle,
  countSkeletons = 6,
  isScrollable = true,
  LoadingComponent = <SkeletonList className="pt-none" countSkeletons={countSkeletons} />,
  HeaderComponent = headerTitle ? <Header title={headerTitle} titleSize="h4" /> : null,
  ...props
}: PageContainerProps) => {
  const paddingClassName = getPaddingClassName(padding);
  return (
    <YStack className={concatClasses('size-full flex-1 overflow-clip', className)} {...props}>
      {HeaderComponent}
      <YStack
        className={concatClasses(
          'flex-1 size-full max-h-screen',
          isLoading && padding === 'none' ? 'px-lg' : paddingClassName,
        )}
        {...props}
      >
        {isLoading ? (
          LoadingComponent
        ) : isScrollable ? (
          <ScrollView className={contentContainerClassName}>{children}</ScrollView>
        ) : (
          children
        )}
      </YStack>
    </YStack>
  );
};

const getPaddingClassName = (padding: Spacing) =>
  match(padding)
    .with('none', () => safeCss('p-none'))
    .with('xs', () => safeCss('px-xs'))
    .with('sm', () => safeCss('px-sm'))
    .with('md', () => safeCss('px-md'))
    .with('lg', () => safeCss('px-lg'))
    .with('xl', () => safeCss('px-xl'))
    .with('xxl', () => safeCss('px-xxl'))
    .with('xxxl', () => safeCss('px-xxxl'))
    .with('1', () => safeCss('px-1'))
    .exhaustive();
