import { P, match } from '@frontend/duck-tape';
import { safeCss } from '@frontend/web-utils';

export const ASPECT_RATIOS = ['3/4', '16/9', '4/3'] as const;

export type AspectRatios = (typeof ASPECT_RATIOS)[number];

export const getAspectRatio = (aspectRatio: MaybeNil<AspectRatios>) =>
  match(aspectRatio)
    .with('3/4', () => 3 / 4)
    .with('16/9', () => 16 / 9)
    .with('4/3', () => 4 / 3)
    .with(P.nullish, () => undefined)
    .exhaustive();

export const getAspectRatioClassName = (aspectRatio: MaybeNil<AspectRatios>) =>
  match(aspectRatio)
    .with('3/4', () => safeCss('aspect-4/3'))
    .with('16/9', () => safeCss('aspect-16/9'))
    .with('4/3', () => safeCss('aspect-3/4'))
    .with(P.nullish, () => undefined)
    .exhaustive();
