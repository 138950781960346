import type { CommonSVGProps } from '../internal';

export const AppleLogo = (props: Pick<CommonSVGProps, 'height' | 'width'>) => (
  <svg fill="none" height={16} width={16} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M13.098 5.454c-.093.072-1.731.996-1.731 3.048 0 2.375 2.084 3.215 2.147 3.236-.01.05-.331 1.15-1.1 2.27-.684.986-1.4 1.97-2.487 1.97-1.088 0-1.368-.632-2.624-.632-1.224 0-1.66.652-2.655.652-.995 0-1.69-.912-2.488-2.032C1.235 12.651.488 10.608.488 8.67c0-3.11 2.023-4.76 4.013-4.76 1.058 0 1.94.694 2.603.694.632 0 1.618-.736 2.821-.736.456 0 2.095.042 3.173 1.587ZM9.354 2.55c.497-.59.85-1.41.85-2.228 0-.114-.01-.23-.03-.322-.81.03-1.774.54-2.355 1.213-.456.518-.881 1.337-.881 2.168 0 .125.02.25.03.29.051.009.135.02.218.02.726 0 1.64-.486 2.168-1.14Z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M.488 0h13.024v16H.488z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
