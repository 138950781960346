import type { Client } from '@frontend/api-types';
import { convertObjectKeysCase } from '@frontend/duck-tape';
import type { UserTraits } from '@segment/analytics-next';
import { AnalyticsBrowser } from '@segment/analytics-next';

// eslint-disable-next-line fp/no-let
let segment: MaybeUndefined<ReturnType<typeof AnalyticsBrowser.load>>;

export const initSegment = ({ writeKey }: { writeKey: string }) => {
  if (!segment) {
    // .load() should only be called once, according to their docs: https://github.com/segmentio/segment-next/tree/master/packages/browser#readme:~:text=%E2%9A%A0%EF%B8%8F,called%20once.
    // eslint-disable-next-line fp/no-mutation
    segment = AnalyticsBrowser.load({ writeKey });
  } else {
    // eslint-disable-next-line no-console
    console.warn(
      'Running initSegment a second time, which should not happen. To get the existing instance, use getSegment instead.',
    );
  }
  return segment;
};

export const getSegment = (params?: { required: boolean }): NotNil<typeof segment> => {
  if (!segment && params?.required) {
    // eslint-disable-next-line no-console
    console.warn('Segment has not been initialized yet. Call initSegment() first.');
  }
  // @ts-expect-error We want consumers of this function to always assume it's not undefined
  return segment;
};

export const identifySegment = ({ client, env }: { client: Client; env: EnvType }) => {
  const { email, firstName, id, lastName } = client;
  const traits = convertObjectKeysCase({ email, firstName, lastName }, 'snakeCase') as UserTraits;
  if (env === 'local') {
    // eslint-disable-next-line no-console
    console.info('Mock Segment identify:', { id, traits });
    return;
  }
  getSegment().identify(id, traits);
  return;
};

export const deidentifySegment = () => {
  getSegment().reset();
  return;
};
