import { formatEndpoint } from '@frontend/duck-tape';

const namespace = 'tasks/draft';

const DRAFT_TASKS_ENDPOINTS = {
  convertDraftTaskToTask: (id: string) => formatEndpoint(`${id}/accept`, namespace),
  listDraftTasks: () => formatEndpoint('', namespace),
  retrieveDraftTask: (id: string) => formatEndpoint(`${id}`, namespace),
};

export default DRAFT_TASKS_ENDPOINTS;
