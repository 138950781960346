import { validateEnv } from '@frontend/duck-tape';

/**
 * Anything prefixed with VITE_ in your .env files will be available here.
 * When adding a new environment variable, do the following steps:
 * 1. Add it the .env.[environment] file, in the format `VITE_[NAME]=[VALUE]`
 * 2. Add it to the `ImportMetaEnv` interface in this app's vite-env.d.ts
 * 3. Add it to the `Env` type below
 *
 * If the app crashes, it's because you're missing a required environment variable in your .env[environment] file. Check the console to see which one.
 */
export const Env: StrictImportMetaEnv = {
  BASE_URL: import.meta.env.BASE_URL,
  DEV: import.meta.env.DEV,
  MODE: import.meta.env.MODE,
  PROD: import.meta.env.PROD,
  SSR: import.meta.env.SSR,
  VITE_API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
  VITE_CANCELLATION_FORM_ID: import.meta.env.VITE_CANCELLATION_FORM_ID,
  VITE_CUSTOMER_IO_SITE_ID: import.meta.env.VITE_CUSTOMER_IO_SITE_ID,
  VITE_DUCKBILL_DIRECTORY_URL: import.meta.env.VITE_DUCKBILL_DIRECTORY_URL,
  VITE_ENV: import.meta.env.VITE_ENV,
  VITE_GOOGLE_API_KEY: import.meta.env.VITE_GOOGLE_API_KEY,
  VITE_GOOGLE_OAUTH_CLIENT_ID: import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID,
  VITE_GOOGLE_TAG_ID: import.meta.env.VITE_GOOGLE_TAG_ID,
  VITE_GROWTHBOOK_CLIENT_KEY: import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY,
  VITE_SEGMENT_WRITE_KEY: import.meta.env.VITE_SEGMENT_WRITE_KEY,
  VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  VITE_STRIPE_PUBLIC_KEY: import.meta.env.VITE_STRIPE_PUBLIC_KEY,
};

validateEnv(Env, { strict: true });
