import {
  canTaskBeCompleted,
  canTaskBeSnoozed,
  evaluateClientQuota,
  shouldPromptTaskReview,
} from '@frontend/api-client';
import type { CreateTaskCommentFormSchema } from '@frontend/forms';
import { useCreateTaskCommentForm } from '@frontend/forms';
import { useFeatureIsOn, useState, useTimedAnimation, useVisibilityController } from '@frontend/react';
import { Header, LoadingSpinnerPanel, PageContainer, Tag, useScrollView, useViewportSize } from '@frontend/web-react';
import { WebTypedFormData } from '@frontend/web-utils';
import {
  CompleteTaskModal,
  ReviewTaskModal,
  SnoozeTaskModal,
  TaskChatFooter,
  UnsnoozeTaskModal,
} from '@frontend/web/components';
import { TaskChatMessages } from '@frontend/web/components/DomainSpecific/Tasks/Chat/TaskChatMessages';
import { TaskDetailModal } from '@frontend/web/components/DomainSpecific/Tasks/TaskDetailModal';
import { ResumeDraftTasksModal } from '@frontend/web/components/DomainSpecific/Tasks/TaskDetailModal/ResumeDraftTasksModal';
import {
  useAnalytics,
  useCreateCommentMutation,
  useListDraftTasksQuery,
  useRetrieveClientQuery,
  useRetrieveClientStatusQuery,
  useRetrieveTaskQuery,
} from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';
import { createFileRoute, useNavigate, useRouter } from '@tanstack/react-router';
import Confetti from 'react-confetti';

const Task = () => {
  const navigate = useNavigate();
  const { taskId } = Route.useParams();
  const { isAnimationOn, turnOnAnimation } = useTimedAnimation({ duration: 5000 });
  const { height, width } = useViewportSize();
  const { track } = useAnalytics();
  const { data: client } = useRetrieveClientQuery();
  const { data: clientStatus } = useRetrieveClientStatusQuery();
  const { data: task, refetch: refetchTask } = useRetrieveTaskQuery(taskId);
  const { data: drafts } = useListDraftTasksQuery();
  const { enabled: isThrottlingEnabled } = useFeatureIsOn('is_throttling_tasks_enabled');
  const { scrollRef, scrollToBottom } = useScrollView({ onRenderScroll: 'bottom' });
  const [isSnoozeTaskModalOpen, { close: closeSnoozeTaskModal, open: openSnoozeTaskModal }] = useVisibilityController();
  const [isReviewTaskModalOpen, { close: closeReviewTaskModal, open: openReviewTaskModal }] = useVisibilityController();
  const [isUnsnoozeTaskModalOpen, { close: closeUnsnoozeTaskModal, open: openUnsnoozeTaskModal }] =
    useVisibilityController();
  const [isCompleteTaskModalOpen, { close: closeCompleteTaskModalOpen, open: openCompleteTaskModal }] =
    useVisibilityController();
  const [isTaskDetailModalOpen, { close: closeTaskDetailModal, open: openTaskDetailModal }] = useVisibilityController();
  const [isResumeDraftTasksModalOpen, { close: closeResumeDraftTasksModal, open: openResumeDraftTasksModal }] =
    useVisibilityController();
  const [createComment, { isLoading: isCreateCommentLoading }] = useCreateCommentMutation();
  const router = useRouter();
  // If user completes the task here, it's required. If they opt-in to review by pressing the banner, it's optional
  const [shouldForceReview, setShouldForceReview] = useState(false);

  const isDetailTagVisible =
    client?.household || task?.sheets?.length || task?.attachments?.length || task?.secureNotes.length;

  const { handleSubmit, setFieldValue, values } = useCreateTaskCommentForm({
    onSubmit: async (values, { resetForm }) =>
      handleMutation({
        mutation: () => {
          const typedForm = new WebTypedFormData<CreateTaskCommentFormSchema>();
          typedForm.append('body', values.body);
          typedForm.appendLocalFiles('attachments', values.attachments);
          return createComment({ formData: typedForm, taskId }).unwrap();
        },
        onSuccess: () => {
          resetForm();
          scrollToBottom();
        },
        showErrorToast: true,
        showSuccessToast: false,
      }),
  });

  const [redirectToTasksListAfterReview, setRedirectToTasksListAfterReview] = useState(true);

  const canBeSnoozed = task && canTaskBeSnoozed(task);
  const canBeUnsnoozed = task?.status === 'snoozed';
  const canBeCompleted = task && canTaskBeCompleted(task);

  const onClickLeaveReview =
    ({ redirectToTasksList }: { redirectToTasksList: boolean }) =>
    () => {
      // If user is opting in to leave a review (not forced csat), don't redirect them to home
      setRedirectToTasksListAfterReview(redirectToTasksList);
      openReviewTaskModal();
      track({ data: { taskId }, event: 'reviewTasksFormViewed', topic: 'tasks' });
    };

  const handleCloseReview = () => {
    closeReviewTaskModal();
    if (isThrottlingEnabled && clientStatus && !evaluateClientQuota(clientStatus).isThrottled && drafts?.length) {
      openResumeDraftTasksModal();
    } else if (redirectToTasksListAfterReview) {
      navigate({ search: { tab: 'waiting_on_you' }, to: '/app/task' });
    }
  };

  return (
    <PageContainer isScrollable={false} padding="none">
      <Header
        onClickBack={router.history.back}
        rightContent={isDetailTagVisible ? <Tag label="Details" onClick={() => openTaskDetailModal()} /> : undefined}
        title={task?.name ?? ''}
      />
      {task ? (
        <TaskChatMessages
          onClickLeaveReview={onClickLeaveReview({ redirectToTasksList: false })}
          ref={scrollRef}
          refetchTask={refetchTask}
          scrollToBottom={scrollToBottom}
          task={task}
        />
      ) : (
        <LoadingSpinnerPanel fullScreen />
      )}
      {task && task?.status !== 'archived' ? (
        <TaskChatFooter
          filesValue={values.attachments}
          inputDisabled={isCreateCommentLoading}
          isSubmitMessageLoading={isCreateCommentLoading}
          onChangeFiles={(attachments) => setFieldValue('attachments', attachments)}
          onChangeText={(body) => setFieldValue('body', body)}
          onClickCompleteTask={canBeCompleted ? openCompleteTaskModal : undefined}
          onClickSnoozeTask={canBeSnoozed ? openSnoozeTaskModal : undefined}
          onClickSubmitMessage={handleSubmit}
          onClickUnsnoozeTask={canBeUnsnoozed ? openUnsnoozeTaskModal : undefined}
          submitDisabled={!values.body}
          taskId={task.id}
          textValue={values.body}
        />
      ) : null}
      {task ? (
        <CompleteTaskModal
          isOpen={isCompleteTaskModalOpen}
          onClose={closeCompleteTaskModalOpen}
          onComplete={() => {
            refetchTask();
            turnOnAnimation();
            // Little hack to force the review modal to open since the task was just completed
            if (shouldPromptTaskReview({ ...task, status: 'solved' })) {
              setShouldForceReview(true);
              openReviewTaskModal();
            }
          }}
          openModal={openCompleteTaskModal}
          task={task}
          taskId={taskId}
        />
      ) : null}
      <ReviewTaskModal
        isOpen={isReviewTaskModalOpen}
        onClose={handleCloseReview}
        openModal={onClickLeaveReview({ redirectToTasksList: true })}
        overrideAllowSkip={!shouldForceReview}
        taskId={taskId}
      />
      {task ? (
        <SnoozeTaskModal
          isOpen={isSnoozeTaskModalOpen}
          onClose={closeSnoozeTaskModal}
          onComplete={refetchTask}
          openModal={openSnoozeTaskModal}
          task={task}
          taskId={taskId}
        />
      ) : null}
      {task ? (
        <UnsnoozeTaskModal
          isOpen={isUnsnoozeTaskModalOpen}
          onClose={closeUnsnoozeTaskModal}
          onComplete={refetchTask}
          openModal={openUnsnoozeTaskModal}
          taskId={taskId}
        />
      ) : null}
      {isAnimationOn ? <Confetti height={height} width={width} /> : null}
      <TaskDetailModal isOpen={isTaskDetailModalOpen} onClose={closeTaskDetailModal} task={task} />
      <ResumeDraftTasksModal isOpen={isResumeDraftTasksModalOpen} onClose={closeResumeDraftTasksModal} />
    </PageContainer>
  );
};

export const Route = createFileRoute('/app/task/$taskId')({
  component: Task,
});
