import { formatEndpoint } from '@frontend/duck-tape';

const namespace = 'waitlists';

const CREATE_WAITLIST_CONTACT = 'contacts';
const WAITLIST_STATUS = 'status';

export const WAITLISTS_ENDPOINTS = {
  createWaitlistContact: formatEndpoint(CREATE_WAITLIST_CONTACT, namespace),
  waitlistStatus: formatEndpoint(WAITLIST_STATUS, namespace),
};
