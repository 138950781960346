import type { StrictOmit } from '@frontend/duck-tape';
import { applyClass, concatClasses } from '@frontend/web-utils';
import { forwardRef } from 'react';
import type { DivProps } from 'react-html-props';
import type { ViewProps } from './View';
import { View } from './View';

export type ClickableProps = StrictOmit<ViewProps, 'onClick'> & {
  canFocus?: boolean;
  disabled?: boolean;
  enableHoverEffect?: boolean;
  flexDirection?: ViewProps['flexDirection'];
  onClick?: DivProps['onClick'];
};

export const Clickable = forwardRef<HTMLDivElement, ClickableProps>(
  (
    {
      canFocus = true,
      className,
      disabled = false,
      enableHoverEffect = false,
      flexDirection = 'column',
      onClick,
      ...props
    },
    ref,
  ) => (
    <View
      aria-disabled={disabled}
      className={concatClasses(
        applyClass(enableHoverEffect && !!onClick && !disabled, 'hover-background-effect hover:bg-neutral-100'),
        applyClass(disabled, 'cursor-not-allowed'),
        className,
      )}
      flexDirection={flexDirection}
      onClick={disabled ? undefined : onClick}
      ref={ref}
      role={onClick ? 'button' : undefined}
      tabIndex={onClick && canFocus ? 0 : undefined}
      {...props}
    />
  ),
);
