import type { StrictOmit } from '@frontend/duck-tape';
import type { ReactNode } from '@frontend/react';
import { concatClasses } from '@frontend/web-utils';
import type { DrawerProps as MDrawerProps } from '@mantine/core';
import { Drawer as MDrawer } from '@mantine/core';
import { Button, type ButtonProps } from '../Buttons';
import { XStack, YStack } from '../Containers';
import { Icon } from '../Icon/Icon';

export type DrawerProps = StrictOmit<MDrawerProps, 'classNames' | 'opened' | 'title'> & {
  HeaderComponent?: ReactNode;
  headerButtonLabel?: string;
  // Intentionally using non-strict Omit
  headerButtonProps?: Omit<ButtonProps, 'label'>;
  isOpen: boolean;
  onClickHeaderButton?: () => void;
};
export const Drawer = ({
  children,
  className,
  headerButtonLabel,
  headerButtonProps,
  HeaderComponent,
  isOpen,
  onClickHeaderButton,
  onClose,
  ...props
}: DrawerProps) => {
  const classNames: MDrawerProps['classNames'] = {
    body: concatClasses('p-lg'),
  };
  return (
    <MDrawer
      className={className}
      classNames={classNames}
      onClose={onClose}
      opened={isOpen}
      position="right"
      size="500px"
      withCloseButton={false}
      {...props}
    >
      <YStack className="gap-y-md">
        {HeaderComponent ? (
          HeaderComponent
        ) : (
          <XStack className="flex-1 justify-between">
            <YStack>
              <Icon name="IconArrowLeft" onClick={onClose} radius="lg" size="lg" />
            </YStack>
            {headerButtonLabel && onClickHeaderButton ? (
              <Button label={headerButtonLabel} onClick={onClickHeaderButton} {...headerButtonProps} size="sm" />
            ) : null}
          </XStack>
        )}
        {children}
      </YStack>
    </MDrawer>
  );
};
