import { getAddressString } from '@frontend/api-client';
import { referralsUrl } from '@frontend/constants';
import { e164ToInternational } from '@frontend/duck-tape';
import { useVisibilityController } from '@frontend/react/hooks';
import { Avatar, Button, Icon, PageContainer, Text, XStack, YStack } from '@frontend/web-react';
import { UpdatePrimaryAddressModal } from '@frontend/web/components/DomainSpecific/Settings';
import { UpdateProfileModal } from '@frontend/web/components/DomainSpecific/Settings/UpdateProfileModal';
import { useListAddressesQuery, useRetrieveClientQuery } from '@frontend/web/hooks';
import { createFileRoute } from '@tanstack/react-router';

const Settings = () => {
  const { data: client, isLoading: isClientLoading } = useRetrieveClientQuery();
  const { data: addresses, isLoading: isAddressesLoading } = useListAddressesQuery();

  const address = addresses?.[0];

  const [isEditProfileModalOpen, { close: closeEditProfileModal, open: openEditProfileModal }] =
    useVisibilityController();
  const [isUpdateAddressModalOpen, { close: closeUpdateProfileModal, open: openUpdateProfileModal }] =
    useVisibilityController();

  return (
    <PageContainer headerTitle="Profile" isLoading={!client || isClientLoading || isAddressesLoading}>
      <YStack className="gap-lg grid grid-cols-2">
        <YStack className="gap-md col-span-2 xxl:col-span-1">
          <YStack className="gap-sm">
            <XStack className="justify-between items-center" gapX="md">
              {client ? <Avatar userInfo={client} /> : null}
              <Button
                color="secondary"
                label="Invite friends"
                // eslint-disable-next-line no-restricted-globals
                onClick={() => open(referralsUrl)}
                size="sm"
                type="filled"
              />
            </XStack>
            <XStack className="gap-sm items-center">
              <Text color="primary" type="h4">
                {client?.firstName} {client?.lastName}
              </Text>
              <Icon name="IconPencil" onClick={openEditProfileModal} />
            </XStack>
          </YStack>
          <YStack className="gap-sm">
            <Text color="tertiary" type="p-md">
              {client?.email}
            </Text>
            {client?.phoneNumber ? (
              <Text color="tertiary" type="p-md">
                {e164ToInternational(client.phoneNumber) ?? 'Invalid phone number'}
              </Text>
            ) : null}
          </YStack>
          <YStack className="gap-sm">
            <XStack alignItems="center" className="gap-sm">
              <Text color="tertiary" type="h6">
                Primary address
              </Text>
              <Icon name="IconPencil" onClick={openUpdateProfileModal} />
            </XStack>
            <Text color="tertiary" type="p-md">
              {address ? getAddressString(address) : null}
            </Text>
          </YStack>
        </YStack>
      </YStack>
      <UpdateProfileModal isOpen={isEditProfileModalOpen} onClose={closeEditProfileModal} />
      {address ? (
        <UpdatePrimaryAddressModal
          isOpen={isUpdateAddressModalOpen}
          onClose={closeUpdateProfileModal}
          primaryAddress={address}
        />
      ) : null}
    </PageContainer>
  );
};

export const Route = createFileRoute('/app/settings/')({
  component: Settings,
});
