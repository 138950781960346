import type { LocalOrSavedFile } from '@frontend/api-types';
import { match } from '@frontend/duck-tape';
import { getCommonFileFields, isImage } from '@frontend/forms';
import { Fragment, useState, useVisibilityController } from '@frontend/react';
import { Clickable } from '../../../Containers';
import { XStack } from '../../../Containers/XStack';
import { YStack } from '../../../Containers/YStack';
import { Icon } from '../../../Icon/Icon';
import { FilePreviewModal } from '../../../Modals/FilePreviewModal';
import { Tag } from '../../../Tags/Tag';
import { Text } from '../../../Texts/Text';
import type { FileInputProps } from './FileInput';

type FilePreviewVariant = 'listItems' | 'tags';

export type FilePreviewsProps = {
  disabled?: boolean;
  files: FileInputProps['value'];
  onDelete?: (index: number, file: LocalOrSavedFile) => void;
  type: FilePreviewVariant;
};

export const FilePreviews = ({ disabled, files, onDelete, type }: FilePreviewsProps) => {
  const [selectedFile, setSelectedFile] = useState<LocalOrSavedFile>();
  const [isFilePreviewModalOpen, { close: closeFilePreview, open: openFilePreview }] = useVisibilityController();

  const onClickPreview = (file: LocalOrSavedFile) => {
    // Pdfs are tedious to preview because of CORS issues, just opening in new tab for now
    if (isImage(file)) {
      setSelectedFile(file);
      openFilePreview();
    } else {
      window.open(getCommonFileFields(file).url, '_blank');
    }
  };

  return (
    <Fragment>
      {match(type)
        .with('tags', () => {
          return (
            <XStack className="gap-xs flex-wrap">
              {files.map((file, index) => {
                const { key, name } = getCommonFileFields(file);
                return (
                  <Tag
                    key={key}
                    label={name}
                    onClick={() => onClickPreview(file)}
                    onClickRightIcon={onDelete ? () => onDelete(index, file) : undefined}
                    rightIconName={onDelete ? 'IconX' : undefined}
                  />
                );
              })}
            </XStack>
          );
        })
        .with('listItems', () => {
          return (
            <YStack className="gap-x-sm">
              {files.map((file, index) => {
                const { key, name } = getCommonFileFields(file);
                return (
                  <Clickable
                    alignItems="center"
                    enableHoverEffect
                    justifyContent="spaceBetween"
                    key={key}
                    onClick={() => onClickPreview(file)}
                  >
                    <Text color="secondary" type="captionBold">
                      {name}
                    </Text>
                    {onDelete ? (
                      <Icon
                        color="accent"
                        disabled={disabled}
                        enableBackgroundColor
                        name={'IconX'}
                        onClick={() => onDelete(index, file)}
                        rounded
                        size="xs"
                      />
                    ) : null}
                  </Clickable>
                );
              })}
            </YStack>
          );
        })
        .exhaustive()}
      {selectedFile ? (
        <FilePreviewModal
          file={selectedFile}
          isOpen={isFilePreviewModalOpen}
          onClose={closeFilePreview}
          title={getCommonFileFields(selectedFile).name}
          url={getCommonFileFields(selectedFile).url}
        />
      ) : null}
    </Fragment>
  );
};
