import { Card, Image, Text, XStack, YStack } from '@frontend/web-react';
import type { ClassName } from '@frontend/web-utils';
import { concatClasses } from '@frontend/web-utils';
import { useNavigate } from '@tanstack/react-router';

export type TaskChatOptionSheetCardProps = {
  cardClassName?: ClassName;
  className?: ClassName;
  imageUrl?: string;
  optionSheetId: string;
  title: string;
};
export const TaskChatOptionSheetCard = ({
  cardClassName,
  className,
  imageUrl,
  optionSheetId,
  title,
}: TaskChatOptionSheetCardProps) => {
  const navigate = useNavigate();
  const onClickCard = () => navigate({ params: { optionSheetId }, to: '/app/option-sheet/$optionSheetId' });
  return (
    <XStack className={className}>
      {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
      <Card
        className={concatClasses('rounded-lg bg-surfaceSecondary w-[300px] overflow-hidden', cardClassName)}
        onClick={onClickCard}
        withBorder
      >
        <YStack gapY="md">
          {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
          <Card.Section className="flex flex-col max-h-[129px] items-center justify-center bg-surfacePrimary">
            {imageUrl ? (
              <Image className="object-cover content-center size-full" source={imageUrl} />
            ) : (
              <XStack className="p-sm">
                {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
                <Text className="italic self-center rotate-[-10.582deg]" type="h2">
                  {title.toLocaleUpperCase()}
                </Text>
              </XStack>
            )}
          </Card.Section>
          <XStack>
            <Text type="h5">{title}</Text>
          </XStack>
        </YStack>
      </Card>
    </XStack>
  );
};
