import type { CreateInviteFormSchema } from '@frontend/forms';
import { useCreateInviteForm } from '@frontend/forms';
import { useEffect } from '@frontend/react';
import type { CommonModalProps } from '@frontend/web-react';
import { Button, Modal, PhoneNumberInput, TextInput, XStack, YStack } from '@frontend/web-react';
import { useAnalytics, useCreateInviteMutation } from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';

export type InviteHouseholdMemberModalProps = CommonModalProps & {
  householdId: string;
};

export const InviteHouseholdMemberModal = ({ householdId, isOpen, onClose }: InviteHouseholdMemberModalProps) => {
  const { track } = useAnalytics();
  const [createInvite, { isLoading }] = useCreateInviteMutation();

  useEffect(() => {
    if (isOpen) {
      track({ event: 'inviteModalOpened', topic: 'invite' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleAddMember = (values: CreateInviteFormSchema) => {
    handleMutation({
      mutation: () => createInvite({ ...values, id: householdId }).unwrap(),
      onSuccess: onClose,
      successMessage: `${values.firstName} has been invited`,
    });
  };

  const { dirty, getControl, handleSubmit, isValid, values } = useCreateInviteForm({
    onSubmit: handleAddMember,
    validateOnMount: false,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Add a member" titlesAlignment="start">
      <form onSubmit={handleSubmit}>
        <YStack className="gap-md">
          <XStack className="gap-md">
            <TextInput {...getControl('firstName')} label="First name" />
            <TextInput {...getControl('lastName')} label="Last name" />
          </XStack>
          <PhoneNumberInput {...getControl('phoneNumber')} label="Phone number" />
          <TextInput {...getControl('email')} label="Email" showOptionalText value={values.email as string} />
          <Button
            className="mt-md"
            disabled={!isValid || !dirty}
            fullWidth
            isForm
            isLoading={isLoading}
            label="Send invite"
          />
        </YStack>
      </form>
    </Modal>
  );
};
