import type { TaskComment } from '@frontend/api-types';
import { getIsLessThanTwoWeeksOld } from '@frontend/duck-tape';

export const getIsSkipAllowed = (comments: TaskComment[]): boolean => {
  const mostRecentComment = comments[comments.length - 1];
  return Boolean(
    comments.length === 0 ||
      !comments.some((comment) => comment.author.type === 'agent') ||
      (mostRecentComment && !getIsLessThanTwoWeeksOld(mostRecentComment.createdAt)),
  );
};
