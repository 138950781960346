import type { MakeInputProps, RatingValue } from '@frontend/forms';
import { safeCss } from '@frontend/web-utils';
import type { RatingProps as MRatingProps } from '@mantine/core';
import { Rating as MRating } from '@mantine/core';
import { Icon } from '../../Icon/Icon';

export type RatingInputProps = MakeInputProps<RatingValue, MRatingProps, 'onChange' | 'value'>;

export const RatingInput = ({ onChange, ...props }: RatingInputProps) => {
  return (
    <MRating
      classNames={{
        root: safeCss('gap-x-xs'),
      }}
      emptySymbol={<Icon name="IconStar" />}
      fullSymbol={<Icon name="IconStarFilled" />}
      onChange={(rating) => onChange(rating as RatingValue)}
      {...props}
    />
  );
};
