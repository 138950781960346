import { isNil } from '../hidash/hidash';

export const validateEnv = <T extends Record<string, boolean | number | string> & { PROD: boolean }>(
  env: T,
  config?: { strict: boolean },
) => {
  const { strict = false } = config || {};
  const keys = Object.keys(env) as Array<keyof T>;

  const missingVars = keys.reduce<Array<string>>(
    (acc, key) => (isNil(env[key]) || env[key] === '' ? [...acc, String(key)] : acc),
    [],
  );

  if (missingVars.length > 0 && !env.PROD) {
    const errorMessage = `Missing environment variables: ${missingVars.join(', ')}`;
    if (strict) {
      throw new Error(errorMessage);
    } else {
      // eslint-disable-next-line no-console
      console.error(errorMessage);
    }
    return false;
  }

  return missingVars.length === 0;
};
