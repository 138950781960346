import { isString } from '@frontend/duck-tape';
import type { ReactNode } from '@frontend/react';
import { XStack } from '../Containers';
import { Text } from '../Texts/Text';

export type InputLabelProps = { inverse?: boolean; label: ReactNode; showOptionalText?: boolean };

export const InputLabel = ({ inverse = false, label, showOptionalText = false }: InputLabelProps) => {
  return (
    <XStack className="flex-1 justify-between">
      {isString(label) ? <Text color={inverse ? 'inverse' : 'primary'}>{label}</Text> : label}
      {showOptionalText ? <Text color={inverse ? 'inverse' : 'secondary'}>Optional</Text> : null}
    </XStack>
  );
};
