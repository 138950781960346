import type { CREATE_TASK_NEXT_STEPS_OPTIONS } from '@frontend/api-client';
import { calculateDefaultRecurrenceBuffer } from '@frontend/api-client';
import type {
  DraftTaskDetail,
  LocalFile,
  RecurrenceInterval,
  TaskInitialQuestion,
  TaskInitialQuestionInput,
  ThreadReferenceForTask,
} from '@frontend/api-types';
import { generateUUID } from '@frontend/duck-tape';
import { createContext } from '@frontend/react';

export type InitializationOptions = {
  data?: Partial<CreateTaskData>;
  draftTask?: DraftTaskDetail;
  isRecurrence?: boolean;
  onCreate?: () => Promise<void>;
  skipSteps?: SkippableCreateTaskStep[];
};

export type MultiSelectAnswer = { id: string; value: string };

export type Answer = {
  answer: MultiSelectAnswer[] | string;
  choices: MultiSelectAnswer[];
  question: TaskInitialQuestionInput['question'];
};

export const defaultTaskData = {
  attachments: [],
  buffer: calculateDefaultRecurrenceBuffer('week', 1),
  calendarRecurrenceId: undefined,
  calendarRecurrenceTaskSuggestionIdx: undefined,
  dateNeeded: undefined,
  description: '',
  exampleId: undefined,
  exampleType: undefined,
  interval: 'week' as RecurrenceInterval,
  intervalCount: 1,
  name: '',
  nextSteps: undefined,
  researchThreadId: undefined,
  researchThreadReferenceData: undefined,
  sharedWithClientIds: [],
};

export type CreateTaskState = {
  currentStep: CreateTaskStep;
  isRecurrence: boolean;
  steps: CreateTaskStep[];
  taskData: CreateTaskData;
  uniqueKey: UUID;
};

export const allSteps = ['description', 'quota', 'dateNeeded', 'interval', 'nextSteps', 'initialQuestions'] as const;
export type CreateTaskStep = (typeof allSteps)[number];

// we rely on nextSteps to buffer the initial questions step
export type SkippableCreateTaskStep = Exclude<CreateTaskStep, 'nextSteps'>;

export const initialCreateTaskState: CreateTaskState = {
  currentStep: allSteps[0],
  isRecurrence: false,
  steps: [...allSteps],
  taskData: { ...defaultTaskData },
  uniqueKey: generateUUID(),
};

export type CreateTaskData = {
  attachments: LocalFile[];
  buffer: number;
  calendarRecurrenceId: MaybeUndefined<string>;
  calendarRecurrenceTaskSuggestionIdx: MaybeUndefined<number>;
  dateNeeded: MaybeUndefined<DateISOString>;
  description: string;
  exampleId: MaybeUndefined<string>;
  exampleType: MaybeUndefined<string>;
  interval: RecurrenceInterval;
  intervalCount: number;
  name: string;
  nextSteps: MaybeUndefined<NextStep>;
  researchThreadId: MaybeUndefined<string>;
  researchThreadReferenceData: MaybeUndefined<ThreadReferenceForTask>;
  sharedWithClientIds: string[];
};

type CreateTaskContextType = {
  answers: Answer[];
  closeCreateTaskModal: () => void;
  createTaskState: CreateTaskState;
  decrementStep: () => void;
  draftTask: DraftTaskDetail | null;
  incrementStep: () => void;
  initialQuestions: TaskInitialQuestion[];
  isOnLastStep: boolean;
  isOpen: boolean;
  isStreaming: boolean;
  isSubmitting: boolean;
  onUpdateAnswer: (index: number, newValue: (originalValue: Answer) => Answer) => void;
  openCreateTaskModal: (options: InitializationOptions) => void;
  updateTaskData: (data: Partial<CreateTaskData>) => void;
};

export const CreateTaskContext = createContext<CreateTaskContextType>({
  answers: [],
  closeCreateTaskModal: () => {},
  createTaskState: initialCreateTaskState,
  decrementStep: () => {},
  draftTask: null,
  incrementStep: () => {},
  initialQuestions: [],
  isOnLastStep: false,
  isOpen: false,
  isStreaming: false,
  isSubmitting: false,
  onUpdateAnswer: (_index: number, _newValue: (originalValue: Answer) => Answer) => {},
  openCreateTaskModal: () => {},
  updateTaskData: () => {},
});

export type NextStep = (typeof CREATE_TASK_NEXT_STEPS_OPTIONS)[number]['value'];
