import { colors } from '@frontend/constants';
import { P, match, type StrictExtract } from '@frontend/duck-tape';
import { forwardRef } from '@frontend/react';
import type { ColorVariant } from '@frontend/web-react/types';
import type { ClassName } from '@frontend/web-utils';
import { applyClass, classNames, concatClasses, safeCss } from '@frontend/web-utils';
import { Clickable } from '../Containers/Clickable';
import { Spinner } from '../Spinner';
import type { TextColorVariant } from '../Texts';
import type { CustomIconName, IconName, TablerIconName } from './helpers';
import { customIconsMap, tablerIconsMap } from './helpers';

export type IconColorVariant =
  | StrictExtract<ColorVariant, 'accent' | 'danger' | 'inverse' | 'primary' | 'secondary' | 'success' | 'tertiary'>
  | StrictExtract<TextColorVariant, 'orange' | 'pink' | 'purple' | 'yellow'>
  | 'transparent'
  | 'warning'
  | 'white';

export type IconSizeVariant = number | 'lg' | 'md' | 'sm' | 'xl' | 'xs';
export type IconRadiusVariant = 'lg' | 'md' | 'sm';

export type IconProps = {
  canFocus?: boolean;
  className?: ClassName;
  color?: IconColorVariant;
  disabled?: boolean;
  enableBackgroundColor?: boolean;
  isLoading?: boolean;
  name: IconName;
  onClick?: () => void;
  radius?: IconRadiusVariant;
  rounded?: boolean;
  size?: IconSizeVariant;
  stroke?: number;
};

export const Icon = forwardRef<HTMLDivElement, IconProps>(
  (
    {
      canFocus = false,
      className,
      color = 'primary',
      disabled = false,
      enableBackgroundColor = false,
      isLoading = false,
      name,
      onClick,
      radius = 'sm',
      rounded = false,
      size = 'md',
      stroke = 2,
      ...props
    },
    ref,
  ) => {
    const ResolvedIcon = tablerIconsMap[name as TablerIconName] ?? customIconsMap[name as CustomIconName];
    const buttonSizeClass = getButtonSizesClass(size);

    return (
      <Clickable
        canFocus={canFocus}
        className={concatClasses(
          'items-center justify-center',
          getBorderRadiusClass({ radius, rounded, size }),
          getClickableContainerClass({ color, disabled, enableBackgroundColor, onClick }),
          applyClass(!!onClick && !className?.includes('p-'), 'p-xs'),
          className,
        )}
        disabled={isLoading || disabled}
        enableHoverEffect={!!onClick}
        onClick={
          onClick
            ? (e) => {
                e.stopPropagation();
                e.preventDefault();
                onClick?.();
              }
            : undefined
        }
        ref={ref}
        {...props}
      >
        {isLoading ? (
          <Spinner
            className={concatClasses(buttonSizeClass, 'items-center justify-center flex')}
            size={match(size)
              .with('xl', () => '24px')
              .with('lg', () => '18px')
              .with('md', () => '12px')
              .with('sm', () => '8px')
              .with('xs', () => '8px')
              .with(P.number, (size) => `${size}px`)
              .exhaustive()}
          />
        ) : (
          <ResolvedIcon className={buttonSizeClass} color={getIconColor({ color, disabled })} stroke={stroke} />
        )}
      </Clickable>
    );
  },
);

const getIconColor = ({ color, disabled }: PickRequired<IconProps, 'color' | 'disabled'>) =>
  disabled
    ? colors.textInverse
    : match(color)
        .with('danger', () => colors.textError)
        .with('primary', () => colors.textPrimary)
        .with('secondary', () => colors.textSecondary)
        .with('success', () => colors.textSuccess)
        .with('accent', () => colors.textPrimary)
        .with('pink', () => colors.pink[950])
        .with('white', () => colors.white)
        .with('tertiary', () => colors.textTertiary)
        .with('inverse', () => colors.textInverse)
        .with('orange', () => colors.rose[300])
        .with('purple', () => colors.violet[950])
        .with('yellow', () => colors.yellow[900])
        .with('transparent', () => colors.transparent)
        .with('warning', () => colors.rose[600])
        .exhaustive();

const getBorderRadiusClass = (args: PickRequired<IconProps, 'radius' | 'rounded' | 'size'>) =>
  match(args)
    // Order matters here
    .with({ rounded: true, size: 'xl' }, () => safeCss('rounded-[32px]'))
    .with({ rounded: true, size: 'lg' }, () => safeCss('rounded-[24px]'))
    .with({ rounded: true, size: 'md' }, () => safeCss('rounded-[18px]'))
    .with({ rounded: true, size: 'sm' }, () => safeCss('rounded-[12px]'))
    .with({ rounded: true, size: 'xs' }, () => safeCss('rounded-[10px]'))
    .with({ radius: 'lg' }, () => safeCss('rounded-lg'))
    .with({ radius: 'md' }, () => safeCss('rounded-md'))
    .with({ radius: 'sm' }, () => safeCss('rounded-sm'))
    // classNames allows dynamic strings to be included in the build
    // eslint-disable-next-line tailwindcss/no-custom-classname
    .with({ size: P.number }, (size) => classNames(`rounded-[${size}px]`))
    .exhaustive();

const getButtonSizesClass = (size: IconSizeVariant) =>
  match(size)
    .with('xl', () => safeCss('size-lg'))
    .with('lg', () => safeCss('size-lg'))
    .with('md', () => safeCss('size-[18px]'))
    .with('sm', () => safeCss('size-[12px]'))
    .with('xs', () => safeCss('size-[10px]'))
    .with(P.number, (size) =>
      // classNames allows dynamic strings to be included in the build
      // eslint-disable-next-line tailwindcss/no-custom-classname
      classNames(`size-[${size}px]`),
    )
    .exhaustive();

const getClickableContainerClass = (
  args: Pick<IconProps, 'onClick'> & PickRequired<IconProps, 'color' | 'disabled' | 'enableBackgroundColor'>,
) =>
  match(args)
    .with({ disabled: true }, () => safeCss('border-1 bg-buttonDisabled border-buttonDisabled'))
    .with({ onClick: P.nullish }, () => safeCss(''))
    .with({ enableBackgroundColor: false }, () => safeCss(''))
    .with({ color: 'danger' }, () => safeCss('border-1 bg-buttonError border-borderError'))
    .with({ color: 'primary' }, () => safeCss('border-1 bg-buttonPrimary border-borderSecondary'))
    .with({ color: 'secondary' }, () => safeCss('border-1 bg-white  border-textSecondary'))
    .with({ color: 'inverse' }, () => safeCss('border-1 bg-white  border-textInverse'))
    .with({ color: 'success' }, () => safeCss('border-1 bg-buttonSuccess  border-borderSuccess'))
    .with({ color: 'accent' }, () => safeCss('border-1 bg-surfaceAccent  border-borderSecondary'))
    .with({ color: 'tertiary' }, () => safeCss('border-1 bg-surfaceTertiary border-borderTertiary'))
    .with({ color: 'pink' }, () => safeCss('border-1 bg-surfacePink border-transparent'))
    .with({ color: 'orange' }, () => safeCss('border-1 bg-rose-300 border-transparent'))
    .with({ color: 'white' }, () => safeCss('bg-white'))
    .with({ color: 'purple' }, () => safeCss('border-1 bg-violet-300 border-transparent'))
    .with({ color: 'yellow' }, () => safeCss('border-1 bg-yellow-300 border-transparent'))
    .with({ color: 'transparent' }, () => safeCss('border-1 border-transparent'))
    .with({ color: 'warning' }, () => safeCss('border-1 bg-rose-300 border-transparent'))
    .exhaustive();
