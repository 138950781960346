import { taskStatuses } from '@frontend/api-client';
import { match } from '@frontend/duck-tape';
import { validators, z } from '@frontend/forms';
import { Fragment, useFeatureIsOn, useState, useVisibilityController } from '@frontend/react';
import { Divider, EmptyList, FlatList, PageContainer, SkeletonList } from '@frontend/web-react';
import type { IconName } from '@frontend/web-react/components/Primitives/Icon/helpers';
import type { ClassName } from '@frontend/web-utils';
import {
  DeleteRecurrenceModal,
  RecurrenceCard,
  ReviewTaskModal,
  SkipTaskModal,
  UnsnoozeTaskModal,
} from '@frontend/web/components';
import { DraftTaskCard } from '@frontend/web/components/DomainSpecific/Tasks/DraftTaskCard';
import { ActiveLimitBanner } from '@frontend/web/components/DomainSpecific/Tasks/Quota/ActiveLimitBanner';
import { TaskCard } from '@frontend/web/components/DomainSpecific/Tasks/TaskCard';
import { TabBarHeader } from '@frontend/web/components/Navigation/TabBar/TabBarHeader';
import {
  useAnalytics,
  useLazyListRecurrencesQuery,
  useListDraftTasksQuery,
  useListTasksQuery,
} from '@frontend/web/hooks';
import { useCreateTaskContext } from '@frontend/web/hooks/context/useCreateTaskContext';
import { createFileRoute } from '@tanstack/react-router';
import { useEffect, useMemo } from 'react';

const tabs: { className?: ClassName; iconName?: IconName; key: TaskTabTypeWithScheduled; title: string }[] = [
  { key: 'waiting_on_you', title: 'For your review' },
  { key: 'in_progress', title: 'In Progress' },
  { className: 'border-dashed border-2 border-borderPrimary', iconName: 'IconNote', key: 'draft', title: 'Drafts' },
  { key: 'scheduled', title: 'Scheduled' },
  { key: 'snoozed', title: 'Snoozed' },
  { key: 'completed', title: 'Completed' },
  { key: 'all_tasks', title: 'All Tasks' },
];

export type TaskTabType = keyof typeof taskStatuses;

export type TaskTabTypeWithScheduled = TaskTabType | 'scheduled';

const DEFAULT_TAB: TaskTabTypeWithScheduled = 'waiting_on_you';

export type TaskModalType = 'review' | 'skip' | 'unsnooze';

const Home = () => {
  const params = Route.useSearch();
  const { newTask, tab = DEFAULT_TAB } = params;
  const { openCreateTaskModal } = useCreateTaskContext();
  const navigate = Route.useNavigate();
  const { track } = useAnalytics();
  const { data: tasks, error: listTasksError, isLoading: isListTasksLoading } = useListTasksQuery();
  const { data: drafts, error: listDraftTasksError, isLoading: isListDraftTaskLoading } = useListDraftTasksQuery();
  const { enabled: isRepeatingTasksEnabled } = useFeatureIsOn('is_repeated_tasks_enabled');
  const modifiedTabs = tabs
    .map((route) => {
      if (route.key === 'draft' && drafts?.length) {
        return { ...route, title: `Drafts (${drafts.length})` };
      }
      return route;
    })
    .filter((route) => {
      if (route.key === 'draft') {
        return drafts?.length && drafts?.length > 0;
      }
      return isRepeatingTasksEnabled || route.key !== 'scheduled';
    });
  const [isUnsnoozeTaskModalOpen, { close: closeUnsnoozeTaskModal, open: openUnsnoozeTaskModal }] =
    useVisibilityController();
  const [isSkipTaskModalOpen, { close: closeSkipTaskModal, open: openSkipTaskModal }] = useVisibilityController();
  const [isReviewTaskModalOpen, { close: closeReviewTaskModal, open: openReviewTaskModal }] = useVisibilityController();
  const [isDeleteRecurrenceModalOpen, { close: closeDeleteRecurrenceModal, open: openDeleteRecurrenceModal }] =
    useVisibilityController();
  const [focusedTaskId, setFocusedTaskId] = useState('');
  const [focusedRecurrenceId, setFocusedRecurrenceId] = useState('');
  const [
    listRecurrences,
    { data: recurrencesData = [], error: listRecurrencesError, isLoading: isRecurrencesLoading },
  ] = useLazyListRecurrencesQuery();

  useEffect(() => {
    if (tab === 'scheduled') {
      listRecurrences();
    }
  }, [tab]);

  useEffect(() => {
    if (newTask) {
      track({
        data: {
          exampleId: params.exampleId,
          exampleType: params.exampleType,
          taskName: params.name,
        },
        event: 'createTaskDirectLinkViewed',
        topic: 'tasks',
      });
      track({
        data: {
          exampleId: params.exampleId,
          exampleType: params.exampleType,
          taskName: params.name,
        },
        event: 'createTaskDirectLinkSelected',
        topic: 'tasks',
      });

      openCreateTaskModal({
        data: {
          description: params.description,
          exampleId: params.exampleId,
          exampleType: params.exampleType,
          name: params.name,
        },
        isRecurrence: !!params.isRecurrence,
      });
    }
  }, [newTask]);

  const filteredTasks = useMemo(
    () => (tab === 'scheduled' ? [] : tasks?.filter((task) => taskStatuses[tab].includes(task.status))),
    [tasks, tab],
  );

  const onOpenModal = (taskId: string, type: TaskModalType) => {
    setFocusedTaskId(taskId);
    return match(type)
      .with('review', openReviewTaskModal)
      .with('skip', openSkipTaskModal)
      .with('unsnooze', openUnsnoozeTaskModal)
      .exhaustive();
  };

  const onCloseModal = (type: TaskModalType) => {
    setFocusedTaskId('');
    return match(type)
      .with('review', closeReviewTaskModal)
      .with('skip', closeSkipTaskModal)
      .with('unsnooze', closeUnsnoozeTaskModal)
      .exhaustive();
  };

  const onClickExploreFeedButton = () => {
    navigate({ to: '/app/feed' });
    track({ event: 'exploreFeedSelected', topic: 'examples' });
  };

  const onClickCreateRecurringTaskButton = () => {
    navigate({ to: '/app/recurrence/create' });
    track({ event: 'createRecurrenceButtonSelected', topic: 'recurrences' });
  };

  const onClickDeleteRecurrence = (recurrenceId: string) => {
    setFocusedRecurrenceId(recurrenceId);
    openDeleteRecurrenceModal();
  };

  const flatList = () => {
    if (tab === 'scheduled') {
      return (
        <FlatList
          ListEmptyComponent={
            <EmptyList
              buttonLabel="Create a new repeating task"
              label="No tasks in scheduled... yet"
              onClickButton={onClickCreateRecurringTaskButton}
            />
          }
          ListLoadingComponent={<SkeletonList countSkeletons={10} />}
          className="px-lg"
          contentContainerClassName="pb-lg"
          data={recurrencesData}
          error={!!listRecurrencesError}
          isLoading={isRecurrencesLoading}
          renderItem={({ array, index, item: recurrence }) => {
            return (
              <Fragment key={recurrence.id}>
                <RecurrenceCard onClickDelete={onClickDeleteRecurrence} recurrence={recurrence} />
                <Divider forList index={index} list={array} />
              </Fragment>
            );
          }}
        />
      );
    } else if (tab === 'draft') {
      return (
        <FlatList
          ListHeaderComponent={<ActiveLimitBanner />}
          ListLoadingComponent={<SkeletonList countSkeletons={10} />}
          className="px-lg"
          contentContainerClassName="pb-lg"
          data={drafts}
          error={!!listDraftTasksError}
          isLoading={isListDraftTaskLoading}
          renderItem={({ array, index, item: task }) => {
            return (
              <Fragment key={task.id}>
                <DraftTaskCard task={task} />
                <Divider forList index={index} list={array} />
              </Fragment>
            );
          }}
        />
      );
    } else {
      return (
        <FlatList
          ListEmptyComponent={
            <EmptyList
              buttonLabel="Explore Feed to find tasks"
              label="No tasks here... yet"
              onClickButton={onClickExploreFeedButton}
            />
          }
          ListLoadingComponent={<SkeletonList countSkeletons={10} />}
          className="px-lg"
          contentContainerClassName="pb-lg"
          data={filteredTasks}
          error={!!listTasksError}
          isLoading={isListTasksLoading}
          renderItem={({ array, index, item: task }) => {
            return (
              <Fragment key={task.id}>
                <TaskCard onOpenModal={onOpenModal} task={task} />
                <Divider forList index={index} list={array} />
              </Fragment>
            );
          }}
        />
      );
    }
  };

  // In the future we might want to leverage more of the Tabs component from Mantine to improve rendering efficiency
  return (
    <PageContainer isScrollable={false} padding="none">
      <TabBarHeader
        activeTab={tab}
        onSetActiveTab={(tab) => navigate({ search: { tab }, to: '/app/task' })}
        tabs={modifiedTabs}
      />
      {flatList()}
      {focusedTaskId ? (
        <UnsnoozeTaskModal
          isOpen={isUnsnoozeTaskModalOpen}
          onClose={() => onCloseModal('unsnooze')}
          openModal={openUnsnoozeTaskModal}
          taskId={focusedTaskId}
        />
      ) : null}
      {focusedTaskId ? (
        <ReviewTaskModal
          isOpen={isReviewTaskModalOpen}
          onClose={() => onCloseModal('review')}
          openModal={openReviewTaskModal}
          overrideAllowSkip
          taskId={focusedTaskId}
        />
      ) : null}
      {focusedTaskId ? (
        <SkipTaskModal isOpen={isSkipTaskModalOpen} onClose={() => onCloseModal('skip')} taskId={focusedTaskId} />
      ) : null}
      {focusedRecurrenceId ? (
        <DeleteRecurrenceModal
          isOpen={isDeleteRecurrenceModalOpen}
          onClose={closeDeleteRecurrenceModal}
          recurrenceId={focusedRecurrenceId}
        />
      ) : null}
    </PageContainer>
  );
};

export const homeValidator = validators.object({
  description: validators.encodedURI().optional(),
  exampleId: validators.uuid().optional(),
  exampleType: validators.encodedURI().optional(),
  isRecurrence: validators.boolean().optional(),
  name: validators.encodedURI().optional(),
  newTask: validators.boolean().optional(),
  source: z.string().optional(),
  tab: validators
    .readonlyStringEnum(tabs.map(({ key }) => key))
    .catch(DEFAULT_TAB)
    .optional(),
});

export const Route = createFileRoute('/app/task/')({
  component: Home,
  validateSearch: (search) => homeValidator.parse(search),
});
