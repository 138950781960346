import { createTag } from '@frontend/api-client/utils';
import type { BaseApi } from '@frontend/api-client/utils/types';

import type {
  CloseTaskRequest,
  CloseTaskResponse,
  CreateCommentRequest,
  CreateTaskMutation,
  CreateTaskResponse,
  ListCommentsResponse,
  ListTasksResponse,
  RetrieveTaskResponse,
  ShareTaskRequest,
  SnoozeTaskRequest,
  SnoozeTaskResponse,
  StartTaskResponse,
  Task,
  TaskDetail,
  UnshareTaskRequest,
  UnsnoozeTaskRequest,
  UnsnoozeTaskResponse,
  UpdateTaskRequest,
  UpdateTaskResponse,
} from '@frontend/api-types';
import { convertArrayObjectKeysCase, convertObjectKeysCase } from '@frontend/duck-tape';
import { TASKS_ENDPOINTS } from './endpoints';
import { commentDoesNotOpenStatuses } from './utils';

export const getTasksApi = (baseApi: BaseApi) => {
  const tasksApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
      closeTask: builder.mutation<CloseTaskResponse, CloseTaskRequest>({
        invalidatesTags: (_result, _error, { id: taskId }) => [
          createTag('Client'),
          createTag({ id: 'LIST', type: 'Task' }),
          createTag({ id: taskId, prefix: 'taskId', type: 'Task' }),
          createTag({ id: taskId, prefix: 'taskId', type: 'Comment' }),
        ],
        query: ({ id }) => ({
          method: 'POST',
          url: TASKS_ENDPOINTS.closeTask(id),
        }),
      }),
      createComment: builder.mutation<void, CreateCommentRequest['payload']>({
        invalidatesTags: (_result, _error, { taskId }) => [
          createTag({ id: taskId, prefix: 'taskId', type: 'Comment' }),
        ],
        onQueryStarted: async ({ taskId }, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled;
            dispatch(
              tasksApi.util.updateQueryData('retrieveTask', taskId, (draft) => {
                return commentDoesNotOpenStatuses.includes(draft.status) ? draft : { ...draft, status: 'open' };
              }),
            );
            dispatch(
              tasksApi.util.updateQueryData('listTasks', undefined, (draft) =>
                draft.reduce((agg: Task[], task) => {
                  const { id } = task;
                  return id === taskId && !commentDoesNotOpenStatuses.includes(task.status)
                    ? [...agg, { ...task, status: 'open' }]
                    : [...agg, task];
                }, []),
              ),
            );
          } catch {
            /* If there is an error, don't update the cache */
          }
        },
        query: ({ formData: body, taskId }) => ({
          body,
          method: 'POST',
          url: TASKS_ENDPOINTS.createComment(taskId),
        }),
      }),
      createTask: builder.mutation<CreateTaskResponse, CreateTaskMutation['payload']>({
        invalidatesTags: [
          createTag({ id: 'LIST', type: 'Task' }),
          createTag('Client'),
          createTag({ id: 'LIST', type: 'Example' }),
          createTag({ id: 'LIST', type: 'DraftTask' }),
        ],
        query: ({ formData: body }) => ({
          body,
          method: 'POST',
          url: TASKS_ENDPOINTS.createTask(),
        }),
        transformResponse: (task: GenericRecord) => convertObjectKeysCase(task, 'camelCase') as CreateTaskResponse,
      }),
      listComments: builder.query<ListCommentsResponse, string>({
        providesTags: (_result, _error, taskId) => [createTag({ id: taskId, prefix: 'taskId', type: 'Comment' })],
        query: (id) => TASKS_ENDPOINTS.listComments(id),
        transformResponse: ({ comments }: { comments: GenericRecord[] }) =>
          convertArrayObjectKeysCase(comments, 'camelCase') as ListCommentsResponse,
      }),
      listTasks: builder.query<ListTasksResponse, void>({
        providesTags: [createTag({ id: 'LIST', type: 'Task' })],
        query: TASKS_ENDPOINTS.listTasks,
        transformResponse: (response: GenericRecord[]) =>
          convertArrayObjectKeysCase(response, 'camelCase') as ListTasksResponse,
      }),
      retrieveTask: builder.query<RetrieveTaskResponse, string>({
        providesTags: (_result, _error, taskId) => [createTag({ id: taskId, prefix: 'taskId', type: 'Task' })],
        query: TASKS_ENDPOINTS.retrieveTask,
        transformResponse: (response: GenericRecord) =>
          convertObjectKeysCase(response, 'camelCase') as RetrieveTaskResponse,
      }),
      shareTask: builder.mutation<void, ShareTaskRequest>({
        invalidatesTags: (_result, _error, { id }) => [createTag({ id, prefix: 'taskId', type: 'Task' })],
        query: ({ clientId, id }) => ({
          method: 'POST',
          url: TASKS_ENDPOINTS.accessTask(id, clientId),
        }),
      }),
      skipTask: builder.mutation<TaskDetail, string>({
        invalidatesTags: (_result, _error) => [createTag({ id: 'LIST', type: 'Task' })],
        onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
          try {
            const { data: updatedTask } = await queryFulfilled;
            dispatch(
              tasksApi.util.updateQueryData('retrieveTask', id, (draft) => {
                return { ...draft, ...updatedTask };
              }),
            );
          } catch {
            /* If there is an error, don't update the cache */
          }
        },
        query: (id) => ({
          method: 'POST',
          url: TASKS_ENDPOINTS.skipTask(id),
        }),
        transformResponse: (response: GenericRecord) => convertObjectKeysCase(response, 'camelCase') as TaskDetail,
      }),
      snoozeTask: builder.mutation<SnoozeTaskResponse, SnoozeTaskRequest>({
        invalidatesTags: (_result, _error, { taskId }) => [
          createTag({ id: 'LIST', type: 'Task' }),
          createTag({ id: taskId, prefix: 'taskId', type: 'Task' }),
          createTag({ id: taskId, prefix: 'taskId', type: 'Comment' }),
        ],
        query: ({ snoozeDate, taskId }) => ({
          body: convertObjectKeysCase({ snoozeDate }, 'snakeCase'),
          method: 'POST',
          url: TASKS_ENDPOINTS.snoozeTask(taskId),
        }),
      }),
      startTask: builder.mutation<StartTaskResponse, string>({
        invalidatesTags: (_result, _error) => [createTag({ id: 'LIST', type: 'Task' })],
        onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
          try {
            const { data: updatedTask } = await queryFulfilled;
            dispatch(
              tasksApi.util.updateQueryData('retrieveTask', id, (draft) => {
                return { ...draft, ...updatedTask };
              }),
            );
          } catch {
            /* If there is an error, don't update the cache */
          }
        },
        query: (id) => ({
          method: 'POST',
          url: TASKS_ENDPOINTS.startTask(id),
        }),
        transformResponse: (task: GenericRecord) => convertObjectKeysCase(task, 'camelCase') as StartTaskResponse,
      }),
      unshareTask: builder.mutation<void, UnshareTaskRequest>({
        invalidatesTags: (_result, _error, { id }) => [createTag({ id, prefix: 'taskId', type: 'Task' })],
        query: ({ clientId, id }) => ({
          method: 'DELETE',
          url: TASKS_ENDPOINTS.accessTask(id, clientId),
        }),
      }),
      unsnoozeTask: builder.mutation<UnsnoozeTaskResponse, UnsnoozeTaskRequest>({
        invalidatesTags: (_result, _error, { taskId }) => [
          createTag({ id: 'LIST', type: 'Task' }),
          createTag({ id: taskId, prefix: 'taskId', type: 'Task' }),
          createTag({ id: taskId, prefix: 'taskId', type: 'Comment' }),
        ],
        query: ({ taskId }) => ({
          body: { id: taskId },
          method: 'POST',
          url: TASKS_ENDPOINTS.unsnoozeTask(taskId),
        }),
      }),
      updateTask: builder.mutation<UpdateTaskResponse, UpdateTaskRequest>({
        invalidatesTags: (_result, _error, { id }) => [
          createTag({ id: 'LIST', type: 'Task' }),
          createTag({ id, prefix: 'taskId', type: 'Task' }),
        ],
        query: ({ data, id }) => ({
          body: convertObjectKeysCase(data, 'snakeCase'),
          method: 'PATCH',
          url: TASKS_ENDPOINTS.updateTask(id),
        }),
        transformResponse: (task: GenericRecord) => convertObjectKeysCase(task, 'camelCase') as UpdateTaskResponse,
      }),
    }),
    overrideExisting: true,
  });

  return tasksApi;
};
