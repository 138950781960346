import type { AuthReduxState } from '@frontend/redux/slices/authSlice';
import * as AuthSlice from '@frontend/redux/slices/authSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from './useSelector';

export const useAuthSlice = <TRootState extends { auth: AuthReduxState }>() => {
  const dispatch = useDispatch();

  const { token } = useSelector<TRootState>()(({ auth }) => auth);

  const storeToken = (authToken: Parameters<(typeof AuthSlice)['storeToken']>[0]) =>
    dispatch(AuthSlice.storeToken(authToken));

  const clearAuth = () => dispatch(AuthSlice.clearAuth());

  return { clearAuth, storeToken, token };
};
