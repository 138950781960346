import { Hero404 } from '@frontend/assets';
import { Button, ImageBackground, Text, Title, XStack, YStack } from '@frontend/web-react';
import { useNavigate } from '@tanstack/react-router';

export const OptionSheetError = () => {
  const navigate = useNavigate();
  return (
    <ImageBackground aspectRatio="16/9" className="flex min-h-screen w-full" source={Hero404}>
      <YStack alignItems="center" className="gap-y-xxl flex-1" gapY="md" justifyContent="center">
        <YStack alignItems="center" gapY="xl">
          {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
          <Title
            // eslint-disable-next-line tailwindcss/no-arbitrary-value, tailwindcss/no-contradicting-classname
            className="rotate-[-10.582deg] text-[154px] leading-[168px] font-extrabold uppercase italic text-shadow-md"
            color="inverse"
            type="h1"
          >
            Oops.
          </Title>
          <Text className="italic text-shadow-md" color="inverse" type="h1" uppercase>
            {'Something went wrong.'}
          </Text>
          <Text className="italic" color="inverse" type="h3" uppercase>
            {"but don't worry, we're on it."}
          </Text>
        </YStack>
        {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
        <XStack className="w-[240px]">
          <Button fullWidth onClick={() => navigate({ to: '/app' })}>
            Dashboard
          </Button>
        </XStack>
      </YStack>
    </ImageBackground>
  );
};
