import type { BaseApi } from '@frontend/api-client/utils/types';
import type { PushNotificationToken } from '@frontend/api-types';
import { convertObjectKeysCase } from '@frontend/duck-tape';
import PUSH_NOTIFICATIONS_ENDPOINTS from './endpoints';

export const getPushNotificationsApi = (baseApi: BaseApi) =>
  baseApi.injectEndpoints({
    endpoints: (builder) => ({
      registerForPushToken: builder.mutation<PushNotificationToken, PushNotificationToken>({
        query: (payload) => ({
          body: convertObjectKeysCase(payload, 'snakeCase'),
          method: 'POST',
          url: PUSH_NOTIFICATIONS_ENDPOINTS.register(),
        }),
        transformResponse: (response: GenericRecord) =>
          convertObjectKeysCase(response, 'camelCase') as PushNotificationToken,
      }),
    }),
    overrideExisting: true,
  });
