import { createTag } from '@frontend/api-client/utils';
import type { BaseApi } from '@frontend/api-client/utils/types';
import type { ListExamplesResponse } from '@frontend/api-types';
import { convertArrayObjectKeysCase } from '@frontend/duck-tape';
import EXAMPLES_ENDPOINTS from './endpoints';

export const getExamplesApi = (baseApi: BaseApi) =>
  baseApi.injectEndpoints({
    endpoints: (builder) => ({
      listExamples: builder.query<ListExamplesResponse, void>({
        providesTags: [createTag({ id: 'LIST', type: 'Example' })],
        query: EXAMPLES_ENDPOINTS.listExamples,
        transformResponse: (response: GenericRecord[]) =>
          convertArrayObjectKeysCase(response, 'camelCase') as ListExamplesResponse,
      }),
    }),
    overrideExisting: true,
  });
