import type { CREATE_TASK_NEXT_STEPS_OPTIONS } from '@frontend/api-client';
import type { Answer, LocalFile, SiteData } from '@frontend/api-types';
import type { SetNullable } from '@frontend/duck-tape';

// "System" is used for events that are not directly user-initiated, e.g. a background process
type Action = 'Create' | 'Delete' | 'Dismiss' | 'Select' | 'System' | 'Update' | 'View';

type TaskFlowBaseEventData = {
  currentActiveTasks?: MaybeNull<number>;
  entityId?: string;
  entityType?: string;
  individualMaxActiveTasks?: MaybeNull<number>;
  planMaxActiveTasks?: MaybeNull<number>;
  uniqueKey: string;
};

const makeEvent = <TData extends GenericRecord = never>(config: { action: Action; object: string }) =>
  // Small hack to avoid having to provide a real runtime dummy value
  config as {
    action: Action;
    data: TData;
    /** Object refers to the element interacted with, not a JS object */
    object: string;
  };

export const analyticsMap = {
  auth: {
    login: makeEvent({
      action: 'Select',
      object: 'Login Button',
    }),
    logout: makeEvent({
      action: 'Select',
      object: 'Logout Button',
    }),
  },
  commandPalette: {
    commandPaletteActionSelected: makeEvent<{ description: string; label: string }>({
      action: 'Select',
      object: 'Command Palette Action',
    }),
    commandPaletteOpened: makeEvent({
      action: 'View',
      object: 'Command Palette',
    }),
  },
  draftTasks: {
    deleteDraftTaskButtonSelected: makeEvent<{ draftTaskId: string }>({
      action: 'Select',
      object: 'Delete Draft Task Button',
    }),
    updateDraftTaskSubmitButtonSelected: makeEvent<
      TaskFlowBaseEventData & {
        attachments?: LocalFile[];
        description: string;
        draftTaskId: string;
        name: string;
        nextSteps: MaybeUndefined<string>;
      }
    >({
      action: 'Select',
      object: 'Update Draft Task Submit Button',
    }),
  },
  examples: {
    directoryBannerSelected: makeEvent({
      action: 'Select',
      object: 'Duckbill Directory Banner',
    }),
    exampleSelected: makeEvent<{ exampleId: string; exampleType?: string; taskName: string }>({
      action: 'Select',
      object: 'Example Button',
    }),
    exampleViewed: makeEvent<{ exampleId: string; exampleType?: string; taskName: string }>({
      action: 'View',
      object: 'Example',
    }),
    exploreFeedSelected: makeEvent({
      action: 'Select',
      object: 'Explore Feed Button',
    }),
  },
  invite: {
    inviteModalOpened: makeEvent({
      action: 'View',
      object: 'Invite Modal',
    }),
  },
  navigation: {
    tabBarIconSelected: makeEvent<{
      label: MaybeUndefined<string>;
      params: MaybeUndefined<GenericRecord>;
      route: MaybeUndefined<string>;
    }>({
      action: 'Select',
      object: 'Tab Bar Icon',
    }),
  },
  onboarding: {
    androidDownloadLinkSelected: makeEvent({
      action: 'Select',
      object: 'Android Download Link',
    }),
    iosDownloadLinkSelected: makeEvent({
      action: 'Select',
      object: 'IOS Download Link',
    }),
    scheduleACallSelected: makeEvent({
      action: 'Select',
      object: 'Schedule A Call',
    }),
  },
  options: {
    deselectedOption: makeEvent<{ optionId: string; sheetId: string }>({
      action: 'Select',
      object: 'Option Deselect',
    }),
    reviewSelectedOptions: makeEvent<{ sheetId: string }>({
      action: 'Select',
      object: 'Sheet Review Button',
    }),
    selectedOption: makeEvent<{ optionId: string; sheetId: string }>({
      action: 'Select',
      object: 'Option Select',
    }),
    submitSelectedOptions: makeEvent<{ sheetId: string }>({
      action: 'Select',
      object: 'Sheet Submit Review Button',
    }),
    viewedOption: makeEvent<{ optionId: string; sheetId: string }>({
      action: 'View',
      object: 'Option',
    }),
    viewedSheet: makeEvent<{ sheetId: string }>({
      action: 'View',
      object: 'Sheet',
    }),
  },
  recurrences: {
    createRecurrenceButtonSelected: makeEvent({
      action: 'Select',
      object: 'Create Recurrence Button',
    }),
    createRecurrenceSubmitButtonSelected: makeEvent<
      TaskFlowBaseEventData & {
        description: string;
        exampleId?: string;
        exampleType?: string;
        name: string;
        nextSteps: MaybeUndefined<string>;
      }
    >({ action: 'Select', object: 'Create Repeating Task Submit Button' }),
    createRecurrenceSuccessMessageViewed: makeEvent<
      TaskFlowBaseEventData & { exampleId?: string; exampleType?: string; recurrenceId: string }
    >({
      action: 'View',
      object: 'Create Repeating Task Success Message',
    }),
    deleteRecurrenceButtonSelected: makeEvent<{ recurrenceId: string }>({
      action: 'Select',
      object: 'Delete Repeating Task Button',
    }),
  },
  research: {
    fetchUrlMetadataFailed: makeEvent<Pick<SiteData, 'url'> & { threadId: string }>({
      action: 'System',
      object: 'Fetch Url Metadata Failed',
    }),
    // Highlighted text in the chat
    linkTextSelected: makeEvent<{ entityId: string; text: string; threadId: string }>({
      action: 'Select',
      object: 'Thread Link Text',
    }),
    researchThreadExampleButtonSelected: makeEvent<{
      example: string;
    }>({
      action: 'Select',
      object: 'Research Thread Example Button',
    }),
    researchThreadFromExampleCreated: makeEvent<{
      example: string;
      threadDescription: string;
    }>({
      action: 'Create',
      object: 'Research Thread',
    }),
    unusedSuggestedTaskCardSelected: makeEvent<{ id: string; isRecurrence: boolean; researchThreadId: string }>({
      action: 'Select',
      object: 'Unused Suggested Task Card',
    }),
    // Web view closed after viewing
    webViewClosedFromLink: makeEvent<SiteData & { threadId: string }>({
      action: 'Dismiss',
      object: 'Thread Link Web View',
    }),
    // Web view opened from a link
    webViewOpenedFromLink: makeEvent<SiteData & { threadId: string }>({
      action: 'Select',
      object: 'Thread Link Web View',
    }),
  },
  secureNote: {
    secureNoteDeleted: makeEvent<{ secureNoteId: string }>({
      action: 'Delete',
      object: 'Secure Note',
    }),
    secureNoteUpdated: makeEvent<{ secureNoteId: string }>({
      action: 'Update',
      object: 'Secure Note',
    }),
    secureNoteViewed: makeEvent<{ secureNoteId: string }>({
      action: 'View',
      object: 'Secure Note',
    }),
  },
  tasks: {
    completeTaskButtonSelected: makeEvent<{ taskId: string }>({
      action: 'Select',
      object: 'Complete Task Button In Chat Footer',
    }),
    completeTaskModalSubmitButtonSelected: makeEvent<{ taskId: string }>({
      action: 'Select',
      object: 'Complete Task Modal Submit Button',
    }),
    createTaskDirectLinkSelected: makeEvent<{ exampleId?: string; exampleType?: string; taskName?: string }>({
      action: 'Select',
      object: 'Create Task Direct Link',
    }),
    createTaskDirectLinkViewed: makeEvent<{ exampleId?: string; exampleType?: string; taskName?: string }>({
      action: 'View',
      object: 'Create Task Direct Link',
    }),
    createTaskNextButtonSelected: makeEvent<
      TaskFlowBaseEventData & {
        answers: SetNullable<
          {
            answers: Answer[];
            attachments: LocalFile[];
            description: string;
            name: string;
            nextSteps: (typeof CREATE_TASK_NEXT_STEPS_OPTIONS)[number]['value'];
          },
          'nextSteps'
        >;
        exampleId?: string;
        exampleType?: string;
      }
    >({ action: 'Select', object: 'Create Task Next Button' }),
    createTaskSubmitButtonSelected: makeEvent<
      TaskFlowBaseEventData & {
        attachments?: LocalFile[];
        description: string;
        exampleId?: string;
        exampleType?: string;
        name: string;
        nextSteps: MaybeUndefined<string>;
      }
    >({ action: 'Select', object: 'Create Task Submit Button' }),
    createTaskSuccessMessageViewed: makeEvent<
      TaskFlowBaseEventData & {
        exampleId?: string;
        exampleType?: string;
        isDraft: boolean;
        taskId: string;
      }
    >({
      action: 'View',
      object: 'Create Task Success Message',
    }),
    reviewTaskBannerSelected: makeEvent<{ taskId: string }>({
      action: 'Select',
      object: 'Review Task Banner',
    }),
    // From the task list
    reviewTaskButtonSelected: makeEvent<{ taskId: string }>({
      action: 'Select',
      object: 'Review Task Button In Task Card',
    }),
    reviewTasksFormViewed: makeEvent<{ taskId: string }>({ action: 'View', object: 'Review Tasks Form' }),
    selectedFindRepeatingTasksBanner: makeEvent({
      action: 'Select',
      object: 'Find Repeating Tasks Banner',
    }),
    skipTaskButtonInCardSelected: makeEvent<{ taskId: string }>({
      action: 'Select',
      object: 'Skip Task Button In Task Card',
    }),
    skipTaskButtonSelected: makeEvent<{ taskId: string }>({ action: 'Select', object: 'Skip Task Button' }),
    skipTaskSubmitButtonSelected: makeEvent<{ taskId: string }>({
      action: 'Select',
      object: 'Skip Task Submit Button',
    }),
    snoozeTaskButtonSelected: makeEvent<{ taskId: string }>({
      action: 'Select',
      object: 'Snooze Task Button In Chat Footer',
    }),
    snoozeTaskSubmitButtonSelected: makeEvent<{ taskId: string }>({
      action: 'Select',
      object: 'Snooze Task Submit Button',
    }),
    startTaskButtonSelected: makeEvent<{ taskId: string }>({ action: 'Select', object: 'Start Task Button' }),
    taskFlowEnded: makeEvent<TaskFlowBaseEventData & { currentStep: string }>({
      action: 'View',
      object: 'Task Flow Ended',
    }),
    taskFlowStarted: makeEvent<TaskFlowBaseEventData>({ action: 'View', object: 'Task Flow Started' }),
    // From the task list
    unsnoozeTaskButtonInCardSelected: makeEvent<{ taskId: string }>({
      action: 'Select',
      object: 'Unsnooze Task Button In Task Card',
    }),
    unsnoozeTaskButtonSelected: makeEvent<{ taskId: string }>({
      action: 'Select',
      object: 'Unsnooze Task Button In Chat Footer',
    }),
    unsnoozeTaskSubmitButtonSelected: makeEvent<{ taskId: string }>({
      action: 'Select',
      object: 'Unsnooze Task Submit Button',
    }),
    viewedTaskCreationQuestion: makeEvent<{
      aiGenerated: boolean;
      question: string;
      taskDescription: string;
    }>({ action: 'View', object: 'Task Creation Question' }),
  },
} as const;

export type AnalyticsMap = typeof analyticsMap;

export type AnalyticsTopic = keyof AnalyticsMap;

export type AnalyticsEventPayload<
  Topic extends AnalyticsTopic,
  Event extends keyof AnalyticsMap[Topic],
> = AnalyticsMap[Topic][Event] extends { data: GenericRecord } ? AnalyticsMap[Topic][Event]['data'] : never;
