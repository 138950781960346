import { useEffect, useState } from '@frontend/react';
import type { Embla } from '@frontend/web-react';
import { Button, Carousel, Image, RenderHTML, XStack, YStack } from '@frontend/web-react';
import { useCompleteStoryMutation, useListStoriesQuery } from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';

type StoriesSlidesProps = {
  onComplete: () => void;
};

export const StoriesSlides = ({ onComplete }: StoriesSlidesProps) => {
  const { data: stories, isFetching } = useListStoriesQuery();
  const story = stories?.[0];

  const [activeSlide, setActiveSlide] = useState(0);
  const [embla, setEmbla] = useState<Embla | null>(null);

  const [completeStory] = useCompleteStoryMutation();
  const isOnLastSlide = story && activeSlide === story.pages.length - 1;

  useEffect(() => {
    if (!isFetching && !stories?.length) {
      onComplete();
    }
  }, [isFetching]);

  const handleNext = () => {
    if (isOnLastSlide) {
      handleMutation({
        mutation: async () => completeStory(story.id).unwrap(),
        showErrorToast: false,
        showSuccessToast: false,
      });
      onComplete();
    } else {
      embla?.scrollNext();
    }
  };

  const slides = story?.pages.map((page) => (
    <Carousel.Slide className="flex grow-1 items-center justify-center h-full px-md" key={page.id}>
      <YStack className="flex grow-1 items-center justify-center h-full py-lg">
        {page.text ? <RenderHTML className="text-textInverse select-none" html={page.text} /> : null}
        {page.imageUrl ? (
          <YStack className="max-h-full flex-1 grow-1 justify-center">
            {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
            <Image className="max-h-[65vh] object-contain" source={page.imageUrl} />
          </YStack>
        ) : null}
      </YStack>
    </Carousel.Slide>
  ));

  return (
    <YStack className="h-screen w-screen flex items-center justify-center bg-surfaceBrand">
      {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
      <YStack className="min-w-[320px] max-w-[501px] size-full flex items-center py-md">
        {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
        <YStack
          // eslint-disable-next-line tailwindcss/no-arbitrary-value
          className="flex items-center min-w-[320px] max-w-[501px] w-full"
          //eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          style={{ height: 'calc(100% - 80px)' }}
        >
          <Carousel
            className="size-full items-center justify-center grow-1 "
            classNames={{
              container: 'h-full',
              indicator: 'flex-1 bg-white',
              indicators: 'px-md',
              viewport: 'h-full',
            }}
            draggable
            getEmblaApi={setEmbla}
            onSlideChange={(index) => setActiveSlide(index)}
            styles={{ indicators: { bottom: 'unset !important', top: 0 } }}
            withControls={false}
            withIndicators
          >
            {slides}
          </Carousel>
        </YStack>
        <XStack className="px-md w-full">
          <Button fullWidth label={isOnLastSlide ? 'Get started now' : 'Continue'} onClick={handleNext} />
        </XStack>
      </YStack>
    </YStack>
  );
};
