import type { MakeInputProps, SelectOption } from '@frontend/forms';
import type { ClassName } from '@frontend/web-utils';
import { concatClasses } from '@frontend/web-utils';
import { XStack } from '../../../Containers';
import { Tag } from '../../../Tags';

type TagSingleSelectInputProps<TValue extends string, CanDeselect extends boolean> = MakeInputProps<
  CanDeselect extends true ? MaybeUndefined<TValue> : TValue,
  GenericRecord
> & {
  canDeselect: CanDeselect;
  className?: ClassName;
  options: SelectOption<TValue, never, { label: string }>[];
};

export const TagSingleSelectInput = <TValue extends string, CanDeselect extends boolean>({
  canDeselect,
  className,
  onChange,
  options,
  value,
}: TagSingleSelectInputProps<TValue, CanDeselect>) => {
  const onClickSelectOption = (option: SelectOption<TValue, never, { label: string }>) => {
    if (value && canDeselect && option.value === value) {
      // @ts-expect-error Tedious types
      return onChange(undefined);
    }
    return onChange(option.value);
  };

  return (
    <XStack className={concatClasses('flex-wrap gap-sm justify-center', className)}>
      {options.map((option) => {
        return (
          <Tag
            isActive={option.value === value}
            key={option.value}
            label={option.label}
            onClick={() => onClickSelectOption(option)}
          />
        );
      })}
    </XStack>
  );
};
