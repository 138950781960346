import { createTag } from '@frontend/api-client/utils';
import type { BaseApi } from '@frontend/api-client/utils/types';
import type { CalendarRecurrence, ListCalendarRecurrencesResponse } from '@frontend/api-types';
import { convertArrayObjectKeysCase } from '@frontend/duck-tape';
import CALENDAR_RECURRENCES_ENDPOINTS from './endpoints';

export const getCalendarRecurrencesApi = (baseApi: BaseApi) =>
  baseApi.injectEndpoints({
    endpoints: (builder) => ({
      dismissAllCalendarRecurrences: builder.mutation<void, void>({
        invalidatesTags: [createTag({ id: 'LIST', type: 'CalendarRecurrence' })],
        query: () => ({
          method: 'POST',
          url: CALENDAR_RECURRENCES_ENDPOINTS.dismissAllCalendarRecurrences(),
        }),
      }),
      dismissCalendarRecurrence: builder.mutation<void, string>({
        invalidatesTags: (_result, _error, _data) => [createTag({ id: 'LIST', type: 'CalendarRecurrence' })],
        query: (id) => ({
          method: 'POST',
          url: CALENDAR_RECURRENCES_ENDPOINTS.dismissCalendarRecurrence(id),
        }),
      }),
      listCalendarRecurrences: builder.query<CalendarRecurrence[], void>({
        providesTags: [createTag({ id: 'LIST', type: 'CalendarRecurrence' })],
        query: CALENDAR_RECURRENCES_ENDPOINTS.listCalendarRecurrences,
        transformResponse: (response: GenericRecord[]) =>
          convertArrayObjectKeysCase(response, 'camelCase') as ListCalendarRecurrencesResponse,
      }),
    }),
    overrideExisting: true,
  });
