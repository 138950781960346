import { getApiReactHooks } from '@frontend/api-client';
import { baseApi } from '@frontend/web/redux';

export const {
  useChangeEmailMutation,
  useChangePasswordMutation,
  useCloseTaskMutation,
  useCompleteStoryMutation,
  useConvertDraftTaskToTaskMutation,
  useCreateAddressMutation,
  useCreateClientMutation,
  useCreateCommentMutation,
  useCreateGeneralFeedbackMutation,
  useCreateInviteMutation,
  useCreateRecurrenceMutation,
  useCreateSetupIntentMutation,
  useCreateSubscriptionMutation,
  useCreateTaskMutation,
  useCreateTaskReviewMutation,
  useCreateThreadMutation,
  useCreateWaitlistContactMutation,
  useDeactivateOAuthAccountMutation,
  useDeleteCardMutation,
  useDeleteDraftTaskMutation,
  useDeleteRecurrenceMutation,
  useDeleteSecureNoteMutation,
  useDeleteThreadMutation,
  useEditProfileMutation,
  useForgotPasswordMutation,
  useLazyListCommentsQuery,
  useLazyListExamplesQuery,
  useLazyListRecurrencesQuery,
  useLazyListTaskSearchResultsQuery,
  useLazyListThreadsQuery,
  useLazyRetrieveClientQuery,
  useLazyRetrieveClientStatusQuery,
  useLazyRetrieveHouseholdQuery,
  useLazyRetrievePromotionDetailsQuery,
  useLazyRetrieveRecurrenceQuery,
  useLazyRetrieveReferrerDetailsQuery,
  useLazyRetrieveSecureNoteContentQuery,
  useLazyRetrieveTaskQuery,
  useLazyRetrieveThreadEntityLinksQuery,
  useLazyRetrieveThreadQuery,
  useListAddressesQuery,
  useListCalendarRecurrencesQuery,
  useListCardsQuery,
  useListCommentsQuery,
  useListDraftTasksQuery,
  useListExamplesQuery,
  useListOAuthAccountsQuery,
  useListRecurrencesQuery,
  useListStoriesQuery,
  useListTasksQuery,
  useListThreadsQuery,
  useLogInMutation,
  useReplaceSecureNoteContentMutation,
  useResetPasswordMutation,
  useRetrieveClientQuery,
  useRetrieveClientStatusQuery,
  useRetrieveDraftTaskQuery,
  useRetrieveHouseholdQuery,
  useRetrieveInviteQuery,
  useRetrieveNotificationSettingsQuery,
  useRetrieveOptionSheetQuery,
  useRetrieveRecurrenceQuery,
  useRetrieveSecureNoteContentQuery,
  useRetrieveTaskQuery,
  useRetrieveThreadQuery,
  useRetrieveWaitlistStatusQuery,
  useReviewOptionSheetMutation,
  useSetupIntentConfirmedMutation,
  useShareRecurrenceMutation,
  useShareTaskMutation,
  useSkipTaskMutation,
  useSnoozeTaskMutation,
  useStartTaskMutation,
  useUnshareRecurrenceMutation,
  useUnshareTaskMutation,
  useUnsnoozeTaskMutation,
  useUpdateAddressMutation,
  useUpdateCardMutation,
  useUpdateDefaultCardMutation,
  useUpdateDraftTaskMutation,
  useUpdateNotificationSettingsMutation,
  useUpdateOAuthCodeMutation,
  useUpdateTaskMutation,
} = getApiReactHooks(baseApi);
