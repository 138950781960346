import type { ReactNode } from '@frontend/react';
import { Env } from '@frontend/web/utils';
import { Elements } from '@stripe/react-stripe-js';
import type { StripeElementsOptions } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import type { ComponentType } from 'react';

const stripeOptions: StripeElementsOptions = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap',
    },
  ],
};

const initiateStripe = loadStripe(Env.VITE_STRIPE_PUBLIC_KEY);

export const StripeContext = ({ children }: { children: ReactNode }) => (
  <Elements options={stripeOptions} stripe={initiateStripe}>
    {children}
  </Elements>
);

export const withStripe = <TProps extends GenericRecord>(WrappedComponent: ComponentType) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const StripeHOC = (props: TProps) => {
    return (
      <Elements options={stripeOptions} stripe={initiateStripe}>
        <WrappedComponent {...props} />
      </Elements>
    );
  };
  return StripeHOC;
};
