import type { TaskExample } from '@frontend/api-types';
import { P, match, splitIntoGroups } from '@frontend/duck-tape';
import { validators, z } from '@frontend/forms';
import { useFeatureIsOn, useMemo } from '@frontend/react';
import { PageContainer, SkeletonList, Tag, XStack, YStack, useBreakpoints } from '@frontend/web-react';
import { ExampleCard } from '@frontend/web/components';
import { DirectoryCard } from '@frontend/web/components/DomainSpecific/DirectoryCard';
import { useAnalytics, useListExamplesQuery } from '@frontend/web/hooks';
import { useCreateTaskContext } from '@frontend/web/hooks/context/useCreateTaskContext';
import { Env, createFileRoute } from '@frontend/web/utils';

type FilterType = 'community' | 'forYou';

const Feed = () => {
  const { track } = useAnalytics();
  const { openCreateTaskModal } = useCreateTaskContext();
  const navigate = Route.useNavigate();
  const search = Route.useSearch();
  const { data: examples = [], isLoading } = useListExamplesQuery();
  const { enabled: isDirectoryBannerVisible } = useFeatureIsOn('directory-banner');
  const onClickFilter = (newFilter: FilterType) => () =>
    navigate({ search: { filter: newFilter === search.filter ? undefined : newFilter }, to: '/app/feed' });

  const { currentBreakpoint } = useBreakpoints();

  const groups = useMemo(
    () =>
      splitIntoGroups(
        examples?.filter((example) =>
          match(search.filter)
            .with('community', () => !example.client)
            .with('forYou', () => example.client)
            .with(undefined, () => true)
            .exhaustive(),
        ),
        match(currentBreakpoint)
          .with('mobile', () => 1)
          .with('tablet', () => 2)
          .with(P.union('smallDesktop'), () => 3)
          .with(P.union('extraLargeDesktop', 'largeDesktop'), () => 4)
          .exhaustive(),
      ),
    [examples, search, currentBreakpoint],
  );

  const onClickExampleCard = (example: TaskExample) => {
    track({
      data: { exampleId: example.id, exampleType: example.exampleType, taskName: example.name },
      event: 'exampleSelected',
      topic: 'examples',
    });
    openCreateTaskModal({
      data: {
        description: example.description,
        exampleId: example.id,
        exampleType: example.exampleType,
        name: example.name,
      },
      isRecurrence: false,
    });
  };

  return (
    <PageContainer isScrollable={false} padding="none">
      <XStack className="gap-x-sm p-md">
        <Tag isActive={search.filter === 'community'} label="Community" onClick={onClickFilter('community')} />
        <Tag isActive={search.filter === 'forYou'} label="For You" onClick={onClickFilter('forYou')} />
      </XStack>
      <YStack className="overflow-y-scroll px-md">
        {isLoading ? (
          <SkeletonList countSkeletons={6} />
        ) : (
          <XStack className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-md">
            {groups.map((group, index) => {
              return (
                <YStack className="gap-y-md" key={index}>
                  {index === 0 && isDirectoryBannerVisible ? (
                    <DirectoryCard
                      directoryUrl={Env.VITE_DUCKBILL_DIRECTORY_URL}
                      onClickUrl={() => track({ event: 'directoryBannerSelected', topic: 'examples' })}
                    />
                  ) : null}
                  {group.map((example) => (
                    <ExampleCard key={example.id} onClick={() => onClickExampleCard(example)} {...example} />
                  ))}
                </YStack>
              );
            })}
          </XStack>
        )}
      </YStack>
    </PageContainer>
  );
};

export const Route = createFileRoute('/app/feed')({
  component: Feed,
  validateSearch: (search) =>
    z
      .object({
        filter: validators.readonlyStringEnum<FilterType>(['community', 'forYou']).optional(),
      })
      .parse(search),
});
