import type { BaseApi } from '@frontend/api-client/utils/types';
import type { CreateGeneralFeedbackRequest } from '@frontend/api-types';
import { convertObjectKeysCase } from '@frontend/duck-tape';
import { PRODUCT_FEEDBACK_ENDPOINTS } from './endpoints';

export const getProductFeedbackApi = (baseApi: BaseApi) =>
  baseApi.injectEndpoints({
    endpoints: (builder) => ({
      createGeneralFeedback: builder.mutation<unknown, CreateGeneralFeedbackRequest>({
        query: (payload) => ({
          body: convertObjectKeysCase(payload, 'snakeCase'),
          method: 'POST',
          url: PRODUCT_FEEDBACK_ENDPOINTS.createProductFeedback,
        }),
        transformResponse: (response: GenericRecord) =>
          convertObjectKeysCase(response, 'camelCase') as CreateGeneralFeedbackRequest,
      }),
    }),
  });
