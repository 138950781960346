import { ElderlyLadyWithPhone } from '@frontend/assets';
import {
  Button,
  Card,
  Icon,
  ScrollableContentPanel,
  SplitImagePageContainer,
  Text,
  XStack,
  YStack,
} from '@frontend/web-react';
import type { IconName } from '@frontend/web-react/components/Primitives/Icon/helpers';
import { useOnboardingContext } from '@frontend/web/context/OnboardingContext';
import { OnboardingStepHeader } from './OnboardingStepHeader';

const FAQ_CONTENT = [
  {
    answer:
      "Think of us as your trusted partner or an extension of yourself who you can count on to execute your life admin - scheduling, researching, shopping, booking, and planning. Need something on repeat? Tell us once, and we'll take over the remembering. If your task requires an expert (like a wedding planner, travel advisor, or accountant), we'll hook you up with the right person to get the job done.",
    icon: 'IconChecklist',
    question: 'What kind of things can you help me with?',
  },
  {
    answer:
      'Our chaos-organizing copilots, super-powered with AI, who see ahead and do ahead for you, so you can actually let go a little - without anything getting dropped.',
    icon: 'IconMoodHeart',
    question: 'Who is completing the tasks?',
  },
  {
    answer:
      "Your data is securely stored using modern encryption standards and accessed by our copilot team only when required for your tasks. All of our copilots are background checked, and the entire Duckbill team takes your security seriously. You are free at any time to request we delete anything you've shared.",
    icon: 'IconLock',
    question: "Who has visibility into the information I'm sharing?",
  },
] satisfies {
  answer: string;
  icon: IconName;
  question: string;
}[];

export const FAQs = () => {
  const { decrementStep, incrementStep } = useOnboardingContext();

  return (
    <SplitImagePageContainer imageSource={ElderlyLadyWithPhone}>
      <ScrollableContentPanel
        footer={
          <YStack>
            <Button disabled={false} label="Sounds good" onClick={incrementStep} />
          </YStack>
        }
        justifyContent="start"
      >
        <YStack className="gap-y-md">
          <YStack gapY="md">
            <OnboardingStepHeader
              onClickBackArrow={decrementStep}
              title="Got some questions already? We're one step ahead of you!"
            />
          </YStack>
          <YStack className="justify-start items-start py-md" gapY="md">
            {FAQ_CONTENT.map((faq, index) => (
              <Card className="bg-white flex gap-y-md rounded-lg" key={index} padding="md" withBorder>
                <XStack alignItems="center" gapX="md">
                  <XStack className="rounded-full p-sm bg-lime-100">
                    <Icon name={faq.icon} />
                  </XStack>
                  <Text type="h4">{faq.question}</Text>
                </XStack>
                <Text>{faq.answer}</Text>
              </Card>
            ))}
          </YStack>
        </YStack>
      </ScrollableContentPanel>
    </SplitImagePageContainer>
  );
};
