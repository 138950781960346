import { formatEndpoint } from '@frontend/duck-tape';

const namespace = 'push_notifications';

const REGISTER = 'tokens';

const PUSH_NOTIFICATIONS_ENDPOINTS = {
  register: () => formatEndpoint(REGISTER, namespace),
};

export default PUSH_NOTIFICATIONS_ENDPOINTS;
