import { getSocketClient } from '@frontend/sockets';
import { captureSentryException, captureSentryMessage } from '@frontend/web-utils';
import { Env } from '@frontend/web/utils/env';

export const { disconnectSocket, getSocket, initializeSocket } = getSocketClient({
  apiUrl: Env.VITE_API_BASE_URL,
  captureException: captureSentryException,
  captureMessage: captureSentryMessage,
  env: Env.VITE_ENV,
});
