import type { Spacing } from '@frontend/constants';
import { isString, match, type StrictExtract } from '@frontend/duck-tape';
import { useState, type ReactNode } from '@frontend/react';
import { Icon, ScrollView, Spinner, Text, XStack, YStack } from '@frontend/web-react';
import type { ClassName } from '@frontend/web-utils';
import { applyClass, concatClasses, safeCss } from '@frontend/web-utils';
import type { ModalProps as MModalProps } from '@mantine/core';
import { Modal as MModal, useMantineTheme } from '@mantine/core';
import { Title } from '../../Texts';

type BorderRadius =
  | 'rounded-2xl'
  | 'rounded-3xl'
  | 'rounded-full'
  | 'rounded-lg'
  | 'rounded-md'
  | 'rounded-none'
  | 'rounded-sm'
  | 'rounded-xl'
  | 'rounded';

export type ModalProps = Omit<MModalProps, 'centered' | 'classNames' | 'opened'> & {
  Footer?: ReactNode;
  Header?: ReactNode;
  contentBorderRadius?: BorderRadius;
  contentContainerClassName?: ClassName;
  headerClassName?: ClassName;
  headerGap?: 'lg' | 'md' | 'none' | 'sm';
  isForced?: boolean;
  isLoading?: boolean;
  isOpen: boolean;
  padding?: StrictExtract<Spacing, 'lg' | 'md' | 'sm' | 'xl' | 'xs'> | 'none';
  subtitle?: ReactNode;
  titlesAlignment?: 'center' | 'end' | 'start';
};

const getHeaderBottomPaddingClassName = ({ headerGap }: PickRequired<ModalProps, 'headerGap'>) =>
  match(headerGap)
    .with('lg', () => 'pb-lg')
    .with('md', () => 'pb-md')
    .with('sm', () => 'pb-sm')
    .with('none', () => 'pb-none')
    .exhaustive();

export const getModalHeaderProps = ({ headerGap }: PickRequired<ModalProps, 'headerGap'>) =>
  concatClasses('flex flex-col gap-y-sm min-h-none', getHeaderBottomPaddingClassName({ headerGap }));

export const Modal = ({
  children,
  className,
  contentBorderRadius = 'rounded-md',
  contentContainerClassName,
  Footer,
  Header,
  headerClassName,
  headerGap = 'lg',
  isForced,
  isLoading,
  isOpen,
  onClose,
  padding = 'lg',
  size = '460px',
  subtitle,
  title,
  titlesAlignment = 'center',
  withCloseButton,
  ...props
}: ModalProps) => {
  const theme = useMantineTheme();
  const classNames: MModalProps['classNames'] = {
    content: safeCss(contentBorderRadius),
    root: className,
  };

  const [shake, setShake] = useState(false);

  const onCloseComposed = () => {
    if (isForced) {
      setShake(true);
      setTimeout(() => setShake(false), 250); // reset shake after animation duration
      return;
    }
    onClose();
  };

  return (
    <MModal.Root
      centered
      classNames={classNames}
      onClose={onCloseComposed}
      opened={isOpen}
      padding={padding}
      size={size}
      {...props}
    >
      <MModal.Overlay color={theme.colors.neutral[9]} />
      <MModal.Content className={applyClass(shake, 'shake-effect')}>
        {Header ? (
          Header
        ) : title || subtitle ? (
          <MModal.Header className={concatClasses(getModalHeaderProps({ headerGap }), headerClassName)}>
            <XStack alignItems="center" className="w-full" justifyContent="spaceBetween">
              {withCloseButton ? <YStack className="size-lg" /> : null}
              {title ? (
                <Title textAlign={titlesAlignment} type="h4">
                  {title}
                </Title>
              ) : null}
              {withCloseButton ? <Icon canFocus={false} name="IconX" onClick={onCloseComposed} size="lg" /> : null}
            </XStack>
            {subtitle ? isString(subtitle) ? <Text type="p-sm">{subtitle}</Text> : subtitle : null}
          </MModal.Header>
        ) : null}
        <MModal.Body>
          {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
          <ScrollView className={concatClasses('max-h-[60vh] overflow-auto', contentContainerClassName)}>
            {isLoading ? (
              // eslint-disable-next-line tailwindcss/no-arbitrary-value
              <YStack alignItems="center" className="min-h-[300px]" justifyContent="center">
                <Spinner />
              </YStack>
            ) : (
              children
            )}
          </ScrollView>
        </MModal.Body>
        {Footer ? <YStack>{Footer}</YStack> : null}
      </MModal.Content>
    </MModal.Root>
  );
};
