import type { DeleteSecureNoteRequest } from '@frontend/api-types';
import { formatEndpoint } from '@frontend/duck-tape';

const namespace = 'secure_notes';
const CONTENT = 'content';

export const SECURE_NOTES_ENDPOINTS = {
  deleteSecureNote: ({ secureNoteId, taskId }: DeleteSecureNoteRequest) =>
    formatEndpoint(`${secureNoteId}`, namespace, { queryParams: { task_id: taskId } }),
  replaceSecureNoteContent: (secureNoteId: string) => formatEndpoint(`${secureNoteId}/${CONTENT}`, namespace),
  retrieveSecureNoteContent: (secureNoteId: string) => formatEndpoint(`${secureNoteId}/${CONTENT}`, namespace),
};
