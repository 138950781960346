import type { AppShellProps as MAppShellProps } from '@mantine/core';
import { AppShell as MAppShell } from '@mantine/core';

export type AppShellProps = MAppShellProps;
const AppShell = (props: AppShellProps) => {
  return <MAppShell header={{ height: 72, offset: false }} {...props} />;
};

// eslint-disable-next-line fp/no-mutation
AppShell.Header = MAppShell.Header;
// eslint-disable-next-line fp/no-mutation
AppShell.Main = MAppShell.Main;
// eslint-disable-next-line fp/no-mutation
AppShell.Aside = MAppShell.Aside;
// eslint-disable-next-line fp/no-mutation
AppShell.Footer = MAppShell.Footer;
// eslint-disable-next-line fp/no-mutation
AppShell.Navbar = MAppShell.Navbar;
// eslint-disable-next-line fp/no-mutation
AppShell.Section = MAppShell.Section;

export { AppShell };
