import { identity } from '@frontend/duck-tape';
import type { CommonModalProps } from '@frontend/web-react';
import { ConfirmationModal, Icon } from '@frontend/web-react';
import { QuackActionsContext } from '@frontend/web/context/QuackActionsContext';
import { useAnalytics, useRetrieveTaskQuery, useUnsnoozeTaskMutation } from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';
import { useContext, useEffect } from 'react';

export type UnsnoozeTaskModalProps = CommonModalProps & {
  onComplete?: () => void;
  openModal: () => void;
  taskId: string;
};

export const UnsnoozeTaskModal = ({ isOpen, onClose, onComplete, openModal, taskId }: UnsnoozeTaskModalProps) => {
  const [unsnoozeTask, { isLoading: isUnsnoozeLoading }] = useUnsnoozeTaskMutation();
  const { data: task } = useRetrieveTaskQuery(taskId);
  const { addAction, removeAction } = useContext(QuackActionsContext);
  const canBeUnsnoozed = task?.status === 'snoozed';
  const { track } = useAnalytics();

  useEffect(() => {
    if (canBeUnsnoozed) {
      addAction({
        actions: [
          {
            description: 'Unsnooze task',
            id: 'unsnooze',
            label: 'Unsnooze Task',
            leftSection: <Icon name="IconAlarmOff" />,
            onClick: openModal,
          },
        ],
        group: 'Current Task',
      });

      return () => {
        removeAction('unsnooze', 'Current Task');
      };
    }
    return identity;
  }, [task, canBeUnsnoozed]);

  const onClickUnsnoozeTask = async () => {
    track({ data: { taskId }, event: 'unsnoozeTaskSubmitButtonSelected', topic: 'tasks' });
    return handleMutation({
      mutation: async () => {
        return unsnoozeTask({ taskId }).unwrap();
      },
      onSuccess: () => {
        onClose();
        onComplete?.();
      },
      showErrorToast: true,
      showSuccessToast: true,
      successMessage: 'Task unsnoozed successfully',
    });
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      isSubmitLoading={isUnsnoozeLoading}
      onClickCancel={onClose}
      onClickSubmit={onClickUnsnoozeTask}
      onClose={onClose}
      submitLabel="Unsnooze"
      subtitle='This task will show up in the "In progress" tab.'
      title="Would you like to bring this task back?"
      variant="primary"
    />
  );
};
