import { getAddressesApi } from './api/addresses/slice';
import { getCalendarRecurrencesApi } from './api/calendarRecurrences/slice';
import { getClientApi } from './api/client/slice';
import { getDraftTasksApi } from './api/draftTasks/slice';
import { getSheetsApi } from './api/duckuments/sheets/slice';
import { getExamplesApi } from './api/examples/slice';
import { getHouseholdApi } from './api/household/slice';
import { getInvitesApi } from './api/invites/slice';
import { getMinAppVersionApi } from './api/minAppVersion/slice';
import { getNotificationSettingsApi } from './api/notificationSettings/slice';
import { getOAuthApi } from './api/oauth/slice';
import { getPasswordApi } from './api/password/slice';
import { getPaymentsApi } from './api/payments/slice';
import { getProductFeedbackApi } from './api/productFeedback/slice';
import { getPushNotificationsApi } from './api/pushNotifications/slice';
import { getRecurrencesApi } from './api/recurrences/slice';
import { getTaskReviewsApi } from './api/reviews/slice';
import { getSecureNotesApi } from './api/secureNotes/slice';
import { getStoriesApi } from './api/stories/slice';
import { getTasksApi } from './api/tasks/slice';
import { getTextSearchApi } from './api/textSearch/slice';
import { getThreadsApi } from './api/threads/slice';
import { getWaitlistsApi } from './api/waitlists/slice';
import type { BaseApi } from './utils/types';

export const getApiReactHooks = (api: BaseApi) => {
  const {
    useChangeEmailMutation,
    useCreateClientMutation,
    useEditProfileMutation,
    useLazyRetrieveClientQuery,
    useLazyRetrieveClientStatusQuery,
    useLazyRetrieveReferrerDetailsQuery,
    useLogInMutation,
    useRetrieveClientQuery,
    useRetrieveClientStatusQuery,
  } = getClientApi(api);

  const tasksApi = getTasksApi(api);

  const {
    useCloseTaskMutation,
    useCreateCommentMutation,
    useCreateTaskMutation,
    useLazyListCommentsQuery,
    useLazyRetrieveTaskQuery,
    useListCommentsQuery,
    useListTasksQuery,
    useRetrieveTaskQuery,
    useShareTaskMutation,
    useSkipTaskMutation,
    useSnoozeTaskMutation,
    useStartTaskMutation,
    useUnshareTaskMutation,
    useUnsnoozeTaskMutation,
    useUpdateTaskMutation,
  } = tasksApi;

  const {
    useConvertDraftTaskToTaskMutation,
    useDeleteDraftTaskMutation,
    useListDraftTasksQuery,
    useRetrieveDraftTaskQuery,
    useUpdateDraftTaskMutation,
  } = getDraftTasksApi(api);

  const { useCreateAddressMutation, useListAddressesQuery, useUpdateAddressMutation } = getAddressesApi(api);

  const { useLazyListExamplesQuery, useListExamplesQuery } = getExamplesApi(api);

  const { useChangePasswordMutation, useForgotPasswordMutation, useResetPasswordMutation } = getPasswordApi(api);

  const { useCreateTaskReviewMutation } = getTaskReviewsApi(api, tasksApi);

  const { useRetrieveOptionSheetQuery, useReviewOptionSheetMutation } = getSheetsApi(api);

  const {
    useDeleteSecureNoteMutation,
    useLazyRetrieveSecureNoteContentQuery,
    useReplaceSecureNoteContentMutation,
    useRetrieveSecureNoteContentQuery,
  } = getSecureNotesApi(api);
  const { useRetrieveMinAppVersionQuery } = getMinAppVersionApi(api);

  const { useRetrieveNotificationSettingsQuery, useUpdateNotificationSettingsMutation } =
    getNotificationSettingsApi(api);

  const { useCreateGeneralFeedbackMutation } = getProductFeedbackApi(api);

  const { useDeactivateOAuthAccountMutation, useListOAuthAccountsQuery, useUpdateOAuthCodeMutation } = getOAuthApi(api);

  const {
    useCreateRecurrenceMutation,
    useDeleteRecurrenceMutation,
    useLazyListRecurrencesQuery,
    useLazyRetrieveRecurrenceQuery,
    useListRecurrencesQuery,
    useRetrieveRecurrenceQuery,
    useShareRecurrenceMutation,
    useUnshareRecurrenceMutation,
  } = getRecurrencesApi(api);

  const { useCompleteStoryMutation, useListStoriesQuery } = getStoriesApi(api);
  const { useCreateWaitlistContactMutation, useRetrieveWaitlistStatusQuery } = getWaitlistsApi(api);

  const { useCreateInviteMutation, useRetrieveInviteQuery } = getInvitesApi(api);
  const {
    useCreateSetupIntentMutation,
    useCreateSubscriptionMutation,
    useDeleteCardMutation,
    useLazyRetrievePromotionDetailsQuery,
    useListCardsQuery,
    useSetupIntentConfirmedMutation,
    useUpdateCardMutation,
    useUpdateDefaultCardMutation,
  } = getPaymentsApi(api);

  const { useLazyRetrieveHouseholdQuery, useRetrieveHouseholdQuery } = getHouseholdApi(api);

  const {
    useCreateThreadMutation,
    useDeleteThreadMutation,
    useLazyListThreadsQuery,
    useLazyRetrieveThreadEntityLinksQuery,
    useLazyRetrieveThreadQuery,
    useListThreadsQuery,
    useRetrieveThreadQuery,
  } = getThreadsApi(api);

  const { useLazyListTaskSearchResultsQuery } = getTextSearchApi(api);

  const { useRegisterForPushTokenMutation } = getPushNotificationsApi(api);

  const {
    useDismissAllCalendarRecurrencesMutation,
    useDismissCalendarRecurrenceMutation,
    useListCalendarRecurrencesQuery,
  } = getCalendarRecurrencesApi(api);

  return {
    useChangeEmailMutation,
    useChangePasswordMutation,
    useCloseTaskMutation,
    useCompleteStoryMutation,
    useConvertDraftTaskToTaskMutation,
    useCreateAddressMutation,
    useCreateClientMutation,
    useCreateCommentMutation,
    useCreateGeneralFeedbackMutation,
    useCreateInviteMutation,
    useCreateRecurrenceMutation,
    useCreateSetupIntentMutation,
    useCreateSubscriptionMutation,
    useCreateTaskMutation,
    useCreateTaskReviewMutation,
    useCreateThreadMutation,
    useCreateWaitlistContactMutation,
    useDeactivateOAuthAccountMutation,
    useDeleteCardMutation,
    useDeleteDraftTaskMutation,
    useDeleteRecurrenceMutation,
    useDeleteSecureNoteMutation,
    useDeleteThreadMutation,
    useDismissAllCalendarRecurrencesMutation,
    useDismissCalendarRecurrenceMutation,
    useEditProfileMutation,
    useForgotPasswordMutation,
    useLazyListCommentsQuery,
    useLazyListExamplesQuery,
    useLazyListRecurrencesQuery,
    useLazyListTaskSearchResultsQuery,
    useLazyListThreadsQuery,
    useLazyRetrieveClientQuery,
    useLazyRetrieveClientStatusQuery,
    useLazyRetrieveHouseholdQuery,
    useLazyRetrievePromotionDetailsQuery,
    useLazyRetrieveRecurrenceQuery,
    useLazyRetrieveReferrerDetailsQuery,
    useLazyRetrieveSecureNoteContentQuery,
    useLazyRetrieveTaskQuery,
    useLazyRetrieveThreadEntityLinksQuery,
    useLazyRetrieveThreadQuery,
    useListAddressesQuery,
    useListCalendarRecurrencesQuery,
    useListCardsQuery,
    useListCommentsQuery,
    useListDraftTasksQuery,
    useListExamplesQuery,
    useListOAuthAccountsQuery,
    useListRecurrencesQuery,
    useListStoriesQuery,
    useListTasksQuery,
    useListThreadsQuery,
    useLogInMutation,
    useRegisterForPushTokenMutation,
    useReplaceSecureNoteContentMutation,
    useResetPasswordMutation,
    useRetrieveClientQuery,
    useRetrieveClientStatusQuery,
    useRetrieveDraftTaskQuery,
    useRetrieveHouseholdQuery,
    useRetrieveInviteQuery,
    useRetrieveMinAppVersionQuery,
    useRetrieveNotificationSettingsQuery,
    useRetrieveOptionSheetQuery,
    useRetrieveRecurrenceQuery,
    useRetrieveSecureNoteContentQuery,
    useRetrieveTaskQuery,
    useRetrieveThreadQuery,
    useRetrieveWaitlistStatusQuery,
    useReviewOptionSheetMutation,
    useSetupIntentConfirmedMutation,
    useShareRecurrenceMutation,
    useShareTaskMutation,
    useSkipTaskMutation,
    useSnoozeTaskMutation,
    useStartTaskMutation,
    useUnshareRecurrenceMutation,
    useUnshareTaskMutation,
    useUnsnoozeTaskMutation,
    useUpdateAddressMutation,
    useUpdateCardMutation,
    useUpdateDefaultCardMutation,
    useUpdateDraftTaskMutation,
    useUpdateNotificationSettingsMutation,
    useUpdateOAuthCodeMutation,
    useUpdateTaskMutation,
  };
};
