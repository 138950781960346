import type { Client } from '@frontend/api-types';
import type { SetRequired } from '@frontend/duck-tape';
import type { Context } from '@growthbook/growthbook-react';
import { GrowthBook } from '@growthbook/growthbook-react';

// eslint-disable-next-line fp/no-let
let growthbook: GrowthBook | undefined;

export const initGrowthBook = ({
  autoInit,
  env,
  ...config
}: SetRequired<Partial<Context>, 'clientKey'> & { autoInit: boolean; env: EnvType }) => {
  if (!growthbook) {
    // eslint-disable-next-line fp/no-mutation
    growthbook = new GrowthBook({
      apiHost: 'https://cdn.growthbook.io',
      enableDevMode: env === 'local' || env === 'development',
      ...config,
    });
    if (autoInit) {
      growthbook.init({ streaming: true });
    }
  } else {
    // eslint-disable-next-line no-console
    console.warn(
      'Running initGrowthBook a second time, which should not happen. To get the existing instance, use getGrowthBook instead.',
    );
  }

  return growthbook;
};

export const getGrowthBook = (params?: { required?: boolean }): NotNil<typeof growthbook> => {
  if (!growthbook && params?.required) {
    // eslint-disable-next-line no-console
    console.warn('Growthbook has not been initialized yet. Call initGrowthbook() first.');
  }
  // @ts-expect-error We want consumers of this function to always assume it's not undefined
  return growthbook;
};

export const identifyGrowthBook = async ({ client }: { client: Client }) => {
  const { id } = client;
  const growthbook = getGrowthBook({ required: true });
  await growthbook.setAttributes({ clientId: id });
  return growthbook?.refreshFeatures();
};

export const deidentifyGrowthBook = () => {
  const growthbook = getGrowthBook({ required: true });
  growthbook.setAttributes({});
  return;
};

export { type GrowthBook };
