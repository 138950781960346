import type { MakeInputProps } from '@frontend/forms';
import { safeCss } from '@frontend/web-utils';
import type { PasswordInputProps as MPasswordInputProps } from '@mantine/core';
import { PasswordInput as MPasswordInput } from '@mantine/core';
import type { SetOptional } from 'type-fest';
import { Icon } from '../../../Icon/Icon';
import { InputLabel } from '../../InputLabel';
import { textInputClassNames } from './TextInput';

type PasswordInputProps = SetOptional<
  MakeInputProps<string, MPasswordInputProps, 'autoComplete' | 'classNames' | 'type' | 'visibilityToggleIcon'>,
  'label'
>;

const passwordInputClassNames: MPasswordInputProps['classNames'] = {
  innerInput: safeCss('px-md py-lg'),
  input: textInputClassNames.input,
  root: textInputClassNames.root,
  section: safeCss('right-md'),
};

export const PasswordInput = ({
  label = 'Password',
  onChange,
  placeholder = 'Enter password',
  ...props
}: PasswordInputProps) => {
  return (
    <MPasswordInput
      classNames={passwordInputClassNames}
      label={<InputLabel label={label} />}
      onChange={(event) => {
        onChange(event.target.value);
      }}
      placeholder={placeholder}
      type="password"
      visibilityToggleIcon={({ reveal }) => <Icon name={reveal ? 'IconEyeOff' : 'IconEye'} />}
      {...props}
    />
  );
};
