import type { CreateClientRequest } from '@frontend/api-types';
import { getNextItem, getPreviousItem, match } from '@frontend/duck-tape';
import { validators } from '@frontend/forms';
import { useEffect, useState } from '@frontend/react';
import { AppShell, LoadingSpinnerPanel, useBreakpoints } from '@frontend/web-react';
import { AppContentWrapper, UnauthenticatedAppHeader } from '@frontend/web/components';
import { CreatePassword, UserInfo, Welcome } from '@frontend/web/components/DomainSpecific/AcceptInvite';

import { useAuthentication, useRetrieveWaitlistStatusQuery } from '@frontend/web/hooks';
import { createFileRoute } from '@tanstack/react-router';

const signUpSteps = ['welcome', 'userInfo', 'createPassword'] as const;

type SignUpStep = (typeof signUpSteps)[number];

const initialValues: CreateClientRequest = {
  birthday: '',
  email: '',
  firstName: '',
  lastName: '',
  nickname: '',
  password: '',
  phoneNumber: '',
};

const SignUp = () => {
  const navigate = Route.useNavigate();
  const searchParams = Route.useSearch();
  const { isMobile, isTablet } = useBreakpoints();
  const [currentStep, setCurrentStep] = useState<SignUpStep>('welcome');
  const [userInfo, setUserInfo] = useState<CreateClientRequest>(initialValues);
  const { isAuthenticated } = useAuthentication();
  const { data: waitlistStatus, isLoading: isWaitlistStatusLoading } = useRetrieveWaitlistStatusQuery();

  const incrementStep = () => {
    const nextStep = getNextItem(signUpSteps, currentStep);
    if (nextStep) {
      setCurrentStep(nextStep);
    }
  };
  const decrementStep = () => {
    const lastStep = getPreviousItem(signUpSteps, currentStep);
    if (lastStep) {
      setCurrentStep(lastStep);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate({ to: '/app' });
    }
  }, []);

  useEffect(() => {
    if (waitlistStatus?.isWaitlistOn) {
      navigate({ search: searchParams, to: '/wait-list' });
    }
  }, [waitlistStatus]);

  return (
    <AppShell>
      <UnauthenticatedAppHeader isHidden={isMobile || isTablet} />
      {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
      <AppContentWrapper className="flex-1">
        {isWaitlistStatusLoading ? (
          <LoadingSpinnerPanel fullScreen />
        ) : (
          match(currentStep)
            .with('welcome', () => <Welcome incrementStep={incrementStep} />)
            .with('userInfo', () => (
              <UserInfo
                incrementStep={incrementStep}
                onSubmitUserInfo={(userInfo) => setUserInfo((prev) => ({ ...prev, ...userInfo }))}
                userInfo={userInfo}
              />
            ))
            .with('createPassword', () => (
              <CreatePassword
                decrementStep={decrementStep}
                searchParamsPromoCode={searchParams.promo}
                userInfo={userInfo}
              />
            ))
            .exhaustive()
        )}
      </AppContentWrapper>
    </AppShell>
  );
};

export const Route = createFileRoute('/sign-up')({
  component: SignUp,
  validateSearch: (search) =>
    validators
      .object({
        promo: validators.optionalString(),
        referrer: validators.uuid().optional(),
        utm_campaign: validators.optionalString(),
        utm_content: validators.optionalString(),
        utm_medium: validators.optionalString(),
        utm_source: validators.optionalString(),
        utm_term: validators.optionalString(),
      })
      .parse(search),
});
