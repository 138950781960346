import { evaluateClientQuota } from '@frontend/api-client';
import { Fragment, useEffect } from '@frontend/react';
import { Divider, FlatList, Modal, SkeletonList, Text, YStack } from '@frontend/web-react';
import { DraftTaskCard } from '@frontend/web/components/DomainSpecific/Tasks/DraftTaskCard';
import { useListDraftTasksQuery, useRetrieveClientStatusQuery } from '@frontend/web/hooks';

type ResumeDraftTasksModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ResumeDraftTasksModal = ({ isOpen, onClose }: ResumeDraftTasksModalProps) => {
  const { data: clientStatus } = useRetrieveClientStatusQuery();
  const { data: drafts, error: listDraftTasksError, isLoading: isListDraftTaskLoading } = useListDraftTasksQuery();

  useEffect(() => {
    if ((drafts && !drafts?.length) || (clientStatus && evaluateClientQuota(clientStatus).isThrottled)) {
      onClose();
    }
  }, [drafts?.length, clientStatus]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <YStack className="gap-lg">
        <YStack className="gap-xs">
          <Text type="h4">You have {drafts?.length} tasks in draft.</Text>
          <Text color="tertiary" type="p-md">
            Get started on one of them now.
          </Text>
        </YStack>
        <FlatList
          ListLoadingComponent={<SkeletonList countSkeletons={10} />}
          contentContainerClassName="pb-lg"
          data={drafts}
          error={!!listDraftTasksError}
          isLoading={isListDraftTaskLoading}
          renderItem={({ array, index, item: task }) => {
            return (
              <Fragment key={task.id}>
                <DraftTaskCard onCloseModal={onClose} task={task} />
                <Divider forList index={index} list={array} />
              </Fragment>
            );
          }}
        />
      </YStack>
    </Modal>
  );
};
