import { evaluateClientQuota } from '@frontend/api-client';
import type { LocalFile } from '@frontend/api-types';
import { Fragment, useEffect, useFeatureIsOn, useState } from '@frontend/react';
import { FileInput, FilePreviews, Icon, Tag, Text, XStack, YStack, useShortcut } from '@frontend/web-react';
import { safeCss } from '@frontend/web-utils';
import {
  useCreateTaskContext,
  useLazyRetrieveHouseholdQuery,
  useRetrieveClientQuery,
  useRetrieveClientStatusQuery,
} from '@frontend/web/hooks';
import type { TextareaProps } from '@mantine/core';
import { Textarea } from '@mantine/core';

const textareaInputClassNames: TextareaProps['classNames'] = {
  input: safeCss(
    'p-md text-p-md rounded-t-md border-borderPrimary outline-none text-textPrimary placeholder:text-textTertiary border-b-0',
  ),
  root: safeCss('gap-y-xs flex flex-col flex-1 w-full'),
};

export const Description = () => {
  const { createTaskState, decrementStep, draftTask, incrementStep, isOnLastStep, isSubmitting, updateTaskData } =
    useCreateTaskContext();
  const canAttachFiles = !createTaskState.isRecurrence;
  const [isStepComplete, setIsStepComplete] = useState(!!createTaskState.taskData.description);
  const isSubmitDisabled = !isStepComplete;
  const { attachments, description, sharedWithClientIds } = createTaskState.taskData;
  const { data: client } = useRetrieveClientQuery();
  const { data: clientStatus } = useRetrieveClientStatusQuery();
  const [retrieveHousehold, { data: household }] = useLazyRetrieveHouseholdQuery();
  const { enabled: isThrottlingTasksEnabled } = useFeatureIsOn('is_throttling_tasks_enabled');

  const isFileMaxReached = attachments && attachments.length + (draftTask?.attachments?.length || 0) >= 5;

  useEffect(() => {
    setIsStepComplete(!!description);
  }, [description]);

  useEffect(() => {
    if (client?.household) {
      retrieveHousehold(client.household);
    }
  }, [client?.household]);

  useShortcut({
    Enter: {
      isDisabled: isSubmitDisabled,
      onSubmit: incrementStep,
    },
  });

  const handleHouseholdTagClick = async (id: string) => {
    if (!sharedWithClientIds?.length) {
      updateTaskData({ sharedWithClientIds: [id] });
    } else {
      updateTaskData({
        sharedWithClientIds: sharedWithClientIds.includes(id)
          ? sharedWithClientIds.filter((h) => h !== id)
          : [...sharedWithClientIds, id],
      });
    }
  };

  const isDraftTask = isThrottlingTasksEnabled && clientStatus && evaluateClientQuota(clientStatus).isThrottled;

  return (
    <YStack gap="xs">
      <XStack>
        {createTaskState.currentStep !== createTaskState.steps[0] ? (
          <Icon name="IconArrowLeft" onClick={decrementStep} size={18} />
        ) : null}
      </XStack>
      <XStack className="py-sm justify-between items-end">
        <Text type="h4">What do you need help with?</Text>
        {!createTaskState.isRecurrence && isDraftTask ? (
          <Text color="warning" type="captionBold">
            Task will go to drafts.
          </Text>
        ) : null}
      </XStack>
      <YStack>
        <Textarea
          autoFocus
          autosize
          classNames={textareaInputClassNames}
          maxRows={10}
          minRows={1}
          name="message"
          onChange={(event) => updateTaskData({ description: event.currentTarget.value })}
          placeholder="Enter message"
          value={description}
        />
        <XStack className="justify-between self-start w-full bg-surfacePrimary border-borderPrimary border-t-0 border-1 py-sm px-md rounded-b-md gap-x-sm">
          {!canAttachFiles || isFileMaxReached ? null : (
            <FileInput
              allowedFileTypes={['image', 'pdf']}
              disabled={isFileMaxReached}
              name="files"
              noLabel
              onChange={(attachments) => updateTaskData({ attachments: attachments as LocalFile[] })}
              value={attachments}
            />
          )}
          <YStack className="w-full justify-center grow" gapY="sm">
            {attachments.length ? (
              <Fragment>
                <FilePreviews
                  files={attachments}
                  onDelete={(deleteIndex) =>
                    updateTaskData({ attachments: attachments.filter((_, index) => deleteIndex !== index) })
                  }
                  type="tags"
                />
                {isFileMaxReached ? (
                  <Text color="danger" type="captionBold">
                    {"You've"} reached the maximum file limit.
                  </Text>
                ) : null}
              </Fragment>
            ) : null}
          </YStack>
          <XStack gap="md">
            {isDraftTask
              ? null
              : household?.members.map(({ id, name }) => (
                  <Tag
                    containerRadius="rounded-md"
                    isActive={sharedWithClientIds?.includes(id)}
                    key={id}
                    label={name}
                    onClick={() => handleHouseholdTagClick(id)}
                    rightIconName={sharedWithClientIds?.includes(id) ? 'IconMinus' : 'IconPlus'}
                    rightIconSize="md"
                  />
                ))}
            <Icon
              className="self-end"
              color="primary"
              disabled={isSubmitDisabled}
              enableBackgroundColor
              isLoading={isSubmitting}
              name={isOnLastStep ? 'IconSend' : 'IconArrowRight'}
              onClick={incrementStep}
              rounded
              size="lg"
            />
          </XStack>
        </XStack>
      </YStack>
    </YStack>
  );
};
