import { getNotificationCategories } from '@frontend/api-client';
import type { NotificationType } from '@frontend/api-types';
import { isLast } from '@frontend/duck-tape';
import { Fragment } from '@frontend/react';
import { Divider, PageContainer, YStack } from '@frontend/web-react';
import { NotificationCategory } from '@frontend/web/components/DomainSpecific/Notifications/NotificationCategory';
import { useRetrieveNotificationSettingsQuery, useUpdateNotificationSettingsMutation } from '@frontend/web/hooks';
import { createFileRoute } from '@tanstack/react-router';

const Notifications = () => {
  // const { track } = useAnalytics();
  const { data: notificationSettings, isLoading } = useRetrieveNotificationSettingsQuery();
  const [updateNotificationSettings] = useUpdateNotificationSettingsMutation();

  const handleToggle = (category: string, type: NotificationType) => {
    if (notificationSettings) {
      const value = notificationSettings[category]!;

      const newValue =
        ['sms', 'email', 'push']
          .filter(
            (option) => (value.includes(option) && option !== type) || (!value.includes(option) && option === type),
          )
          .join('_') || 'none';

      updateNotificationSettings({
        [category]: newValue,
      });
    }
  };

  return (
    <PageContainer headerTitle="Notifications" isLoading={isLoading || !notificationSettings}>
      <YStack gapY="lg">
        {notificationSettings
          ? getNotificationCategories('web').map(({ category, ...restOfProps }, index, array) => (
              <Fragment key={category}>
                <NotificationCategory
                  category={category}
                  onToggle={handleToggle}
                  value={notificationSettings[category]!}
                  {...restOfProps}
                />
                {isLast(array, index) ? null : <Divider />}
              </Fragment>
            ))
          : null}
      </YStack>
    </PageContainer>
  );
};

export const Route = createFileRoute('/app/settings/notifications')({
  component: Notifications,
});
