import type { CommonSVGProps } from '../internal';

export const IconBeachWithLinks = (props: Pick<CommonSVGProps, 'height' | 'width'>) => (
  <svg fill="none" height={80} width={80} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M58.51 55.834a25 25 0 0 0-35.353 0M60 12.68A20 20 0 0 0 32.68 20l34.64 20A20 20 0 0 0 60 12.68Zm0 0c3.19 1.84 1.3 11.087-4.227 20.653M60 12.68c-3.19-1.84-10.25 4.42-15.773 13.987M50 30 40 47.32M10 64.167a8 8 0 0 1 3.333-.834A8 8 0 0 1 20 66.667 8 8 0 0 0 26.667 70a8 8 0 0 0 6.666-3.333A8.001 8.001 0 0 1 40 63.333a8.001 8.001 0 0 1 6.667 3.334A8 8 0 0 0 53.333 70 8 8 0 0 0 60 66.667a8 8 0 0 1 6.667-3.334 8 8 0 0 1 3.333.834"
      stroke="#7C7C7C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.333}
    />
    <path
      d="m27.872 39.491 4.255-5.647m-3.649-2.012.32-.495a4.166 4.166 0 1 1 6.656 5.015l-.387.445m-3.546 4.706-.266.487a4.223 4.223 0 0 1-5.881.826 4.145 4.145 0 0 1-.82-5.835l.378-.443"
      stroke="#7C7C7C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.667}
    />
  </svg>
);
