import type { Client } from '@frontend/api-types';
import * as Sentry from '@sentry/react';

export const initSentry = ({ dsn, env }: { dsn: string; env: EnvType }) => {
  if (env !== 'local') {
    Sentry.init({
      dsn,
      environment: env,
      integrations: [Sentry.replayIntegration()],
      replaysOnErrorSampleRate: 1.0,
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      tracesSampleRate: 1.0,
    });
  } else {
    // eslint-disable-next-line no-console
    console.info("Mock initSentry because it's disabled in local environments");
  }
};

export const identifySentry = ({ email, env, id }: Pick<Client, 'email' | 'id'> & { env: EnvType }) => {
  if (env !== 'local') {
    Sentry.setUser({
      email,
      id,
    });
  } else {
    // eslint-disable-next-line no-console
    console.info("Mock identifySentry because it's disabled in local environments");
  }
};

// We can probably capture local errors too, for testing purposes?
export const captureSentryError = (error: Error) => Sentry.captureException(error);

export const captureSentryException = Sentry.captureException;

export const captureSentryMessage = (message: string) => Sentry.captureMessage(message);
