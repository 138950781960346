import type { MakeInputProps, SelectOption } from '@frontend/forms';
import type { ClassName } from '@frontend/web-utils';
import { concatClasses } from '@frontend/web-utils';
import { XStack } from '../../../Containers';
import { Tag } from '../../../Tags';

type TagMultiSelectInputProps<TValue extends string> = MakeInputProps<TValue[], GenericRecord> & {
  className?: ClassName;
  options: SelectOption<TValue, never, { label: string }>[];
};

export const TagMultiSelectInput = <TValue extends string>({
  className,
  onChange,
  options,
  value,
}: TagMultiSelectInputProps<TValue>) => {
  const onClickSelectOption = (option: SelectOption<TValue, never, { label: string }>) => {
    const alreadySelected = value && value.includes(option.value);
    if (alreadySelected) {
      const deselected = value.filter((v) => v !== option.value);
      return onChange(deselected);
    }
    return onChange([...(value ?? []), option.value]);
  };

  return (
    <XStack className={concatClasses('flex-wrap gap-sm justify-center', className)}>
      {options.map((option) => {
        return (
          <Tag
            isActive={value?.includes(option.value)}
            key={option.value}
            label={option.label}
            onClick={() => onClickSelectOption(option)}
          />
        );
      })}
    </XStack>
  );
};
