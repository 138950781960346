import type { SetRequired, StrictExtract } from '@frontend/duck-tape';
import { convertDateLikeToDateISOString, dayjs, match } from '@frontend/duck-tape';
import { safeCss } from '@frontend/web-utils';
import type {
  DateValue,
  DatePickerInputProps as MDatePickerInputProps,
  DatePickerType as MDatePickerType,
} from '@mantine/dates';
import { DatePickerInput as MDatePickerInput } from '@mantine/dates';
import { InputLabel } from '../../InputLabel';

export type DatePickerType = StrictExtract<MDatePickerType, 'default' | 'range'>;

export type DateISOStringRange = [MaybeUndefined<DateISOString>, MaybeUndefined<DateISOString>];

export type DateInputProps<T extends DatePickerType> = SetRequired<
  Omit<MDatePickerInputProps<T>, 'onChange' | 'type' | 'value'>,
  'label'
> & {
  onChange: (date: T extends 'range' ? DateISOStringRange : MaybeUndefined<DateISOString>) => void;
  showOptionalText?: boolean;
  type: T;
  value: T extends 'range' ? DateISOStringRange : MaybeUndefined<DateISOString>;
};

export const DateInput = <T extends DatePickerType = 'default'>({
  label,
  onChange,
  showOptionalText,
  type,
  value,
  ...props
}: DateInputProps<T>) => {
  const classNames: MDatePickerInputProps<T>['classNames'] = {
    day: safeCss(' text-textPrimary data-[selected]:bg-surfaceAccent data-[disabled]:text-textTertiary'),
    input: safeCss('p-md text-p-md h-[56px] rounded-sm focus-within:border-textPrimary'),
    root: safeCss('gap-y-sm flex flex-col'),
  };

  const onChangeComposed = (value: T extends 'range' ? [DateValue, DateValue] : DateValue) => {
    const transformedValue = match<'default' | 'range', DateISOStringRange | MaybeUndefined<DateISOString>>(type)
      .with('range', () => {
        const castedValue = value as [DateValue, DateValue];
        return castedValue.map((v) => (v ? convertDateLikeToDateISOString(v) : undefined)) as DateISOStringRange;
      })
      .with('default', () => {
        const castedValue = value as MaybeNull<DateISOString>;
        return castedValue ? convertDateLikeToDateISOString(castedValue) : undefined;
      })
      .exhaustive();

    // @ts-expect-error Fake news
    onChange(transformedValue);
  };

  const transformedValue = value
    ? match<'default' | 'range'>(type as T)
        .with('range', () => (value as DateISOStringRange).map((v) => (v ? dayjs(v).toDate() : null)))
        .with('default', () =>
          (value as MaybeNull<DateISOString>) ? dayjs(value as DateISOString).toDate() : undefined,
        )
        .exhaustive()
    : undefined;

  return (
    <MDatePickerInput
      classNames={classNames}
      label={<InputLabel label={label} showOptionalText={showOptionalText} />}
      onChange={onChangeComposed}
      placeholder={'mm/dd/yy'}
      type={type}
      // @ts-expect-error Fake news
      value={transformedValue}
      valueFormat="MM/DD/YY"
      {...props}
    />
  );
};
