import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type NavReduxState = {
  next?: string;
};

const initialState: NavReduxState = {};

const navSlice = createSlice({
  initialState,
  name: 'nav',
  reducers: {
    clearNext: (state) => {
      // eslint-disable-next-line fp/no-mutation
      state.next = undefined;
    },
    storeNext: (state, { payload }: PayloadAction<string>) => {
      // eslint-disable-next-line fp/no-mutation
      state.next = payload;
    },
  },
});

export const { reducer: navReducer } = navSlice;
export const { clearNext, storeNext } = navSlice.actions;
