import { match } from '@frontend/duck-tape';
import type { ReactNode } from '@frontend/react';
import { concatClasses, safeCss } from '@frontend/web-utils';
import { YStack } from './YStack';

export type ScrollableContentPanelProps = {
  children: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
  justifyContent?: 'center' | 'end' | 'start';
};

export const ScrollableContentPanel = ({
  children,
  footer,
  header,
  justifyContent = 'center',
}: ScrollableContentPanelProps) => {
  const contentWidth = safeCss('w-9/10  lg:w-3/5 xxl:w-1/2');
  return (
    // On mobile browsers, the bottom url bar section is part of the viewport height, so padding-bottom is added to account for that space
    <YStack
      // eslint-disable-next-line tailwindcss/no-arbitrary-value
      className="flex-1 lg:py-[80px] xxl:py-[100px] md:py-[60px] py-[32px] pb-[100px] md:pb-md justify-center"
      gapY="md"
    >
      {header ? <YStack className={concatClasses('self-center', contentWidth)}>{header}</YStack> : null}
      <YStack
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className={concatClasses(
          'flex-1 overflow-scroll items-center no-scroll-bar',
          match(justifyContent)
            .with('end', () => safeCss('justify-end'))
            .with('start', () => safeCss('justify-start'))
            .with('center', () => safeCss('justify-center'))
            .exhaustive(),
        )}
        gapY="md"
      >
        <YStack className={contentWidth}>{children}</YStack>
      </YStack>
      <YStack className={concatClasses('self-center justify-center', contentWidth)}>{footer}</YStack>
    </YStack>
  );
};
