import type { OptionSheetOption } from '@frontend/api-types';
import type { Currency } from '@frontend/duck-tape';
import { formatCurrencyWithCommasAndDecimal, isNil } from '@frontend/duck-tape';
import { Clickable, Icon, Image, ImageBackground, Text, YStack, getAspectRatioClassName } from '@frontend/web-react';
import { concatClasses } from '@frontend/web-utils';

export type OptionSheetOptionProps = Pick<OptionSheetOption, 'description' | 'price' | 'title' | 'url'> & {
  currency?: Currency;
  imageUrl: MaybeNil<string>;
};

export const OptionSheetOptionImage = ({
  currency,
  description,
  imageUrl,
  price,
  title,
  url,
}: OptionSheetOptionProps) => {
  // eslint-disable-next-line no-restricted-globals
  const openURL = () => open(url, '_blank');

  if (imageUrl) {
    if (!description) {
      return (
        <ImageBackground aspectRatio="3/4" className="rounded-md" source={imageUrl}>
          <YStack className="justify-end px-md py-lg gap-x-sm gap-y-xs">
            <Text className="text-shadow-md" color="inverse" type="h3">
              {title}
            </Text>
            {price ? (
              <Text className="text-shadow-md" color="inverse" type="h3">
                {formatCurrencyWithCommasAndDecimal(price, currency)}
              </Text>
            ) : null}
            {url ? (
              <Clickable alignItems="center" flexDirection="row" gapX="xs" onClick={openURL}>
                <Icon color="inverse" name="IconExternalLink" />
                <Text className="text-shadow-md" color="inverse" type="h6">
                  Open Website
                </Text>
              </Clickable>
            ) : null}
          </YStack>
        </ImageBackground>
      );
    }

    // eslint-disable-next-line tailwindcss/no-arbitrary-value
    return <Image aspectRatio="16/9" className="rounded-md object-contain" source={imageUrl} />;
  }
  return (
    <YStack
      className={concatClasses(
        getAspectRatioClassName('16/9'),
        'rounded-md justify-center bg-surfaceBrand p-md gap-sm',
      )}
    >
      <Text color="accent" type="h1" uppercase>
        {title}
      </Text>
      {!isNil(price) ? (
        <Text color="inverse" type="h1" uppercase>
          {formatCurrencyWithCommasAndDecimal(price, currency)}
        </Text>
      ) : null}
      {url ? (
        <Clickable alignItems="center" flexDirection="row" gapX="xs" onClick={openURL}>
          <Icon color="inverse" name="IconExternalLink" />
          <Text color="inverse" type="h6">
            Open Website
          </Text>
        </Clickable>
      ) : null}
    </YStack>
  );
};
