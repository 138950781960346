import type { StrictOmit } from '@frontend/duck-tape';
import { safeCss } from '@frontend/web-utils';
import type { SwitchProps as MSwitchProps } from '@mantine/core';
import { Switch as MSwitch } from '@mantine/core';

export type SwitchProps = StrictOmit<MSwitchProps, 'classNames' | 'color' | 'size'>;

/** This component doesn't support different sizes since it's somewhat tedious to override Mantine's styles. We only use one consistent
 * size across the app so it's fine for now
 */
export const Switch = ({ labelPosition = 'left', ...props }: SwitchProps) => {
  const classNames: MSwitchProps['classNames'] = {
    body: safeCss('flex flex-1 justify-between hover:cursor-pointer'),
    thumb: safeCss('w-[16px] h-[16px]'),
    track: safeCss('w-[46px] h-[26px]'),
  };
  return <MSwitch classNames={classNames} color="blue.9" labelPosition={labelPosition} size="md" {...props} />;
};
