import { Button } from '../Buttons';
import { XStack, YStack } from '../Containers';
import { Text } from '../Texts/Text';

export type EmptyListProps = {
  buttonLabel: string;
  label: string;
  onClickButton: () => void;
};
export const EmptyList = ({ buttonLabel, label, onClickButton }: EmptyListProps) => {
  //   const { track } = useSegment();
  return (
    // eslint-disable-next-line tailwindcss/no-arbitrary-value
    <YStack className="h-[60vh] gap-y-lg justify-center items-center">
      <Text type="h4">{label}</Text>
      <XStack>
        <Button label={buttonLabel} onClick={onClickButton} />
      </XStack>
    </YStack>
  );
};
