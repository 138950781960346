import { validators } from '@frontend/forms';
import { resetPasswordFormDefaultValues, useResetPasswordForm } from '@frontend/forms/client/resetPasswordForm';
import { AppShell, Button, PasswordInput, Text, TextInput, YStack } from '@frontend/web-react';
import { AppContentWrapper, UnauthenticatedAppHeader } from '@frontend/web/components';
import { useAuthentication, useResetPasswordMutation } from '@frontend/web/hooks';
import { router } from '@frontend/web/router';
import { handleMutation } from '@frontend/web/utils';
import { createFileRoute } from '@tanstack/react-router';

const ResetPassword = () => {
  const { token } = Route.useParams();
  const search = Route.useSearch();
  const [resetPassword, { isLoading: resetPasswordIsLoading }] = useResetPasswordMutation();
  const { isLoading: authIsLoading, login } = useAuthentication();

  const handleResetPassword = ({ email, password }: { email: string; password: string }) => {
    handleMutation({
      mutation: () => resetPassword({ email, password, verificationToken: token }).unwrap(),
      onSuccess: async () => {
        await login({ email, handleRedirect: true, password });
        router.navigate({ to: '/login' });
      },
      successMessage: 'Password reset successfully.',
    });
  };

  const { getControl, handleSubmit, isValid } = useResetPasswordForm({
    initialValues: {
      ...resetPasswordFormDefaultValues,
      email: search.email ?? '',
    },
    onSubmit: handleResetPassword,
    validateOnMount: true,
  });

  return (
    <AppShell>
      <UnauthenticatedAppHeader />
      <AppContentWrapper className="justify-center items-center flex flex-col place-items-center">
        {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
        <YStack className="space-y-md px-md lg:justify-center min-w-full sm:min-w-[358px] sm:max-w-[358px]">
          <Text type="h4">Reset password</Text>
          <Text>Enter your current email address below.</Text>
          <form className="space-y-md" onSubmit={handleSubmit}>
            <TextInput {...getControl('email')} label="Email" />
            <PasswordInput {...getControl('password')} label="Password" />
            <PasswordInput {...getControl('confirmPassword')} label="Confirm password" />
            <Button
              className="mt-md"
              disabled={!isValid}
              fullWidth
              isForm
              isLoading={authIsLoading || resetPasswordIsLoading}
            >
              Reset password
            </Button>
          </form>
        </YStack>
      </AppContentWrapper>
    </AppShell>
  );
};

export const Route = createFileRoute('/reset-password/$token')({
  component: ResetPassword,
  validateSearch: (search) =>
    validators
      .object({
        email: validators.email().optional(),
      })
      .parse(search),
});
