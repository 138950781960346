import type { StrictOmit } from '@frontend/duck-tape';
import type { MakeInputProps } from '@frontend/forms';
import type { SwitchProps } from '../../Switch';
import { Switch } from '../../Switch';

export type SwitchInputProps = StrictOmit<MakeInputProps<boolean, SwitchProps>, 'value'>;

/** This component doesn't support different sizes since it's somewhat tedious to override Mantine's styles. We only use one consistent
 * size across the app so it's fine for now
 */
export const SwitchInput = ({ labelPosition = 'left', onChange, ...props }: SwitchInputProps) => {
  return <Switch labelPosition={labelPosition} onChange={(e) => onChange(e.currentTarget.checked)} {...props} />;
};
