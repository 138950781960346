import { Spinner } from '@frontend/web-react';
import type { ClassName } from '@frontend/web-utils';
import { applyClass, concatClasses } from '@frontend/web-utils';
import classNames from 'classnames';
import { YStack } from '../Containers';

type LoadingSpinnerPanelProps = { className?: ClassName; fullScreen?: boolean };

export const LoadingSpinnerPanel = ({ className, fullScreen = false }: LoadingSpinnerPanelProps) => {
  // classNames allows dynamic strings to be included in the build
  // eslint-disable-next-line tailwindcss/no-custom-classname
  const widthClassName = classNames(`w-[100%]`);
  // eslint-disable-next-line tailwindcss/no-custom-classname
  const heightClassName = classNames(`h-[400px]`);

  const sizeClassName =
    className || applyClass(fullScreen, 'flex-1 h-full w-full', concatClasses(heightClassName, widthClassName));

  return (
    <YStack className={concatClasses('justify-center items-center', sizeClassName)}>
      <Spinner />
    </YStack>
  );
};
