import type { StrictOmit } from '@frontend/duck-tape';
import type { ReactNode } from '@frontend/react';
import type { ButtonColorVariant, ButtonProps } from '../Buttons';
import { Button } from '../Buttons';
import { YStack } from '../Containers';
import { Modal } from './Modal';
import type { CommonModalProps } from './types';

type ConfirmationVariant = ButtonColorVariant;

export type ConfirmationModalProps = CommonModalProps & {
  cancelButtonProps?: StrictOmit<ButtonProps, 'color' | 'onClick'>;
  cancelLabel?: string;
  isMandatory?: boolean;
  isSubmitLoading?: boolean;
  onClickCancel?: () => void;
  onClickSubmit: () => void;
  submitButtonProps?: StrictOmit<ButtonProps, 'color' | 'onClick'>;
  submitLabel?: string;
  subtitle: ReactNode;
  title: string;
  variant?: ConfirmationVariant;
};
export const ConfirmationModal = ({
  cancelButtonProps,
  cancelLabel = 'Cancel',
  isMandatory,
  isOpen,
  isSubmitLoading,
  onClickCancel,
  onClickSubmit,
  onClose,
  submitButtonProps,
  submitLabel = 'Submit',
  subtitle,
  title,
  variant = 'primary',
}: ConfirmationModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} subtitle={subtitle} title={title}>
      <YStack gapY="md">
        <Button
          color={variant}
          isLoading={isSubmitLoading}
          label={submitLabel}
          onClick={onClickSubmit}
          {...submitButtonProps}
        />
        {isMandatory ? null : onClickCancel ? (
          <Button color="secondary" label={cancelLabel} onClick={onClickCancel} {...cancelButtonProps} />
        ) : null}
      </YStack>
    </Modal>
  );
};
