import { canTaskBeCompleted } from '@frontend/api-client';
import type { TaskDetail } from '@frontend/api-types';
import { identity } from '@frontend/duck-tape';
import type { CommonModalProps } from '@frontend/web-react';
import { Button, Icon, Modal, YStack } from '@frontend/web-react';
import { useQuackActionsContext } from '@frontend/web/context/QuackActionsContext';
import { useAnalytics, useCloseTaskMutation } from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';
import { useEffect } from 'react';

export type CompleteTaskModalProps = CommonModalProps & {
  onComplete: () => void;
  openModal: () => void;
  task: TaskDetail;
  taskId: string;
};

export const CompleteTaskModal = ({ isOpen, onClose, onComplete, openModal, task, taskId }: CompleteTaskModalProps) => {
  const [closeTask, { isLoading }] = useCloseTaskMutation();
  const { addAction, removeAction } = useQuackActionsContext();
  const canBeCompleted = task && canTaskBeCompleted(task);
  const { track } = useAnalytics();

  useEffect(() => {
    if (canBeCompleted) {
      addAction({
        actions: [
          {
            description: 'Mark task as completed',
            id: 'complete',
            label: 'Complete task',
            leftSection: <Icon name="IconCheck" />,
            onClick: openModal,
          },
        ],
        group: 'Current Task',
      });

      return () => {
        removeAction('complete', 'Current Task');
      };
    }
    return identity;
  }, [task, canBeCompleted]);

  const handleComplete = () => {
    track({ data: { taskId }, event: 'completeTaskModalSubmitButtonSelected', topic: 'tasks' });
    handleMutation({
      mutation: async () => closeTask({ id: taskId }).unwrap(),
      onSuccess: () => {
        onClose();
        onComplete();
      },
      successMessage: 'Task marked as completed',
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      subtitle="You can always reopen tasks within 30 days by messaging in the chat."
      title="Ready to mark this task as complete?"
    >
      <YStack className="gap-y-md">
        <Button color="success" isLoading={isLoading} label="Mark as complete" onClick={handleComplete} />
        <Button color="secondary" label="Cancel" onClick={onClose} />
      </YStack>
    </Modal>
  );
};
