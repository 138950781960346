import type { ThreadTaskSuggestion } from '@frontend/api-types';
import { Clickable, Icon, LinearGradientCard, Spinner, Text, XStack, YStack } from '@frontend/web-react';
import { applyClass, concatClasses } from '@frontend/web-utils';

export type ThreadSuggestionCard = {
  isAnyStreaming: boolean;
  isCurrentStreaming: boolean;
  onClick: () => void;
  suggestion: ThreadTaskSuggestion;
};

export const ThreadSuggestionCard = ({
  isAnyStreaming,
  isCurrentStreaming,
  onClick,
  suggestion: { recurrenceId, taskId, title },
}: ThreadSuggestionCard) => {
  const wasUsed = !!taskId || !!recurrenceId;
  const isLoading = isCurrentStreaming || isAnyStreaming;
  return (
    <Clickable
      disabled={isLoading}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
    >
      <LinearGradientCard
        // eslint-disable-next-line tailwindcss/no-arbitrary-value
        className={concatClasses(
          'gap-md rounded-lg py-md px-lg flex-row justify-between items-center p-[2px]',
          applyClass(isLoading, 'bg-surfaceSecondary', 'bg-white'),
        )}
        disabled={isLoading || wasUsed}
      >
        <XStack alignItems="center" gapX="md">
          <Text color={wasUsed ? 'tertiary' : 'primary'} lineClamp={2} type="h5">
            {title}
          </Text>
        </XStack>
        <YStack>
          {isLoading ? <Spinner size="xs" /> : wasUsed ? <Icon color="success" name={'IconCheck'} /> : null}
        </YStack>
      </LinearGradientCard>
    </Clickable>
  );
};
