// import { concatClasses } from '@frontend/web-utils';
// import type { ImageProps as MImageProps } from '@mantine/core';
import type { ImageProps as MImageProps } from '@mantine/core';
import { Image as MImage } from '@mantine/core';

import type { StrictOmit } from '@frontend/duck-tape';
import { useEffect, useState } from '@frontend/react';
import { concatClasses } from '@frontend/web-utils';
import { YStack } from '../Containers';
import { Icon } from '../Icon/Icon';
import { Text } from '../Texts/Text';
import { getAspectRatioClassName, type AspectRatios } from './utils';

export type ImageProps = StrictOmit<MImageProps, 'classNames' | 'src'> & {
  FallbackComponent?: Children;
  aspectRatio?: AspectRatios;
  onImageStateChange?: (state: ImageState) => void;
  source: MaybeUndefined<MImageProps['src']>;
};

export type ImageState = 'error' | 'loading' | 'success';

const INITIAL_IMAGE_STATE = 'loading';

// We could add a loading bar indicator here using the onProgress callback fn or BlurHash?
export const Image = ({
  aspectRatio,
  className,
  FallbackComponent,
  onImageStateChange,
  source,
  ...restProps
}: ImageProps) => {
  const [imageState, setImageState] = useState<ImageState>(INITIAL_IMAGE_STATE);

  const aspectRatioClassName = getAspectRatioClassName(aspectRatio);

  // If the source changes we need to restart the component state
  useEffect(() => {
    setImageState(INITIAL_IMAGE_STATE);
  }, [source]);

  useEffect(() => {
    onImageStateChange?.(imageState);
  }, [imageState]);

  if (!source) {
    return (
      <YStack
        alignItems="center"
        className={concatClasses('bg-surfaceAccent', aspectRatioClassName, className)}
        justifyContent="center"
      >
        <Icon name="DuckbillLogo" size="lg" />
      </YStack>
    );
  }

  if (imageState === 'error') {
    return (
      FallbackComponent ?? (
        <YStack
          className={concatClasses(
            'justify-center items-center gap-sm p-md border-1 border-borderPrimary bg-surfaceSecondary',
            aspectRatioClassName,
            className,
          )}
        >
          {/* eslint-disable-next-line tailwindcss/enforces-shorthand, tailwindcss/no-arbitrary-value */}
          <YStack className="w-[20px] h-[20px]">
            <Icon name="IconAlertCircle" />
          </YStack>
          <Text textAlign="center" type="caption">
            Oops, it seems the image {"couldn't"} load. We recommend checking your internet connection.
          </Text>
        </YStack>
      )
    );
  }

  return (
    <MImage
      className={concatClasses(aspectRatioClassName, 'select-none', className)}
      onError={() => setImageState('error')}
      onLoadCapture={() => setImageState('success')}
      // If source prop ever changes this will be recalled
      onLoadStart={() => setImageState('loading')}
      // TODO: Use prefetched fallback if error
      src={source}
      {...restProps}
    />
  );
};
