import type { ListTaskSearchResultsRequest } from '@frontend/api-types';
import { formatEndpoint } from '@frontend/duck-tape';

const namespace = 'text_search/search';
const SEARCH_TASKS = 'tasks';

export const TEXT_SEARCH_ENDPOINTS = {
  searchTasks: ({ limit, query }: ListTaskSearchResultsRequest) =>
    formatEndpoint(SEARCH_TASKS, namespace, { queryParams: { limit, query } }),
};
