import { createFileRoute, redirect } from '@tanstack/react-router';
import { homeValidator } from '../task';

// This route handles redirects for backwards compatibility purposes
export const Route = createFileRoute('/app/recurrence/create')({
  beforeLoad: ({ search }) => {
    throw redirect({
      search: {
        ...search,
        isRecurrence: true,
        newTask: true,
      },
      to: '/app/task',
    });
  },
  component: () => <div />,
  validateSearch: (search) => homeValidator.parse(search),
});
