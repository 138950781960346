import type { ReactNode } from '@frontend/react';
import type { CardProps } from './Card';
import { Card } from './Card';
import { LinearGradientWrapper } from './LinearGradientWrapper';

export type LinearGradientCardProps = {
  children: ReactNode;
  className?: CardProps['className'];
  disabled?: boolean;
};
export const LinearGradientCard = ({ children, className, disabled }: LinearGradientCardProps) => {
  return (
    // eslint-disable-next-line tailwindcss/no-arbitrary-value
    <LinearGradientWrapper className="rounded-lg p-[2px]" disabled={disabled}>
      <Card className={className}>{children}</Card>
    </LinearGradientWrapper>
  );
};
