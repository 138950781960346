import type { Client } from '@frontend/api-types';
import { onboardingUrl } from '@frontend/constants';
import { identity } from '@frontend/duck-tape';
import { useFeatureIsOn, useState } from '@frontend/react';
import {
  AppShell,
  Button,
  DropdownMenu,
  Icon,
  ScrollView,
  Text,
  Tooltip,
  UserInfo,
  XStack,
  YStack,
  useHotkeys,
  useIsOnMobile,
  useIsOnTablet,
} from '@frontend/web-react';
import { safeCss } from '@frontend/web-utils';
import { SidebarAnchor, SidebarNavLink } from '@frontend/web/components/Navigation/SidebarItems';
import { useAnalytics, useRetrieveClientQuery } from '@frontend/web/hooks';
import { useCreateTaskContext } from '@frontend/web/hooks/context/useCreateTaskContext';
import { spotlight } from '@mantine/spotlight';
import { useMatchRoute, useNavigate } from '@tanstack/react-router';

export const AppSidebar = () => {
  const { track } = useAnalytics();
  const [isCreateTaskMenuOpen, setIsCreateTaskMenuOpen] = useState(false);
  const isOnMobile = useIsOnMobile();
  const isOnTablet = useIsOnTablet();
  const navigate = useNavigate();
  const { data: client } = useRetrieveClientQuery();
  const matchRoute = useMatchRoute();
  const { enabled: isThreadsEnabled } = useFeatureIsOn('is_duckbill_research_enabled');
  // There must be a less arduous way to do this, but brute forcing for now
  const isHomeActive = isThreadsEnabled
    ? !!matchRoute({ fuzzy: true, to: '/app/thread' })
    : !!matchRoute({ includeSearch: true, to: '/app' }) ||
      !!matchRoute({ fuzzy: true, includeSearch: true, to: '/app/task' });
  const isTasksActive = isThreadsEnabled
    ? !!matchRoute({ fuzzy: true, includeSearch: true, to: '/app/task' })
    : !!matchRoute({ fuzzy: true, includeSearch: true, to: '/app' });

  const isThreadsActive = isThreadsEnabled
    ? !!matchRoute({ includeSearch: true, to: '/app' }) || !!matchRoute({ fuzzy: true, to: '/app/thread' })
    : false;
  const isFeedActive = !!matchRoute({ to: '/app/feed' });
  const isSettingsActive = !!matchRoute({ fuzzy: true, to: '/app/settings' });
  const shouldMinimize = Boolean(isSettingsActive || isFeedActive || isOnMobile || isOnTablet || isThreadsActive);
  const { openCreateTaskModal } = useCreateTaskContext();

  useHotkeys([['c', () => setIsCreateTaskMenuOpen(true)]]);

  const onClickCreateOneOffTask = () => openCreateTaskModal({ isRecurrence: false });
  const onClickCreateRepeatingTask = () => openCreateTaskModal({ isRecurrence: true });

  return (
    <AppShell.Navbar
      classNames={{
        navbar: safeCss('bg-surfacePrimary'),
      }}
      p="md"
      withBorder={false}
    >
      <AppShell.Section>
        <YStack alignItems={shouldMinimize ? 'center' : undefined} gapY="md">
          {shouldMinimize ? (
            <Icon
              // eslint-disable-next-line tailwindcss/no-arbitrary-value
              className="rounded-xl p-[14px]"
              color="secondary"
              enableBackgroundColor
              name="IconSearch"
              onClick={spotlight.open}
              rounded
            />
          ) : (
            <YStack gapY="sm" onClick={spotlight.open}>
              <XStack
                className="bg-white p-sm rounded-md border-borderPrimary border-1 click-scale-effect hover-opacity-effect cursor-text"
                justifyContent="spaceBetween"
              >
                <XStack alignItems="center" gapX="sm">
                  <Icon color="secondary" name="IconSearch" />
                  <Text color="secondary" isSelectable={false} type="h6">
                    Search
                  </Text>
                </XStack>
                <XStack
                  alignItems="center"
                  className="bg-surfaceSecondary rounded-sm border-1 border-borderPrimary px-xs"
                  gapX="xs"
                >
                  <Text isSelectable={false} type="captionBold">
                    Ctrl + K
                  </Text>
                </XStack>
              </XStack>
            </YStack>
          )}
          {/* {isThreadsEnabled && !isTasksActive ? null : ( */}
          <DropdownMenu
            layout={[
              {
                icon: 'IconCheck',
                label: 'Create a one-off task',
                onClick: onClickCreateOneOffTask,
                type: 'item',
              },
              {
                icon: 'IconRepeat',
                label: 'Create a repeating task',
                onClick: onClickCreateRepeatingTask,
                type: 'item',
              },
            ]}
            onChange={setIsCreateTaskMenuOpen}
            opened={isCreateTaskMenuOpen}
          >
            {shouldMinimize ? (
              // eslint-disable-next-line tailwindcss/no-arbitrary-value
              <Icon className="p-[14px] rounded-xl" enableBackgroundColor name="IconPlus" rounded />
            ) : (
              <Button label="Create Task" leftIconName="IconPlus" onClick={identity} />
            )}
          </DropdownMenu>
        </YStack>
      </AppShell.Section>
      <AppShell.Section component={ScrollView} grow>
        <YStack className="gap-xs py-md items-center">
          <SidebarNavLink
            isActive={isHomeActive}
            isCollapsed={shouldMinimize}
            label="Home"
            leftIconName={'IconHome'}
            search
            to={isThreadsEnabled ? '/app/thread/create' : '/app/task'}
          />
          <SidebarNavLink
            isActive={isFeedActive}
            isCollapsed={shouldMinimize}
            label="Feed"
            leftIconName={'IconLayout2'}
            to="/app/feed"
          />
          {isThreadsEnabled ? (
            <SidebarNavLink
              isActive={isTasksActive}
              isCollapsed={shouldMinimize}
              label="Tasks"
              leftIconName={'IconCopyCheck'}
              to="/app/task"
            />
          ) : null}
          <SidebarNavLink
            isActive={isSettingsActive}
            isCollapsed={shouldMinimize}
            label="Settings"
            leftIconName={'IconSettings'}
            to="/app/settings"
          />
        </YStack>
      </AppShell.Section>
      <AppShell.Section className="flex flex-col">
        {shouldMinimize ? null : (
          <SidebarAnchor
            href={onboardingUrl}
            label="Schedule Onboarding Call"
            leftIconName="IconPhone"
            onClick={() => track({ event: 'scheduleACallSelected', topic: 'onboarding' })}
          />
        )}
        {client ? (
          <Tooltip disabled={!shouldMinimize} label="Account" position="right">
            <UserInfo
              isCollapsed={shouldMinimize}
              onClick={() => navigate({ to: '/app/settings' })}
              userInfo={
                {
                  email: client.email,
                  firstName: client.firstName,
                  lastName: client.lastName,
                } as Client
              }
            />
          </Tooltip>
        ) : null}
      </AppShell.Section>
    </AppShell.Navbar>
  );
};
