import { useFeatureIsOn } from '@frontend/react';
import { useEffect } from 'react';
import { createFileRoute } from '../../utils';

const Home = () => {
  const { enabled: isThreadsEnabled } = useFeatureIsOn('is_duckbill_research_enabled');
  const navigate = Route.useNavigate();

  useEffect(() => {
    navigate({
      to: isThreadsEnabled ? '/app/thread/create' : '/app/task',
    });
  }, [isThreadsEnabled]);

  return <div />;
};

export const Route = createFileRoute('/app/')({
  component: Home,
});
