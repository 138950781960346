import { getThreadTitle } from '@frontend/api-client';
import type {
  RetrieveThreadResponse,
  SocketThreadUpdate,
  ThreadMessage,
  ThreadReferenceInput,
  ThreadTaskSuggestion,
} from '@frontend/api-types';
import {
  P,
  convertDateLikeToDateTimeISOString,
  dayjs,
  debounce,
  deserializeJson,
  identity,
  match,
  reverse,
  serializeJson,
} from '@frontend/duck-tape';
import * as ArrayHelpers from '@frontend/duck-tape/array/array';
import { z } from '@frontend/forms';
import { useAuthSlice, useEffect, useListState, useRef, useState, useVisibilityController } from '@frontend/react';
import { useThreadLinks } from '@frontend/react-domain';
import type { FlatListProps } from '@frontend/web-react';
import {
  AnimatingFloatingButton,
  Banner,
  FlatList,
  Header,
  LoadingSpinnerPanel,
  PageContainer,
  YStack,
  useScrollStatus,
  useScrollView,
} from '@frontend/web-react';
import {
  ThreadChatFooter,
  ThreadChatWhisper,
  ThreadSuggestionMessage,
  ThreadTextMessage,
} from '@frontend/web/components';
import { ThreadLinksModal } from '@frontend/web/components/DomainSpecific/Threads/ThreadLinksModal';
import type { ThreadMarkdownRendererProps } from '@frontend/web/components/DomainSpecific/Threads/ThreadMarkdownRenderer';
import {
  useLazyRetrieveThreadEntityLinksQuery,
  useLazyRetrieveThreadQuery,
  useListThreadsQuery,
} from '@frontend/web/hooks';
import { useCreateTaskContext } from '@frontend/web/hooks/context/useCreateTaskContext';
import { createFileRoute } from '@frontend/web/utils';
import { disconnectSocket, getSocket, initializeSocket } from '@frontend/web/utils/sockets';

export type ThreadReferenceForTask = {
  id: NonNullable<ThreadReferenceInput['id']>;
  messageIdx: NonNullable<ThreadReferenceInput['messageIdx']>;
  taskSuggestionIdx: NonNullable<ThreadReferenceInput['taskSuggestionIdx']>;
} & { suggestion: ThreadTaskSuggestion };

const Thread = () => {
  const { threadId } = Route.useParams();
  const { autoMessageSendContent } = Route.useSearch();
  const [retrieveThreadData, { data: researchThreadData }] = useLazyRetrieveThreadQuery();
  const { refetch: listThreads } = useListThreadsQuery();
  const { token } = useAuthSlice();
  const navigate = Route.useNavigate();
  // Refs
  const { scrollRef, scrollToBottom } = useScrollView({ onRenderScroll: 'bottom' });
  const textInputRef = useRef<HTMLTextAreaElement>(null);

  // State
  const [messages, { prepend, setState: setMessages }] = useListState<ThreadMessage>(
    autoMessageSendContent
      ? [
          {
            content: autoMessageSendContent,
            createdAt: convertDateLikeToDateTimeISOString(dayjs().toISOString()),
            role: 'client',
            type: 'text',
          },
        ]
      : [],
  );
  const [title, setTitle] = useState<MaybeNil<string>>(researchThreadData?.title);
  const [isSocketInitialized, setIsSocketInitialized] = useState(false);
  const [isAIStreaming, setIsAIStreaming] = useState(false);
  const [renderErrorFooter, setRenderErrorFooter] = useState(false);
  const [{ currentInputMessage, inFlightInputMessage }, setInputMessage] = useState({
    currentInputMessage: '',
    // Refers to message that was used to generate the current AI response, needed for "Retry" logic on error
    inFlightInputMessage: '',
  });
  const [isGeneratingInitialResponse, setIsGeneratingInitialResponse] = useState(!!autoMessageSendContent);
  const [retrieveThreadEntityLinks, { data: threadEntityLinksData }] = useLazyRetrieveThreadEntityLinksQuery();
  const { openCreateTaskModal } = useCreateTaskContext();
  const [isThreadLinksModalOpen, { close: closeThreadLinksModal, open: openThreadLinksModal }] =
    useVisibilityController();

  const { isScrolled } = useScrollStatus(scrollRef);
  const [isThreadLoading, setIsThreadLoading] = useState(true);
  const onClickScrollDownButton = () => scrollToBottom();

  const { isThreadEntityLinksPollingLoading, linksData, queryThreadEntityLinks, setLinksData } = useThreadLinks({
    retrieveThreadEntityLinks: async (uuid, useCache) => (await retrieveThreadEntityLinks(uuid, useCache)).data,
  });

  const handleMessageSocketUpdate = (data: SocketThreadUpdate) =>
    match(data)
      // Noop
      .with({ type: P.union('search_thread_request_type', 'search_thread_title') }, identity)
      .with({ type: P.union('task_suggestions', 'text') }, (data) => {
        // must use functional update to ensure we always see most up-to-date version
        setMessages((current) => {
          if (current[0]?.type === data.type && current[0].role === data.role) {
            return [{ ...current[0], ...data }, ...current.slice(1)];
          }

          return ArrayHelpers.prepend(current, {
            ...data,
            createdAt: convertDateLikeToDateTimeISOString(dayjs().toISOString()),
          });
        });
      })
      .exhaustive();

  const setupSocket = ({
    generateInitial,
    threadData,
    token,
  }: {
    generateInitial: boolean;
    threadData: RetrieveThreadResponse;
    token: string;
  }) => {
    if (getSocket('researchThread')) {
      disconnectSocket();
    }
    const socket = initializeSocket({
      channelType: 'researchThread',
      params: { id: threadId },
      token,
    });

    const onConnect = () => {
      setIsSocketInitialized(true);
      // Checking for messages.length accounts for the case where the user navigates to a
      // thread with the example still in the search params
      if (
        generateInitial &&
        isGeneratingInitialResponse &&
        autoMessageSendContent &&
        threadData?.messages.length === 0
      ) {
        setIsGeneratingInitialResponse(false);
        prepend({
          content: autoMessageSendContent,
          createdAt: convertDateLikeToDateTimeISOString(dayjs().toISOString()),
          role: 'client',
          type: 'text',
        });
        socket.emit(
          'generate_search_response',
          serializeJson({ request_text: autoMessageSendContent, search_thread_id: threadId, v2: true }),
        );
        setIsAIStreaming(true);
        setInputMessage({ currentInputMessage: '', inFlightInputMessage: autoMessageSendContent });
      }
    };

    const onDisconnect = () => {
      setIsAIStreaming(false);
    };

    const onGenerationCompleted = () => {
      // Hack around forcing cache update
      if (!threadData.title) {
        listThreads();
      }
      setIsAIStreaming(false);
      disconnectSocket();
      setIsSocketInitialized(false);
    };

    const onGenerationFailed = () => {
      setIsAIStreaming(false);
      setRenderErrorFooter(true);
      disconnectSocket();
      setIsSocketInitialized(false);
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('generation_completed', onGenerationCompleted);
    socket.on('generation_failed', onGenerationFailed);
    return socket;
  };

  useEffect(() => {
    (async () => {
      if (threadId && token) {
        setIsThreadLoading(true);
        const threadData = (await retrieveThreadData(threadId)).data;
        setIsThreadLoading(false);
        if (threadData) {
          setupSocket({ generateInitial: true, threadData, token });
        } else {
          // eslint-disable-next-line no-console
          console.error('Problem initializing thread socket connection');
        }
        return () => {
          disconnectSocket();
        };
      }
      return identity;
    })();
    return identity;
  }, [threadId, token]);

  useEffect(() => {
    if (researchThreadData?.messages) {
      setMessages(reverse(researchThreadData.messages));
      updateTitle(researchThreadData.title ?? '');
    }
  }, [researchThreadData]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const updateTitle = (title: string) => {
    setTitle(title);
  };

  const handleSocketUpdate = debounce(
    (json: SerializedJson<SocketThreadUpdate>) => {
      const data = deserializeJson(json);
      match(data)
        .with({ type: 'search_thread_title' }, (message) => {
          updateTitle(message.content);
        })
        .with({ type: 'task_suggestions' }, (data) => handleMessageSocketUpdate(data))
        .with({ type: 'text' }, (data) => handleMessageSocketUpdate(data))
        // Noop for now, but we'll use this for captive threads
        .with({ type: 'search_thread_request_type' }, identity)
        .exhaustive();
    },
    10,
    { maxWait: 100 },
  );

  // Listens for updates to the search thread and update functions as dependencies changes
  useEffect(() => {
    const socket = getSocket('researchThread');
    if (socket && isSocketInitialized) {
      socket.on('update_search_thread', handleSocketUpdate);
      return () => {
        socket.off('update_search_thread', handleSocketUpdate);
      };
    }
    return identity;
  }, [messages, isSocketInitialized]);

  const onClickInterruptStreaming = () => {
    setIsAIStreaming(false);
    const socket = getSocket('researchThread');
    if (socket && isSocketInitialized && token) {
      socket.emit('stop_generation');
    }
    // Since this socket emission will have some latency, we should re-initialize the socket
    // to drop any lagging results
    disconnectSocket();
  };

  const onClickCreateAIResponse = (message: string) => {
    setRenderErrorFooter(false);
    textInputRef.current?.blur();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const payload = { request_text: message, search_thread_id: threadId, v2: true };
    if (token) {
      const socket = setupSocket({ generateInitial: false, threadData: researchThreadData!, token });
      socket.emit('generate_search_response', serializeJson(payload));
      setIsAIStreaming(true);
      prepend(
        // Prepending this message to the list so that the user knows their message is being processed
        {
          content: '',
          createdAt: convertDateLikeToDateTimeISOString(dayjs().toISOString()),
          role: 'ai_bot',
          type: 'text',
        },
        {
          content: message,
          // createdAt must be unique
          createdAt: convertDateLikeToDateTimeISOString(dayjs().add(1, 'ms').toISOString()),
          role: 'client',
          type: 'text',
        },
      );
      setInputMessage({ currentInputMessage: '', inFlightInputMessage: message });
    }
  };

  const updateTaskSuggestion = async () => {
    if (threadId) {
      await retrieveThreadData(threadId);
    }
  };

  const onClickTaskSuggestion = (researchThreadReferenceData: ThreadReferenceForTask) =>
    match(researchThreadReferenceData.suggestion)
      .with({ recurrenceId: P.string }, ({ recurrenceId }) =>
        navigate({
          params: { recurrenceId },
          to: '/app/recurrence/$recurrenceId',
        }),
      )
      .with({ taskId: P.string }, ({ taskId }) =>
        navigate({
          params: researchThreadReferenceData.suggestion.isDraftTask ? { draftTaskId: taskId } : { taskId },
          to: researchThreadReferenceData.suggestion.isDraftTask ? '/app/draft-task/$draftTaskId' : '/app/task/$taskId',
        }),
      )
      .otherwise(() =>
        openCreateTaskModal({
          data: {
            description: researchThreadReferenceData.suggestion.title,
            name: researchThreadReferenceData.suggestion.title,
            researchThreadId: threadId,
            researchThreadReferenceData: researchThreadReferenceData,
          },
          isRecurrence: researchThreadReferenceData.suggestion.isRecurrence,
          onCreate: updateTaskSuggestion,
          skipSteps: ['description'],
        }),
      );

  const onClickEntity: ThreadMarkdownRendererProps['onClickLink'] = async (linkData) => {
    openThreadLinksModal();
    queryThreadEntityLinks(linkData);
  };

  const renderItem: FlatListProps<ThreadMessage>['renderItem'] = ({ array, index, item: message }) => {
    const messageIsStreaming = index === 0 && isAIStreaming;
    const reversedIndex = array.length - 1 - index;
    return match(message)
      .with({ role: 'ai_recommender', type: 'task_suggestions' }, (message) => {
        return (
          <ThreadSuggestionMessage
            isStreaming={messageIsStreaming}
            message={message}
            messageIndex={reversedIndex}
            onClickSuggestionCard={onClickTaskSuggestion}
            onCreateTask={updateTaskSuggestion}
            researchThreadId={threadId}
          />
        );
      })
      .with({ role: 'ai_bot', type: 'task_created' }, (message) => {
        return (
          <ThreadChatWhisper
            content={{
              onClickBoldText: () => {
                navigate({ params: { taskId: message.content.id }, to: '/tasks/$taskId' });
              },
              taskTitle: message.content.name,
            }}
          />
        );
      })
      .with({ role: 'ai_bot', type: 'draft_task_created' }, (message) => {
        return (
          <ThreadChatWhisper
            content={{
              onClickBoldText: () => {
                navigate({ params: { draftTaskId: message.content.id }, to: '/app/draft-task/$draftTaskId' });
              },
              taskTitle: message.content.name,
            }}
          />
        );
      })
      .with({ role: 'ai_bot', type: 'recurrence_created' }, (message) => {
        return (
          <ThreadChatWhisper
            content={{
              onClickBoldText: () =>
                navigate({
                  params: { recurrenceId: message.content.id },
                  to: '/app/recurrence/$recurrenceId',
                }),
              taskTitle: message.content.name,
            }}
          />
        );
      })
      .with({ role: P.union('ai_bot', 'client') }, (message) => (
        <ThreadTextMessage
          index={index}
          isStreaming={messageIsStreaming}
          message={message}
          messagesList={messages}
          onClickLink={onClickEntity}
          threadId={threadId}
        />
      ))
      .exhaustive();
  };

  return (
    <PageContainer isScrollable={false} padding="none">
      <Header
        onClickRightIcon={() => navigate({ to: '/app/thread/create' })}
        rightIconName="IconPencil"
        title={isThreadLoading ? '' : getThreadTitle(researchThreadData ? { ...researchThreadData, title } : undefined)}
      />
      <YStack className="flex-1 overflow-y-auto relative">
        {/* Inverting FlatList and reversing comments so that most recent messages are laid out first */}
        {isThreadLoading ? (
          <LoadingSpinnerPanel fullScreen />
        ) : (
          <FlatList
            ListFooterComponent={
              renderErrorFooter ? (
                <Banner
                  className="mb-md mx-lg"
                  color="danger"
                  leftIconName="IconRefresh"
                  onClick={() => onClickCreateAIResponse(inFlightInputMessage)}
                  title={'Oops! We hit a snag generating your response. Retry?'}
                />
              ) : null
            }
            className="flex-1"
            contentContainerClassName="pt-md px-md gap-y-md"
            data={messages}
            inverted
            ref={scrollRef}
            renderItem={({ index, item }) => renderItem({ array: messages, index, item })}
          />
        )}
        <ThreadLinksModal
          isOpen={isThreadLinksModalOpen}
          isRetrieveLinksLoading={isThreadEntityLinksPollingLoading}
          linkText={linksData?.selectedText}
          onClose={() => {
            closeThreadLinksModal();
            setLinksData({ selectedText: undefined, siteData: [] });
          }}
          siteData={linksData.siteData}
          status={threadEntityLinksData?.status}
          threadId={threadId}
        />
        {isScrolled ? (
          // eslint-disable-next-line tailwindcss/no-arbitrary-value
          <AnimatingFloatingButton className="bottom-md" onClick={onClickScrollDownButton} />
        ) : null}
      </YStack>
      <ThreadChatFooter
        isAIResponseLoading={isAIStreaming}
        isCreateCommentLoading={false}
        onChangeText={(messageValue) => setInputMessage((cur) => ({ ...cur, currentInputMessage: messageValue }))}
        onClickInterruptAIResponse={onClickInterruptStreaming}
        onClickSubmit={() => onClickCreateAIResponse(currentInputMessage)}
        placeholder="Enter message"
        ref={textInputRef}
        textValue={currentInputMessage}
      />
    </PageContainer>
  );
};

export const Route = createFileRoute('/app/thread/$threadId')({
  component: Thread,
  validateSearch: (search) =>
    z
      .object({
        autoMessageSendContent: z.string().optional(),
      })
      .parse(search),
});
