import { Icon } from '@frontend/web-react';
import { AppStoresButton } from './Buttons';
import { XStack, YStack } from './Containers';
import { Divider } from './Divider';
import { Text, Title } from './Texts';

type DesktopOnlyProps = {
  onClickNavigateAccount: () => void;
  onTrackAppStoreButton: (platform: MobilePlatform) => void;
};

export const DesktopOnly = ({ onClickNavigateAccount, onTrackAppStoreButton }: DesktopOnlyProps) => (
  <YStack alignItems="center" className="h-screen gap-y-md" justifyContent="center">
    <YStack className="gap-y-md">
      <Title type="h3">On a mobile device? Use our app</Title>
      <YStack className="gap-y-md">
        <XStack alignItems="center" className="gap-x-sm">
          <Icon name="IconSend" />
          <Text type="p-sm">Hand off your task on the go</Text>
        </XStack>
        <XStack alignItems="center" className="gap-x-sm">
          <Icon name="IconBell" />
          <Text type="p-sm">Native notifications</Text>
        </XStack>
        <XStack alignItems="center" className="gap-x-sm">
          <Icon name="IconSparkles" />
          <Text type="p-sm">Personalized tasks</Text>
        </XStack>
      </YStack>
      <YStack className="gap-y-md">
        <Text type="captionBold">Download</Text>
        <XStack className="gap-x-sm">
          <AppStoresButton onClick={() => onTrackAppStoreButton('android')} platform="android" />
          <AppStoresButton onClick={() => onTrackAppStoreButton('ios')} platform="ios" />
        </XStack>
      </YStack>
      <Divider className="mb-md mt-xxxl" />
      <Title type="h5">
        Trying to find your{' '}
        <Text inherit onClick={onClickNavigateAccount} span td="underline" type="h5">
          account?
        </Text>
      </Title>
    </YStack>
  </YStack>
);
