import { formatEndpoint } from '@frontend/duck-tape';

const namespace = 'calendars';

const CALENDAR_RECURRENCES_ENDPOINTS = {
  dismissAllCalendarRecurrences: () => formatEndpoint('recurrences/dismiss_all', namespace),
  dismissCalendarRecurrence: (id: string) => formatEndpoint(`recurrences/${id}/dismiss`, namespace),
  listCalendarRecurrences: () => formatEndpoint('recurrences', namespace),
};

export default CALENDAR_RECURRENCES_ENDPOINTS;
