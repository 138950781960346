import { camelCase, isObject, kebabCase, snakeCase, startCase, toLower, upperCase } from '../hidash/hidash';
import { match } from '../match';
import type { CamelCase, ConstantCase, KebabCase, SnakeCase, UpperCase } from '../types';

type CaseType = 'camelCase' | 'constantCase' | 'kebabCase' | 'sentenceCase' | 'snakeCase' | 'upperCase';

export const changeCase = (input: string, caseType: CaseType): string =>
  match<CaseType, string>(caseType)
    .with('camelCase', () => camelCase(input))
    .with('kebabCase', () => kebabCase(input))
    .with('constantCase', () => upperCase(input).replace(/ /g, '_'))
    .with('snakeCase', () => snakeCase(input))
    .with('upperCase', () => input.toUpperCase())
    .with('sentenceCase', () => startCase(toLower(input)))
    .exhaustive();

type ConvertCase<T extends string, C extends CaseType> = C extends 'camelCase'
  ? CamelCase<T>
  : C extends 'kebabCase'
    ? KebabCase<T>
    : C extends 'constantCase'
      ? ConstantCase<T>
      : C extends 'snakeCase'
        ? SnakeCase<T>
        : C extends 'upperCase'
          ? UpperCase<T>
          : never;

type ConvertedKeys<T, C extends CaseType> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K in keyof T as ConvertCase<K & string, C>]: T[K] extends Record<string, any>
    ? ConvertedKeys<T[K], C> // Using ConvertedKeys for recursive type
    : T[K];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertArrayObjectKeysCase<T extends Array<any>>(
  object: T,
  caseType: 'camelCase',
): ConvertedKeys<T, 'camelCase'>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertArrayObjectKeysCase<T extends Array<any>>(
  object: T,
  caseType: 'kebabCase',
): ConvertedKeys<T, 'kebabCase'>;
// @ts-expect-error Unknown error
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertArrayObjectKeysCase<T extends Array<any>>(
  object: T,
  caseType: 'constantCase',
): ConvertedKeys<T, 'constantCase'>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertArrayObjectKeysCase<T extends Array<any>>(
  object: T,
  caseType: 'snakeCase',
): ConvertedKeys<T, 'snakeCase'>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertArrayObjectKeysCase<T extends Array<any>>(
  object: T,
  caseType: 'upperCase',
): ConvertedKeys<T, 'upperCase'>;
export function convertArrayObjectKeysCase<T>(array: T[], caseType: CaseType): ConvertedKeys<T, CaseType>[] {
  // @ts-expect-error Unknown error
  return array.map((item) => {
    if (Array.isArray(item)) {
      // @ts-expect-error Unknown error
      return convertArrayObjectKeysCase(item, caseType);
    } else if (isObject(item)) {
      // @ts-expect-error Unknown error
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return convertObjectKeysCase(item, caseType);
    } else {
      return item;
    }
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertObjectKeysCase<T extends Record<string, any>>(
  object: T,
  caseType: 'camelCase',
): ConvertedKeys<T, 'camelCase'>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertObjectKeysCase<T extends Record<string, any>>(
  object: T,
  caseType: 'kebabCase',
): ConvertedKeys<T, 'kebabCase'>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertObjectKeysCase<T extends Record<string, any>>(
  object: T,
  caseType: 'constantCase',
): ConvertedKeys<T, 'constantCase'>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertObjectKeysCase<T extends Record<string, any>>(
  object: T,
  caseType: 'snakeCase',
): ConvertedKeys<T, 'snakeCase'>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertObjectKeysCase<T extends Record<string, any>>(
  object: T,
  caseType: 'upperCase',
): ConvertedKeys<T, 'upperCase'>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertObjectKeysCase<T extends Record<string, any>>(
  object: T,
  caseType: CaseType,
): ConvertedKeys<T, CaseType> {
  return Object.keys(object).reduce(
    (acc, key) => {
      const newKey = changeCase(key, caseType) as keyof typeof acc;
      const value = object[key];
      // Handle arrays containing objects or other arrays
      // eslint-disable-next-line fp/no-mutation
      acc[newKey] = Array.isArray(value)
        ? // @ts-expect-error Unknown error
          convertArrayObjectKeysCase(value, caseType)
        : isObject(value)
          ? // @ts-expect-error Unknown error
            convertObjectKeysCase(value, caseType)
          : value;
      return acc;
    },
    {} as ConvertedKeys<T, CaseType>,
  );
}
