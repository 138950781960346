import type { Address } from '@frontend/api-types';
import { useAddressForm } from '@frontend/forms';
import type { CommonModalProps } from '@frontend/web-react';
import { AddressInputGroup, Button, Modal, YStack } from '@frontend/web-react';
import { useUpdateAddressMutation } from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';

type UpdatePrimaryAddressModalProps = CommonModalProps & {
  primaryAddress: Address;
};

export const UpdatePrimaryAddressModal = ({ isOpen, onClose, primaryAddress }: UpdatePrimaryAddressModalProps) => {
  const autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
  const [updateAddress, { isLoading: isUpdateAddressLoading }] = useUpdateAddressMutation();

  const addressForm = useAddressForm({
    initialValues: { ...primaryAddress, line2: '' },
    onSubmit: async (values) =>
      handleMutation({
        mutation: () => {
          return updateAddress({
            ...values,
            alias: values.alias || 'Home',
          }).unwrap();
        },
        onSuccess: onClose,
        showSuccessToast: false,
      }),
    validateOnChange: true,
  });

  const { isValid, submitForm } = addressForm;

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Edit address" titlesAlignment="start">
      <YStack gapY="xl">
        <AddressInputGroup autocompleteSessionToken={autocompleteSessionToken} form={addressForm} />
        <Button disabled={!isValid} isLoading={isUpdateAddressLoading} label="Submit" onClick={submitForm} />
      </YStack>
    </Modal>
  );
};
