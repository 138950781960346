import type { RetrieveThreadEntityLinksResponse, SiteData } from '@frontend/api-types';
import { IconBeachWithLinks } from '@frontend/web-icons';
import type { ModalProps } from '@frontend/web-react';
import { Clickable, Icon, Image, Modal, Skeleton, Text, View, XStack, YStack } from '@frontend/web-react';
import { applyClass, concatClasses, safeCss } from '@frontend/web-utils';
import { useAnalytics } from '@frontend/web/hooks';

type ThreadLinksModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  isRetrieveLinksLoading: boolean;
  linkText: MaybeUndefined<string>;
  siteData: SiteData[];
  status: MaybeUndefined<RetrieveThreadEntityLinksResponse['status']>;
  threadId: string;
};

export const ThreadLinksModal = ({
  isOpen,
  isRetrieveLinksLoading,
  linkText,
  onClose,
  siteData,
  status,
  threadId,
}: ThreadLinksModalProps) => {
  const { track } = useAnalytics();

  const onPressLink = (siteData: SiteData) => {
    window.open(siteData.url, '_blank');
    track({
      data: { ...siteData, threadId },
      event: 'webViewOpenedFromLink',
      topic: 'research',
    });
  };

  return (
    <Modal
      headerClassName={safeCss('p-md')}
      headerGap="md"
      isOpen={isOpen}
      onClose={onClose}
      padding="none"
      size="md"
      title={linkText ? `Links for ${linkText}` : 'Website Links'}
      titlesAlignment="center"
      withCloseButton
    >
      <YStack>
        {status === 'error' ? (
          <View className="items-center gap-y-md p-md mb-xl">
            <IconBeachWithLinks />
            <YStack className="gap-y-md">
              <Text textAlign="center" type="h3">
                Links Unavailable
              </Text>
              <Text color="secondary" textAlign="center">
                Well, this is awkward. The links {"we're"} looking for seem to be on an extended vacation. Try again in
                a minute.
              </Text>
            </YStack>
          </View>
        ) : isRetrieveLinksLoading ? (
          // eslint-disable-next-line tailwindcss/no-arbitrary-value
          <XStack className="p-md flex flex-row gap-x-sm min-h-[260px]">
            <Skeleton height={24} radius={6} width={24} />
            <YStack className="gap-y-sm w-full">
              <Skeleton height={24} radius={6} width="100%" />
              <Skeleton height={16} radius={6} width="100%" />
            </YStack>
          </XStack>
        ) : (
          siteData.map(({ description, faviconUrl, thumbnailUrl, title, url }) => (
            <Clickable
              canFocus={false}
              className="gap-x-sm items-center p-md border-b-1 border-borderPrimary"
              enableHoverEffect
              flexDirection="row"
              key={url}
              onClick={() => onPressLink({ description, faviconUrl, thumbnailUrl, title, url })}
            >
              <YStack>
                <YStack
                  className={concatClasses(
                    'border-1 rounded-md border-borderPrimary justify-center items-center size-xl',
                    applyClass(faviconUrl, 'p-xs'),
                  )}
                >
                  <Image
                    FallbackComponent={<Icon color="secondary" name="IconLink" size="md" />}
                    className="size-lg object-cover rounded-md"
                    source={faviconUrl}
                  />
                </YStack>
              </YStack>
              <YStack className="grow-1">
                {title ? (
                  <Text className="break-all" lineClamp={1} type="p-md">
                    {title}
                  </Text>
                ) : null}
                <Text className="break-all" color="tertiary" lineClamp={1} type="p-sm">
                  {url}
                </Text>
              </YStack>
            </Clickable>
          ))
        )}
      </YStack>
    </Modal>
  );
};
