import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware, MiddlewareAPI } from 'redux';
import { clearAuth } from '../slices';

export const errorMiddleware: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isRejectedWithValue(action)) {
      // @ts-expect-error Not sure where to find this type
      const code = action.payload?.data?.code;

      if (code === 4011) {
        dispatch(clearAuth());
      }
    }

    return next(action);
  };
