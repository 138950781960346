import type { PromotionDetails } from '@frontend/api-types';
import type { PromoReduxState } from '@frontend/redux/slices/promoSlice';
import * as PromoSlice from '@frontend/redux/slices/promoSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from './useSelector';

export const usePromoSlice = <TRootState extends { promo: PromoReduxState }>() => {
  const promo = useSelector<TRootState>()(({ promo }) => promo);
  const dispatch = useDispatch();

  const setPromoCode = (promoCode: string) => dispatch(PromoSlice.setPromoCode(promoCode));
  const setPromoDetails = (promoDetails: PromotionDetails) => dispatch(PromoSlice.setPromoDetails(promoDetails));
  const clearPromo = () => dispatch(PromoSlice.clearPromo());

  return {
    clearPromo,
    setPromoCode,
    setPromoDetails,
    state: { promoCode: promo.promoCode, promoDetails: promo.promoDetails },
  };
};
