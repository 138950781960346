import type { DraftTask } from '@frontend/api-types';
import { pluralize } from '@frontend/duck-tape';
import { useEffect } from '@frontend/react';
import { Button, Divider, Icon, Text, YStack } from '@frontend/web-react';
import { useQuackActionsContext } from '@frontend/web/context/QuackActionsContext';

type DraftTaskChatInitialActionsProps = {
  draftTask: DraftTask;
  isConvertDraftTaskToTaskLoading: boolean;
  onConvertDraftTaskToTask: () => Promise<void> | void;
  remainingActiveTasksQuota: number;
};

export const DraftTaskChatInitialActions = ({
  draftTask,
  isConvertDraftTaskToTaskLoading,
  onConvertDraftTaskToTask,
  remainingActiveTasksQuota,
}: DraftTaskChatInitialActionsProps) => {
  const { addAction, removeAction } = useQuackActionsContext();
  const canStartTask = remainingActiveTasksQuota > 0;
  const tasksToComplete = Math.abs(remainingActiveTasksQuota) + 1;
  const message = canStartTask
    ? 'This task will be moved to in-progress.'
    : `You can start this task when you complete ${pluralize('active task', tasksToComplete, true)}.`;

  useEffect(() => {
    addAction({
      actions: [
        {
          description: 'Start now',
          id: 'startTask',
          label: 'Start task now',
          leftSection: <Icon name="IconPlayerPlay" />,
          onClick: onConvertDraftTaskToTask,
        },
      ],
      group: 'Current Task',
    });

    return () => {
      removeAction('startTask', 'Current Task');
    };
  }, [draftTask]);

  return (
    <YStack className="mb-lg">
      <Divider />
      <YStack alignItems="start" className="pt-lg" gap="md">
        <Button
          // eslint-disable-next-line tailwindcss/no-arbitrary-value
          className="w-full max-w-[358px] px-lg py-sm"
          color="secondary"
          disabled={!canStartTask}
          isLoading={isConvertDraftTaskToTaskLoading}
          label="Start Now"
          leftIconColor="primary"
          leftIconName="IconSend"
          onClick={onConvertDraftTaskToTask}
        />
        {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
        <YStack alignItems="center" className="w-full max-w-[358px]">
          <Text color="tertiary" textAlign="center" type="caption">
            {message}
          </Text>
        </YStack>
      </YStack>
    </YStack>
  );
};
