import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type AuthReduxState = {
  token?: string;
};

const initialState: AuthReduxState = {};

const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    clearAuth: (state) => {
      // eslint-disable-next-line fp/no-mutation
      state.token = undefined;
    },
    storeToken: (state, { payload }: PayloadAction<string>) => {
      // eslint-disable-next-line fp/no-mutation
      state.token = payload;
    },
  },
});

export const { reducer: authReducer } = authSlice;
export const { clearAuth, storeToken } = authSlice.actions;
