import { canTaskBeSnoozed } from '@frontend/api-client';
import type { TaskDetail } from '@frontend/api-types';
import { dayjs, identity, omit } from '@frontend/duck-tape';
import { SNOOZE_OPTIONS, useSnoozeTaskForm } from '@frontend/forms';
import { useEffect } from '@frontend/react';
import {
  Button,
  DateInput,
  Icon,
  Modal,
  TagSingleSelectInput,
  Text,
  YStack,
  type CommonModalProps,
} from '@frontend/web-react';
import { useQuackActionsContext } from '@frontend/web/context/QuackActionsContext';
import { useAnalytics, useSnoozeTaskMutation } from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';

export type SnoozeTaskModalProps = CommonModalProps & {
  onComplete?: () => void;
  openModal: () => void;
  task: TaskDetail;
  taskId: string;
};

export const SnoozeTaskModal = ({ isOpen, onClose, onComplete, openModal, task, taskId }: SnoozeTaskModalProps) => {
  const { track } = useAnalytics();
  const { addAction, removeAction } = useQuackActionsContext();
  const [snoozeTask, { isLoading }] = useSnoozeTaskMutation();
  const canBeSnoozed = task && canTaskBeSnoozed(task);
  const { dirty, getControl, isValid, resetForm, setFieldValue, submitForm } = useSnoozeTaskForm({
    onSubmit: async (values) => {
      track({ data: { taskId }, event: 'snoozeTaskSubmitButtonSelected', topic: 'tasks' });
      return handleMutation({
        mutation: () =>
          snoozeTask({
            snoozeDate: values.customSnoozeUntilDate,
            taskId,
          }),
        onSuccess: () => {
          onCloseComposed();
          onComplete?.();
        },
        successMessage: 'Task snoozed successfully',
      });
    },
  });

  const onCloseComposed = () => {
    onClose();
    resetForm();
  };

  useEffect(() => {
    if (canBeSnoozed) {
      addAction({
        actions: [
          {
            description: 'Snooze this task',
            id: 'snooze',
            label: 'Snooze task',
            leftSection: <Icon name="IconAlarmSnooze" />,
            onClick: openModal,
          },
        ],
        group: 'Current Task',
      });

      return () => {
        removeAction('snooze', 'Current Task');
      };
    }
    return identity;
  }, [task, canBeSnoozed]);

  return (
    <Modal isOpen={isOpen} onClose={onCloseComposed} title="When do you want to bring this task back?">
      <YStack className="gap-y-md">
        <TagSingleSelectInput
          canDeselect
          onChange={(value) => {
            setFieldValue('singleOptionSnoozeUntilDate', value, true);
            // @ts-expect-error Tedious types
            setFieldValue('customSnoozeUntilDate', value, true);
          }}
          options={SNOOZE_OPTIONS}
          {...omit(getControl('singleOptionSnoozeUntilDate'), ['onChange'])}
        />
        <Text className="text-center" color="secondary" type="p-sm" uppercase>
          or
        </Text>
        <DateInput
          label="Choose a date for the task"
          minDate={dayjs().add(1, 'day').toDate()}
          onChange={(value) => {
            if (value) {
              setFieldValue('singleOptionSnoozeUntilDate', undefined, true);
              setFieldValue('customSnoozeUntilDate', value, true);
            }
          }}
          type="default"
          {...omit(getControl('customSnoozeUntilDate'), ['onChange'])}
        />
        <YStack className="gap-y-md">
          <Button
            color="primary"
            disabled={!isValid || !dirty}
            isLoading={isLoading}
            label="Submit"
            onClick={submitForm}
          />
          <Button color="secondary" label="Cancel" onClick={onCloseComposed} />
        </YStack>
      </YStack>
    </Modal>
  );
};
