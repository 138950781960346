import type { BaseApi } from '@frontend/api-client/utils/types';
import type { ListTaskSearchResultsRequest, ListTaskSearchResultsResponse } from '@frontend/api-types';
import { convertArrayObjectKeysCase } from '@frontend/duck-tape';
import { TEXT_SEARCH_ENDPOINTS } from './endpoints';

export const getTextSearchApi = (baseApi: BaseApi) =>
  baseApi.injectEndpoints({
    endpoints: (builder) => ({
      listTaskSearchResults: builder.query<ListTaskSearchResultsResponse, ListTaskSearchResultsRequest>({
        query: TEXT_SEARCH_ENDPOINTS.searchTasks,
        transformResponse: (results: GenericRecord[]) =>
          convertArrayObjectKeysCase(results, 'camelCase') as ListTaskSearchResultsResponse,
      }),
    }),
  });
