import ctl from '@netlify/classnames-template-literals';
import clsx from 'clsx';

import classNames from 'classnames';
/**
 * Important: If a change is made to this file, you might need to update the regex in settings.json/eslint config files
 */

/* Concatenates the styles without handling conflicting styles */
export const concatClasses = clsx;

// TODO (ethan): Maybe make opaque type for ClassName and make it safeCss' return type
export type ClassName = string;

/* Collapses css into one line + it gives type hints */
export const safeCss = ctl;

/* Conditionally applies css class */
export const applyClass = (
  value: MaybeNil<boolean | string>,
  className: string,
  elseClassName?: MaybeUndefined<string>,
) => (value ? safeCss(className) : safeCss(elseClassName ?? ''));

export { classNames };
