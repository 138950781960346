import { CREATE_TASK_NEXT_STEPS_OPTIONS } from '@frontend/api-client';
import { useEffect, useState } from '@frontend/react';
import { Icon, RadioInput, Text, XStack, YStack, useShortcut } from '@frontend/web-react';
import { useAnalytics } from '@frontend/web/hooks';
import { useCreateTaskContext } from '@frontend/web/hooks/context/useCreateTaskContext';

export const NEXT_STEPS_QUESTION = 'How should we proceed?';

export const NextSteps = () => {
  const { track } = useAnalytics();
  const {
    createTaskState,
    decrementStep,
    incrementStep,
    initialQuestions,
    isOnLastStep,
    isStreaming,
    isSubmitting,
    updateTaskData,
  } = useCreateTaskContext();
  const { nextSteps } = createTaskState.taskData;
  const [isStepComplete, setIsStepComplete] = useState(!!nextSteps);
  const canProceed = isStreaming ? !!initialQuestions.length : true;
  const isSubmitDisabled = !isStepComplete || !canProceed;

  useEffect(() => {
    track({
      data: {
        aiGenerated: false,
        question: NEXT_STEPS_QUESTION,
        taskDescription: createTaskState.taskData.description,
      },
      event: 'viewedTaskCreationQuestion',
      topic: 'tasks',
    });
  }, []);

  useEffect(() => {
    setIsStepComplete(!!nextSteps);
  }, [nextSteps]);

  useShortcut({
    Enter: {
      isDisabled: isSubmitDisabled,
      onSubmit: incrementStep,
    },
  });

  return (
    <YStack gapY="md">
      <XStack>
        {createTaskState.currentStep !== createTaskState.steps[0] ? (
          <Icon name="IconArrowLeft" onClick={decrementStep} size={18} />
        ) : null}
      </XStack>
      <Text type="captionBold">{NEXT_STEPS_QUESTION}</Text>
      <RadioInput
        onChange={(nextSteps) => {
          updateTaskData({ nextSteps });
        }}
        options={CREATE_TASK_NEXT_STEPS_OPTIONS.map((o) => ({ id: o.title, label: o.title, value: o.value }))}
        value={nextSteps}
      />
      <XStack justifyContent="end">
        <Icon
          color="primary"
          disabled={isSubmitDisabled}
          enableBackgroundColor
          isLoading={isSubmitting || !canProceed}
          name={isOnLastStep || (!initialQuestions.length && !isStreaming) ? 'IconSend' : 'IconArrowRight'}
          onClick={incrementStep}
          rounded
          size="lg"
        />
      </XStack>
    </YStack>
  );
};
