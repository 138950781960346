import { Amex, Mastercard, Visa } from '@frontend/assets';
import { Icon, Image, LinkButton, Text, View, XStack, YStack } from '@frontend/web-react';

export const paymentCardBrands = { amex: Amex, mastercard: Mastercard, visa: Visa };

export type CreditCardProps = {
  brand: keyof typeof paymentCardBrands;
  expiryDate: string;
  isDefault: boolean;
  isDeleteCardLoading: boolean;
  isMakeDefaultLoading: boolean;
  lastFourDigits: string;
  onDelete: () => void;
  onEditCard: () => void;
  onMakeDefault: () => void;
};

export const CreditCard = ({
  brand,
  expiryDate,
  isDefault,
  isDeleteCardLoading,
  isMakeDefaultLoading,
  lastFourDigits,
  onDelete,
  onEditCard,
  onMakeDefault,
}: CreditCardProps) => (
  <YStack className="gap-md pb-md border-b-1 border-b-borderPrimary">
    <XStack className="gap-sm items-center">
      {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
      <View className="w-[27px] h-[18px]">
        <Image source={paymentCardBrands[brand]} />
      </View>
      <XStack className="gap-md items-center">
        <Text type="h6">
          {brand.charAt(0).toUpperCase() + brand.slice(1)} {lastFourDigits}
        </Text>
        <Text color="tertiary" type="p-sm">
          {expiryDate}
        </Text>
        {isDefault ? (
          <Text className="p-xs rounded-sm bg-surfacePrimary" type="caption">
            Default
          </Text>
        ) : null}
      </XStack>
    </XStack>
    <XStack className="w-full justify-end gap-lg">
      {isDefault ? (
        <LinkButton
          label="Edit default card"
          leftIconName="IconPencil"
          leftIconProps={{ size: 'md' }}
          onClick={onEditCard}
          type="h6"
        />
      ) : (
        <XStack className="gap-lg">
          <LinkButton
            isLoading={isMakeDefaultLoading}
            label="Make this card default"
            leftIconName="IconCheck"
            leftIconProps={{ size: 'md' }}
            onClick={onMakeDefault}
            type="h6"
          />
          <Icon color="danger" isLoading={isDeleteCardLoading} name="IconTrash" onClick={onDelete} size="md" />
        </XStack>
      )}
    </XStack>
  </YStack>
);
