import {
  AppScreenshot,
  BrowsingBooks,
  CoupleEatingDinner,
  ElderlyLadyWithPhone,
  HelloStranger,
  Mailboxes,
  ManDiscoBall,
  PhoneCalendar,
  VipCat,
  WomanWalkingWithPhone,
} from '@frontend/assets';
import { match } from '@frontend/duck-tape';
import type { ReactNode } from '@frontend/react';
import { AppShell, useIsOnMobile, useIsOnTablet } from '@frontend/web-react';
import {
  AddPaymentMethod,
  Calendar,
  // IntroVideo,
  ProvideAddress,
  SelectMembership,
  Stories,
} from '@frontend/web/components/DomainSpecific/Onboarding';
import { useImagePreloader } from '@frontend/web/hooks';
import { createFileRoute } from '@tanstack/react-router';
import { AppContentWrapper, UnauthenticatedAppHeader } from '../components';
import { DownloadAppAndComplete } from '../components/DomainSpecific/Onboarding/DownloadAppAndComplete';
import { FAQs } from '../components/DomainSpecific/Onboarding/FAQs';
import type { OnboardingStep } from '../context/OnboardingContext';
import { OnboardingProvider } from '../context/OnboardingContext';
import { redirectUnauthenticated } from '../utils';

const Onboarding = () => {
  useImagePreloader([
    AppScreenshot,
    BrowsingBooks,
    CoupleEatingDinner,
    ElderlyLadyWithPhone,
    HelloStranger,
    Mailboxes,
    PhoneCalendar,
    VipCat,
    ManDiscoBall,
    WomanWalkingWithPhone,
  ]);

  const isOnMobile = useIsOnMobile();
  const isOnTablet = useIsOnTablet();

  return (
    <AppShell className="flex flex-col">
      <UnauthenticatedAppHeader isHidden={isOnMobile || isOnTablet} />
      {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
      <AppContentWrapper>
        <OnboardingProvider>
          {({ onboardingState }) => {
            return !onboardingState.isHydrated
              ? null
              : match<OnboardingStep, ReactNode>(onboardingState.currentOnboardingStep)
                  .with('selectMembership', () => <SelectMembership />)
                  .with('addPaymentMethod', () => <AddPaymentMethod />)
                  // .with('introVideo', () => <IntroVideo />)
                  .with('stories', () => <Stories />)
                  .with('faqs', () => <FAQs />)
                  .with('calendar', () => <Calendar />)
                  .with('downloadAppAndComplete', () => <DownloadAppAndComplete />)
                  .with('provideAddress', () => <ProvideAddress />)
                  .exhaustive();
          }}
        </OnboardingProvider>
      </AppContentWrapper>
    </AppShell>
  );
};

export const Route = createFileRoute('/onboarding')({
  beforeLoad: async ({ context, location }) => {
    redirectUnauthenticated({ context, location });
  },
  component: Onboarding,
});
