import { formatEndpoint } from '@frontend/duck-tape';

const namespace = 'notifications';

const NOTIFICATIONS_SETTINGS_ENDPOINTS = {
  retrieve: () => formatEndpoint('', namespace),
  update: () => formatEndpoint('', namespace),
};

export default NOTIFICATIONS_SETTINGS_ENDPOINTS;
