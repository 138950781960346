import { make } from 'ts-brand';
import type { FormattableDate } from '../date/date';
import { DATE_ISO_STRING_FORMAT, dateFormatters, dayjs } from '../date/date';

export const makeDateISOString = make<DateISOString>();

export const convertDateISOStringToDate = (d: DateISOString): Date => new Date(d);

const formatDateISOString = (d: FormattableDate): string => dateFormatters['DATE_ISO_STRING_FORMAT'](d);

// This function can still return a string "Invalid Date" if an invalid input is passed in
// There are some ideas for how we do error handling or better type checking on this
// (like only taking Dayjs/Date as an input), but punting for now
export const convertDateLikeToDateISOString = (input: FormattableDate): DateISOString =>
  makeDateISOString(formatDateISOString(input));

export const isStringValidDateISOString = (dateString: string): boolean =>
  dayjs(dateString, DATE_ISO_STRING_FORMAT, true).isValid();
