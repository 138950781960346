import { uuidRegex } from '@frontend/forms';
import { Text } from '@frontend/web-react';
import { useAnalytics } from '@frontend/web/hooks/useAnalytics';
import { useMemo } from 'react';
import type { Components } from 'react-markdown';
import Markdown from 'react-markdown';

export type ThreadMarkdownRendererProps = {
  markdownContent: string;
  onClickLink: (linkData: { text: string; uuid: string }) => Promise<void>;
  threadId: string;
};

export const ThreadMarkdownRenderer = ({ markdownContent, onClickLink, threadId }: ThreadMarkdownRendererProps) => {
  const { track } = useAnalytics();

  const onPressHighlightedText = async ({ linkOrId, text }: { linkOrId: string; text: string }) => {
    const isUuid = uuidRegex.test(linkOrId);
    if (isUuid) {
      track({
        data: {
          entityId: linkOrId,
          text,
          threadId,
        },
        event: 'linkTextSelected',
        topic: 'research',
      });
      await onClickLink({ text, uuid: linkOrId });
    }
    return false;
  };

  const components: Components = useMemo(
    () => ({
      a: ({ children, href }) => {
        return (
          <Text
            className="underline font-semibold text-teal-700 inline-block"
            onClick={() => onPressHighlightedText({ linkOrId: href as string, text: children as string })}
          >
            {children}
          </Text>
        );
      },
      h1: ({ children }) => <Text type="h1">{children}</Text>,
      h2: ({ children }) => <Text type="h2">{children}</Text>,
      h3: ({ children }) => <Text type="h3">{children}</Text>,
      h4: ({ children }) => <Text type="h4">{children}</Text>,
      h5: ({ children }) => <Text type="h5">{children}</Text>,
      h6: ({ children }) => <Text type="h6">{children}</Text>,
      p: ({ children }) => (
        <Text span type="p-md">
          {children}
        </Text>
      ),
      strong: ({ children }) => <strong className="font-semibold text-textPrimary">{children}</strong>,
    }),
    [],
  );

  return (
    <Markdown className={'duckbill-markdown prose max-w-full text-textPrimary'} components={components}>
      {markdownContent}
    </Markdown>
  );
};
