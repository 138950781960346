import { validators } from '../utils';
import { makeFormComponent, makeFormHook } from '../utils/factory';
import type { InferFormType } from '../utils/types';

export const createTaskCommentFormSchema = validators.object({
  attachments: validators.fileArrayValidator('localFile').max(5),
  body: validators.requiredString('Message content'),
});

export type CreateTaskCommentFormSchema = InferFormType<typeof createTaskCommentFormSchema>;

export const createTaskCommentFormDefaultValues: CreateTaskCommentFormSchema = {
  attachments: [],
  body: '',
};

export const useCreateTaskCommentForm = makeFormHook(createTaskCommentFormSchema, createTaskCommentFormDefaultValues);

export const CreateTaskCommentForm = makeFormComponent(createTaskCommentFormSchema, createTaskCommentFormDefaultValues);
