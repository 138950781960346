/* eslint-disable @typescript-eslint/no-explicit-any */
// We need "any" here for variadic argument function types
export const tryCatch =
  <ParamsT extends any[], ReturnT>(cb: (...args: ParamsT) => ReturnT, onError?: (error: Error) => unknown) =>
  (...args: ParamsT) => {
    try {
      return cb(...args);
    } catch (e) {
      onError?.(e as Error);
      return;
    }
  };
