import { P, capitalize, match } from '@frontend/duck-tape';
import type { useAddressForm } from '@frontend/forms';
import { STATES } from '@frontend/forms';
import { useState } from '@frontend/react';
import { showToast } from '../../../../utils/toast';
import { XStack, YStack } from '../../Containers';
import { AutocompleteInput, SelectInput, TextInput } from '../Inputs';

type PlacePrediction = google.maps.places.AutocompletePrediction;

export type AddressInputGroupProps = {
  autocompleteSessionToken: google.maps.places.AutocompleteSessionToken;
  includeAlias?: boolean;
} & { autoFocus?: boolean; form: AddressForm };

type AddressForm = ReturnType<typeof useAddressForm>;

export const AddressInputGroup = ({
  autocompleteSessionToken,
  autoFocus = false,
  form,
  includeAlias,
}: AddressInputGroupProps) => {
  const service = new google.maps.places.AutocompleteService();
  const [placePredictions, setPlacePredictions] = useState<PlacePrediction[]>([]);
  const { getControl, values } = form;

  const getNewPlacePredictions = async (searchValue: string) => {
    if (searchValue && autocompleteSessionToken) {
      service.getPlacePredictions(
        {
          componentRestrictions: { country: 'us' },
          input: searchValue,
          sessionToken: autocompleteSessionToken,
          types: ['address'],
        },
        (data, status) => {
          match(status)
            .with(
              P.union(google.maps.places.PlacesServiceStatus.OK, google.maps.places.PlacesServiceStatus.ZERO_RESULTS),
              () => setPlacePredictions(data ?? []),
            )
            .with(
              P.union(
                google.maps.places.PlacesServiceStatus.INVALID_REQUEST,
                google.maps.places.PlacesServiceStatus.NOT_FOUND,
                google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT,
                google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR,
                google.maps.places.PlacesServiceStatus.REQUEST_DENIED,
              ),
              () => {
                showToast({
                  title: capitalize(status.replace(/_/g, ' ').toLowerCase()),
                  variant: 'danger',
                });
              },
            )
            .exhaustive();
        },
      );
    } else {
      setPlacePredictions([]);
    }
  };

  const onSelectPlacePrediction = async (placeId: string) => {
    if (placeId) {
      const geocoder = new google.maps.Geocoder();
      try {
        const result = await geocoder.geocode({ placeId: placeId });
        if (result.results?.[0]?.['address_components']) {
          const addressDetails = parseAddressComponents(result.results[0]['address_components']);
          getControl('line1').onChange(addressDetails.lineOne);
          getControl('line2').onChange(addressDetails.lineTwo);
          getControl('city').onChange(addressDetails.city);
          getControl('postalCode').onChange(addressDetails.postalCode);
          getControl('countryCode').onChange(addressDetails.country);
          getControl('stateCode').onChange(addressDetails.state);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  const parseAddressComponents = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const initialAddress = {
      city: '',
      country: '',
      lineOne: '',
      lineTwo: '',
      postalCode: '',
      state: '',
    };

    const address = addressComponents.reduce((acc, component) => {
      if (component.types.includes('locality')) {
        return { ...acc, city: component.long_name };
      } else if (component.types.includes('country')) {
        return { ...acc, country: component.short_name };
      } else if (component.types.includes('street_number')) {
        return { ...acc, lineOne: component.long_name };
      } else if (component.types.includes('route')) {
        return { ...acc, lineOne: `${acc.lineOne} ${component.short_name}`.trim() };
      } else if (component.types.includes('postal_code')) {
        return { ...acc, postalCode: component.long_name };
      } else if (component.types.includes('administrative_area_level_1')) {
        return { ...acc, state: component.short_name };
      }
      return acc;
    }, initialAddress);

    return address;
  };

  return (
    <YStack gapY="md">
      <AutocompleteInput
        {...getControl('line1', ['value', 'onChange'])}
        autoComplete="address-line1"
        autoFocus={autoFocus}
        data={placePredictions.map((place) => ({
          label: place.description,
          value: place.place_id,
        }))}
        hideRightArrow
        label="Address line 1"
        name="lineOne"
        onChange={(value) => {
          getControl('line1').onChange(value);
          getNewPlacePredictions(value);
        }}
        onOptionSubmit={(value) => {
          onSelectPlacePrediction(value);
        }}
        placeholder="Street address"
        value={values.line1}
      />
      <TextInput
        {...getControl('line2')}
        autoComplete="shipping address-line2"
        label="Address line 2"
        placeholder="Apartment/unit #"
      />
      <XStack gapX="sm">
        <TextInput {...getControl('city')} autoComplete="shipping address-level2" label="City" placeholder="City" />
        <TextInput {...getControl('postalCode')} autoComplete="shipping postal-code" label="Zip" placeholder="Zip" />
      </XStack>
      <XStack gapX="sm">
        <SelectInput
          autoComplete="shipping country"
          className="flex-1"
          name="country"
          options={[{ emojiName: ':flag-us:', label: 'United States', value: 'US' as string }]}
          {...getControl('countryCode', ['value'])}
          label="Country"
          placeholder="Country"
          value={getControl('countryCode').value}
        />
        <SelectInput
          className="flex-1"
          name="state"
          options={STATES}
          {...getControl('stateCode')}
          autoComplete="shipping address-level1"
          label="State"
          placeholder="State"
        />
      </XStack>
      {includeAlias ? (
        <TextInput {...getControl('alias')} label="Nickname" placeholder="Vacation home" showOptionalText />
      ) : null}
    </YStack>
  );
};
