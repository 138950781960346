import { AppleLogo, DuckbillLogo, OutlookLogo } from '@frontend/web-icons';
import type { Icon, IconProps, icons } from '@tabler/icons-react';
import {
  IconAlarmOff,
  IconAlarmSnooze,
  IconAlertCircle,
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
  IconArrowUp,
  IconBell,
  IconBook,
  IconCalendar,
  IconCalendarEvent,
  IconCaretDown,
  IconCaretLeft,
  IconCaretRight,
  IconCaretUp,
  IconCheck,
  IconCheckbox,
  IconChecklist,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp,
  IconCircleArrowUpRight,
  IconCircleCheck,
  IconCopyCheck,
  IconCornerDownLeft,
  IconCreditCard,
  IconDots,
  IconDownload,
  IconEdit,
  IconExternalLink,
  IconEye,
  IconEyeOff,
  IconFilePlus,
  IconHome,
  IconHomeFilled,
  IconInfoCircle,
  IconLayout2,
  IconLayout2Filled,
  IconLink,
  IconList,
  IconListCheck,
  IconLock,
  IconLogout,
  IconMail,
  IconMenu2,
  IconMessage,
  IconMessageDots,
  IconMinus,
  IconMoodHeart,
  IconNote,
  IconPencil,
  IconPhone,
  IconPlayerPauseFilled,
  IconPlayerPlay,
  IconPlayerSkipForward,
  IconPlus,
  IconRefresh,
  IconRepeat,
  IconScript,
  IconSearch,
  IconSend,
  IconSettings,
  IconSettingsFilled,
  IconShieldCheck,
  IconShieldLock,
  IconSparkles,
  IconStar,
  IconStarFilled,
  IconTrash,
  IconUser,
  IconUserCircle,
  IconUserPlus,
  IconUserStar,
  IconUsers,
  IconX,
} from '@tabler/icons-react';

import type { ForwardRefExoticComponent, RefAttributes } from 'react';

type AllIconName = keyof typeof icons;

type IconComponent = ForwardRefExoticComponent<IconProps & RefAttributes<Icon>>;

// https://tabler.io/icons
export const tablerIconsMap = {
  IconAlarmOff,
  IconAlarmSnooze,
  IconAlertCircle,
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
  IconArrowUp,
  IconBell,
  IconBook,
  IconCalendar,
  IconCalendarEvent,
  IconCaretDown,
  IconCaretLeft,
  IconCaretRight,
  IconCaretUp,
  IconCheck,
  IconCheckbox,
  IconChecklist,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp,
  IconCircleArrowUpRight,
  IconCircleCheck,
  IconCopyCheck,
  IconCornerDownLeft,
  IconCreditCard,
  IconDots,
  IconDownload,
  IconEdit,
  IconExternalLink,
  IconEye,
  IconEyeOff,
  IconFilePlus,
  IconHome,
  IconHomeFilled,
  IconInfoCircle,
  IconLayout2,
  IconLayout2Filled,
  IconLink,
  IconList,
  IconListCheck,
  IconLock,
  IconLogout,
  IconMail,
  IconMenu2,
  IconMessage,
  IconMessageDots,
  IconMinus,
  IconMoodHeart,
  IconNote,
  IconPencil,
  IconPhone,
  IconPlayerPauseFilled,
  IconPlayerPlay,
  IconPlayerSkipForward,
  IconPlus,
  IconRefresh,
  IconRepeat,
  IconScript,
  IconSearch,
  IconSend,
  IconSettings,
  IconSettingsFilled,
  IconShieldCheck,
  IconShieldLock,
  IconSparkles,
  IconStar,
  IconStarFilled,
  IconTrash,
  IconUser,
  IconUserCircle,
  IconUserPlus,
  IconUserStar,
  IconUsers,
  IconX,
} satisfies Partial<Record<AllIconName, IconComponent>>;

export const customIconsMap = {
  AppleLogo,
  DuckbillLogo,
  OutlookLogo,
} as const;

export type CustomIconName = keyof typeof customIconsMap;

export type TablerIconName = keyof typeof tablerIconsMap;

export type IconName = CustomIconName | TablerIconName;
