import {
  escapeRegExp,
  isFinite,
  isNil,
  groupBy as lodashGroupBy,
  isString as lodashIsString,
  keys as lodashKeys,
  map as lodashMap,
  omit as lodashOmit,
  pickBy,
  replace,
  toNumber,
} from 'lodash-es';
import { P, match } from '../match';
import type { StrictOmit } from '../types';

export const keys = <T extends GenericRecord>(obj: T): (keyof T)[] => lodashKeys(obj);

export const isString = <T extends string = string>(s: T | unknown): s is T => lodashIsString(s);

export const replaceAll = (string: string, pattern: string, replacement: string): string =>
  pattern === '' ? string : replace(string, new RegExp(escapeRegExp(pattern), 'g'), replacement);

export const curriedReplaceAll = (search: string, replace: string) => (string: string) =>
  replaceAll(string, search, replace);

export const isNotNil = <T>(val: T) => !isNil(val);

/** Immutable reverse */
// eslint-disable-next-line fp/no-mutating-methods
export const reverse = <T>(array: T[]) => array.slice().reverse();

export const omit = <T extends GenericRecord, K extends keyof T>(obj: MaybeUndefined<T>, keys: K[]) =>
  lodashOmit(obj, keys) as StrictOmit<T, K>;

/**
 * @description Removes keys from object that have the following values: "" | undefined | null
 */
export const removeNullishAndEmptyStringValues = <T extends Record<string, unknown>>(obj: T): T =>
  pickBy(obj, (value) => value !== '' && !isNil(value)) as T;

const isPossibleNumber = (value: string) => isFinite(toNumber(value));

export const safeParseInt = (stringNum: number | string, fallback: number = 0): number =>
  match(stringNum)
    .with(P.number, (val) => val)
    .with('-', () => 0)
    .with(P.when(isPossibleNumber), (val) => parseInt(val))
    .otherwise(() => fallback);

export const extractReadonlyValues = <T extends Readonly<GenericRecord>, K extends keyof T>(
  array: Readonly<T[]>,
  key: K,
): T[K][] => lodashMap(array, key);

export const getRandomElementsFromArray = <T>(arr: T[], n: number): T[] => {
  // eslint-disable-next-line fp/no-let, prefer-const
  let copy = [...arr];
  return Array.from({ length: Math.min(n, arr.length) }).map(() => {
    const randIndex = Math.floor(Math.random() * copy.length);
    // eslint-disable-next-line fp/no-mutating-methods
    return copy.splice(randIndex, 1)[0];
  }) as T[];
};

export {
  camelCase,
  capitalize,
  chunk,
  concat,
  debounce,
  entries,
  filter,
  identity,
  includes,
  isArray,
  isEmpty,
  isEqual,
  isFinite,
  isFunction,
  isNumber,
  isObject,
  kebabCase,
  last,
  merge,
  partition,
  remove,
  snakeCase,
  sortBy,
  startCase,
  toLower,
  toNumber,
  toUpper,
  trim,
  uniqBy,
  upperCase,
  values,
} from 'lodash-es';

export { lodashGroupBy as groupBy, isNil };
