import type { MakeInputProps, SelectOption } from '@frontend/forms';
import type { FlexStyleConvenientProps } from '@frontend/web-react/utils';
import type { RadioGroupProps as MRadioGroupProps } from '@mantine/core';
import { RadioGroup as MRadioGroup } from '@mantine/core';
import { View } from '../../../Containers/View';
import { Radio } from './Radio';

type RadioInputProps<
  TValue extends string,
  TOption extends SelectOption<TValue, never, { label: string }>,
> = MakeInputProps<
  MaybeUndefined<TOption['value']>,
  MRadioGroupProps,
  'children' | 'className' | 'classNames' | 'label' | 'onChange' | 'value'
> & {
  flexDirection?: FlexStyleConvenientProps['flexDirection'];
  options: TOption[];
};

export const RadioInput = <TValue extends string, TOption extends SelectOption<TValue, never, { label: string }>>({
  flexDirection = 'column',
  onChange,
  options,
  value,
}: RadioInputProps<TValue, TOption>) => {
  const onChangeComposed = (value: TOption['value']) => {
    if (value) {
      onChange(value);
    }
  };

  return (
    <MRadioGroup onChange={onChangeComposed as (s: string) => void} value={value}>
      <View className="gap-sm" flexDirection={flexDirection}>
        {options.map((option) => (
          <Radio key={option.value} label={option.label} value={option.value} />
        ))}
      </View>
    </MRadioGroup>
  );
};
