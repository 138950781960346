import { dateFormatters } from '@frontend/duck-tape';
import { Text, YStack } from '@frontend/web-react';

type TaskChatTimeProps = {
  time: DateTimeISOString;
};
export const TaskChatTime = ({ time }: TaskChatTimeProps) => (
  <YStack className="items-center">
    <Text type="captionBold">{dateFormatters.message(time)}</Text>
  </YStack>
);
