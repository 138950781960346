import { MEMBERSHIP_PLANS } from '@frontend/api-client';
import { CoupleEatingDinner, VipCat, WomanWalkingWithPhone } from '@frontend/assets';
import { privacyUrl } from '@frontend/constants';
import { P, match } from '@frontend/duck-tape';
import { Fragment, useEffect, usePromoSlice } from '@frontend/react';
import {
  Anchor,
  Button,
  Icon,
  LoadingSpinnerPanel,
  ScrollableContentPanel,
  SplitImagePageContainer,
  Text,
  XStack,
  YStack,
  showToast,
} from '@frontend/web-react';
import { useOnboardingContext } from '@frontend/web/context/OnboardingContext';
import {
  useCreateSetupIntentMutation,
  useCreateSubscriptionMutation,
  useLazyRetrieveHouseholdQuery,
  useRetrieveClientQuery,
  useSetupIntentConfirmedMutation,
  useStripeCard,
} from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';
import { CardNumberElement } from '@stripe/react-stripe-js';
import { type SetupIntent } from '@stripe/stripe-js';
import type { FormEvent } from 'react';
import { useState } from 'react';
import { CardCvc, CardExpiry, CardNumber, withStripe } from '../Payment';
import { MembershipPlanCard } from './MembershipPlanCard';
import { OnboardingStepHeader } from './OnboardingStepHeader';

export const AddPaymentMethod = withStripe(() => {
  const { decrementStep, incrementStep, onboardingState } = useOnboardingContext();
  const {
    clearPromo,
    state: { promoCode, promoDetails },
  } = usePromoSlice();
  const { data: client, isLoading: isClientLoading } = useRetrieveClientQuery();
  const [retrieveHousehold, { data: householdData, isLoading: isHouseholdLoading }] = useLazyRetrieveHouseholdQuery();
  const [
    createSetupIntent,
    { data: stripeSetupIntent, error: createSetupIntentError, isLoading: isCreateSetupIntentLoading },
  ] = useCreateSetupIntentMutation();
  const [createSubscription, { isLoading: isCreateSubscriptionLoading }] = useCreateSubscriptionMutation();
  const [createPaymentMethod, { isLoading: isCreatePaymentMethodLoading }] = useSetupIntentConfirmedMutation();
  const [isStripeIntentLoading, setIsStripeIntentLoading] = useState(false);
  const [savedSetupIntent, setSavedSetupIntent] = useState<SetupIntent>();
  const {
    cardCvcInfo,
    cardExpiryInfo,
    cardNumberInfo,
    elements,
    setCardCvcInfo,
    setCardExpiryInfo,
    setCardNumberInfo,
    stripe,
  } = useStripeCard();

  const isAnyLoading =
    isCreateSetupIntentLoading || isStripeIntentLoading || isCreateSubscriptionLoading || isCreatePaymentMethodLoading;

  const isPageLoading = isClientLoading || isCreateSetupIntentLoading || isHouseholdLoading;
  // const isFormValid = cardsData?.length ? true : (!cardNumberInfo?.complete || !cardExpiryInfo?.complete || !cardCvcInfo?.complete);
  const isFormValid = !cardNumberInfo?.complete || !cardExpiryInfo?.complete || !cardCvcInfo?.complete;

  const image = match(onboardingState.selectMembership.selectedPlan)
    .with(P.nullish, () => '')
    .with('individual', () => WomanWalkingWithPhone)
    .with('household', () => CoupleEatingDinner)
    .with('vip', () => VipCat)
    // This should never happen
    .with('champagneHousehold', () => '')
    .with('champagneIndividual', () => '')
    .exhaustive();

  useEffect(() => {
    if (!stripeSetupIntent) {
      (async () =>
        handleMutation({
          mutation: () => createSetupIntent().unwrap(),
          showSuccessToast: false,
        }))();
    }
  }, [stripeSetupIntent]);

  useEffect(() => {
    if (onboardingState.isHouseholdMember && client?.household) {
      retrieveHousehold(client?.household);
    }
  }, [client?.household, onboardingState.isHouseholdMember]);

  const onCreateSubscription = (setupIntent: SetupIntent) => {
    return handleMutation({
      errorMessage: 'Error ocurred when creating subscription. Please try again.',
      mutation: () =>
        createSubscription({
          isDefault: true,
          planCode: MEMBERSHIP_PLANS[onboardingState.selectMembership.selectedPlan!].planCode,
          promotionCode: promoCode,
          promotionCodeId: promoDetails?.id,
          setupIntentId: setupIntent.id,
        }).unwrap(),
      onSuccess: () => {
        clearPromo();
        incrementStep();
      },
      showSuccessToast: false,
    });
  };

  // For household members, we don't subscribe them
  const onCreatePaymentMethod = (setupIntent: SetupIntent) => {
    return handleMutation({
      errorMessage: 'Error ocurred when creating subscription. Please try again.',
      mutation: () =>
        createPaymentMethod({
          isDefault: true,
          promotionCode: promoCode,
          promotionCodeId: promoDetails?.id,
          setupIntentId: setupIntent.id,
        }).unwrap(),
      onSuccess: () => {
        clearPromo();
        incrementStep();
      },
      showSuccessToast: false,
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    // This is for when the add payment method mutation succeeded but the subscription mutation failed and the user retried
    if (savedSetupIntent) {
      return onCreateSubscription(savedSetupIntent);
    }
    e.preventDefault();
    const cardElement = elements && elements.getElement(CardNumberElement);
    if (stripe && cardElement && client && stripeSetupIntent) {
      setIsStripeIntentLoading(true);
      const { error, setupIntent } = await stripe.confirmCardSetup(stripeSetupIntent.stripeClientSecret, {
        payment_method: {
          billing_details: {
            email: client.email,
            name: `${client.firstName} ${client.lastName}`,
            phone: client.phoneNumber,
          },
          card: cardElement,
        },
      });
      setSavedSetupIntent(setupIntent);
      setIsStripeIntentLoading(false);
      if (error) {
        showToast({
          title: error.message || 'Encountered an error while creating payment method',
          variant: 'danger',
        });
      } else if (setupIntent) {
        if (onboardingState.isHouseholdMember) {
          return onCreatePaymentMethod(setupIntent);
        }
        return onCreateSubscription(setupIntent);
      }
    }
    return;
  };

  const canGoBack = onboardingState.onboardingSteps.findIndex((s) => s === onboardingState.currentOnboardingStep) > 0;

  return (
    <form onSubmit={handleSubmit}>
      <SplitImagePageContainer imageSource={image}>
        <ScrollableContentPanel
          footer={
            isPageLoading || !stripeSetupIntent ? null : (
              <YStack gapY="sm">
                <Button className="mt-lg" disabled={isFormValid} isForm isLoading={isAnyLoading} label="Continue" />
              </YStack>
            )
          }
          header={
            <YStack>
              <OnboardingStepHeader
                onClickBackArrow={canGoBack ? decrementStep : undefined}
                title="Add a payment method"
              />
              <Text>
                {onboardingState.isHouseholdMember
                  ? 'This allows us to make purchases on your behalf.'
                  : 'This allows us to make purchases on your behalf, and for your monthly subscription cost.'}
              </Text>
            </YStack>
          }
          justifyContent="start"
        >
          {isPageLoading ? (
            <YStack>
              {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
              <LoadingSpinnerPanel className="h-[300px]" />
            </YStack>
          ) : (
            <YStack gapY="md">
              {createSetupIntentError ? (
                <Text type="h6">{"We're currently experiencing technical difficulties. Please try again later."}</Text>
              ) : (
                <Fragment>
                  {onboardingState.selectMembership.selectedPlan ? (
                    <MembershipPlanCard
                      hideBenefitDetails
                      household={householdData}
                      isSelected={false}
                      plan={onboardingState.selectMembership.selectedPlan}
                      promoDetails={promoDetails}
                      showOwner
                    />
                  ) : null}

                  <YStack gapY="md">
                    <CardNumber onChange={setCardNumberInfo} />
                    <XStack className="gap-md">
                      <CardExpiry onChange={setCardExpiryInfo} />
                      <CardCvc onChange={setCardCvcInfo} />
                    </XStack>
                  </YStack>
                  <YStack alignItems="center" gapY="xs">
                    <XStack>
                      <Icon color="tertiary" name="IconShieldCheck" />
                    </XStack>
                    <Text color="tertiary" type="h6">
                      Duckbill takes security seriously. All transactions are processed by Stripe, so our team will
                      never see your credit card information. Please visit our{' '}
                      <Anchor href={privacyUrl} type="h6">
                        privacy policy
                      </Anchor>{' '}
                      for more details on how we handle sensitive information.
                    </Text>
                  </YStack>
                </Fragment>
              )}
            </YStack>
          )}
        </ScrollableContentPanel>
      </SplitImagePageContainer>
    </form>
  );
});
