import type { MakeInputProps } from '@frontend/forms';
import type { CheckboxProps } from './Checkbox';
import { Checkbox } from './Checkbox';

type CheckboxInputProps = MakeInputProps<
  boolean,
  CheckboxProps,
  'children' | 'className' | 'label' | 'onChange' | 'value'
>;

export const CheckboxInput = ({ onChange, value, ...props }: CheckboxInputProps) => {
  return <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} {...props} />;
};
