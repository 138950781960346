import { validators } from '../utils';
import { makeFormComponent, makeFormHook } from '../utils/factory';
import type { InferFormType } from '../utils/types';

const editProfileFormSchema = validators.object({
  birthday: validators.dateISOString().optional(),
  email: validators.email(),
  firstName: validators.requiredString('First name'),
  lastName: validators.requiredString('Last name'),
  nickname: validators.optionalString(),
  phoneNumber: validators.e164PhoneNumber(),
});

export type EditProfileFormSchema = InferFormType<typeof editProfileFormSchema>;

const editProfileFormDefaultValues: EditProfileFormSchema = {
  // @ts-expect-error Tedious types
  birthday: '',
  email: '',
  firstName: '',
  lastName: '',
  nickname: '',
  phoneNumber: '',
};

export const useEditProfileForm = makeFormHook(editProfileFormSchema, editProfileFormDefaultValues);

export const EditProfileForm = makeFormComponent(editProfileFormSchema, editProfileFormDefaultValues);
