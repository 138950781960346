import * as LPN from 'libphonenumber-js';
import { tryCatch } from '../error/error';

export const stringToE164 = tryCatch((input: string) => {
  const phoneNumber = LPN.parsePhoneNumber(input, 'US');

  if (!phoneNumber.isValid()) {
    return undefined;
  }

  return phoneNumber.format('E.164');
});

export const stringToInternational = tryCatch((input: string) => {
  const phoneNumber = LPN.parsePhoneNumber(input, 'US');

  if (!phoneNumber.isValid()) {
    return undefined;
  }

  return phoneNumber.formatInternational();
});

// Example input: +16505802564, example output: +1 (650)-580-2564
export const e164ToInternational = tryCatch((input: string) => {
  const phoneNumber = LPN.parsePhoneNumber(input, 'US');

  if (!phoneNumber.isValid()) {
    return undefined;
  }

  // Only replace the spaces with dashes if it isn't the first space
  const defaultIntFormat = phoneNumber.formatInternational();
  const internationalFormat =
    defaultIntFormat.slice(0, 6) +
    defaultIntFormat.slice(6, 9).replace(' ', '-') +
    defaultIntFormat.slice(9, 12).replace(' ', '-') +
    defaultIntFormat.slice(12, 16);

  return internationalFormat;
});
