export const AppScreenshot =
  'https://ik.imagekit.io/duckbill/prod/onboarding_assets/app-screenshot.png?updatedAt=1719346845557';
export const ManDiscoBall =
  'https://ik.imagekit.io/duckbill/prod/onboarding_assets/man-disco-ball.jpeg?updatedAt=1717795048538';
export const ElderlyLadyWithPhone =
  'https://ik.imagekit.io/duckbill/prod/onboarding_assets/elderly-lady-with-phone.jpeg?updatedAt=1717795047913';
export const VipCat = 'https://ik.imagekit.io/duckbill/prod/onboarding_assets/vip-cat.jpeg?updatedAt=1717795047725';
export const CoupleEatingDinner =
  'https://ik.imagekit.io/duckbill/prod/onboarding_assets/couple-eating-dinner.jpeg?updatedAt=1717795047717';
export const BrowsingBooks =
  'https://ik.imagekit.io/duckbill/prod/onboarding_assets/browsing-books.jpeg?updatedAt=1717803384544';
export const PhoneCalendar =
  'https://ik.imagekit.io/duckbill/prod/onboarding_assets/phone-calendar.jpeg?updatedAt=1717795047587';
export const HelloStranger =
  'https://ik.imagekit.io/duckbill/prod/onboarding_assets/hello-stranger.jpeg?updatedAt=1717795047797';
export const WomanWalkingWithPhone =
  'https://ik.imagekit.io/duckbill/prod/onboarding_assets/woman-walking-with-phone.jpeg?updatedAt=1717795046796';
export const Mailboxes = 'https://ik.imagekit.io/duckbill/prod/onboarding_assets/mailboxes.jpeg';
