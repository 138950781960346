import type { LocalFile } from '@frontend/api-types';
import { filter } from '@frontend/duck-tape';
import { FileInput, FilePreviews, Icon, Text, XStack, YStack, useHotkeys } from '@frontend/web-react';
import type { IconName } from '@frontend/web-react/components/Primitives/Icon/helpers';
import { applyClass, concatClasses, safeCss } from '@frontend/web-utils';
import { useAnalytics } from '@frontend/web/hooks';
import type { TextareaProps } from '@mantine/core';
import { Textarea } from '@mantine/core';

export type TaskChatFooterProps = {
  autoFocus?: boolean;
  filesValue?: LocalFile[];
  inputDisabled?: boolean;
  isCompleteLoading?: boolean;
  isSnoozeLoading?: boolean;
  isSubmitMessageLoading?: boolean;
  isUnsnoozeLoading?: boolean;
  muted?: boolean;
  onChangeFiles?: (files: LocalFile[]) => void;
  onChangeText: (text: string) => void;
  onClickCompleteTask?: () => Promise<void> | void;
  onClickSnoozeTask?: () => Promise<void> | void;
  onClickSubmitMessage: () => Promise<void> | void;
  onClickUnsnoozeTask?: () => Promise<void> | void;
  placeholder?: string;
  submitDisabled?: boolean;
  submitIconName?: IconName;
  taskId?: string;
  textValue: string;
};

export const TaskChatFooter = ({
  autoFocus,
  filesValue,
  inputDisabled,
  isCompleteLoading,
  isSnoozeLoading,
  isSubmitMessageLoading,
  isUnsnoozeLoading,
  muted,
  onChangeFiles,
  onChangeText,
  onClickCompleteTask,
  onClickSnoozeTask,
  onClickSubmitMessage,
  onClickUnsnoozeTask,
  placeholder = 'Enter message',
  submitDisabled,
  submitIconName = 'IconArrowRight',
  taskId,
  textValue,
}: TaskChatFooterProps) => {
  const { track } = useAnalytics();
  const textareaInputClassNames: TextareaProps['classNames'] = {
    input: safeCss(
      'p-md text-p-md rounded-t-md outline-none text-textPrimary placeholder:text-textTertiary border-none',
    ),
    root: safeCss('gap-y-xs flex flex-col flex-1 w-full'),
  };

  const isFileMaxReached = filesValue && filesValue.length >= 5;

  useHotkeys([['mod+Enter', onClickSubmitMessage]], []);

  return (
    <YStack className={concatClasses('px-lg pb-lg gap-y-sm', applyClass(muted, 'opacity-60'))}>
      <YStack className="border-1 border-borderPrimary rounded-md gap-y-md">
        <YStack className="shadow-one rounded-md">
          <Textarea
            autoFocus={autoFocus}
            autosize
            classNames={textareaInputClassNames}
            disabled={inputDisabled}
            maxRows={10}
            minRows={1}
            name="message"
            onChange={(event) => onChangeText(event.currentTarget.value)}
            placeholder={placeholder}
            value={textValue}
          />
          {filesValue?.length ? (
            <YStack className="bg-surfacePrimary pt-md px-md" gapY="sm">
              <FilePreviews
                disabled={inputDisabled}
                files={filesValue}
                onDelete={(deleteIndex) => onChangeFiles?.(filter(filesValue, (_, index) => deleteIndex !== index))}
                type="tags"
              />
              {isFileMaxReached ? (
                <Text color="danger" type="captionBold">
                  {"You've"} reached the maximum file limit.
                </Text>
              ) : null}
            </YStack>
          ) : null}
          <XStack className="items-center justify-between self-start w-full bg-neutral-100 p-sm rounded-b-md">
            <XStack className="space-x-sm items-center">
              {onClickSnoozeTask ? (
                <Icon
                  color="accent"
                  enableBackgroundColor
                  isLoading={isSnoozeLoading}
                  name="IconAlarmSnooze"
                  onClick={() => {
                    // Task id will always be present though
                    if (taskId) {
                      track({ data: { taskId }, event: 'snoozeTaskButtonSelected', topic: 'tasks' });
                    }
                    onClickSnoozeTask();
                  }}
                  rounded
                />
              ) : null}
              {onClickUnsnoozeTask ? (
                <Icon
                  color="accent"
                  enableBackgroundColor
                  isLoading={isUnsnoozeLoading}
                  name="IconAlarmOff"
                  onClick={() => {
                    // Task id will always be present though
                    if (taskId) {
                      track({ data: { taskId }, event: 'unsnoozeTaskButtonSelected', topic: 'tasks' });
                    }
                    onClickUnsnoozeTask();
                  }}
                  rounded
                />
              ) : null}
              {onClickCompleteTask ? (
                <Icon
                  color="accent"
                  enableBackgroundColor
                  isLoading={isCompleteLoading}
                  name="IconCheck"
                  onClick={() => {
                    // Task id will always be present though
                    if (taskId) {
                      track({ data: { taskId }, event: 'completeTaskButtonSelected', topic: 'tasks' });
                    }
                    onClickCompleteTask();
                  }}
                  rounded
                />
              ) : null}
              {filesValue && onChangeFiles ? (
                <FileInput
                  allowedFileTypes={['image', 'pdf']}
                  disabled={inputDisabled || isFileMaxReached}
                  error={undefined}
                  name="files"
                  noLabel
                  onChange={(files) => onChangeFiles?.(files as LocalFile[])}
                  value={filesValue}
                />
              ) : null}
            </XStack>
            <Icon
              color="primary"
              disabled={submitDisabled || !textValue}
              enableBackgroundColor
              isLoading={isSubmitMessageLoading}
              name={submitIconName}
              onClick={onClickSubmitMessage}
              rounded
            />
          </XStack>
        </YStack>
      </YStack>
    </YStack>
  );
};
