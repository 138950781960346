import { canReviewOptionSheet } from '@frontend/api-client';
import type { OptionSheetOption } from '@frontend/api-types';
import { pluralize } from '@frontend/duck-tape';
import { useEffect, useListState, useMemo, useState, useVisibilityController } from '@frontend/react';
import {
  Button,
  Icon,
  LoadingSpinnerPanel,
  PageContainer,
  ScrollView,
  Text,
  XStack,
  YStack,
} from '@frontend/web-react';
import { NavLinkWrapper } from '@frontend/web/components';
import { OptionSheetCard, OptionSheetReviewDrawer } from '@frontend/web/components/DomainSpecific/Options';
import { OptionSheetSelectionModal } from '@frontend/web/components/DomainSpecific/Options/OptionSheetSelectionModal';
import { OptionSheetError } from '@frontend/web/errorPages';
import { useAnalytics, useLazyRetrieveTaskQuery, useRetrieveOptionSheetQuery } from '@frontend/web/hooks';

import { createFileRoute } from '@tanstack/react-router';

const OptionSheet = () => {
  const { track } = useAnalytics();
  const { optionSheetId } = Route.useParams();
  const [isDrawerOpen, { close: closeReviewResponseDrawer, open: openReviewResponseDrawer }] =
    useVisibilityController();
  const [
    isOptionSheetSelectionModalOpen,
    { close: closeOptionSheetSelectionModal, open: openOptionSheetSelectionModal },
  ] = useVisibilityController();
  const { data: optionSheetData, error, isLoading } = useRetrieveOptionSheetQuery(optionSheetId);
  // Optimistically fetching the task data
  const [retrieveTask, { data: taskData }] = useLazyRetrieveTaskQuery();
  const [selectedOptions, { setState: setSelectedOptions, toggle: toggleSelectedOption }] =
    useListState<OptionSheetOption>();
  const [currentModalOption, setCurrentModalOption] = useState<OptionSheetOption>();

  // The assumption is that the first group is the only group we need to care about
  const optionSheet = optionSheetData?.groups[0];

  const options = useMemo(() => {
    if (optionSheet?.options.length) {
      return optionSheet.options;
    }
    return [];
  }, [optionSheet?.options]);

  useEffect(() => {
    if (optionSheetData) {
      track({ data: { sheetId: optionSheetData.id }, event: 'viewedSheet', topic: 'options' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionSheetData?.id]);

  useEffect(() => {
    if (error) {
      throw Error('Error fetching option sheet');
    }
  }, [error]);

  const onClickOption = toggleSelectedOption;

  const onClickViewOption = (option: OptionSheetOption) => {
    setCurrentModalOption(option);
    openOptionSheetSelectionModal();
  };

  useEffect(() => {
    if (optionSheetData?.task) {
      const selectionOptions = optionSheetData.selectedOptionIds.map(
        (id) => options.find((option) => option.id === id)!,
      );
      setSelectedOptions(selectionOptions ?? []);
    }
  }, [optionSheetData?.selectedOptionIds]);

  useEffect(() => {
    if (optionSheetData?.task) {
      retrieveTask(optionSheetData.task);
    }
  }, [optionSheetData?.task]);

  const onSelect = (option: OptionSheetOption) => {
    setSelectedOptions((prev) => {
      if (!prev.find(({ id }) => id === option.id)) {
        return [...prev, option];
      }
      return prev;
    });
    track({ data: { optionId: option.id, sheetId: optionSheetId }, event: 'selectedOption', topic: 'options' });
  };

  const onDeselect = (option: OptionSheetOption) => {
    setSelectedOptions((prev) => prev.filter(({ id }) => id !== option.id));
    track({ data: { optionId: option.id, sheetId: optionSheetId }, event: 'deselectedOption', topic: 'options' });
  };

  const onReviewResponse = () => {
    openReviewResponseDrawer();
    track({ data: { sheetId: optionSheetId }, event: 'reviewSelectedOptions', topic: 'options' });
  };

  return (
    <PageContainer
      LoadingComponent={<LoadingSpinnerPanel fullScreen />}
      isLoading={isLoading}
      isScrollable={false}
      padding="none"
    >
      <XStack className="px-lg md:px-xxxl py-lg gap-x-xl items-center">
        <NavLinkWrapper className="items-center justify-center p-sm bg-surfaceAccent rounded-sm" to="/app">
          <Icon name="DuckbillLogo" size="md" />
        </NavLinkWrapper>
        <Text className="flex-1" type="h4">
          {optionSheetData?.title}
        </Text>
        <XStack alignItems="center" gapX="xl">
          {selectedOptions.length ? (
            <Text color="tertiary" type="captionBold">
              {selectedOptions.length} {pluralize('option', selectedOptions.length)} selected
            </Text>
          ) : null}
          {taskData?.status && canReviewOptionSheet(taskData.status) ? (
            <Button label="Review Response" onClick={onReviewResponse} size="sm" />
          ) : null}
        </XStack>
      </XStack>
      <ScrollView>
        <YStack className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-10 xl:grid-cols-5 p-md">
          <YStack className="gap-lg grid-cols-subgrid grid pb-lg col-span-1 md:col-span-2 lg:col-span-8 lg:col-start-3 xl:col-span-3 xl:col-start-2">
            {options.map((option) => {
              const isSelected = !!selectedOptions.find(({ id }) => id === option.id);
              return (
                <XStack className={'col-span-1 lg:col-span-3 xl:col-span-1'} key={option.id}>
                  <OptionSheetCard
                    currency={optionSheetData?.currency}
                    disabled={Boolean(taskData?.status && !canReviewOptionSheet(taskData?.status))}
                    isSelected={isSelected}
                    onClick={onClickOption}
                    onClickView={onClickViewOption}
                    option={option}
                  />
                </XStack>
              );
            })}
          </YStack>
        </YStack>
      </ScrollView>
      {optionSheetData && taskData?.status ? (
        <OptionSheetReviewDrawer
          isOpen={isDrawerOpen}
          onClose={closeReviewResponseDrawer}
          optionSheet={optionSheetData}
          selectedOptions={selectedOptions}
          taskStatus={taskData?.status}
        />
      ) : null}
      {currentModalOption && taskData?.status ? (
        <OptionSheetSelectionModal
          allOptions={options}
          currency={optionSheetData?.currency}
          currentOption={currentModalOption}
          isOpen={isOptionSheetSelectionModalOpen}
          onClickBack={closeOptionSheetSelectionModal}
          onClickReviewResponse={() => {
            closeOptionSheetSelectionModal();
            onReviewResponse();
          }}
          onClose={closeOptionSheetSelectionModal}
          onDeselect={onDeselect}
          onSelect={onSelect}
          optionSheetId={optionSheetId}
          selectedOptions={selectedOptions}
          setCurrentOption={setCurrentModalOption}
          taskStatus={taskData?.status}
        />
      ) : null}
    </PageContainer>
  );
};

export const Route = createFileRoute('/app/option-sheet/$optionSheetId/')({
  component: OptionSheet,
  errorComponent: OptionSheetError,
});
