import { convertObjectKeysCase } from '@frontend/duck-tape';
import type { Socket } from 'socket.io-client';
import { io } from 'socket.io-client';
import type { ChannelConfig, ConnectionOptionsConfig, SocketType, TypedSocket } from './types';

// eslint-disable-next-line fp/no-let
let globalSocket: MaybeNull<Socket>;

const disconnectSocket =
  ({ env }: SocketClientConfig) =>
  () => {
    if (globalSocket) {
      if (env !== 'production') {
        // eslint-disable-next-line no-console
        console.info('Websocket disconnected');
      }
      globalSocket.disconnect();
      // eslint-disable-next-line fp/no-mutation
      globalSocket = null;
    } else {
      if (env !== 'production') {
        // eslint-disable-next-line no-console
        console.warn('No websocket to disconnect');
      }
    }
  };

const initializeSocket =
  ({ apiUrl, captureException, captureMessage, env }: SocketClientConfig) =>
  <T extends SocketType>(
    config: (ChannelConfig[T] extends {
      params: unknown;
    }
      ? ChannelConfig[T] extends ConnectionOptionsConfig
        ? {
            channelType: T;
            connectionOptions: ChannelConfig[T]['connectionOptions'];
            params: ChannelConfig[T]['params'];
          }
        : { channelType: T; params: ChannelConfig[T]['params'] }
      : { channelType: T }) &
      // In the future, we may want to pass in a token in the SocketClientConfig, which would make it dependent on a hook before being used
      (ChannelConfig[T] extends { authenticated: true } ? { token: string } : EmptyObject),
  ): TypedSocket<T> => {
    if (!globalSocket) {
      if (env !== 'production') {
        // eslint-disable-next-line no-console
        console.info('Websocket connecting...');
      }
      // eslint-disable-next-line fp/no-mutation
      globalSocket = io(apiUrl, {
        auth: 'token' in config ? { token: config.token } : undefined,
        // @ts-expect-error Types are complicated here
        query: 'params' in config ? convertObjectKeysCase(config.params, 'snakeCase') : undefined,
        transports: ['websocket'],
        withCredentials: true,
        ...('connectionOptions' in config ? config.connectionOptions : {}),
      });

      globalSocket.on('connect', () => {
        if (env !== 'production') {
          // eslint-disable-next-line no-console
          console.info('Websocket connected');
        }
      });

      // Read more here: https://socket.io/docs/v4/client-socket-instance/#connect_error
      globalSocket.on('connect_error', (error) => {
        // If the socket is still active, the socket will automatically try to reconnect
        if (globalSocket?.active) {
          const warningMessage = 'Temporary connection failure, socket will attempt to reconnect...';
          // eslint-disable-next-line no-console
          console.info(warningMessage);
          captureMessage(warningMessage);
        } else {
          const errorMessage = 'Websocket connection error, socket will not attempt to reconnect';
          // eslint-disable-next-line no-console
          console.warn(errorMessage);
          captureException({
            channelType: config.channelType,
            connectionOptions: 'connectionOptions' in config ? config.connectionOptions : {},
            message: errorMessage,
            params: 'params' in config ? config.params : {},
            rawErrorDescription: 'description' in error ? error.description : {},
          });
        }
      });
    } else {
      if (env !== 'production') {
        // eslint-disable-next-line no-console
        console.warn("Websocket already exists. We don't override sockets in this case, just return the existing one.");
      }
    }
    return globalSocket;
  };

const getSocket = <Type extends SocketType>(_type: Type) => globalSocket as MaybeNull<TypedSocket<Type>>;

type SocketClientConfig = {
  apiUrl: string;
  captureException: (error: Record<string, unknown>) => void;
  captureMessage: (message: string) => void;
  env: EnvType;
};

const getSocketClient = (config: SocketClientConfig) => {
  return {
    disconnectSocket: disconnectSocket(config),
    getSocket,
    initializeSocket: initializeSocket(config),
  };
};

export { getSocketClient };
