import type { OptionSheetOption } from '@frontend/api-types';
import type { Currency } from '@frontend/duck-tape';
import { formatCurrencyWithCommasAndDecimal, identity, isNil } from '@frontend/duck-tape';
import type { UseListStateHandlers } from '@frontend/react';
import { Button, Card, Checkbox, Image, RenderHTML, Text, XStack, YStack } from '@frontend/web-react';
import { applyClass, concatClasses } from '@frontend/web-utils';

export type OptionSheetCardProps = {
  currency?: Currency;
  disabled?: boolean;
  isSelected: boolean;
  onClick: UseListStateHandlers<OptionSheetOption>['toggle'];
  onClickView: (option: OptionSheetOption) => void;
  option: OptionSheetOption;
};
export const OptionSheetCard = ({
  currency,
  disabled = false,
  isSelected,
  onClick,
  onClickView,
  option,
}: OptionSheetCardProps) => {
  return (
    <Card
      // eslint-disable-next-line tailwindcss/no-arbitrary-value
      className={concatClasses('flex-1 border-1 bg-white', applyClass(isSelected, 'border-borderSecondary'))}
      onClick={() => onClick(option, ({ id }) => id)}
      withBorder
    >
      <YStack gapY="md">
        <Image aspectRatio="16/9" className="rounded-md size-full" fit="contain" source={option.imageUrls?.[0]} />
        <XStack gapX="sm">
          {disabled ? null : (
            <Checkbox
              checked={isSelected}
              // Resolves warning log
              onChange={identity}
            />
          )}
          <YStack className="flex-1 w-full gap-y-xs">
            <XStack gapX="sm" justifyContent="spaceBetween">
              <Text className="leading-snug" lineClamp={3} type="h5">
                {option.title}
              </Text>
              <XStack>
                <Button color="secondary" label="View" onClick={() => onClickView(option)} size="sm" />
              </XStack>
            </XStack>
            <RenderHTML className="line-clamp-2" html={option.description} />
            {!isNil(option.price) ? (
              <Text type="h6">{formatCurrencyWithCommasAndDecimal(option.price, currency)}</Text>
            ) : null}
          </YStack>
        </XStack>
      </YStack>
    </Card>
  );
};
