import type { ClassName } from '@frontend/web-utils';
import { concatClasses, safeCss } from '@frontend/web-utils';
import type { ClickableProps } from '../Containers';
import { Clickable } from '../Containers';
import type { IconProps } from '../Icon/Icon';
import { Icon } from '../Icon/Icon';
import type { IconName } from '../Icon/helpers';
import { Text } from '../Texts/Text';

export type TagProps = {
  containerRadius?: ClassName;
  isActive?: boolean;
  label: string;
  leftIconName?: IconName;
  leftIconSize?: IconProps['size'];
  onClick?: ClickableProps['onClick'];
  onClickLeftIcon?: IconProps['onClick'];
  onClickRightIcon?: IconProps['onClick'];
  rightIconName?: IconName;
  rightIconSize?: IconProps['size'];
};

/**
 * Currently this Tag component assumes you'll only ever use the right icon for delete purposes (but I named it onClickRightIcon)
 * to imply future intensions of using generalizing the icon.
 */
export const Tag = ({
  containerRadius = safeCss('rounded-xxl'),
  isActive = false,
  label,
  leftIconName,
  leftIconSize,
  onClick,
  onClickLeftIcon,
  onClickRightIcon,
  rightIconName,
  rightIconSize,
}: TagProps) => {
  const baseClassName = concatClasses(
    'border-1 flex items-center justify-center py-xs px-sm gap-x-xs max-w-[300px]',
    safeCss(isActive ? 'border-borderSecondary' : 'border-borderTertiary'),
    safeCss(onClick ? 'cursor-pointer hover-opacity-effect' : ''),
    safeCss(isActive ? 'bg-surfaceAccent' : 'bg-white'),
    containerRadius,
  );
  return (
    <Clickable className={baseClassName} flexDirection="row" onClick={onClick}>
      {leftIconName ? <Icon name={leftIconName} onClick={onClickLeftIcon} size={leftIconSize || 'xs'} /> : null}
      <Text className="truncate" color={isActive ? 'primary' : 'tertiary'} isSelectable={false} span type="captionBold">
        {label}
      </Text>
      {rightIconName ? (
        <Icon
          color={isActive ? 'primary' : 'tertiary'}
          name={rightIconName}
          onClick={onClickRightIcon}
          size={rightIconSize || 'xs'}
        />
      ) : null}
    </Clickable>
  );
};
