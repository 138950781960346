import type { ClassName } from '@frontend/web-utils';
import type { EmojiShortcode } from './emojiData';
import { emojis } from './emojiData';

export type EmojiProps = {
  className?: ClassName;
  emoji: EmojiShortcode;
};
export const Emoji = ({ className, emoji }: EmojiProps) => {
  const emojiObject = emojis.find((e) => e.shortcode === emoji);
  return (
    <span aria-label={emojiObject?.name} className={className} role="img">
      {emojiObject?.emoji}
    </span>
  );
};
