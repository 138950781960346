import { getInitialComment, getInitials, recurrenceBufferString, recurrenceIntervalString } from '@frontend/api-client';
import { identity } from '@frontend/duck-tape';
import { useVisibilityController } from '@frontend/react';
import {
  Divider,
  DropdownMenu,
  Header,
  Icon,
  LoadingSpinnerPanel,
  PageContainer,
  Tag,
  Text,
  XStack,
  YStack,
} from '@frontend/web-react';
import { safeCss } from '@frontend/web-utils';
import { DeleteRecurrenceModal, RecurrenceDueDateTag, TaskChatMessageBubble } from '@frontend/web/components';
import { useAnalytics, useRetrieveClientQuery, useRetrieveRecurrenceQuery } from '@frontend/web/hooks';

import { TaskDetailModal } from '@frontend/web/components/DomainSpecific/Tasks/TaskDetailModal';
import { createFileRoute, useRouter } from '@tanstack/react-router';

const Recurrence = () => {
  const { recurrenceId } = Route.useParams();
  const router = useRouter();
  const { track } = useAnalytics();
  const { data: client } = useRetrieveClientQuery();
  const { data: recurrence, isLoading: isRecurrenceLoading } = useRetrieveRecurrenceQuery(recurrenceId);
  const [isDeleteRecurrenceModalOpen, { close: closeDeleteRecurrenceModal, open: openDeleteRecurrenceModal }] =
    useVisibilityController();
  const [isTaskDetailModalOpen, { close: closeTaskDetailModal, open: openTaskDetailModal }] = useVisibilityController();

  const isRecurrenceOwner = client?.household ? recurrence?.ownerId === client?.id : true;

  const onClickDeleteRecurrence = () => {
    openDeleteRecurrenceModal();
    track({ data: { recurrenceId }, event: 'deleteRecurrenceButtonSelected', topic: 'recurrences' });
  };

  const initialComment = recurrence
    ? getInitialComment(recurrence, client, {
        labelClassName: safeCss('text-caption text-textPrimary'),
        textClassName: safeCss('text-p-md text-textPrimary'),
      })
    : undefined;

  const isDetailTagVisible =
    client?.household ||
    recurrence?.sheets?.length ||
    recurrence?.attachments?.length ||
    recurrence?.secureNotes?.length;

  return (
    <PageContainer isScrollable={false} padding="none">
      <Header
        isLoading={isRecurrenceLoading}
        onClickBack={router.history.back}
        rightContent={isDetailTagVisible ? <Tag label="Details" onClick={() => openTaskDetailModal()} /> : undefined}
        title={recurrence?.name ?? 'Recurrence'}
        titleIconName="IconRepeat"
      >
        <XStack alignItems="center" className="flex-1" gapX="sm" justifyContent="spaceBetween">
          <XStack alignItems="center" gapX="md">
            {recurrence ? (
              <Text color="tertiary" type="p-sm">
                {recurrenceIntervalString(recurrence)}, tasks start {recurrenceBufferString(recurrence)}
              </Text>
            ) : null}
            {recurrence?.nextTaskDueDate ? <RecurrenceDueDateTag nextTaskDueDate={recurrence.nextTaskDueDate} /> : null}
          </XStack>
          <DropdownMenu
            disabled={!isRecurrenceOwner}
            layout={[
              {
                color: 'danger',
                icon: 'IconTrash',
                label: 'Delete this repeating task',
                onClick: onClickDeleteRecurrence,
                type: 'item',
              },
            ]}
          >
            <Icon color={!isRecurrenceOwner ? 'tertiary' : 'primary'} name="IconDots" onClick={identity} />
          </DropdownMenu>
        </XStack>
      </Header>
      {isRecurrenceLoading ? (
        <LoadingSpinnerPanel fullScreen />
      ) : (
        <YStack className="flex-1 justify-end px-lg pb-xxxl gap-y-xl">
          {initialComment && client ? (
            <TaskChatMessageBubble
              {...initialComment}
              authorInfo={{ initials: getInitials(client), label: 'You', type: 'client' }}
              isAuthorVisible
            />
          ) : null}
          <YStack className="px-xl">
            <Divider />
          </YStack>
        </YStack>
      )}
      <DeleteRecurrenceModal
        isOpen={isDeleteRecurrenceModalOpen}
        onClose={closeDeleteRecurrenceModal}
        onComplete={router.history.back}
        recurrenceId={recurrenceId}
      />
      <TaskDetailModal isOpen={isTaskDetailModalOpen} onClose={closeTaskDetailModal} recurrence={recurrence} />
    </PageContainer>
  );
};

export const Route = createFileRoute('/app/recurrence/$recurrenceId')({
  component: Recurrence,
});
