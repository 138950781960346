import type { LocalFile, LocalOrSavedFile, SavedFile } from '@frontend/api-types';
import {
  capitalize,
  convertEncodedToDecodedURI,
  isStringValidDateISOString,
  isValidEncodedURI,
  makeDateISOString,
  makeEncodedURI,
  match,
} from '@frontend/duck-tape';
import { isValidUrl } from '@frontend/duck-tape/url/url';
import * as LPN from 'libphonenumber-js';
import * as z from 'zod';

type FileType = 'localFile' | 'localOrSavedFile' | 'savedFile';

const fileArrayValidator = <TFileType extends FileType>(type: TFileType) => z.array(fileValidator<TFileType>(type)!);

const fileValidator = <TFileType extends FileType>(
  type: TFileType,
): TFileType extends 'localFile'
  ? z.ZodType<LocalFile, z.ZodTypeDef, LocalFile>
  : TFileType extends 'savedFile'
    ? z.ZodType<SavedFile, z.ZodTypeDef, SavedFile>
    : z.ZodType<LocalOrSavedFile, z.ZodTypeDef, LocalOrSavedFile> => {
  return (
    match(type)
      // @ts-expect-error Tedious types
      .with('localFile', () => z.custom<LocalFile>())
      // @ts-expect-error Tedious types
      .with('savedFile', () => z.custom<SavedFile>())
      // @ts-expect-error Tedious types
      .with('localOrSavedFile', () => z.custom<LocalOrSavedFile>())
      // @ts-expect-error Tedious types
      .exhaustive()
  );
};

const looseUrlValidator = () =>
  z.string().refine((value) => isValidUrl(value), {
    message: 'Please provide a valid url',
  });

const positiveInteger = () => z.number().int().positive();

const numericString = () =>
  z
    .string()
    .refine(
      (value) => {
        return !isNaN(parseFloat(value)) && isFinite(value as unknown as number);
      },
      {
        message: 'Invalid numeric string', // Custom error message
      },
    )
    .transform((v) => parseInt(v, 10));

const fiveStarReview = () => z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4), z.literal(5)]);

const dateISOString = () =>
  z
    .string()
    .refine(isStringValidDateISOString, {
      message: 'Invalid ISO 8601 date format',
    })
    .transform(makeDateISOString);

const encodedURI = () =>
  z
    .string()
    .refine(isValidEncodedURI, {
      message: 'Invalid encoded uri',
    })
    .transform(makeEncodedURI)
    .transform(convertEncodedToDecodedURI);

const readonlyStringEnum = <T extends string>(values: T[]) => z.enum(values as [T, ...T[]]);

const emailRegex =
  /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*/;

export const emailWithOptImposterRegex = RegExp(`^${emailRegex.source}( as ${emailRegex.source})?$`);

export const validators = {
  NANPPhoneNumber: (fieldName: string = 'Phone number') =>
    z.string({ message: `${capitalize(fieldName)} is required` }).refine(
      (value) => {
        const phoneNumberPattern = /^\+1 \d{3}-\d{3}-\d{4}$/;
        return phoneNumberPattern.test(value);
      },
      {
        message: 'Invalid phone number format. Expected format: +1 000-000-0000',
      },
    ),
  array: z.array,
  boolean: z.boolean,
  dateISOString,
  e164PhoneNumber: (fieldName: string = 'Phone number') =>
    z.string({ message: `${capitalize(fieldName)} is required` }).refine(
      (phoneNumber) => {
        // Try to parse the phone number
        const parsedNumber = LPN.parsePhoneNumberFromString(phoneNumber);

        // Validate if it's a valid number and in E.164 format
        return parsedNumber && LPN.isValidNumber(phoneNumber) && parsedNumber.format('E.164') === phoneNumber;
      },
      {
        message: 'Invalid US phone number format',
      },
    ),
  email: () => z.string({ message: 'Email cannot be blank' }).regex(emailRegex, 'Invalid email').min(1),
  encodedURI,
  fileArrayValidator,
  fileValidator,
  fiveStarReview,
  integer: () =>
    z.number().refine((val) => Number.isInteger(val), {
      message: 'Number must be an integer with no decimal places',
    }),
  internationalPhoneNumber: (fieldName: string = 'Phone number') =>
    z.string({ message: `${capitalize(fieldName)} is required` }).refine(
      (phoneNumber) => {
        const parsedNumber = LPN.parsePhoneNumberFromString(phoneNumber);
        return parsedNumber && LPN.isValidNumber(phoneNumber);
      },
      {
        message: 'Invalid phone number format. Please provide a valid phone number.',
      },
    ),
  legacyPassword: () => z.string({ message: 'Password is required' }),
  literal: z.literal,
  loginEmail: () =>
    z.string({ message: 'Email cannot be blank' }).regex(emailWithOptImposterRegex, 'Invalid email').min(1),
  looseUrlValidator,
  numericString,
  object: z.object,
  optionalString: () => z.string().optional().default(''),
  password: () =>
    z
      .string({ message: 'Password is required' })
      // New password rules
      .min(12, { message: 'Password must be at least 12 characters' }),
  // Old web didn't enforce any rules, so many people's passwords might violate this
  // .min(12, { message: 'Password must be at least 12 characters' })
  positiveInteger,
  readonlyStringEnum,
  requiredString: (fieldName?: string) =>
    z.string({ message: fieldName ? `${capitalize(fieldName)} cannot be blank` : 'This field is required' }).min(1),
  union: z.union,
  usZipCode: () =>
    z.string({ message: 'Zip code is required' }).refine(
      (value) => {
        const zipCodePattern = /^\d{5}(?:-\d{4})?$/;
        return zipCodePattern.test(value);
      },
      {
        message: 'Invalid zip code format. Expected format: 12345 or 12345-6789',
      },
    ),
  uuid: () => z.string().uuid(),
};
export const validateUSZipCode = (zipCode: string) => validators.usZipCode().safeParse(zipCode);

export const validateE164USPhoneNumber = (phoneNumber: string) => validators.e164PhoneNumber().safeParse(phoneNumber);

export const validateNANPPhoneNumber = (phoneNumber: string) => validators.NANPPhoneNumber().safeParse(phoneNumber);

export const uuidPattern = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';
export const uuidRegex = new RegExp(uuidPattern);

export { z };
