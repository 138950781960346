import { createTag } from '@frontend/api-client/utils';
import type { BaseApi } from '@frontend/api-client/utils/types';
import type {
  NotificationSettingValue,
  RetrieveNotificationSettingsResponse,
  UpdateNotificationSettingsResponse,
} from '@frontend/api-types';
import { convertObjectKeysCase } from '@frontend/duck-tape';
import NOTIFICATIONS_SETTINGS_ENDPOINTS from './endpoints';

type NotificationSettingsRequest = {
  birthdays?: NotificationSettingValue;
  nudges?: NotificationSettingValue;
  reviews?: NotificationSettingValue;
  taskUpdates?: NotificationSettingValue;
};

export const getNotificationSettingsApi = (baseApi: BaseApi) => {
  const notificationSettingsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
      retrieveNotificationSettings: builder.query<RetrieveNotificationSettingsResponse, void>({
        providesTags: [createTag('Notifications')],
        query: NOTIFICATIONS_SETTINGS_ENDPOINTS.retrieve,
        transformResponse: (response: GenericRecord) =>
          convertObjectKeysCase(response, 'camelCase') as RetrieveNotificationSettingsResponse,
      }),
      updateNotificationSettings: builder.mutation<UpdateNotificationSettingsResponse, NotificationSettingsRequest>({
        invalidatesTags: [createTag('Notifications')],
        onQueryStarted: async (updates, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            notificationSettingsApi.util.updateQueryData('retrieveNotificationSettings', undefined, (draft) => {
              Object.entries(updates).forEach(([key, value]) => {
                // eslint-disable-next-line fp/no-mutation
                draft[key] = value;
              });
            }),
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
        query: (payload) => ({
          body: convertObjectKeysCase(payload, 'snakeCase'),
          method: 'PATCH',
          url: NOTIFICATIONS_SETTINGS_ENDPOINTS.update(),
        }),
        transformResponse: (response: GenericRecord) =>
          convertObjectKeysCase(response, 'camelCase') as UpdateNotificationSettingsResponse,
      }),
    }),
    overrideExisting: true,
  });
  return notificationSettingsApi;
};
