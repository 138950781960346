import type { RefObject } from 'react';
import { useEffect, useState } from 'react';

export const useScrollStatus = (ref: RefObject<HTMLDivElement>) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const { clientHeight, scrollHeight, scrollTop } = ref.current;
        // Check if scrolled is not at the bottom
        const isNotAtBottom = scrollHeight - scrollTop > clientHeight + 10; // Add some buffer

        setIsScrolled(isNotAtBottom);
      }
    };

    handleScroll();

    const container = ref.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    // Cleanup event listener on component unmount
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [ref.current]);

  return { isScrolled };
};
