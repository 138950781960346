import type { Client } from '@frontend/api-types';
import { analyticsMap, type AnalyticsEventPayload, type AnalyticsMap, type AnalyticsTopic } from './helpers';

// eslint-disable-next-line @typescript-eslint/ban-types
type SegmentTrack = <TData extends Record<string, unknown> = {}>(
  object: string,
  action: string,
  data: TData,
  client?: Client,
) => void;

export const getUseAnalyticsHook = ({
  client,
  segmentTrack,
}: {
  client: MaybeUndefined<Client>;
  segmentTrack: SegmentTrack;
}) => {
  const track = <Topic extends AnalyticsTopic, Event extends keyof AnalyticsMap[Topic]>(
    config: AnalyticsEventPayload<Topic, Event> extends never
      ? {
          event: Event;
          topic: Topic;
        }
      : {
          data: AnalyticsEventPayload<Topic, Event>;
          event: Event;
          topic: Topic;
        },
  ) => {
    segmentTrack(
      (analyticsMap[config.topic][config.event] as { object: string }).object,
      (analyticsMap[config.topic][config.event] as { action: string }).action,
      'data' in config ? config.data : {},
      client,
    );
  };

  return { track };
};

export type TypedSegmentTrack = ReturnType<typeof getUseAnalyticsHook>['track'];
