import { entries, identity } from '@frontend/duck-tape';
import { useHotkeys } from '@frontend/web-react';

type ShortCut = 'Enter' | 'mod+Enter';

type UseShortcut = Partial<{
  [key in ShortCut]: {
    isDisabled?: boolean;
    onSubmit: () => void;
  };
}>;

export const useShortcut = (shortcuts: UseShortcut, tagsToIgnore?: string[], triggerOnContentEditable?: boolean) => {
  useHotkeys(
    entries(shortcuts).map(([key, { isDisabled, onSubmit }]) => [key, isDisabled ? identity : onSubmit]),
    tagsToIgnore,
    triggerOnContentEditable,
  );
};
