import { debounce, identity } from '@frontend/duck-tape';
import { useListState, type ReactNode, type SetStateFn } from '@frontend/react';
import { Icon } from '@frontend/web-react';
import { useLazyListTaskSearchResultsQuery } from '@frontend/web/hooks';
import { Env, useRouter } from '@frontend/web/utils';
import type { SpotlightActionGroupData } from '@mantine/spotlight/lib/Spotlight';
import { createContext, useCallback, useContext } from 'react';
import type { QuackActionsProps } from '../components/QuackActions/QuackActions';
import { useCreateTaskContext } from '../hooks/context/useCreateTaskContext';

type QuackActionsContext = {
  actions: QuackActionsProps['actionGroups'];
  addAction: (actions: SpotlightActionGroupData) => void;
  isSearchLoading: boolean;
  onStartSearch: (query: string) => void;
  removeAction: (actionId: string, groupName: string) => void;
  resetActions: () => void;
  setActions: SetStateFn<QuackActionsProps['actionGroups']>;
};

export const QuackActionsContext = createContext<QuackActionsContext>({
  actions: [],
  addAction: (_actions: SpotlightActionGroupData) => {},
  isSearchLoading: false,
  onStartSearch: (_query: string) => {},
  removeAction: (_actionId: string, _groupName: string) => {},
  resetActions: identity,
  setActions: identity,
});

export const useQuackActionsContext = () => useContext(QuackActionsContext);

export type QuackActionsProviderProps = {
  children: (context: QuackActionsContext) => ReactNode;
};

export const QuackActionsProvider = ({ children }: QuackActionsProviderProps) => {
  const router = useRouter();
  const { openCreateTaskModal } = useCreateTaskContext();
  const [searchTasks, { isLoading: isSearchLoading }] = useLazyListTaskSearchResultsQuery();

  const initialPageActions: SpotlightActionGroupData = {
    actions: [
      {
        description: 'View tasks',
        id: 'home',
        label: 'Home',
        leftSection: <Icon name="IconHome" />,
        onClick: () => router.navigate({ to: '/app' }),
      },
      {
        description: 'View feed',
        id: 'feed',
        label: 'Feed',
        leftSection: <Icon name="IconList" />,
        onClick: () => router.navigate({ to: '/app/feed' }),
      },
      {
        description: 'View settings',
        id: 'settings',
        label: 'Settings',
        leftSection: <Icon name="IconSettings" />,
        onClick: () => router.navigate({ to: '/app/settings' }),
      },
      {
        description: 'Visit the Duckbill Directory',
        id: 'directory',
        label: 'Duckbill Directory',
        leftSection: <Icon name="IconBook" />,
        // eslint-disable-next-line no-restricted-globals
        onClick: () => open(Env.VITE_DUCKBILL_DIRECTORY_URL, '_blank'),
      },
      {
        description: 'Create a one-off task',
        id: 'createTask',
        label: 'Create One-Off Task',
        leftSection: <Icon name="IconChecklist" />,
        onClick: () => openCreateTaskModal({ isRecurrence: false }),
      },
      {
        description: 'Create a repeating task',
        id: 'createRepeatingTask',
        label: 'Create Repeating Task',
        leftSection: <Icon name="IconRepeat" />,
        onClick: () => openCreateTaskModal({ isRecurrence: true }),
      },
    ],
    group: 'Pages',
  };

  const [quackActions, { replace, setState: setQuackActions }] = useListState<SpotlightActionGroupData>([
    initialPageActions,
  ]);

  const findGroupIndex = (groupName: string) => quackActions.findIndex((action) => action.group === groupName);

  const onStartSearch = useCallback(
    debounce(
      async (query: string) => {
        if (query.length > 1) {
          removeGroup('Tasks');
          const searchTasksResults = await searchTasks({ limit: 10, query }).unwrap();
          if (searchTasksResults.length) {
            const taskActions: SpotlightActionGroupData = {
              actions: searchTasksResults.map((result) => ({
                description: result.statusSummary,
                id: result.id,
                label: result.title,
                onClick: () => router.navigate({ params: { taskId: result.id }, to: '/app/task/$taskId' }),
              })),
              group: 'Tasks',
            };
            const tasksActionsIndex = findGroupIndex('Tasks');
            if (tasksActionsIndex !== -1) {
              replace(tasksActionsIndex, () => taskActions);
            } else {
              addAction(taskActions);
            }
          }
        }
        // called only after the user has stopped typing (trailing edge)
      },
      300,
      { leading: false, trailing: true },
    ),
    [],
  );

  const addAction = (newActionGroup: SpotlightActionGroupData) => {
    setQuackActions((currentActionGroups) => {
      if (findGroupIndex(newActionGroup.group) !== -1) {
        // Group already exists, merge and dedupe from the actions list
        return currentActionGroups.map((curActionGroup) => {
          if (curActionGroup.group === newActionGroup.group) {
            return {
              ...curActionGroup,
              actions: [
                ...curActionGroup.actions,
                ...newActionGroup.actions.filter(
                  (action) => !curActionGroup.actions.some((curAction) => curAction.id === action.id),
                ),
              ],
            };
          }
          return curActionGroup;
        });
      }
      // Group does not exist, add prepend it to the actions list
      return [newActionGroup, ...currentActionGroups];
    });
  };

  const removeGroup = (groupName: string) => {
    setQuackActions((currentActionGroups) => {
      // Filter out the group with the specified name
      return currentActionGroups.filter((action) => !('group' in action && action.group === groupName));
    });
  };

  const removeAction = (actionId: string, groupName: string) => {
    setQuackActions((currentActionGroups) => {
      return (
        currentActionGroups
          .map((actionGroup) => {
            if (actionGroup.group === groupName) {
              const filteredActions = actionGroup.actions.filter((a) => a.id !== actionId);
              return { ...actionGroup, actions: filteredActions };
            }
            return actionGroup;
          })
          // Filter out any empty groups
          .filter((actionGroup) => actionGroup.actions.length > 0)
      );
    });
  };

  const resetActions = () => {
    setQuackActions([initialPageActions]);
  };

  const contextValue = {
    actions: quackActions,
    addAction,
    isSearchLoading,
    onStartSearch,
    removeAction,
    resetActions,
    setActions: setQuackActions,
  };

  return <QuackActionsContext.Provider value={contextValue}>{children(contextValue)}</QuackActionsContext.Provider>;
};
