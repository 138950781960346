import type { Client } from '@frontend/api-types';
import { identifyGrowthBook } from '@frontend/react';
import { identifyCio } from './cio';
import { identifySegment } from './segment';
import { identifySentry } from './sentry';

export const identifyClient = async (client: Client, env: EnvType) => {
  if (!client) return;

  identifySegment({ client, env });
  identifyCio({ client, env });
  identifySentry({ email: client.email, env, id: client.id });
  await identifyGrowthBook({ client });
};
