import type { IconProps } from '@frontend/web-react';
import { Icon } from '@frontend/web-react';
import { concatClasses, type ClassName } from '@frontend/web-utils';
import { YStack } from '../../Containers';

export type AnimatingFloatingButtonProps = {
  className?: ClassName;
  onClick: IconProps['onClick'];
};

export const AnimatingFloatingButton = ({ className, onClick }: AnimatingFloatingButtonProps) => {
  return (
    <YStack
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className={concatClasses(
        className,
        'z-10 top-auto left-none right-none justify-center items-center gap-x-md absolute float-effect self-center',
      )}
    >
      <Icon className="shadow-one" enableBackgroundColor name="IconArrowDown" onClick={onClick} rounded size="sm" />
    </YStack>
  );
};
