import type { ReactNode } from '@frontend/react';
import { Text, YStack } from '@frontend/web-react';

type DetailItemContainerProps = {
  children: ReactNode;
  title: string;
};

const DetailItemContainer = ({ children, title }: DetailItemContainerProps) => (
  <YStack className="pb-md gap-sm">
    <Text type="h5">{title}</Text>
    {children}
  </YStack>
);

export default DetailItemContainer;
