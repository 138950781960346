import { createTag } from '@frontend/api-client/utils';
import type { BaseApi } from '@frontend/api-client/utils/types';
import type {
  CreateAddressRequest,
  CreateAddressResponse,
  ListAddressesRequest,
  UpdateAddressRequest,
  UpdateAddressResponse,
} from '@frontend/api-types';
import { convertArrayObjectKeysCase, convertObjectKeysCase } from '@frontend/duck-tape';
import { ADDRESSES_ENDPOINTS } from './endpoints';

export const getAddressesApi = (baseApi: BaseApi) =>
  baseApi.injectEndpoints({
    endpoints: (builder) => ({
      createAddress: builder.mutation<CreateAddressResponse, CreateAddressRequest>({
        invalidatesTags: [createTag('Client')],
        query: ({ line1, line2, ...rest }) => ({
          body: { line1, line2, ...convertObjectKeysCase(rest, 'snakeCase') },
          method: 'PUT',
          url: ADDRESSES_ENDPOINTS.createAddress,
        }),
        transformResponse: (task: GenericRecord) => convertObjectKeysCase(task, 'camelCase') as CreateAddressResponse,
      }),
      listAddresses: builder.query<ListAddressesRequest, void>({
        providesTags: [createTag({ id: 'LIST', type: 'Address' })],
        query: ADDRESSES_ENDPOINTS.retrieveAddresses,
        transformResponse: (response: GenericRecord[]) =>
          convertArrayObjectKeysCase(response, 'camelCase') as ListAddressesRequest,
      }),
      updateAddress: builder.mutation<UpdateAddressResponse, UpdateAddressRequest>({
        invalidatesTags: [createTag({ id: 'LIST', type: 'Address' })],
        query: ({ line1, line2, ...rest }) => ({
          body: { line1, line2, ...convertObjectKeysCase(rest, 'snakeCase') },
          method: 'PUT',
          url: ADDRESSES_ENDPOINTS.updateAddress,
        }),
        transformResponse: (task: GenericRecord) => convertObjectKeysCase(task, 'camelCase') as UpdateAddressResponse,
      }),
    }),
    overrideExisting: true,
  });
