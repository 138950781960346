import { createTag } from '@frontend/api-client/utils';
import type { BaseApi } from '@frontend/api-client/utils/types';
import type {
  DeactivateOAuthAccountResponse,
  ListOAuthAccountsResponse,
  UpdateOAuthCodeRequest,
  UpdateOAuthCodeResponse,
} from '@frontend/api-types';
import { convertArrayObjectKeysCase, convertObjectKeysCase } from '@frontend/duck-tape';
import { OAUTH_ENDPOINTS } from './endpoints';

export const getOAuthApi = (baseApi: BaseApi) => {
  const oAuthApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
      deactivateOAuthAccount: builder.mutation<DeactivateOAuthAccountResponse, { id: string }>({
        invalidatesTags: [createTag({ id: 'LIST', type: 'Account' })],
        query: ({ id }) => ({
          method: 'POST',
          url: OAUTH_ENDPOINTS.deactivateOAuthAccount(id),
        }),
      }),
      listOAuthAccounts: builder.query<ListOAuthAccountsResponse, void>({
        query: () => ({
          method: 'GET',
          providesTags: [createTag({ id: 'LIST', type: 'Account' })],
          url: OAUTH_ENDPOINTS.listOAuthAccounts,
        }),
        transformResponse: (response: GenericRecord[]) => {
          return convertArrayObjectKeysCase(response, 'camelCase') as ListOAuthAccountsResponse;
        },
      }),
      updateOAuthCode: builder.mutation<UpdateOAuthCodeResponse, UpdateOAuthCodeRequest>({
        query: (payload) => ({
          body: convertObjectKeysCase(payload, 'snakeCase'),
          invalidatesTags: [createTag({ id: 'LIST', type: 'Account' })],
          method: 'POST',
          url: OAUTH_ENDPOINTS.updateOAuthCode,
        }),
      }),
    }),
  });
  return oAuthApi;
};
