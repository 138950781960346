import { isArray, keys } from '@frontend/duck-tape';
import type { FormikErrors } from 'formik';

export const flattenErrors = <TValues, TErrorMap extends FormikErrors<TValues>>(errors: TErrorMap) => {
  return keys(errors).reduce(
    (acc, cur) => {
      const value = errors[cur];
      if (isArray(value)) {
        return { [cur]: value.join(','), ...acc };
      }
      return { [cur]: value, ...acc };
    },
    {} as Record<keyof TErrorMap, MaybeUndefined<string>>,
  );
};
