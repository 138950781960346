import { useState } from '@frontend/react';
import { Emoji, Spinner } from '@frontend/web-react';
import { safeCss } from '@frontend/web-utils';
import { useAnalytics } from '@frontend/web/hooks';
import type { SpotlightSearchProps as MSpotlightSearchProps, SpotlightActionGroupData } from '@mantine/spotlight';
import { Spotlight as MSpotlight } from '@mantine/spotlight';

export type QuackActionsProps = {
  actionGroups: SpotlightActionGroupData[];
  isSearchLoading?: boolean;
  onQueryChange?: (query: string) => void;
  onResetQuery?: () => void;
};

export const QuackActions = ({ actionGroups, isSearchLoading, onQueryChange, onResetQuery }: QuackActionsProps) => {
  const { track } = useAnalytics();
  const [query, setQuery] = useState('');

  const onReset = () => {
    onResetQuery?.();
    setQuery('');
  };

  const onChangeSearch: MSpotlightSearchProps['onChange'] = (event) => {
    const query = event.currentTarget.value;
    if (!query) {
      onReset();
    } else {
      setQuery(query);
      onQueryChange?.(query);
    }
  };

  const onOpen = () => track({ event: 'commandPaletteOpened', topic: 'commandPalette' });

  const filteredActions = actionGroups
    .map(({ actions, group }) => ({
      actions: actions
        .filter(
          (action) =>
            group === 'Tasks' ||
            !query ||
            action.label?.toLocaleLowerCase().trim().includes(query.toLocaleLowerCase().trim()),
        )
        .map((action) => ({
          ...action,
          onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
            track({
              data: { description: action.description ?? '', label: action.label ?? '' },
              event: 'commandPaletteActionSelected',
              topic: 'commandPalette',
            });
            action.onClick?.(e);
          },
        })),
      group,
    }))
    .filter(({ actions }) => actions.length);

  return (
    <MSpotlight.Root
      classNames={{ content: safeCss('rounded-md') }}
      maxHeight={500}
      onSpotlightClose={onReset}
      onSpotlightOpen={onOpen}
      scrollable
      shadow="xs"
      shortcut={['mod + K', 'mod + P']}
    >
      <MSpotlight.Search
        leftSection={<Emoji emoji=":duck:" />}
        onChange={onChangeSearch}
        placeholder="Search..."
        rightSection={isSearchLoading ? <Spinner size="xs" /> : null}
      />
      <MSpotlight.ActionsList>
        {filteredActions.length ? (
          filteredActions.map(({ actions, group }) => (
            <MSpotlight.ActionsGroup key={group} label={group} title={group}>
              {actions.map((action) => (
                <MSpotlight.Action
                  classNames={{
                    action: safeCss('data-[selected]:bg-neutral-100 hover-background-effect'),
                    actionBody: safeCss('font-normal text-textPrimary'),
                    actionDescription: safeCss('font-normal text-textPrimary'),
                    actionLabel: safeCss('font-medium text-textPrimary'),
                  }}
                  highlightQuery
                  key={action.id}
                  {...action}
                />
              ))}
            </MSpotlight.ActionsGroup>
          ))
        ) : (
          <MSpotlight.Empty>{isSearchLoading ? 'Searching...' : 'Nothing found...'}</MSpotlight.Empty>
        )}
      </MSpotlight.ActionsList>
    </MSpotlight.Root>
  );
};
