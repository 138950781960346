import { validators, z } from '../utils';
import { makeFormComponent, makeFormHook } from '../utils/factory';
import type { InferFormType } from '../utils/types';

const onboardingCreatePasswordFormSchema = z
  .object({
    confirmPassword: validators.password(),
    password: validators.password(),
    promoCode: validators.optionalString(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword'],
  });

export type OnboardingCreatePasswordFormSchema = InferFormType<typeof onboardingCreatePasswordFormSchema>;

export const onboardingCreatePasswordFormDefaultValues: OnboardingCreatePasswordFormSchema = {
  confirmPassword: '',
  password: '',
  promoCode: '',
};

export const useOnboardingCreatePasswordForm = makeFormHook(
  onboardingCreatePasswordFormSchema,
  onboardingCreatePasswordFormDefaultValues,
);

export const OnboardingCreatePasswordForm = makeFormComponent(
  onboardingCreatePasswordFormSchema,
  onboardingCreatePasswordFormDefaultValues,
);
