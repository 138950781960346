import { colors, fontStyles, sansFontFamily, spacing } from '@frontend/constants';
import { createTheme } from '@mantine/core';

const fontFamily = sansFontFamily.join(' ,');

export const mantineTheme = createTheme({
  colors: {
    blue: [
      colors.blue[50],
      colors.blue[100],
      colors.blue[200],
      colors.blue[300],
      colors.blue[400],
      colors.blue[500],
      colors.blue[600],
      colors.blue[700],
      colors.blue[800],
      colors.blue[950],
    ],
    cyan: [
      colors.cyan[50],
      colors.cyan[100],
      colors.cyan[200],
      colors.cyan[300],
      colors.cyan[400],
      colors.cyan[500],
      colors.cyan[600],
      colors.cyan[700],
      colors.cyan[800],
      colors.cyan[900],
    ],
    green: [
      colors.green[50],
      colors.green[100],
      colors.green[200],
      colors.green[300],
      colors.green[400],
      colors.green[500],
      colors.green[600],
      colors.green[700],
      colors.green[800],
      colors.green[900],
    ],
    lime: [
      colors.lime[50],
      colors.lime[100],
      colors.lime[200],
      colors.lime[300],
      colors.lime[400],
      colors.lime[500],
      colors.lime[600],
      colors.lime[700],
      colors.lime[800],
      colors.lime[900],
    ],
    navy: [
      colors.navy[50],
      colors.navy[100],
      colors.navy[200],
      colors.navy[300],
      colors.navy[400],
      colors.navy[500],
      colors.navy[600],
      colors.navy[700],
      colors.navy[800],
      colors.navy[900],
    ],
    neutral: [
      colors.neutral[50],
      colors.neutral[100],
      colors.neutral[200],
      colors.neutral[300],
      colors.neutral[400],
      colors.neutral[500],
      colors.neutral[600],
      colors.neutral[700],
      colors.neutral[800],
      colors.neutral[900],
    ],
    pink: [
      colors.pink[50],
      colors.pink[100],
      colors.pink[200],
      colors.pink[300],
      colors.pink[400],
      colors.pink[500],
      colors.pink[600],
      colors.pink[700],
      colors.pink[800],
      colors.pink[900],
    ],

    red: [
      colors.red[50],
      colors.red[100],
      colors.red[200],
      colors.red[300],
      colors.red[400],
      colors.red[500],
      colors.red[600],
      colors.red[700],
      colors.red[800],
      colors.red[900],
    ],
    rose: [
      colors.rose[50],
      colors.rose[100],
      colors.rose[200],
      colors.rose[300],
      colors.rose[400],
      colors.rose[500],
      colors.rose[600],
      colors.rose[700],
      colors.rose[800],
      colors.rose[900],
    ],
    slate: [
      colors.slate[50],
      colors.slate[100],
      colors.slate[200],
      colors.slate[300],
      colors.slate[400],
      colors.slate[500],
      colors.slate[600],
      colors.slate[700],
      colors.slate[800],
      colors.slate[900],
    ],
    teal: [
      colors.teal[50],
      colors.teal[100],
      colors.teal[200],
      colors.teal[300],
      colors.teal[400],
      colors.teal[500],
      colors.teal[600],
      colors.teal[700],
      colors.teal[800],
      colors.teal[900],
    ],
    violet: [
      colors.violet[50],
      colors.violet[100],
      colors.violet[200],
      colors.violet[300],
      colors.violet[400],
      colors.violet[500],
      colors.violet[600],
      colors.violet[700],
      colors.violet[800],
      colors.violet[900],
    ],
    yellow: [
      colors.yellow[50],
      colors.yellow[100],
      colors.yellow[200],
      colors.yellow[300],
      colors.yellow[400],
      colors.yellow[500],
      colors.yellow[600],
      colors.yellow[700],
      colors.yellow[800],
      colors.yellow[900],
    ],
  },
  cursorType: 'pointer',
  fontFamily,
  fontSizes: {
    lg: fontStyles.rem['p-lg'].fontSize,
    md: fontStyles.rem['p-md'].fontSize,
    sm: fontStyles.rem['p-sm'].fontSize,
    xl: undefined,
    xs: undefined,
  },
  headings: {
    fontFamily,
    fontWeight: '700',
    sizes: {
      h1: fontStyles.rem.h1,
      h2: fontStyles.rem.h2,
      h3: fontStyles.rem.h3,
      h4: fontStyles.rem.h4,
      h5: fontStyles.rem.h5,
      h6: fontStyles.rem.h6,
    },
  },
  primaryColor: 'blue',
  spacing: spacing.web,
});
