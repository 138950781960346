import { createFileRoute, redirect } from '@tanstack/react-router';

// This route handles redirects for backwards compatibility purposes
export const Route = createFileRoute('/tasks/$taskId')({
  beforeLoad: ({ params }) => {
    throw redirect({
      params,
      to: '/app/task/$taskId',
    });
  },
  component: () => <div />,
});
