import { canTaskBeReviewed, getIsSkipAllowed } from '@frontend/api-client';
import type { StrictOmit } from '@frontend/duck-tape';
import { identity, isNil } from '@frontend/duck-tape';
import { NEGATIVE_REVIEW_CANNED_OPTIONS, POSITIVE_REVIEW_CANNED_OPTIONS, useTaskReviewForm } from '@frontend/forms';
import { useEffect } from '@frontend/react';
import type { CommonModalProps } from '@frontend/web-react';
import {
  Button,
  ExpandableText,
  Icon,
  Modal,
  RatingInput,
  TagMultiSelectInput,
  Text,
  TextareaInput,
  YStack,
} from '@frontend/web-react';
import { useQuackActionsContext } from '@frontend/web/context/QuackActionsContext';
import {
  useCreateTaskReviewMutation,
  useLazyListCommentsQuery,
  useLazyRetrieveTaskQuery,
  useUpdateTaskMutation,
} from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';
import { useNavigate } from '@tanstack/react-router';

export type ReviewTaskModalProps = StrictOmit<CommonModalProps, 'isForced'> & {
  onComplete?: (taskId: string) => void;
  // We don't always need this prop
  openModal?: () => void;
  overrideAllowSkip?: boolean;
  redirectToTasksListOnSuccess?: boolean;
  taskId: string;
};

export const ReviewTaskModal = ({
  isOpen,
  onClose,
  onComplete,
  openModal,
  overrideAllowSkip = false,
  redirectToTasksListOnSuccess = false,
  taskId,
}: ReviewTaskModalProps) => {
  const navigate = useNavigate();
  const [createTaskReview, { isLoading: createReviewIsLoading }] = useCreateTaskReviewMutation();
  const [updateTask] = useUpdateTaskMutation();
  const [retrieveTask, { data: task, isFetching }] = useLazyRetrieveTaskQuery();
  const [listComments, { data: comments = [], isFetching: isCommentsFetching }] = useLazyListCommentsQuery();
  const isSkipAllowed = !isCommentsFetching && getIsSkipAllowed(comments);
  const canBeReviewed = task ? canTaskBeReviewed(task) : false;
  const { addAction, removeAction } = useQuackActionsContext();

  useEffect(() => {
    if (taskId) {
      (async () => {
        retrieveTask(taskId);
        listComments(taskId);
      })();
    }
  }, [taskId]);

  const { getControl, isValid, resetForm, submitForm, values } = useTaskReviewForm({
    onSubmit: async (values, { resetForm }) =>
      handleMutation({
        mutation: () =>
          createTaskReview({
            cannedResponses:
              values.rating && values.rating < 5 ? values.negativeCannedResponses : values.positiveCannedResponses,
            description: values.description,
            rating: values.rating,
            task: taskId,
          }).unwrap(),
        onSuccess: ({ task }) => {
          onComplete?.(task);
          resetForm();
          onClose();
          if (redirectToTasksListOnSuccess) {
            navigate({ search: { tab: 'waiting_on_you' }, to: '/app' });
          }
        },
        successMessage: 'Thanks for your feedback!',
      }),
    validateOnMount: false,
  });

  useEffect(() => {
    if (canBeReviewed && openModal) {
      addAction({
        actions: [
          {
            description: 'Review this task',
            id: 'review',
            label: 'Review task',
            leftSection: <Icon name="IconStar" />,
            onClick: openModal,
          },
        ],
        group: 'Current Task',
      });

      return () => {
        removeAction('review', 'Current Task');
      };
    }
    return identity;
  }, [task, canBeReviewed, openModal]);

  const onCloseComposed = async () => {
    if (task && !task.isReviewSubmitted && isSkipAllowed) {
      onClose();
      resetForm();
      return handleMutation({
        mutation: async () => updateTask({ data: { isReviewSubmitted: true }, id: task.id }).unwrap(),
        onSuccess: () => onComplete?.(task.id),
        // Should silently fail or succeed in the background
        showErrorToast: false,
        showSuccessToast: false,
      });
    }
    onClose();
    resetForm();
    return;
  };

  const isRatingLessThanFive = values.rating && values.rating < 5;

  return (
    <Modal
      headerGap="md"
      isForced={overrideAllowSkip ? false : !isSkipAllowed}
      isLoading={isFetching}
      isOpen={isOpen}
      onClose={onCloseComposed}
      title={task?.name}
      titlesAlignment="start"
    >
      <YStack className="space-y-md">
        <YStack>
          <ExpandableText labelDirection="right" textProps={{ type: 'p-sm' }}>
            {task ? task.description : null}
          </ExpandableText>
        </YStack>
        <YStack className="gap-y-md">
          <Text type="h3">{"How'd"} we do?</Text>
          <RatingInput className="self-center" {...getControl('rating')} />
          {values.rating ? (
            isRatingLessThanFive ? (
              <TagMultiSelectInput
                options={NEGATIVE_REVIEW_CANNED_OPTIONS}
                {...getControl('negativeCannedResponses')}
              />
            ) : (
              <TagMultiSelectInput
                options={POSITIVE_REVIEW_CANNED_OPTIONS}
                {...getControl('positiveCannedResponses')}
              />
            )
          ) : (
            <Text color="tertiary" textAlign="center" type="captionBold">
              The best thank you is your candid feedback.
            </Text>
          )}
          {!isNil(values.rating) ? (
            <TextareaInput
              height={126}
              label=""
              placeholder={
                isRatingLessThanFive ? 'Please let us know how we can improve.' : 'Let us know what went well.'
              }
              {...getControl('description')}
              error={getControl('description').error}
            />
          ) : null}
        </YStack>
        <Button
          color="primary"
          disabled={
            !values.rating ||
            !isValid ||
            (isRatingLessThanFive && values.negativeCannedResponses?.includes('Other') && values.description === '') ||
            (isRatingLessThanFive && !values.negativeCannedResponses?.length)
          }
          fullWidth
          isLoading={createReviewIsLoading}
          label="Submit"
          onClick={submitForm}
        />
      </YStack>
    </Modal>
  );
};
