import type { CrossPlatformFile, LocalFile, LocalOrSavedFile, SavedFile, SavedFileFields } from '@frontend/api-types';
import { match } from '@frontend/duck-tape';

export const makeLocalFile = (file: CrossPlatformFile): LocalFile => {
  // @ts-expect-error No way around this, unfortunately
  // eslint-disable-next-line fp/no-mutation
  file.__typename = 'LocalFile';

  // TODO (ethan): Not sure if the URL class is polyfilled in react native environments
  // @ts-expect-error No way around this, unfortunately
  // eslint-disable-next-line fp/no-mutation
  file.url = URL.createObjectURL(file);

  return file as LocalFile;
};

export const makeSavedFile = <T extends Record<string, unknown> & SavedFileFields = SavedFileFields>(
  object: T,
): SavedFile & T => ({
  __typename: 'SavedFile',
  ...object,
});

export const makeSavedFileList = <T extends Record<string, unknown>>(
  objects: T[],
  mapper: (object: T) => SavedFileFields & T,
): (SavedFile & T)[] =>
  objects.map((object) => ({
    __typename: 'SavedFile',
    ...mapper(object),
  }));

export const makeLocalFileList = (fileList: File[] | null): LocalFile[] =>
  fileList ? [...fileList].map(makeLocalFile) : [];

export const getLocalFiles = (files: LocalOrSavedFile[]): LocalFile[] =>
  files.filter((f) => f.__typename === 'LocalFile') as LocalFile[];

export const getSavedFiles = (files: LocalOrSavedFile[]): SavedFile[] =>
  files.filter((f) => f.__typename === 'SavedFile') as SavedFile[];

export const isLocalOrSavedFile = (value: MaybeUndefined<LocalOrSavedFile>): boolean =>
  value?.__typename === 'LocalFile' ? value instanceof File : value?.__typename === 'SavedFile';

export const findRemovedAttachments = (
  originalAttachments: SavedFile[],
  newAttachments: LocalOrSavedFile[],
): SavedFile[] => {
  const modifiedIdentifiers = new Set(newAttachments.map((att) => ('key' in att ? att.key : att.url)));

  return originalAttachments.filter((originalAtt) => !modifiedIdentifiers.has(originalAtt.key));
};

export const getFileExceededError = (files: LocalFile[]) => {
  const exceededFile = Array.from(files).find((file) => file.size / (1024 * 1024) > 50);
  if (exceededFile) {
    return `File ${exceededFile.name} exceeds the maximum file size limit of 50MB`;
  } else {
    return null;
  }
};

export const getCommonFileFields = (file: LocalOrSavedFile): { key: string; name: string; url: string } =>
  match(file)
    .with({ __typename: 'LocalFile' }, ({ name, size, url }) => ({ key: `${name}-${size}`, name, url }))
    .with({ __typename: 'SavedFile' }, ({ key, url }) => ({ key, name: key, url }))
    .exhaustive();

export const isImage = (file: LocalOrSavedFile): boolean =>
  match(file)
    .with({ __typename: 'LocalFile' }, ({ type }) => type.includes('image'))
    .with({ __typename: 'SavedFile' }, ({ contentType }) => contentType.includes('image'))
    .exhaustive();

export const isImageUrl = (url: string): boolean =>
  /\.(JPG|jpg|JPEG|jpeg|PNG|png|WEBP|webp|AVIF|avif|GIF|gif|SVG|svg)$/.test(url);
