import { validators } from '../utils';
import { makeFormComponent, makeFormHook } from '../utils/factory';
import type { InferFormType } from '../utils/types';

export const LEARNED_ABOUT_DUCKBILL_SOURCES = [
  {
    label: 'Referred by a friend',
    value: 'friendReferral',
  },
  {
    label: 'From a podcast',
    value: 'podcast',
  },
  {
    label: 'Instagram',
    value: 'instagram',
  },
  {
    label: 'Linkedin',
    value: 'linkedin',
  },
  {
    label: 'A gift card',
    value: 'giftCard',
  },
  {
    label: 'An article',
    value: 'article',
  },
  {
    label: 'My company',
    value: 'myCompany',
  },
  {
    label: 'TV',
    value: 'tv',
  },
  {
    label: 'An email newsletter',
    value: 'newsletter',
  },
  {
    label: 'A community app, like Slack or Discord',
    value: 'communityApp',
  },
  {
    label: 'Other',
    value: 'other',
  },
] as const;

const waitlistFormSchema = validators.object({
  email: validators.email(),
  firstName: validators.requiredString('First name'),
  hdyhau: validators.readonlyStringEnum(LEARNED_ABOUT_DUCKBILL_SOURCES.map((s) => s.value)).optional(),
  // Only if friend, podcast, or other was selected
  hdyhauDetails: validators.optionalString(),
  lastName: validators.requiredString('Last name'),
  phoneNumber: validators.e164PhoneNumber(),
  promoCode: validators.optionalString(),
});

export type WaitlistFormSchema = InferFormType<typeof waitlistFormSchema>;

export const waitlistFormDefaultValues: WaitlistFormSchema = {
  email: '',
  firstName: '',
  hdyhau: undefined,
  hdyhauDetails: '',
  lastName: '',
  phoneNumber: '',
  promoCode: '',
};

export const useWaitlistForm = makeFormHook(waitlistFormSchema, waitlistFormDefaultValues);

export const WaitlistForm = makeFormComponent(waitlistFormSchema, waitlistFormDefaultValues);
