// A component that renders Skeleton lines in a list, depending on the number of skeletons in the countLines prop. Call it SkeletonListItem

import { isLast } from '@frontend/duck-tape';
import type { ClassName } from '@frontend/web-utils';
import { concatClasses } from '@frontend/web-utils';
import { YStack } from '../Containers';
import { Skeleton } from './Skeleton';

export type SkeletonListItemProps = { className?: ClassName; countLines?: number };

export const SkeletonListItem = ({ className, countLines = 3 }: SkeletonListItemProps) => (
  <YStack className={concatClasses('py-sm', className)} gapY="sm">
    {Array.from({ length: countLines }).map((_, index, array) => (
      <Skeleton height={8} key={index} width={isLast(array, index) ? '70%' : '100%'} />
    ))}
  </YStack>
);
