import { Outlet } from '@frontend/web/components';
import { createRootRouteWithContext } from '@frontend/web/utils';
import { useLocation, useScrollRestoration } from '@tanstack/react-router';
import { useEffect } from 'react';
import type { AuthContext } from '../hooks';
import { useAnalytics } from '../hooks';

export type RouterContext = {
  authentication: MaybeUndefined<AuthContext>;
};

const Root = () => {
  useScrollRestoration();

  const location = useLocation();
  const { trackRouteChange } = useAnalytics();

  useEffect(() => {
    trackRouteChange({
      properties: {
        path: location.pathname,
        search: location.search as Record<string, string>,
        searchStr: location.searchStr,
        title: window.document.title,
        url: `${window.location.origin}${location.href}`,
      },
    });
  }, [location]);

  return <Outlet />;
};

export const Route = createRootRouteWithContext<RouterContext>()({
  component: Root,
});
