import type { NotificationSettingValue, NotificationType } from '@frontend/api-types';
import { match } from '@frontend/duck-tape';
import { SwitchInput, Text, YStack } from '@frontend/web-react';
import type { ClassName } from '@frontend/web-utils';
import { concatClasses } from '@frontend/web-utils';

type NotificationCategoryProps = {
  category: string;

  className?: ClassName;
  onToggle: (category: string, type: NotificationType) => void;
  options: NotificationType[];
  subtitle: string;
  title: string;
  value: NotificationSettingValue;
};

export const NotificationCategory = ({
  category,
  className,
  onToggle,
  options,
  subtitle,
  title,
  value,
}: NotificationCategoryProps) => {
  const getToggleLabel = (type: NotificationType) =>
    match(type)
      .with('email', () => 'Email')
      .with('sms', () => 'SMS')
      .with('push', () => 'Push notifications')
      .exhaustive();

  const handleToggle = (type: NotificationType) => {
    onToggle(category, type);
  };

  return (
    <YStack className={concatClasses('gap-y-md', className)}>
      <YStack className="flex-1" gap="xs">
        <Text type="h5">{title}</Text>
        <Text type="p-sm">{subtitle}</Text>
      </YStack>
      <YStack gapY="md">
        {options.map((type) => (
          <SwitchInput
            checked={value.includes(type)}
            key={type}
            label={getToggleLabel(type)}
            onChange={() => handleToggle(type)}
          />
        ))}
      </YStack>
    </YStack>
  );
};
