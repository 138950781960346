import { createTag } from '@frontend/api-client/utils';
import type { BaseApi } from '@frontend/api-client/utils/types';
import type { CreateInviteRequest, CreateInviteResponse, RetrieveInviteResponse } from '@frontend/api-types';
import { convertObjectKeysCase } from '@frontend/duck-tape';
import { INVITES_ENDPOINTS } from './endpoints';

export const getInvitesApi = (baseApi: BaseApi) =>
  baseApi.injectEndpoints({
    endpoints: (builder) => ({
      createInvite: builder.mutation<CreateInviteResponse, CreateInviteRequest>({
        invalidatesTags: (_result, _error, { id }) => [createTag({ id: id, prefix: 'householdId', type: 'Household' })],
        query: ({ id: _id, ...rest }) => ({
          body: convertObjectKeysCase(rest, 'snakeCase'),
          method: 'POST',
          url: INVITES_ENDPOINTS.createInvite(),
        }),
      }),
      retrieveInvite: builder.query<RetrieveInviteResponse, string>({
        query: (inviteId) => INVITES_ENDPOINTS.retrieveInvite(inviteId),
        transformResponse: (response: GenericRecord) =>
          convertObjectKeysCase(response, 'camelCase') as RetrieveInviteResponse,
      }),
    }),
    overrideExisting: true,
  });
