import type { ColorValue } from '@frontend/constants';
import type { CommonSVGProps } from '@frontend/web-icons';

type UnderlineVectorProps = CommonSVGProps & {
  color: ColorValue;
};

export const UnderlineVector = ({ color, ...props }: UnderlineVectorProps) => (
  <svg fill="none" height={13} viewBox="0 0 40 13" width={40} {...props}>
    <path
      d="M2 6.051c10.733-.905 21.297-3.31 32.015-4.394.835-.084 1.672-.157 2.511-.157 1.299 0 1.161.182.157.759-3.139 1.801-6.743 3.067-10.096 4.368-3.753 1.455-7.547 2.796-11.352 4.106-.279.096-.673.042-.837.288-.57.855 1.984-.538 2.982-.785 6.893-1.708 13.878-2.979 20.872-4.185"
      stroke={color}
      strokeLinecap="round"
      strokeWidth={3}
    />
  </svg>
);
