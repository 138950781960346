import { getInitials, getThreadTextMessageProps } from '@frontend/api-client';
import type { TaskCommentAuthor, ThreadMessage, ThreadTextMessage as TTextMessage } from '@frontend/api-types';
import type { StrictExtract } from '@frontend/duck-tape';
import { match } from '@frontend/duck-tape';
import { useMemo } from '@frontend/react';
import { Divider, YStack } from '@frontend/web-react';
import { useRetrieveClientQuery } from '@frontend/web/hooks';
import { TaskChatTime } from '../Tasks';
import type { ThreadChatMessageBubbleProps } from './ThreadChatMessageBubble';
import { ThreadChatMessageBubble } from './ThreadChatMessageBubble';

export type ThreadTextMessageProps = Pick<ThreadChatMessageBubbleProps, 'onClickLink'> & {
  index: number;
  isStreaming: boolean;
  message: TTextMessage;
  messagesList: ThreadMessage[];
  threadId: string;
};

export type ThreadChatAuthorType = StrictExtract<TaskCommentAuthor['type'], 'ai_bot' | 'client'>;

export const ThreadTextMessage = ({
  index,
  isStreaming,
  message,
  messagesList,
  onClickLink,
  threadId,
}: ThreadTextMessageProps) => {
  const client = useRetrieveClientQuery();
  const { isDividerVisible, isTimeVisible } = getThreadTextMessageProps(messagesList, index);

  const resolvedType = match<typeof message.role, ThreadChatAuthorType>(message.role)
    .with('ai_bot', () => 'ai_bot')
    .with('client', () => 'client')
    .exhaustive();

  const label = match<typeof message.role>(message.role)
    .with('ai_bot', () => 'Bill')
    .with('client', () => 'You')
    .exhaustive();

  const authorInfo = useMemo(() => {
    if (client.data) {
      return {
        initials: getInitials(client.data),
        label,
        type: resolvedType,
      };
    }
    return;
  }, [client, resolvedType]);

  return (
    <YStack gapY="md">
      {isTimeVisible ? <TaskChatTime time={message.createdAt} /> : null}
      {authorInfo ? (
        <ThreadChatMessageBubble
          authorInfo={authorInfo}
          content={message.content}
          isStreaming={isStreaming}
          onClickLink={onClickLink}
          threadId={threadId}
        />
      ) : null}
      {isDividerVisible ? <Divider /> : null}
    </YStack>
  );
};
