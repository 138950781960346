import { AppStoresButton, Card, DownloadAppQRCode, PageContainer, Text, XStack } from '@frontend/web-react';
import { useAnalytics } from '@frontend/web/hooks';
import { createFileRoute } from '@tanstack/react-router';

const DownloadApp = () => {
  const { track } = useAnalytics();
  return (
    <PageContainer headerTitle="Download App">
      <XStack className="gap-md">
        <Card
          // eslint-disable-next-line tailwindcss/no-arbitrary-value
          className="max-w-[300px] w-full gap-y-md items-center justify-center"
          padding="md"
          withBorder
        >
          <DownloadAppQRCode platform="ios" />
          <Text type="captionBold">Scan to download</Text>
          <AppStoresButton
            onClick={() => track({ event: 'iosDownloadLinkSelected', topic: 'onboarding' })}
            platform="ios"
          />
        </Card>
        <Card
          // eslint-disable-next-line tailwindcss/no-arbitrary-value
          className="max-w-[300px] w-full gap-y-md items-center justify-center"
          padding="md"
          withBorder
        >
          <DownloadAppQRCode platform="android" />
          <Text type="captionBold">Scan to download</Text>
          <AppStoresButton
            onClick={() => track({ event: 'androidDownloadLinkSelected', topic: 'onboarding' })}
            platform="android"
          />
        </Card>
      </XStack>
    </PageContainer>
  );
};

export const Route = createFileRoute('/app/settings/download-app')({
  component: DownloadApp,
});
