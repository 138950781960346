import { useGeneralFeedbackForm } from '@frontend/forms';
import { Button, PageContainer, Text, TextareaInput, XStack, YStack } from '@frontend/web-react';
import { useCreateGeneralFeedbackMutation } from '@frontend/web/hooks';
import { handleMutation } from '@frontend/web/utils';
import { createFileRoute } from '@tanstack/react-router';

const Feedback = () => {
  const [createFeedback, { isLoading }] = useCreateGeneralFeedbackMutation();
  const { dirty, getControl, isValid, submitForm } = useGeneralFeedbackForm({
    onSubmit: (values, { resetForm }) =>
      handleMutation({
        mutation: async () =>
          createFeedback({
            feedbackSource: 'web',
            text: values.feedback,
          }).unwrap(),
        onSuccess: () => {
          resetForm();
          Route.router?.history.back();
        },
        showErrorToast: true,
        showSuccessToast: true,
        successMessage: 'Thank you for your feedback!',
      }),
    validateOnMount: false,
  });

  return (
    <PageContainer headerTitle="General Feedback">
      <YStack className="grid grid-cols-2">
        <YStack className="col-span-full md:col-span-2 xl:col-span-1 gap-y-md">
          <Text type="p-sm">
            At Duckbill we care about giving you the best experience possible and appreciate any feedback you have for
            how we can serve you better.
          </Text>
          <YStack className="gap-y-md">
            <TextareaInput label="" {...getControl('feedback')} placeholder="Please let us know how we can improve." />
            <XStack>
              <Button
                disabled={!isValid || !dirty}
                isLoading={isLoading}
                label="Submit"
                onClick={submitForm}
                size="sm"
              />
            </XStack>
          </YStack>
        </YStack>
      </YStack>
    </PageContainer>
  );
};

export const Route = createFileRoute('/app/settings/feedback')({
  component: Feedback,
});
