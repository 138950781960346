import type { StrictOmit } from '@frontend/duck-tape';
import type { MouseEvent } from '@frontend/react';
import { Icon } from '@frontend/web-react';
import type { IconName } from '@frontend/web-react/components/Primitives/Icon/helpers';
import type { ClassName } from '@frontend/web-utils';
import { concatClasses, safeCss } from '@frontend/web-utils';
import type { SidebarItemColor } from '@frontend/web/components/Navigation/SidebarItems/types';
import type { AnchorProps as MAnchorProps } from '@mantine/core';
import { Anchor as MAnchor } from '@mantine/core';
import type { HTMLAttributeAnchorTarget } from 'react';

type SidebarAnchorProps = StrictOmit<MAnchorProps, 'className' | 'color'> & {
  className?: ClassName;
  color?: SidebarItemColor;
  href: string;
  isCollapsed?: boolean;
  label: string;
  leftIconName?: IconName;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  rightIconName?: IconName;
  target?: HTMLAttributeAnchorTarget;
};

export const SidebarAnchor = ({
  className,
  color = 'primary',
  isCollapsed = false,
  label,
  leftIconName,
  onClick,
  rightIconName,
  target = '_blank',
  ...props
}: SidebarAnchorProps) => {
  return (
    <MAnchor
      classNames={{
        root: concatClasses(getRootClassName({ color }), className),
      }}
      target={target}
      underline="never"
      {...props}
      onClick={onClick}
    >
      {leftIconName ? <Icon color="tertiary" name={leftIconName} /> : undefined}
      {isCollapsed ? null : label}
      {rightIconName ? <Icon color="tertiary" name={rightIconName} /> : undefined}
    </MAnchor>
  );
};

const getRootClassName = ({ color }: PickRequired<SidebarAnchorProps, 'color'>) => {
  const labelClassMap = {
    danger: safeCss('text-textError'),
    primary: safeCss('text-textTertiary'),
  };

  return concatClasses(
    safeCss('font-bold gap-x-[12px] hover-background-effect rounded-md p-sm text-h5 flex flex-row items-center'),
    labelClassMap[color],
  );
};
