export const flexShrinkGrow = {
  0: '0',
  1: '1',
  10: '10',
  11: '11',
  12: '12',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
};

export type FlexShrinkGrowNumber = keyof typeof flexShrinkGrow;
