export const onboardingUrl = 'https://calendly.com/d/ywz-b7f-q2r/duckbill-call';
export const appleAppStoreUrl = 'https://apps.apple.com/us/app/duckbill/id6455085338';
export const googlePlayStoreUrl = 'https://play.google.com/store/apps/details?id=com.getduckbill.duckbill&hl=en&gl=US';

export const termsUrl = 'https://www.getduckbill.com/terms';
export const privacyUrl = 'https://www.getduckbill.com/privacy';
export const contactUrl = 'mailto:support@getduckbill.com';
// Auto-scroll to faq isn't working, so copying a url with the highlighted text to force auto-scroll
export const faqUrl = 'https://www.getduckbill.com/#faq:~:text=Sign%20up-,FAQ,-What%20is%20Duckbill';
export const referralsUrl = 'https://referral.duckbill.ai/rnkEym/join';
