import type { ClientStatus } from '@frontend/api-types';
import { dateFormatters } from '@frontend/duck-tape';

export const evaluateClientQuota = (clientStatus: ClientStatus) => {
  const { currentActiveTasks, individualMaxActiveTasks, planMaxActiveTasks } = clientStatus;

  const exceedsPlanButWithIndividualLimit =
    currentActiveTasks !== null &&
    planMaxActiveTasks !== null &&
    individualMaxActiveTasks !== null &&
    currentActiveTasks >= planMaxActiveTasks &&
    currentActiveTasks < individualMaxActiveTasks;

  const exceedsPlanWithoutIndividualLimit =
    currentActiveTasks !== null &&
    planMaxActiveTasks !== null &&
    currentActiveTasks >= planMaxActiveTasks &&
    individualMaxActiveTasks === null;

  // This is the case where the user has exceeded both the plan limit and the individual limit
  //  is expected to be higher than the plan limit
  const exceedsBothPlanAndIndividualLimit =
    currentActiveTasks !== null &&
    individualMaxActiveTasks !== null &&
    planMaxActiveTasks !== null &&
    currentActiveTasks >= individualMaxActiveTasks &&
    individualMaxActiveTasks >= planMaxActiveTasks;

  const remainingTasks = () => {
    if (individualMaxActiveTasks && currentActiveTasks) {
      return individualMaxActiveTasks - currentActiveTasks;
    } else if (planMaxActiveTasks && currentActiveTasks) {
      return planMaxActiveTasks - currentActiveTasks;
    } else {
      return 0;
    }
  };

  return {
    exceedsBothPlanAndIndividualLimit,
    exceedsPlanButWithIndividualLimit,
    exceedsPlanWithoutIndividualLimit,
    isThrottled: exceedsBothPlanAndIndividualLimit || exceedsPlanWithoutIndividualLimit,
    remainingTasks: remainingTasks(),
  };
};

export const skipQuotaStep = (
  clientStatus: ClientStatus,
  isThrottlingEnabled: boolean,
  hideIndividualTaskQuotaStep: boolean,
) => {
  const quota = evaluateClientQuota(clientStatus);
  if (!isThrottlingEnabled) {
    return true;
  } else if (
    !quota.exceedsPlanWithoutIndividualLimit &&
    !quota.exceedsPlanButWithIndividualLimit &&
    !quota.exceedsBothPlanAndIndividualLimit
  ) {
    return true;
  } else if (quota.exceedsPlanButWithIndividualLimit && hideIndividualTaskQuotaStep) {
    return true;
  } else {
    return false;
  }
};

export const getQuotaContent = (clientStatus: ClientStatus, migrationDate: Date, plan: string) => {
  const quota = evaluateClientQuota(clientStatus);

  if (quota.exceedsPlanButWithIndividualLimit) {
    if (new Date() < migrationDate) {
      return {
        boldHighlight: `${dateFormatters['MMM D'](migrationDate)}`,
        description: `We're giving our members until ${dateFormatters['MMM D'](migrationDate)} to bring their number down to that limit by completing tasks. After that, any extra tasks will go into your drafts until you're ready to tackle them.`,
        // Hack to add <underline-marker> to the highlight to make in unique, in case there is another exact same number in the title (e.g currentActiveTasks = 5 and planMaxActiveTasks = 5)
        title: `Looks like you're juggling ${clientStatus.currentActiveTasks} active tasks — impressive! Unfortunately, your ${plan} plan only allows for ${clientStatus.planMaxActiveTasks}<underline-marker> active tasks in parallel`,
        titleHighlight: clientStatus.planMaxActiveTasks ? `${clientStatus.planMaxActiveTasks}<underline-marker>` : '',
      };
    }
    return {
      description:
        "In the future, we may decide to reduce you to your plan's default limit, but will give you a heads up first!",
      title: `We've increased your maximum number of active tasks to ${clientStatus.individualMaxActiveTasks}, although your plan only allows ${clientStatus.planMaxActiveTasks} active tasks at once.`,
      titleHighlight: clientStatus.individualMaxActiveTasks ? `${clientStatus.individualMaxActiveTasks},` : '',
    };
  } else if (quota.exceedsPlanWithoutIndividualLimit) {
    return {
      description: 'We’ll start working on it after you complete another task.',
      title: `Uh-oh. You've exceeded your plan's active task limit of ${clientStatus.planMaxActiveTasks}. This task will be added to your draft folder.`,
      titleHighlight: clientStatus.planMaxActiveTasks ? `${clientStatus.planMaxActiveTasks}.` : '',
    };
  } else if (quota.exceedsBothPlanAndIndividualLimit) {
    return {
      description:
        'We’ll start working on it after you complete another task. In the future, we may reduce your limit, but will give you a heads up first!',
      title: `Uh-oh! You've exceeded your plan's active task limit of ${clientStatus.planMaxActiveTasks}. No stress though: this new task will be added to your draft folder.`,
      titleHighlight: clientStatus.planMaxActiveTasks ? `${clientStatus.planMaxActiveTasks}.` : '',
    };
  } else {
    return { description: '', title: '', titleHighlight: '' };
  }
};
