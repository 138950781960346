import { createFileRoute, redirect } from '@tanstack/react-router';

// This route handles redirects for backwards compatibility purposes
export const Route = createFileRoute('/options/$optionSheetId/')({
  beforeLoad: ({ params }) => {
    throw redirect({
      params,
      to: '/app/option-sheet/$optionSheetId',
    });
  },
  component: () => <div />,
});
