import { append, isArray, remove } from '@frontend/duck-tape';
import { useState } from '@frontend/react';
import {
  CheckboxInput,
  Emoji,
  Icon,
  LinkButton,
  Text,
  TextInput,
  XStack,
  YStack,
  useShortcut,
} from '@frontend/web-react';
import type { MultiSelectAnswer } from '@frontend/web/context/CreateTaskContext/createTaskContext';
import { useAnalytics } from '@frontend/web/hooks';
import { useCreateTaskContext } from '@frontend/web/hooks/context/useCreateTaskContext';

export const InitialQuestions = () => {
  const { track } = useAnalytics();
  const {
    answers,
    createTaskState,
    decrementStep,
    incrementStep,
    initialQuestions,
    isOnLastStep,
    isStreaming,
    isSubmitting,
    onUpdateAnswer,
  } = useCreateTaskContext();
  // assumes this is always the last step so we don't need to store additional state in context to return to last question seen
  const [IQIndex, setIQIndex] = useState(0);
  const canSeeNextQuestion = IQIndex < initialQuestions.length;
  const currentQuestion = initialQuestions[IQIndex];
  const currentAnswer = answers[IQIndex];
  const isCurrentIQStreaming = isStreaming && IQIndex + 1 === answers.length;
  const canSubmit = IQIndex === answers.length - 1 && !isStreaming && isOnLastStep;

  const onClickChoice = (index: number, choice: string) => () => {
    onUpdateAnswer(index, (prev) => ({
      ...prev,
      answer: (prev.answer as MultiSelectAnswer[]).find((a) => a.id === choice)
        ? remove(prev.answer as MultiSelectAnswer[], (a) => a.id !== choice)
        : append(prev.answer as MultiSelectAnswer[], { id: choice, value: choice === 'Other' ? '' : choice }),
    }));
  };

  const onUpdateOtherFreeResponseChoice = (index: number) => (choiceText: string) => {
    onUpdateAnswer(index, (prev) => ({
      // Update the "Other" choice with the new text
      answer: (prev.answer as MultiSelectAnswer[]).map((a) =>
        a.id === 'Other'
          ? {
              id: 'Other',
              value: choiceText,
            }
          : a,
      ),
      choices: prev.choices,
      question: prev.question,
    }));
  };

  const onUpdateFreeResponseAnswer = (index: number) => (text: string) => {
    onUpdateAnswer(index, (prev) => ({ ...prev, answer: text }));
  };

  const onClickBack = () => {
    if (IQIndex === 0) {
      decrementStep();
      return;
    }

    setIQIndex(IQIndex - 1);
  };

  const onClickNext = () => {
    track({
      data: {
        aiGenerated: true,
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        question: initialQuestions[IQIndex]?.question!,
        taskDescription: createTaskState.taskData.description,
      },
      event: 'viewedTaskCreationQuestion',
      topic: 'tasks',
    });

    if (canSubmit) {
      incrementStep();
      return;
    }

    setIQIndex(IQIndex + 1);
  };

  useShortcut({
    Enter: {
      isDisabled: isCurrentIQStreaming,
      onSubmit: onClickNext,
    },
  });

  return (
    <YStack gapY="md">
      <XStack justifyContent="spaceBetween">
        {createTaskState.currentStep !== createTaskState.steps[0] || IQIndex ? (
          <Icon name="IconArrowLeft" onClick={onClickBack} size={18} />
        ) : null}
        {isStreaming ? null : (
          <LinkButton
            color="tertiary"
            disabled={isStreaming}
            label={`Skip questions${isOnLastStep ? ' and submit' : ''}`}
            leftIconName="IconPlayerSkipForward"
            onClick={incrementStep}
          />
        )}
      </XStack>
      <YStack gapY="md">
        <XStack justifyContent="spaceBetween">
          <YStack>
            <Text>
              <Emoji emoji=":sparkles:" /> {currentQuestion?.question}
            </Text>
          </YStack>
          <YStack>
            <Text color="tertiary" textAlign="end" type="caption">
              {IQIndex + 1}/{isStreaming ? '...' : initialQuestions.length}
            </Text>
          </YStack>
        </XStack>
        {isCurrentIQStreaming ? null : isArray(currentAnswer?.answer) ? (
          <YStack gapY="sm">
            {currentQuestion?.choices?.map((choice) => {
              // If "Other" is selected and it's being rendered
              const showOtherTextInput =
                isArray(currentAnswer.answer) &&
                currentAnswer.answer.find((a) => a.id === choice) &&
                choice === 'Other';
              const value = !!(currentAnswer.answer as MultiSelectAnswer[]).find((a) => a.id === choice);
              return (
                <YStack gapY="sm" key={choice}>
                  <CheckboxInput
                    disabled={isCurrentIQStreaming}
                    label={choice}
                    onChange={onClickChoice(IQIndex, choice)}
                    value={value}
                  />
                  {showOtherTextInput ? (
                    <TextInput
                      autoCapitalize="sentences"
                      autoFocus
                      disabled={isCurrentIQStreaming}
                      onChange={onUpdateOtherFreeResponseChoice(IQIndex)}
                      onSubmit={onClickNext}
                      value={(currentAnswer.answer as MultiSelectAnswer[]).find((a) => a.id === 'Other')?.value || ''}
                    />
                  ) : null}
                </YStack>
              );
            })}
          </YStack>
        ) : (
          <TextInput
            autoCapitalize="sentences"
            autoFocus
            disabled={isCurrentIQStreaming}
            onChange={onUpdateFreeResponseAnswer(IQIndex)}
            onSubmit={onClickNext}
            value={currentAnswer?.answer as string}
          />
        )}
      </YStack>
      <XStack justifyContent="end">
        <Icon
          color="primary"
          enableBackgroundColor
          isLoading={(isStreaming && !canSeeNextQuestion) || isCurrentIQStreaming || isSubmitting}
          name={canSubmit ? 'IconSend' : 'IconArrowRight'}
          onClick={onClickNext}
          rounded
          size="lg"
        />
      </XStack>
    </YStack>
  );
};
