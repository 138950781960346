import { buildCancellationURLParams, getMembershipPlanType } from '@frontend/api-client';
import { appStoreLinks } from '@frontend/constants';
import { useVisibilityController } from '@frontend/react/hooks';
import {
  Clickable,
  ConfirmationModal,
  Header,
  Icon,
  LinkButton,
  PageContainer,
  XStack,
  YStack,
  isAndroid,
  isIOS,
  isMobile,
} from '@frontend/web-react';
import { CreditCard, CreditCardModal, InviteHouseholdMemberModal, withStripe } from '@frontend/web/components';
import { MembershipPlanCard } from '@frontend/web/components/DomainSpecific/Onboarding/MembershipPlanCard';
import type { paymentCardBrands } from '@frontend/web/components/DomainSpecific/Payment';
import {
  useAnalytics,
  useAuthentication,
  useDeleteCardMutation,
  useListCardsQuery,
  useRetrieveClientQuery,
  useRetrieveHouseholdQuery,
  useUpdateDefaultCardMutation,
} from '@frontend/web/hooks';
import { Env, handleMutation } from '@frontend/web/utils';
import { createFileRoute } from '@tanstack/react-router';
import { useEffect, useMemo, useState } from 'react';

const Payment = withStripe(() => {
  const { data: client } = useRetrieveClientQuery();
  const { data: cards, isLoading: isCardsLoading } = useListCardsQuery();
  const [updateDefaultCard, { isLoading: isUpdateDefaultCardLoading }] = useUpdateDefaultCardMutation();
  const [deleteCard, { isLoading: isDeleteCardLoading }] = useDeleteCardMutation();
  const { data: household, isLoading: isHouseholdLoading } = useRetrieveHouseholdQuery(client?.household as string);
  const { track } = useAnalytics();
  const { logout } = useAuthentication();
  const [cardId, setCardId] = useState<string>();

  const planType = useMemo(() => (client ? getMembershipPlanType(client) : undefined), [client?.priceId]);
  const isInviteVisible = !!(client?.isHouseholdOwner && household && household.currentUsers < household.maxUsers);

  const [isCreateInviteModalOpen, { close: closeCreateInviteModal, open: openCreateInviteModal }] =
    useVisibilityController();
  const [isCreditCardFormModalOpen, { close: closeCreditCardFormModal, open: openCreditCardFormModal }] =
    useVisibilityController();

  const [isDeleteCreditCardModalOpen, { close: closeDeleteCreditCardModal, open: openDeleteCreditCardModal }] =
    useVisibilityController();

  const declinedCard = useMemo(() => cards?.find((card) => card.dateDeclined), [cards]);

  useEffect(() => {
    if (declinedCard) {
      setCardId(declinedCard.id);
      openCreditCardFormModal();
    }
  }, [declinedCard]);

  const handleUpdateDefaultCard = (id: string) => {
    setCardId(id);
    handleMutation({
      mutation: () => updateDefaultCard(id).unwrap(),
      onSuccess: () => setCardId(undefined),
      successMessage: 'Default card updated.',
    });
  };

  const handleDeleteCard = (id: string) => {
    setCardId(id);
    handleMutation({
      mutation: () => deleteCard(id).unwrap(),
      onSuccess: () => setCardId(undefined),
      successMessage: 'Card deleted.',
    });
  };

  const handleEditCard = (id: string) => {
    setCardId(id);
    openCreditCardFormModal();
  };

  const handleCancelSubscription = () => {
    if (!client) return;
    const url = `https://form.typeform.com/to/${Env.VITE_CANCELLATION_FORM_ID}#` + buildCancellationURLParams(client);
    window.open(url, '_blank');
  };

  const handleCloseCreditCardFormModal = () => {
    if (!declinedCard) {
      closeCreditCardFormModal();
      setCardId(undefined);
    }
  };

  const handleLogout = () => {
    track({ event: 'logout', topic: 'auth' });
    logout();
  };

  const handleOpenApp = () => {
    if (isIOS) {
      window.open(appStoreLinks.ios);
    } else if (isAndroid) {
      window.open(appStoreLinks.android);
    }
  };

  return (
    <PageContainer
      HeaderComponent={
        <XStack alignItems="center" justifyContent="spaceBetween">
          <Header title="Payment" titleSize="h4" />
          {isMobile ? (
            <LinkButton
              className="mr-lg"
              color="danger"
              label="Logout"
              leftIconName="IconLogout"
              onClick={handleLogout}
              type="h5"
            />
          ) : null}
        </XStack>
      }
      isLoading={isCardsLoading || isHouseholdLoading}
      isScrollable={false}
    >
      <YStack className="gap-lg grid grid-cols-4 grow-1">
        <YStack className="col-span-full xxl:col-span-2" gapY="lg">
          <XStack className="w-full items-center" gapX="md">
            {planType ? (
              <MembershipPlanCard
                className="flex-1"
                currentClientId={client?.id}
                hideBenefitDetails
                hidePrice
                household={household}
                isSelected={false}
                plan={planType}
                promoDetails={undefined}
                showCurrentUsers
                showOwner={!!household}
              />
            ) : null}
            {isInviteVisible ? <Icon name="IconUserPlus" onClick={openCreateInviteModal} /> : null}
          </XStack>
          <XStack justifyContent="end">
            <LinkButton
              label="Add new card"
              leftIconName="IconPlus"
              leftIconProps={{ size: 'md' }}
              onClick={openCreditCardFormModal}
              type="h6"
            />
          </XStack>
          <YStack className="gap-md">
            {cards?.map((card) => (
              <CreditCard
                brand={card.brand as keyof typeof paymentCardBrands}
                expiryDate={`${card.month}/${card.year}`}
                isDefault={card.isDefault}
                isDeleteCardLoading={!!(isDeleteCardLoading && cardId === card.id)}
                isMakeDefaultLoading={!!(isUpdateDefaultCardLoading && cardId === card.id)}
                key={card.id}
                lastFourDigits={card.lastFour}
                onDelete={() => {
                  setCardId(card.id);
                  openDeleteCreditCardModal();
                }}
                onEditCard={() => handleEditCard(card.id)}
                onMakeDefault={() => handleUpdateDefaultCard(card.id)}
              />
            ))}
          </YStack>
          <YStack className="items-start">
            <LinkButton color="tertiary" label="Cancel subscription" onClick={handleCancelSubscription} type="p-sm" />
          </YStack>
        </YStack>
      </YStack>
      {isMobile && (isIOS || isAndroid) ? (
        <YStack className="w-full items-center pb-md">
          <Clickable
            className="py-sm px-md bg-surfaceSecondary rounded-md shadow-three text-h6 text-textPrimary"
            onClick={handleOpenApp}
          >
            Open app
          </Clickable>
        </YStack>
      ) : null}
      {household ? (
        <InviteHouseholdMemberModal
          householdId={household.id}
          isOpen={isCreateInviteModalOpen}
          onClose={closeCreateInviteModal}
        />
      ) : null}
      <CreditCardModal
        cardId={cardId}
        declinedCard={cardId === declinedCard?.id}
        isOpen={isCreditCardFormModalOpen}
        onClose={handleCloseCreditCardFormModal}
      />
      <ConfirmationModal
        cancelLabel="Cancel"
        isOpen={isDeleteCreditCardModalOpen}
        onClickCancel={closeDeleteCreditCardModal}
        onClickSubmit={() => {
          handleDeleteCard(cardId!);
        }}
        onClose={closeDeleteCreditCardModal}
        submitLabel="Delete"
        subtitle="Are you sure you want to delete this payment method?"
        title="Delete payment method"
        variant="danger"
      />
    </PageContainer>
  );
});

export const Route = createFileRoute('/app/settings/payments')({
  component: Payment,
});
